.PaginationControlsComponent {
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;

  & > .page-indicator {
    flex-grow: 0;
    white-space: nowrap;
    align-self: center;

    &>div {
    	font-size: 15px;
    	line-height: 18px;
    	font-weight: 500;
    }
  }

  & > .page-selector {
    flex-grow: 1;

    ul.pagination,
    ul.pagination.mb-5 {
      padding-bottom: 0;
      margin-bottom: 0 !important;

      li.page-item,
      a.page-link {
        border-radius: 0.3rem;
        font-size: inherit;
        font-weight: 500;
      }

      li.current-page {
        background-color: rgba(0, 100, 255, 0.5);

        a {
          color: #444;
          font-weight: bold;
        }
      }

      li.no-click {
        cursor: not-allowed;

        a {
          cursor: not-allowed;

          &:hover {
            background-color: #fff;
          }
        }
      }
    }
  }
}
