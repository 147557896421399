@import './../../../../styles/global.variables';

#ReferralPartnerInput {
  position: relative;

  .modal-header {
    background-color: $color--usabg--blue;

    h4 {
      text-align: center;
      color: #fff;
      font-weight: bold;
      margin: auto;
    }
  }

  ul#referralSearchResults {
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    max-height: 200px;
    max-width: calc(100% - 16px);
    width: calc(100% - 16px);
    margin: 0 8px;
    transform: translateY(-8px);
    position: absolute;
    border: thin solid #d3d3d3;
    border-top: none;
    border-bottom-left-radius: 0.35rem;
    border-bottom-right-radius: 0.35rem;
    padding: 0;
    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.15),
      -5px 0 5px -5px rgba(0, 0, 0, 0.15), 0px 5px 5px -5px rgba(0, 0, 0, 0.15);

    transition-property: opacity, height, visibility;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;

    li {
      border-left: 3px solid transparent;
      transition-property: border-color, background-color;
      transition-timing-function: ease-in;
      transition-duration: 0.15s;
      padding: 1px 3px 1px 6px;
      cursor: pointer;

      &:nth-of-type(even) {
        background-color: rgba(0, 0, 0, 0.1);
      }

      &:hover {
        border-color: $color--usabg--blue;
        background-color: rgba(20, 60, 250, 0.1);
      }
    }

    &.open {
      z-index: 100;
      opacity: 1;
      visibility: visible;
      height: auto;
    }
  }

  div.collapse > div.alert {
    margin: 1rem;
    text-align: center;
  }

  .search-input {
    max-width: 100%;
    width: 100%;
    display: inline-block;
    margin: 0;
  }

  .search-btn {
    display: inline-block;
    width: 2rem;
    margin: 5px 0 0 0;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
  }

  &.can-create {
    .search-input {
      max-width: calc(100% - 2rem);
      width: calc(100% - 2rem);
    }

    .search-btn {
      width: 2rem;
    }
  }

  &.can-edit {
    .search-input {
      max-width: calc(100% - 4rem);
      width: calc(100% - 4rem);
    }

    .search-btn {
      width: 2rem;
    }
  }
}
