@import './../../../styles/global.variables';

.SpecRequireContractComponent {
  & > .alert {
    font-weight: 500;
    padding: 4px 2px;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 2px;
    width: calc(100% - 10px);
    margin-left: 5px;

    &.alert-danger {
      color: $color--invalid;
    }

    &.alert-success {
      color: $color--success;
    }
  }
}
