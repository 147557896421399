@import './../../../styles/global.variables';

#SaveContractingCartButtonComponent {
  & > .btn.start-checkout-btn {
    background-color: $color--success !important;
    font-size: 1.25rem;

    i.fa {
      transition: width, opacity 0.2s ease-in-out;
      width: 1.5rem;
      opacity: 1;
    }

    &[disabled] {
      i.fa {
        overflow: hidden;
        width: 0;
        opacity: 0;
      }
    }
  }
}
