#CurrentTopPipBATeamsComponent {
  .top-5-pip-agents {
    padding: 0;

    h4.card-header {
      font-weight: 500;
    }

    .card-body {
      .agent-name,
      .agent-points {
        text-align: center;

        span {
          font-weight: 500;
          margin: auto;
        }
      }

      .team-logo-img-wrapper {
        overflow: hidden;
        margin: auto;
      }
    }
  }

  @media screen and (max-width: 575px) {
    .top-5-pip-agents {
      padding: 0;

      .desktop-card {
        display: none;
      }

      .card-body {
        & > .row > div {
          border-bottom: 1px solid rgb(220, 220, 220);
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        .agent-name,
        .agent-points {
          span {
            font-size: 1.25rem;
          }
        }

        .team-logo-img-wrapper {
          width: 120px;
        }

        .agent-jewel-img {
          margin-top: 10px;
        }
      }
    }
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    .top-5-pip-agents {
      padding: 0;
      margin-left: 10%;
      margin-right: 10%;
      width: calc(100% - 20%);

      .desktop-card {
        display: none;
      }

      .card-body {
        & > .row > div {
          border-bottom: 1px solid rgb(220, 220, 220);
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        .agent-name {
          height: 4rem;

          span {
            font-size: 1.25rem;
          }
        }

        .team-logo-img-wrapper {
          width: 150px;
        }

        .agent-points {
          height: 2rem;

          span {
            font-size: 1.25rem;
          }
        }

        .agent-jewel-img {
          margin-top: 10px;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .top-5-pip-agents {
      padding: 0;

      .mobile-card {
        display: none;
      }

      .card-body {
        .agent-name {
          height: 4rem;

          span {
            font-size: 1.25rem;
          }
        }

        .team-logo-img-wrapper {
          width: 113px;
        }

        .agent-points {
          margin-top: 10px;
          height: 4rem;

          span {
            font-size: 1.25rem;
          }
        }

        .agent-jewel-img {
          margin-top: 10px;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .top-5-pip-agents {
      padding: 0;

      .mobile-card {
        display: none;
      }

      .card-body {
        .agent-name {
          height: 4rem;

          span {
            font-size: 1.25rem;
          }
        }

        .team-logo-img-wrapper {
          width: 150px;
        }

        .agent-points {
          margin-top: 10px;
          height: 4rem;

          span {
            font-size: 1.25rem;
          }
        }

        .agent-jewel-img {
          margin-top: 10px;
        }
      }
    }
  }

  @media screen and (min-width: 1100px) {
    .top-5-pip-agents {
      .card-body {
        .agent-points {
          height: 2rem;
        }
      }
    }
  }

  @media screen and (min-width: 1300px) {
    .top-5-pip-agents {
      .card-body {
        .agent-points {
          height: 4rem;
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .top-5-pip-agents {
      .card-body {
        .agent-points {
          height: 2rem;
        }
      }
    }
  }
}
