@import './../../../../styles/global.variables';
@import './../../../../styles/global.fonts';

#IntakeFormIdentityComponent {
	div.ui-input {
		position: relative;

		.show.tooltip {
			opacity: 1;

			.email-exists-table {
				border: thick solid transparent;
				table-layout: fixed;
				width: 100%;

				&>thead {
					&>tr {
						th {
							background-color: $color--invalid;
							text-align: center;
							color: #FFF;
						}
					}
				}
				&>tbody {
					background-color: #FFF;
					&>tr {
						color: #000;
						td + td {
							padding-left: 5px;
						}
						td[colspan='2'] {
							text-align: center;
						}
						&+tr {
							border-top: 1px solid rgba(210,210,210,0.25)
						}
					}
				}
			}
		}
	}
}
