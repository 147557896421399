#AccessManagementComponent {
  .access-toggle {
    width: 100%;
    border-bottom: 1px solid #dddddd;

    &:last-child {
      border-bottom: none;
    }

    .access-toggle-label {
      width: calc(100% - 60px);

      .access-toggle-description {
        font-size: 0.8rem;
      }
    }

    div.switch {
      & > label {
        display: flex;
        font-size: 1rem;
        font-weight: 500;
        align-items: center;
        justify-content: space-between;
        margin: 0.5rem 0;

        .lever {
          margin: 0rem;
          margin-right: 0rem;
        }
      }
    }
  }

  .loading-spinner {
    .LoadingSpinnerComponent {
      position: relative;
      .loading-wrapper {
        transform: none;
      }
    }
  }
}
