@import 'src/styles/global.variables';

.BATeamFormComponent {
  .ba-team-img-wrapper {
    flex: 0 0 100%;
    max-width: 300px;
    margin: auto;

    img {
      max-width: 100%;
    }
  }

  .row > .col > div {
    margin-bottom: 1rem;
  }

  button.btn[type='button'] {
    background-color: $color--usabg--blue !important;
  }

  button.btn[type='submit'] {
    background-color: $color--success !important;
  }
}
