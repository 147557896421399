#ManageAgentsPage {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;

  h2 {
    margin: 0;
    margin-top: 10px;
  }

  .content-wrapper {
    overflow-x: auto;
    min-height: 10rem;
  }

  .usertype-toggle {
    font-weight: 600;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    display: flex;

    span:nth-of-type(1) {
      flex: 0 0 calc(100% - 100px);
      max-width: calc(100% - 100px);
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    span:nth-of-type(2) {
      flex: 0 0 100px;
      max-width: 100px;
    }

    &:after {
      right: 10px;
      top: calc(50% - 3px);
      position: absolute;
    }
  }

  .dropdown-menu {
    padding: 0;
    width: 100%;
    transform: unset !important;

    button.dropdown-item {
      span {
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:not(:hover):nth-of-type(even) {
        background-color: rgba(0, 0, 0, 0.075);
      }
    }
  }

  .usertype-dropdown {
    .dropdown-menu {
      button.dropdown-item {
        display: flex;

        span:nth-of-type(1) {
          flex: 0 0 calc(100% - 50px);
          max-width: calc(100% - 50px);
        }

        span:nth-of-type(2) {
          text-align: right;
          flex: 0 0 50px;
          max-width: 50px;
        }
      }
    }
  }

  #AgentRowSorter,
  .AgentManagerRow {
    .col {
      &.agent-profile {
        flex: 0 0 12%;
        max-width: 12%;
      }

      &.agent-upline {
        flex: 0 0 10%;
        max-width: 10%;
      }

      &.agent-enrollment-date {
        flex: 0 0 10%;
        max-width: 10%;
      }

      &.agent-contracts {
        flex: 0 0 10%;
        max-width: 10%;
      }

      &.agent-trainings {
        flex: 0 0 10%;
        max-width: 10%;
      }

      &.agent-kpis {
        flex: 0 0 8%;
        max-width: 8%;
      }

      &.agent-usertypes {
        flex: 0 0 14%;
        max-width: 14%;
      }

      &.agent-notes {
        flex: 0 0 8%;
        max-width: 8%;
      }

      &.agent-history {
        flex: 0 0 8%;
        max-width: 8%;
      }

      &.agent-usertype-assignment {
        flex: 0 0 10%;
        max-width: 10%;
      }
    }

    &:nth-of-type(even) {
      .col {
        background-color: rgb(245, 245, 245);
      }
    }
  }
}
.agent-select {
  &.form-control {
    height: calc(2em + 0.75rem + 2px);
    background-color: #3f51b5;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
  }
}
