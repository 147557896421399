@import './../../styles/global.variables';

#KpiDashboardPage {
  padding-bottom: 5rem;

  .kpi-report-proto-wrapper {
    background: #fff;

    .kpi-demo-report {
      width: 100%;
      margin-top: 3rem;

      & + .kpi-demo-report {
        margin-top: 0;
      }

      thead {
        background-color: rgba(180, 180, 180, 0.8);
      }

      tbody {
        background-color: #fff;

        tr + tr {
          td {
            border-top: 1px solid rgb(220, 220, 220);
          }
        }
      }
    }

    div.dataTables_wrapper {
      & > .row:first-of-type {
        margin: 15px;
      }
    }

    table.dataTable {
      thead {
        border-top: 1px solid rgb(220, 220, 220);
      }

      td + td,
      th + th {
        border-left: 1px dotted rgba(0, 20, 120, 0.25);
      }
    }
  }
}
