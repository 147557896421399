
#EditEmailNotificationPage {
	.form-row {
		margin-right: 0px;
		margin-left: 0px;
	}

	.merge-field-legend {
		border: 1px solid rgb(180,180,180);
		td {
			padding: 2px 3px;
			border-bottom: 1px solid rgba(180,180,180,0.25);
		}

		thead {
			background-color: rgb(180,180,180);
		}
		tbody {
			tr:nth-of-type(even) {
				background-color: rgba(180,180,180,0.1);
			}
			td:first-of-type {
				font-weight: 500;
				padding-right: 5px;
			}
		}
	}
}