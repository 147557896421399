@import 'src/styles/global.variables';

#TransactionHistoryComponent {
  .agent-ledger-how-to {
    flex-basis: calc(100% - 30px);
    padding: 0 15px;

    .ContentHtmlComponent {
      .content-html-inner-wrapper {
        padding: 2px 10px;
      }

      & + hr {
        margin: 0;
      }
    }
  }

  ul.transactions-list {
    padding: 0 0 0.5rem 0;
    margin: 0;
    list-style-type: none;
    width: 100%;
    overflow-x: scroll;

    & > li {
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;

      &:first-of-type {
        font-weight: 500;
        color: #666;
      }

      &.trans-expire {
        font-weight: 500;
      }

      &:not(.trans-expire) {
        &:first-of-type {
          background-color: rgba(240, 240, 240, 0.75);
        }

        &:nth-of-type(even) {
          background-color: rgba(0, 225, 225, 0.1);
        }

        & + li {
          border-top: 1px solid rgba(240, 240, 240, 0.75);
        }
      }

      div {
        padding: 2px 0;
        font-size: 0.8rem;
        font-weight: 400;
        transition-property: opacity, visibility, flex, max-width, height;
        transition-timing-function: ease-in-out;
        transition-duration: 0.25s;
      }
    }

    &.agent-list {
      margin-bottom: 0;

      li {
        @media screen and (max-width: 1199px) {
          width: 64rem;
          max-width: 64rem;
        }
      }

      div.al-id {
        padding-left: 2px;
        flex: 0 0 calc(3rem - 2px);
        max-width: calc(3rem - 2px);
      }

      div.al-category {
        flex: 0 0 calc(35% - 16rem);
        max-width: calc(35% - 16rem);

        @media screen and (max-width: 1199px) {
          flex: 0 0 8rem;
          max-width: 8rem;
        }
      }

      div.al-type {
        flex: 0 0 calc(30% - 14rem);
        max-width: calc(30% - 14rem);

        @media screen and (max-width: 1199px) {
          flex: 0 0 8rem;
          max-width: 8rem;
        }
      }

      div.al-source {
        flex: 0 0 calc(35% - 15rem);
        max-width: calc(35% - 15rem);

        @media screen and (max-width: 1199px) {
          flex: 0 0 5rem;
          max-width: 5rem;
        }
      }

      div.al-purpose {
        flex: 0 0 14rem;
        max-width: 14rem;
      }

      div.al-amount {
        flex: 0 0 7rem;
        max-width: 7rem;

        &.is-debit {
          color: $color--red;
        }
      }

      div.al-balance {
        flex: 0 0 7rem;
        max-width: 7rem;

        &.is-debit {
          color: $color--red;
        }
      }

      div.al-date {
        flex: 0 0 7rem;
        max-width: 7rem;
      }

      div.al-btns {
        flex: 0 0 6rem;
        max-width: 6rem;
      }
    }

    &.admin-list {
      li {
        @media screen and (max-width: 1599px) {
          width: 63rem;
          max-width: 63rem;
        }
      }

      div.al-id {
        padding-left: 2px;
        flex: 0 0 calc(3rem - 2px);
        max-width: calc(3rem - 2px);
      }

      div.al-category {
        flex: 0 0 calc(35% - 16rem);
        max-width: calc(35% - 16rem);

        @media screen and (max-width: 1599px) {
          flex: 0 0 7rem;
          max-width: 7rem;
        }
      }

      div.al-type {
        flex: 0 0 calc(30% - 14rem);
        max-width: calc(30% - 14rem);

        @media screen and (max-width: 1599px) {
          flex: 0 0 6rem;
          max-width: 6rem;
        }
      }

      div.al-source {
        flex: 0 0 calc(35% - 15rem);
        max-width: calc(35% - 15rem);

        @media screen and (max-width: 1599px) {
          flex: 0 0 5rem;
          max-width: 5rem;
        }
      }

      div.al-amount {
        flex: 0 0 7rem;
        max-width: 7rem;

        &.is-credit {
        }

        &.is-debit {
          color: $color--red;
        }
      }

      div.al-balance {
        flex: 0 0 7rem;
        max-width: 7rem;

        &.is-debit {
          color: $color--red;
        }
      }

      div.al-purpose {
        flex: 0 0 11rem;
        max-width: 11rem;
      }

      div.al-date {
        flex: 0 0 7rem;
        max-width: 7rem;
      }

      div.al-btns {
        flex: 0 0 5rem;
        max-width: 5rem;
        display: flex;

        .alert {
          width: 100%;
          text-align: center;
          font-weight: 500;
        }

        button.btn {
          padding: 0;
          height: 100%;
          flex: 0 0 calc(100% - 2px);
          max-width: calc(100% - 2px);
          align-items: center;
          justify-content: center;
          margin: 1px 2px 0 0;
          background-color: $color--blue !important;
        }
      }
    }
  }

  div.pagination-wrapper {
    padding: 0.5rem;
    background-color: rgba(240, 240, 240, 0.5);
    width: 100%;

    .PaginationControlsComponent {
      & > .col {
        flex: 0 0 100%;
        max-width: 100%;

        &.page-indicator > div,
        & > ul.justify-content-end {
          text-align: center;
          justify-content: center !important;
        }
      }
    }
  }
}
