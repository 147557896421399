@import 'src/styles/global.variables';

#BackofficeSearchPage {
  .content-wrapper {
    position: relative;
    min-height: 30vh;

    .search-loading-spinner {
      position: absolute;
      height: 0;
      width: calc(100% - 2rem);
      visibility: hidden;
      opacity: 0;
      transition-property: opacity, height, visibility;
      transition-timing-function: ease-in-out;
      transition-duration: 0.3s;
    }

    .search-results {
      visibility: visible;
      opacity: 1;
      height: auto;
      transition-property: opacity, height, visibility;
      transition-timing-function: ease-in-out;
      transition-duration: 0.3s;
    }

    &.is-loading {
      .search-loading-spinner {
        visibility: visible;
        opacity: 1;
        height: calc(100% - 2rem);
      }

      .search-results {
        visibility: hidden;
        opacity: 0;
        height: 0;
      }
    }
  }

  // Updated Datatable Layout.
  // Consider using w/ DataTable update task.
  // Or, Create a component wrapper.
  .usabg-datatable.dataTables_wrapper.dt-bootstrap4 {
  	&>.row:nth-of-type(1) {
  		background-color: $color--gray--md-1;
  		margin-left: 0;
  		margin-right: 0;
  		border: 1px solid $color--gray--md-3;
  		border-top-left-radius: 0.4rem;
  		border-top-right-radius: 0.4rem;
  		border-bottom: none;
  		padding-top: 3px;

      .dataTables_length.d-flex.flex-row {
      	label.mt-4 {
      		margin-top: 0 !important;
      		margin-bottom: 0 !important;
    			font-size: 0.9rem;
    			font-weight: 500;
      	}
      	div.select-wrapper {
      		span.caret {
      			margin-top: 0;
      			margin-right: 3px;
      			color: $color--gray--dk-1;
      			z-index: 100;
      		}
	      	input.select-dropdown {
	      		margin: 0;
	      		background-color: rgba(255,255,255,0.85);
	      		padding-left: 3px;
	      		padding-right: 3px;
	      		border: thin solid $color--gray--md-1;
	      		border-radius: 0.25rem;
	      	}
      	}
      }

      .dataTables_filter.md-form.flex-row {
      	margin: 0;

      	input.form-control {
      		margin-bottom: 0;
      		background-color: rgba(255,255,255,0.85);
      		padding-left: 3px;
      		padding-right: 3px;
      		border: thin solid $color--gray--md-1;
      		border-radius: 0.25rem;
      	}
      }
  	}

  	&>.row:nth-of-type(2) {
      .table-responsive {
      	border-left: 1px solid $color--gray--md-3;
      	border-right: 1px solid $color--gray--md-3;

      	&>table {
      		margin: 0;

      		tbody > tr > td {
      			font-weight: 400;

	      		&:first-of-type {
	      			font-weight: 500;
	      		}
      		}
      	}
      }
  	}

  	&>.row:nth-of-type(3) {
  		background-color: $color--gray--md-1;
  		margin-left: 0;
  		margin-right: 0;
  		border: 1px solid $color--gray--md-3;
  		border-bottom-left-radius: 0.4rem;
  		border-bottom-right-radius: 0.4rem;
  		border-top: none;

  		div.dataTables_info,
  		div.dataTables_paginate {
  			padding: 5px;

    		ul.pagination {
    			margin-bottom: 0;
    		}
  		}

  		div.dataTables_info {
  			padding-top: 10px;
  			font-size: 0.9rem;
  			font-weight: 500;
  		}
  	}
  }
}

.SearchResultsComponent {
  .results-description {
  	h5 {
  		font-weight: 600;
  		color: $color--usabg--blue;
  	}
  	p {
  		font-weight: 500;
  		font-size: 0.8rem;
  	}
  }

  table > tbody > tr > td > a {
  	color: blue;

  	&:hover {
  		text-decoration: underline;
  	}
  }

  .alert.user-active {
		margin: 0;
		padding: 2px;
		text-align: center;
		font-weight: bold;
  }

	.alert.alert-warning.no-rows {
		margin: 0;
		text-align: center;
	}

	&+.SearchResultsComponent {
		margin-top: 50px;
	}
}
