@import './../../../../styles/global.variables';

#ClientGroupManagerComponent {
  width: 100%;
  border: 1px solid transparent;
  border-radius: 0.3rem;
  margin-bottom: 1rem;

  .manager-wrapper {
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 1px solid rgb(206, 212, 218);

    .group-selector {
      display: flex;
      flex-grow: 1;

      .ui-input {
        margin: 0;
        padding: 0;

        input {
          margin: 0;
          border: none;
          padding-left: 0;
          font-weight: 300;
          height: 2.5rem;
        }
      }
    }

    .group-creator {
      display: flex;
      flex-grow: 1;

      .ui-input {
        margin: 0;
        padding: 0;
        width: 100%;

        & > .md-form {
          input {
            margin: 0;
            border: none;
            padding-left: 0;
            font-weight: 300;
            height: 2.5rem;
          }
          label {
            &:not(.active) {
              transform: translateY(0.5rem);
            }
          }
        }
      }
    }

    .controls {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 6rem;

      .btn {
        padding: 5px !important;
        border: none !important;
        border-radius: 0.25rem;
        height: 1.5rem;
        font-size: 10px;
        box-shadow: none;
      }

      .add-group-btn.btn {
        background-color: $color--success !important;
        width: 6rem;
      }

      .save-group-btn.btn,
      .clear-group-btn.btn {
        width: 4rem;
      }

      .save-group-btn.btn {
        background-color: $color--success !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .clear-group-btn.btn {
        background-color: $color--invalid !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.searching {
      .group-creator {
        display: none;
      }
    }

    &.creating {
      .group-selector {
        display: none;
      }
    }
  }

  &:not(.is-selected) {
    // border: thick solid red;
    background-color: rgba(255, 40, 40, 0.15);
  }

  &.is-selected {
    // border: thick solid blue;
  }
}
