@import 'src/styles/global.variables';

.TermHistoryReportComponent {
  padding: 0;

  .card-header {
    background-color: $color--usabg--blue !important;
    color: #fff;
    font-weight: 500;
  }

  table.table th:nth-of-type(4),
  table.table th:nth-of-type(5),
  table.table td:nth-of-type(4),
  table.table td:nth-of-type(5) {
    text-align: center;
  }

  table.table th:nth-of-type(5) {
    &:before,
    &:after {
    	display: none;
    }
  }

  table.table td:nth-of-type(5) {
    width: 50px;
  }

  .view-btn {
    font-size: 1.5rem;
    padding: 0;
    background: transparent;
    color: $color--usabg--blue;
    margin: auto;
    display: inline-block;
    font-size: 0.8rem;
    font-weight: 500;
  }
}
