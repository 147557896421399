@import './../../styles/global.variables';

#WelcomePage {
  .card {
    .card-header {
      background-color: $color--usabg--blue;
    }
  }
}

.item-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
