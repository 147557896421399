.MilestoneWidget {
  height: 150px;

  &.awarded {
    background-color: rgba(255, 215, 0, 0.25);

    table {
      tbody {
        tr {
          td:first-of-type {
            & > div {
              &:nth-child(2) {
                color: white;
                text-shadow: 0px 0px 5px #000000, 0px 0px 2px #000000;
              }
            }
          }
        }
      }
    }
  }

  table {
    width: 100%;

    tbody {
      tr {
        td:first-of-type {
          text-align: left;
          width: 60%;

          & > div {
            font-weight: bold;
            font-size: 18px;

            &:last-of-type {
              font-size: 16px;
            }
          }
        }

        td:last-of-type {
          text-align: center;
          width: 40%;

          .img-wrapper {
            height: 120px;
            width: 90px;
            border: 1px solid #cbcbcb;
            border-radius: 50% !important;
            overflow: hidden;
            box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
