@import './../../../../styles/global.variables';

#TrainingValidator {
  .card-header {
    .ContentHtmlComponent {
      color: black;
    }
    &.usabg-university-logo > img {
      max-width: 100%;
    }
  }
  .card-body {
    // padding-top: 0;

    .md-form {
      &:first-of-type {
        margin-top: 0;
      }
    }

    .form-group {
      position: relative;
    }

    .error-msg {
      position: absolute;
      transform: translateY(-75%);
      right: 5px;
      color: $color--invalid;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 0.5rem;
      float: right;
      height: 1.5rem;
    }

    .submit-btn {
      .error-msg {
        transform: translateY(10px);
        right: 40px;
        text-align: center;
        width: calc(100% - 80px);
      }
    }
  }
}
