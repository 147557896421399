@import './../../../../styles/global.variables';

#CarrierDetailsFormComponent {
  form > .row {
    & > .col-4,
    & > .col-12 {
      position: relative;

      .md-form {
        margin-bottom: 0;
      }

      .fmo-remove-btn {
        flex: 0 0 0;
        max-width: 0;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
      }

      &.carrier-selected {
        display: flex;

        & > div:first-of-type {
          flex: 0 0 calc(100% - 2rem);
          max-width: calc(100% - 2rem);
        }

        .fmo-remove-btn {
          flex: 0 0 2rem;
          max-width: 2rem;
          visibility: visible;
          opacity: 1;
          align-items: center;
          display: flex;

          button {
            margin: auto;
            text-align: center;
            border: thin solid transparent;
            padding: 2px 5px;
            border-radius: 2px;
            transition-property: color, background-color;
            transition-duration: 0.2s;
            transition-timing-function: ease-in-out;
            background-color: transparent;
            cursor: pointer;

            &:hover {
              background-color: rgba(220, 220, 220, 0.8) !important;
            }
          }
        }
      }

      &.carrier-detail-toggle {
        div.switch {
          & > label {
            display: flex;
            font-size: 0.8rem;
            font-weight: 500;
            align-items: center;
            justify-content: space-between;

            .lever {
              margin: 0rem;
              margin-right: 0rem;
            }
          }
        }
      }
    }
  }

  .img-wrapper {
    position: relative;
    padding: 0;
    margin: 0;
    margin-bottom: 15px;

    .delete-btn {
      position: absolute;
      z-index: 50;
      top: 0;
      right: 0;
      border: thin solid transparent;
      padding: 2px 5px;
      border-radius: 2px;
      transition-property: color, background-color;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      color: #666;
      cursor: pointer;

      &:hover {
        background-color: $color--invalid;
        color: #fff;
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      display: block;
    }

    small {
      color: #757575;
      font-family: 'Roboto';
      font-weight: 300;
    }
  }
}
