@import 'src/styles/global.variables';

#TermsReviewerComponent {
  position: relative;
  .terms-loading-wrapper {
    &.is-loading {
      min-height: 50vh;
    }
  }

  .accept-and-sign {
    align-items: center;

    & > div {
      &:nth-of-type(1) {
        font-size: 1.125rem;
        font-weight: 500;
      }
      &:nth-of-type(2) {
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          font-weight: 400;
        }

        button.btn {
          color: #fff;
          background-color: $color--usabg--blue !important;
        }
      }
    }
  }

  iframe {
    margin-top: 2rem;
  }
}
