
#LeaderboardsPage {
	padding-top: 2rem;

	// &>.container-fluid {
	// 	border: thin solid aqua;
	// 	&>.row:not(:first-of-type) {
	// 		border: thin solid red;
	// 		&>div {
	// 			border: thin solid purple;
	// 			padding: 0;
	// 		}
	// 	}
	// }
}