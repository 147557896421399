@import 'src/styles/global.variables';

#RefEditFormComponent {
  .card-header {
    background-color: $color--usabg--blue;
    color: #fff;

    & > button {
      position: absolute;
      right: 8px;
      background-color: transparent;
      border: none;
      color: #fff;
    }
  }

  .card-body {
    & > .row {
      & > div.col-12 {
        margin-bottom: 1rem;

        div.ui-input-validation {
          span {
            font-size: 0.8rem;
            font-weight: 500;
          }
          span.success-msg {
            color: $color--green;
          }
          span.error-msg {
            color: $color--invalid;
          }
        }
      }

      button.btn[type='submit'] {
        background-color: $color--green !important;
      }

      button.btn[type='button'] {
        background-color: $color--blue !important;
      }
    }
  }
}
