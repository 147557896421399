@import 'src/styles/global.variables';

#MessageViewerComponent {
  .card {
    border-color: #d32f2f;
    border-width: 2px;
    padding: 0;

    .card-header {
      background-color: #d32f2f;
      color: #fff;
      position: relative;

      h5 {
        font-weight: 600;
        margin: 0;
      }

      .message-edit {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
      }
    }

    .card-body {
      padding: 0;

      .body-content {
        padding: 1rem;
      }

      .controls {
        margin: 0;

        & > div {
          background-color: rgba(0, 0, 0, 0.03);
          border-top: 1px solid rgba(0, 0, 0, 0.125);
        }

        .creator {
          table {
            tbody {
              tr {
                td {
                  padding-left: 1rem;
                }
              }
            }
          }
        }

        button.btn {
          float: right;
        }
      }
    }

    .card-footer {
      box-shadow: inset 5px 5px 5px -5px rgba(0, 0, 0, 0.15);
      padding: 0;

      .historical-msg {
        border: thick solid orange;
        margin: 1rem;
        max-width: calc(100% - 4rem);
        margin-left: 2rem;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.3rem;

        & > h5 {
          border-bottom: thin solid rgba(0, 0, 0, 0.125);
          margin-top: 0.5rem;
          font-weight: 500;
        }
      }

      .pagination-wrapper {
        padding: 0 0.5rem;
      }
    }
  }

  &.bg-sky-blue {
    .card .card-header {
      background-color: $color--sky-blue;
    }

    .btn.view-more-btn {
      background-color: $color--sky-blue !important;
    }
  }
}
