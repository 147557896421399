@import './../../../styles/global.variables';

#OnboardingCartListComponent {
  padding: 0;
  margin: 0;

  .ContentHtmlComponent > .content-html-inner-wrapper {
    padding: 1rem 0;

    div {
      font-size: 0.9rem;
      font-weight: 400;
      font-family: 'Roboto';

      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  ul.contract-cart-item-list {
    padding: 0;
    margin: 0;
    border: 1px solid rgba(220, 220, 200, 0.5);
    border-radius: 0.3rem;

    & > li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > div.cart-item {
        flex-grow: 1;
        text-align: left;
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        transition-property: opacity, flex, width, max-width;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;

        &-profit {
          flex: 0 0 4rem;
          max-width: 4rem;
        }

        &-carrier {
          flex: 0 0 calc(50% - 4rem);
          max-width: calc(50% - 4rem);

          small {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        &-description {
          flex: 0 0 50%;
          max-width: 50%;
        }

        &-toggle {
        	padding: 0 3px;
        	text-align: center;
          visibility: hidden;
          opacity: 0;

        	&>button.btn {
        		padding: 0;
        		background-color: transparent !important;
        		border: 0;
        		box-shadow: none;
        		color: $color--gray--dk-1;
        		font-size: 1rem;
        		position: relative;

        		&>div.selected,
        		&>div.unselected {
        			height: 1rem;
        			width: 1rem;
        			position: relative;
        		}

        		&>div.unselected {
        			&>.fa,
        			&>.far {
        				position: absolute;
        				display: block;
        				transition: opacity 0.15s ease-in-out;

	        			&.fa {
	        				opacity: 0;
	        			}
	        			&.far {
	        				opacity: 1;
	        			}
        			}
        		}

        		&:not(.active) {
        			&>div.selected {
        				display: none;
        			}

	        		&:hover {
	        			color: $color--usabg--blue;

		        		&>div{
		        			&>.fa {
		        				opacity: 1;
		        			}
		        			&>.far {
		        				opacity: 0;
		        			}
		        		}
	        		}
        		}

        		&.active {
        			&>div.unselected {
        				display: none;
        			}
        		}
        	}
        }

        &-toggle,
        &-external {
          max-width: 0;
          overflow: hidden;

          div.replace-carrier {
            padding: 0;
            margin: 0;
            text-align: center;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            font-size: 0.85rem;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            transition-property: color, background-color, border-color;
            transition-duration: 0.2s;
            transition-timing-function: ease-in-out;

            & > div:first-of-type {
              flex: 0 0 0;
              max-width: 0;
              margin-right: 0;
              opacity: 0;
              overflow: hidden;
              transition-property: flex, max-width, margin-right, opacity;
              transition-duration: 0.2s;
              transition-timing-function: ease-in-out;
            }
          }

          &.show-warning {
            div.replace-carrier {
              padding: 0;
              opacity: 1;
              color: #721c24;
              background-color: #f8d7da;
              border-color: #f5c6cb;

              & > div:first-of-type {
                flex: 0 0 1.125rem;
                max-width: 1.125rem;
                margin-right: 5px;
                opacity: 1;
              }
            }
          }

          .alert {
            background-color: rgba(209, 236, 241, 0.25);
            border-color: rgba(190, 229, 235, 0.5);
            padding: 0;
            margin: 0;
            width: 6.25rem;

            table {
              margin: 0 2px;
              padding: 0;
            }
            table tbody tr td {
              font-size: 0.75rem;
              font-weight: 500;
              text-align: center;
              vertical-align: middle;
              line-height: 0.8rem;
              opacity: 0.85;
            }
          }
        }
      }

      &:nth-of-type(1) {
        background-color: #29733a;
        border-color-bottom: #29733a;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;

        & > div.cart-item {
          font-weight: 600;
          padding: 2px;
          font-size:1.125rem;
          color: #FFF;

          span {
            font-size: 0.7rem;
            font-weight: 400;
          }

          small {
            font-weight: 500;
            display: block;
          }
        }
      }

      &:nth-of-type(2) {
        background-color: rgba(220, 220, 200, 0.35);
        font-weight: 500;
        font-size: 0.9rem;
      }

      &:not(:nth-of-type(1)):not(:nth-of-type(2)) {
        &.is-external-carrier {
          background-color: rgba(220, 220, 200, 0.45);
        }

        &.is-selectable-carrier {
        	// border: thick solid red;
        }

        & > div.cart-item-profit {
          font-weight: 600;
          font-size: 1rem;
        }
      }

      & + li {
        border-top: 1px dotted rgba(0, 25, 125, 0.2);
      }
    }

    &.show-toggle,
    &.show-external {
      & > li {
        & > div.cart-item {
          transition-property: opacity, flex, width, max-width;
          transition-duration: 0.2s;
          transition-timing-function: ease-in-out;
        }
      }
    }

    &.show-toggle {
      & > li {
        & > div.cart-item {
          &-carrier {
            flex: 0 0 calc(50% - 5rem);
            max-width: calc(50% - 5rem);
          }

          &-description {
            flex: 0 0 calc(50% - 3rem);
            max-width: calc(50% - 3rem);
          }

          &-toggle {
            max-width: 4rem;
            visibility: visible;
            opacity: 1;

            div.replace-carrier {
              padding: 0;
            }
          }
        }
      }
    }

    &.show-external {
      & > li {
        & > div.cart-item {
          &-carrier {
            flex: 0 0 calc(50% - 8rem);
            max-width: calc(50% - 8rem);
          }

          &-description {
            flex: 0 0 calc(50% - 3rem);
            max-width: calc(50% - 3rem);
          }

          &-external {
            max-width: 7rem;

            div.replace-carrier {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .max-external-cars {
    text-align: right;
    small {
      font-family: 'Roboto';
      font-weight: 500;
      color: $color--invalid;
      letter-spacing: 0.25px;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &.show small {
      opacity: 0.8;
    }
  }
}
