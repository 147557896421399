@import 'src/styles/global.variables';

#AgentWalletPreviewComponent {
  & > .row {
    margin: 0;
    padding: 0;

    & > div {
      margin: 0;
      padding: 2px;
    }
  }

  .wallet-table,
  .usabg-table,
  .carrier-table,
  .ytd-table,
  .all-time-table {
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    & > div:first-of-type {
      display: flex;
      height: 3rem;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }

    & > div:last-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }

  .wallet-table {
    & > div:first-of-type {
      background-color: $color--usabg--blue;
      border: 1px solid $color--usabg--blue;
      color: #fff;
    }

    & > div:last-of-type {
      background-color: #fff;
      border: 1px solid $color--usabg--blue;
      color: $color--usabg--blue;
    }
  }

  .usabg-table,
  .all-time-table {
    & > div:first-of-type {
      background-color: $color--usabg--red;
      border: 1px solid $color--usabg--red;
      color: #fff;
    }

    & > div:last-of-type {
      background-color: #fff;
      border: 1px solid $color--usabg--red;
      color: $color--usabg--red;
    }
  }

  .carrier-table,
  .ytd-table {
    & > div:first-of-type {
      background-color: #ff8c00;
      border: 1px solid #ff8c00;
      color: #fff;
    }

    & > div:last-of-type {
      background-color: #fff;
      border: 1px solid #ff8c00;
      color: #ff8c00;
    }
  }
}
