#KnowledgebasePage {
  & > div.container-fluid > .row > div {
    padding-bottom: 1rem;

    & > .card {
      .btn.support-ticket-btn {
        margin-bottom: 0.5rem;
      }

      & > .card-body {
        position: relative;
        display: flex;
        flex-wrap: wrap;

        & > div {
          flex: 0 0 100%;
        }

        .btn {
          align-items: center;
          align-self: bottom;
          height: auto;
          padding: 0.75rem;
          font-size: 1rem;
          line-height: 1.125rem;
        }
      }
    }
  }
}
