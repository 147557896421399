@import 'src/styles/global.variables';

#AgentIdentityComponent {
	text-align: left;
	padding-top: 2px;
	padding-bottom: 1rem;
	color: #FFF;

	.agent-headshot {
		border: thin solid white;
	}

	.agent-name {
		padding-left: 3px;
		font-size: 1.5rem;
	}

	.agent-title {
		padding-left: 3px;
	}

	.agent-phone {
		padding-left: 3px;
		a {
			color: inherit;
		}
	}

	.agent-signature {
		max-width: 80%;
		margin-left: 10%;
		margin-top: 1rem;
	}
}