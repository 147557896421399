@import './../../styles/global.variables';

#OauthFormsPage {
  padding-bottom: 2rem;

  .card {
    padding: 0;
    .card-header {
      background-color: $color--usabg--blue;
      color: #fff;
      font-weight: 500;
    }
  }
}
