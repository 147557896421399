@import './../../styles/global.variables';

#EditTechToolsPage {
  .card {
    padding: 0;

    .card-header {
      background-color: $color--usabg--blue;
      color: #fff;
      font-weight: 500;
      font-size: 1.125rem;
      text-align: center;
    }

    .card-body {
      thead {
        th {
          background-color: rgb(220, 220, 220);
          font-weight: 500;
          text-align: center;
          padding: 3px 0;
        }
      }

      tr + tr {
        td {
          border-top: 1px solid rgb(220, 220, 220);
        }
      }
      pre {
        margin: 5px 10px 5px 0;
      }
    }
  }
}
