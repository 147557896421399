@import 'src/styles/global.variables';
// ExportGrowthReportModal.scss
.exportGrowthReportModal {
  .card-header {
    font-weight: 500;
    text-align: center;

    &.prev-year {
      padding: 0.08rem 0.5rem 0.12rem;
      height: 2.5em;
      border-radius: calc(0.25rem - 1px);

      .fa-spinner {
        line-height: 2.32rem;
      }

      .ui-input-dropdown {
        display: inline-block;

        .select-wrapper span.caret {
          top: 0.6rem;
          color: white;
        }

        input {
          margin: 0;
          max-width: 3.5rem;
          border-bottom: transparent;
          color: white;
        }
      }
    }
  }

  .widget-date-selector {
    padding-top: 2px;
    padding-bottom: 2px;
    margin-bottom: 0.5rem;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0.4rem;
    box-shadow: none !important;

    & > .wds-input {
      padding-top: 5px;

      .ui-input.ui-date-picker-input {
        padding-top: 8px;

        .md-form {
          input.form-control {
            margin: 0;
          }
        }
      }
    }

    & > .wds-preset {
      padding-bottom: 5px;
      align-items: center;

      div.btn-group {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        align-self: center;
        padding: 0;

        button.btn {
          padding: 7px;
          margin: 0;
          flex: 0 0 33.3%;
          max-width: 33.3%;
          text-align: center;
          flex-grow: 1;
          box-shadow: none;
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          background-color: $color--indigo !important;
          height: 4rem;

          & > div {
            margin: auto;
          }
        }
      }
    }

    @media (min-width: 768px) {
      & > .wds-input {
        padding-top: 0;
      }
      & > .wds-preset {
        padding-bottom: 0;
        padding-top: 0.25rem;

        div.btn-group {
          button.btn {
            height: 2.5rem;
          }
        }
      }
    }
  }

  .paddingSkip {
    margin: 0 !important;
  }
}
