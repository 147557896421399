@import './../../styles/global.variables';

#AccountBillingInfoPage {
  margin: 0;
  padding: 0;

  .agent-packages-card {
    .card-header {
      background-color: $color--usabg--blue;
      color: #fff;
      text-align: center;
      font-weight: 500;
    }
  }
}
