@import './../../../styles/global.variables';
@import './../../../styles/global.fonts';

#OnboardingCheckoutComponent {
  .collapse {
    text-align: center;

    & > div.alert.alert-success {
      display: inline-block;
      margin: auto;
    }
  }

  .alert.alert-success > .content-wrapper {
    padding: 0;
    border-color: rgb(220, 220, 220);

    & > table {
      margin: 20px 60px;
      max-width: 600px;

      & > thead > tr > th {
        color: $color--usabg--blue;
        font-size: 2.5rem;
        font-weight: 500;
        padding: 10px 5px;

        hr {
          border-color: $color--usabg--blue;
        }
      }

      & > tbody > tr {
        & > td:first-of-type {
          font-size: 3rem;
          i.fa {
            transform: scaleX(-1);
            margin-right: 30px;
            margin-left: 10px;
          }
        }
      }

      td {
        text-align: left;
        padding: 8px;
        vertical-align: middle;
      }

      td > h5 {
        font-family: 'Roboto';
        font-weight: 500;
        color: #333;
      }

      td > p {
        color: #666;
        font-family: 'Roboto';
        font-weight: 400;
        margin: 0;

        & > span {
          display: inline-block;
          color: #fff;
          font-weight: 500;
          text-transform: uppercase;
          background-color: $color--success;
          padding: 0 6px;
        }
      }
    }
  }

  // xs
  @media screen and (max-width: 575px) {
  }

  // sm
  @media screen and (min-width: 576px) {
  }

  // md
  @media screen and (min-width: 768px) {
  }

  // lg
  @media screen and (min-width: 992px) {
  }

  // xl
  @media screen and (min-width: 1200px) {
  }
}
