@import './../../styles/global.variables';

#CarrierGrowthReportPage {
  .card {
    .card-body {
      position: relative;

      & > .loading-wrapper {
        height: 125px;
        margin-top: 50px;
      }

      table {
        th {
          font-weight: 500;
        }

        tr {
          td {
            text-align: center;
            vertical-align: middle;
            font-weight: 400;
          }

          td:first-child {
            font-weight: 500;
          }
        }
      }
    }
  }
}
