@import './../../../../styles/global.variables';

ul.lgr-timespan {
	.has-sorter {
		position: relative;

		&>div:first-of-type {
			margin-right: 1.5rem;
		}

	  & > div.SortIndicatorComponent {
	  	& > ul {
	  		position: absolute;
	  		right: 0;
	  		top: 0;
	  		bottom: 0;
	  		padding: 0;
	  		margin: 0;
	  		list-style-type: none;
	  		width: 1.5rem;

	      li.up,
	      li.down {
	        cursor: pointer;
	        opacity: 0.35;
	        border: 1px solid transparent;
	        border-radius: 0.35rem;
	        transition-property: opacity background-color color;
	        transition-duration: 0.2s;
	        transition-timing-function: ease-in-out;
	        &:hover {
	          opacity: 0.6;
	        }
	      }

			  &.sorted-asc {
			    li.up {
			      background-color: rgba(20, 50, 255, 0.7);
			      .fa {
			        color: #fff;
			      }
			      opacity: 1;
			    }
			  }

			  &.sorted-desc {
			    li.down {
			      background-color: rgba(20, 50, 255, 0.7);
			      .fa {
			        color: #fff;
			      }
			      opacity: 1;
			    }
			  }
	  	}
	  }
	}	
}