#CarrierTableFilterComponent {
  width: 100%;
  padding: 0.5rem;
  background-color: rgba(220, 220, 220, 0.3);
  border: thin solid rgba(220, 220, 220, 0.6);
  border-radius: 0.4rem;
  margin-bottom: 0.5rem;

  .ctfc-filter-row > div {
    h5 {
      border-bottom: 2px solid #999;
      font-weight: 500;
    }

    label {
      color: #333;
      font-weight: 500;
      padding: 0;
      margin: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & > .btn-group {
      button.btn {
        padding: 4px;
        margin: 2px;
      }
    }
  }
}
