@import './../../styles/global.variables';

#LeadProgramsPage {
  .tab-pane {
    & > ul {
      li {
        display: flex;
      }
    }
  }

  .contest-box-wrapper-col {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    & > .card {
      width: 100%;
    }
  }
}
