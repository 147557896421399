#nav-backoffice-btn {
  border: thin solid transparent;
  flex-grow: 1;
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;

  a {
    display: flex;
    align-items: center;
    line-height: 22px;

    .icon-container {
      font-size: 1rem;
      padding-left: 1rem;
      padding-top: 0.25rem;
      transition: 0.3s;

      & + span {
        transition: 0.3s;
        padding-left: 0.5rem;
        @media screen and (max-width: 1200px) {
          font-size: 1.2rem;
        }
      }
    }

    small {
      display: inline-block;
      width: 100%;
      font-size: 0.8rem;
    }
  }

  &:hover {
    a {
      .icon-container {
        padding-left: 0.5rem;

        & + span {
          padding-left: 1rem;
        }
      }
    }
  }
}
