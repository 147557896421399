@import './../../styles/global.variables';

.PageHeadingComponent {
  & > .ph-row {
    & > div.ph-label {
      & > h3.label {
        font-family: Roboto;
        font-weight: 400;
        color: rgb(50, 50, 50);
      }
    }

    & > div.ph-btn-grp {
    }

    @media screen and (min-width: 768px) {
      & > div.ph-label {
      }
      & > div.ph-btn-grp {
        align-items: center;
        justify-content: flex-end;
        display: flex;
      }
    }
  }

  & > hr {
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 1rem 0;
    border: 1px solid $color--usabg--blue;
  }

  & > a.go-back-link {
    border: 1px solid $color--usabg--blue;
    background-color: $color--usabg--blue;
    border-radius: 0.3rem;
    padding: 3px 6px;
    color: #fff;
    font-family: Roboto;
    margin-bottom: 0.5rem;
    display: inline-block;
    font-weight: 400;
    font-size: 0.85rem;
    text-transform: capitalize;
  }
}
