@import './../../../../styles/global.variables';

#AddToCalendarButtonsComponent {
	display: flex;

	.svg-icon-wrapper {
		display: inline-block;
		height: 2.5rem;
		width: 2.5rem;
		border: 3px solid white;
		background-color: #FFF;
		border-radius: 6px;
		transform: scale(75%);

		svg {
			max-width: 100%;
			max-height: 100%;
			width: 100%;
			height: 100%;
		}
	}

	button.btn.add-cal-btn {
		background-color: $color--usabg--blue !important;
		flex-grow: 1;
		padding: 2px;
		color: #FFF;
    flex-wrap: nowrap;
    display: flex; 

    &>div:nth-of-type(1) {
      flex: 0 0 2.5rem;
      max-width: 2.5rem;
    }

    &>div:nth-of-type(2) {
      flex-grow: 1;
    }

		 &.cal-btn-google {}
		 &.cal-btn-outlook {}
		 &.cal-btn-office365 {}
		 &.cal-btn-yahoo {}
		 &.cal-btn-ics {}
	}

	@media screen and (max-width: 575px) {
		flex-wrap: wrap;
		padding-right: 15px;
	}
}
