@import './../../../../styles/global.variables';

#EnrollmentStageIndicatorComponent {
  max-width: 90%;
  margin-left: calc(10% - 1rem);

  div.stepper-container {
    div.step-wrapper {
      position: relative;
      min-height: 9rem;
      display: flex;
      flex-wrap: none;

      &:not(:last-of-type):before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 2.25rem;
        width: 2px;
        background-color: $color--usabg--blue;
        content: ' ';
      }

      div.step-indicator {
        flex: 0 0 5rem;
        max-width: 5rem;

        a.btn.btn-floating {
          border-radius: 50%;
          padding: 0;
          margin-left: 0.75rem;
          margin-right: 0;
          margin-top: 0;

          i {
            line-height: 3rem;
            font-size: 1.75rem;
            padding-right: 2px;
            color: inherit;
          }
        }
      }

      div.step-description {
        flex: 0 0 calc(100% - 6rem);
        max-width: calc(100% - 6rem);
        margin-left: 1rem;
        transform: translateY(-0.75rem);

        div.step-label {
          margin-top: 0.5rem;
          font-size: 1.75rem;
          font-weight: bold;
        }

        div.step-content {
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }
  }

  div.step-wrapper.stage-incomplete {
    a.btn {
      background-color: rgb(225, 225, 225) !important;
      color: rgb(155, 155, 155);

      &:hover {
        background-color: rgb(195, 195, 195) !important;
        color: rgb(95, 95, 95);
      }
    }
  }

  div.step-wrapper.stage-active {
    a.btn {
      background-color: rgb(30, 144, 255) !important;
      color: rgb(255, 255, 255);

      &:hover {
        background-color: rgb(24, 115, 204) !important;
      }
    }
  }

  div.step-wrapper.stage-invalid {
    a.btn {
      background-color: rgb(213, 126, 126) !important;
      color: rgb(178, 16, 16);

      &:hover {
        background-color: rgb(188, 96, 96) !important;
        color: rgb(145, 10, 10);
      }
    }
  }

  div.step-wrapper.stage-complete {
    a.btn {
      background-color: rgb(152, 251, 152) !important;
      color: rgb(27, 170, 27);

      &:hover {
        background-color: rgb(110, 213, 110) !important;
        color: rgb(12, 139, 12);
      }
    }
  }
}
