@import './../../../styles/global.variables';

.ui-tooltip-trigger {
	display: inline;

	i.fa {
		background-color: $color--usabg--blue !important;
		color: #FFF;
		border: 1px solid $color--usabg--blue;
		border-radius: 50%;
		padding: 1px;
		height: 14px;
		width: 14px;
		font-size: 10px;
		text-align: center;
		cursor: pointer;
		transform: translateY(-2px)
	}
}

.ToolTipComponent {
	width: 15rem;
	.ui-tooltip-content {}
}