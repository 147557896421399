@import './../../../../styles/global.variables';

#CarrierNotesFormComponent {
  .note-item {
    border-bottom: 1px solid #efefef;

    &:hover {
      background-color: #efefef;

      .actions {
        visibility: visible;
      }
    }

    .actions {
      text-align: right;
      visibility: hidden;
    }

    &:last-child {
      border-bottom: transparent;
    }

    button.btn {
      padding: 0 3px;
      background-color: transparent !important;
      box-shadow: none;
      height: 16px;
      line-height: 16px;

      &:not([disabled]):hover {
        background-color: rgba(220, 220, 220, 0.8) !important;
      }
    }

    button.btn:first-child {
      color: $color--usabg--blue;
    }

    button.btn:nth-child(2) {
      color: $color--invalid;
    }

    .author-name {
      font-weight: bold;
      font-style: italic;
      padding-right: 2rem;
    }

    .note-footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}
