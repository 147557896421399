@import 'src/styles/global.variables';

#ProductListingComponent {
  text-align: center;
  background-color: #fff;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  height: 100%;
}
.product-cat-head {
  background-color: $color--usabg--blue !important;
}
