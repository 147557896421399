@import './../../styles/global.variables';
@import './../../styles/global.classes';

ul#AdminNavLinksComponent {
  padding-bottom: 1.5rem;

  // hidden: 				collapse
  // visible: 			collapse.show
  // transition: 		collapsing

  & > li {
    & > a {
      font-size: 1rem;
      font-weight: 500;
      border-radius: 0;

      & + div > div > ul {
        padding: 0;

        & > li {
          a {
            font-size: 0.9rem;
            font-weight: 500;
            padding-left: 2rem;

            & > i.fa {
              overflow: hidden;
              width: 0;
              opacity: 0;
              margin-left: 0;
            }
          }

          &.is-hidden-link > a {
            opacity: 0.65;
            & > i.fa {
              opacity: 0.85;
              overflow: visible;
              margin-left: 0.25rem;
            }
          }

          & + li {
            border-top: 1px solid rgba(225, 255, 255, 0.05);
          }

          &:last-of-type {
            border-bottom: 1px solid rgba(0, 20, 100, 0.8);
          }
        }
      }
    }
  }
}
