
#MessagesListComponent {
	.table-wrapper {
        @media screen and (max-width: 576px) {
            margin-top: 50px;
        }

        .dataTables_wrapper {
            @media screen and (max-width: 768px) {
                overflow-x: auto;
            }
        }
    }

    .pagination-wrapper {
        .row {
            margin: 0;
        }
    }
}