#AttendanceForm {
  p {
    margin: 5px -15px;
    padding: 0 10px;
    line-height: 2.5rem;
    font-size: 1.5rem;
    background-color: rgb(230, 230, 230);
    color: rgb(30, 30, 30);
    border: 1px solid rgb(220, 220, 220);
    border-radius: 0.3rem;
  }

  .attendance-intake {
    label {
      font-weight: 500;
      margin: 0;
    }

    div.switch {
      margin-top: 10px;
    }

    & + .attendance-intake {
      border-top: 1px solid rgb(240, 240, 240);
    }
  }
}
