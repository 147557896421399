@import './../../../styles/global.variables';

#CarrierContactsModal {
  .modal {
    .modal-lg {
      margin-left: 1rem;
      width: calc(100vw - 2rem);
      max-width: calc(100vw - 2rem);
    }

    .modal-body {
      position: relative;
      min-height: 150px;
      padding: 0;

      div.dataTables_wrapper {
        & > div.row:first-of-type {
          display: none;
        }

        table {
          margin-bottom: 0;

          thead {
            background-color: rgba(220, 220, 220, 0.5);
          }

          tbody {
            tr:nth-of-type(even) {
              td {
                background-color: rgba(20, 120, 250, 0.1);
              }
            }
          }

          th,
          td:nth-of-type(1),
          td:nth-of-type(2) {
            font-weight: 500;
          }

          td {
            padding: 5px 6px;
          }
        }
      }

      button.btn {
        font-weight: 500;
        font-family: 'Roboto';
        font-size: 1.5rem;
      }

      & > .loading-wrapper {
        height: 100px;
        padding-top: 50px;
        transition-property: opacity, visibility;
        transition-duration: 0.25s;
        transition-timing-function: ease-in-out;
        opacity: 0;
        visibility: hidden;
      }

      &.is-loading {
        & > .loading-wrapper {
          opacity: 1;
          visibility: visible;
        }
      }

      a {
        color: $color--usabg--blue;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
