#MilestonesPage {
  .img-wrapper {
    height: 120px;
    width: 90px;
    border: 1px solid #cbcbcb;
    border-radius: 50% !important;
    overflow: hidden;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
    margin: auto;
    margin-bottom: 0.5rem;
    &.lg {
      width: 120px;
      height: 160px;
    }

    img {
      transform: scale(105%);
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  & > .container-fluid > .card {
    padding: 0;

    .card-header {
      text-align: center;
      font-weight: 500;
    }
  }
}
