#DashboardOverlayComponent {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10vw 10vw rgba(0, 0, 0, 0.5) inset;
}
