@import 'src/styles/global.variables';

#LedgerNotesComponent {
  div.ledger-notes {
    div.content-wrapper {
      background-color: rgba(220, 220, 220, 0.5);
      padding: 3px;

      button.btn[type='button'] {
        display: none !important;
      }

      div.ledger-note-wrapper {
        display: flex;
        flex-wrap: wrap;

        & > div:nth-of-type(1) {
          flex-basis: 100%;
          background-color: rgba(255, 255, 255, 0.8);
          padding: 2px;
          border: thin solid white;
          border-radius: 0.4rem;
        }

        & > div:nth-of-type(2) {
          font-size: 13px;
          font-weight: 500;
          font-style: italic;
          padding-left: 5px;
          color: rgba(0, 0, 0, 0.6);
          background-color: rgba(255, 255, 255, 0.3);
        }

        & > div:nth-of-type(3) {
          margin-left: auto;
          font-size: 13px;
          font-weight: 500;
          font-style: italic;
          padding-right: 5px;
          color: rgba(0, 0, 0, 0.6);
          background-color: rgba(255, 255, 255, 0.3);
        }

        & + .ledger-note-wrapper {
          margin-top: 5px;
        }
      }
    }
  }
}
