@import './../../../styles/global.variables';

.CartItemComponent {
  width: 100%;
  display: flex;

  & > div {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.9rem;
    font-weight: 500;
    align-items: center;
    padding: 0 5px;
    transition-property: width, max-width, flex, font-size;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;
  }

  & > .carrier-name {
    font-size: 1rem;
    flex: 0 0 45%;
    max-width: 45%;

    & > div {
      flex: 0 0 100%;

      &.carrier-descrip {
        font-size: 0.8rem;
        flex: 0 0 0;
        visibility: hidden;
        overflow: hidden;
        font-size: 0;
      }
    }
  }

  & > .carrier-descrip {
    flex: 0 0 55%;
    max-width: 55%;
  }

  & > .carrier-comm-level {
    flex: 0 0 0;
    max-width: 0;
    text-align: center;
    align-content: middle;
    overflow: hidden;
    font-size: 0;
  }

  & > .carrier-instruct {
    flex: 0 0 0;
    max-width: 0;
    overflow: hidden;
    font-size: 0;

    &.is-empty {
      color: #999;
      font-weight: 500;
      opacity: 0.75;
    }
  }

  &.expand {
    & > .carrier-name {
      flex: 0 0 40%;
      max-width: 40%;

      & > div {
        flex: 0 0 100%;
        max-width: 100%;
        font-size: 0.9rem;
        visibility: visible;
      }
    }

    & > .carrier-descrip {
      flex: 0 0 0;
      max-width: 0;
      font-size: 0;
    }

    & > .carrier-comm-level {
      flex: 0 0 15%;
      max-width: 15%;
      font-size: 0.9rem;
    }

    & > .carrier-instruct {
      flex: 0 0 45%;
      max-width: 45%;
      font-size: 0.9rem;
    }
  }

  &.disable {
    & > .carrier-name {
      font-size: 0;
    }

    & > .carrier-descrip {
      font-size: 0;
    }
  }
}
