@import 'src/styles/global.variables';

#CorporateIdentityComponent {
	text-align: center;
	padding-top: 1rem;
	background-color: #FFF;

	.who-we-are {
		color: $color--usabg--blue;

		h4 {
			margin: 0;
		}

		p {
			font-weight: 400;
			margin: 0;
			padding: 1rem 0.5rem;
			font-size: 1.125rem;
		}
	}

	.mission-stmt {
		background-color: $color--usabg--blue;
		color: #FFF;

		h3 {
			color: inherit;
			margin: 1.5rem 0.5rem;
		}

		p {
			font-size: 1rem;
			text-align: justify;
			padding: 0 1rem 1.5rem 1rem;
			font-weight: 400;
		}
	}
}