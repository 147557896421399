.home-footer {
	.offices {
		.img-location {
			width: 100%;
			max-width: 350px;
			text-align: center;
			margin: auto;

			img {
				width: 100%;
				display: block;
			}
		}
	}
}