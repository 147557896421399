@import './../../../../styles/global.variables';

#MetricsHistoryTable {
  box-shadow: none;

  &.card {
    padding: 0;
    .card-header {
      background-color: $color--usabg--blue;
      color: #fff;
      font-weight: 500;
    }
    .card-body {
      padding: 0;
    }
  }

  table.table.table-bordered {
    border: none;
    margin: 0;
  }

  thead {
    background-color: rgb(240, 240, 240);
  }

  small {
    display: block;
    font-weight: bold;
  }

  td {
    padding: 0;

    div.cell-wrapper {
      &.attended,
      &.completed {
        background-color: rgba(30, 250, 40, 0.6);
        color: rgb(15, 150, 20);
        font-weight: bold;
      }

      &.unattended {
        background-color: rgba(250, 30, 40, 0.6);
        color: rgb(150, 15, 20);
        font-weight: bold;
      }
    }
  }

  tfoot {
    td {
      padding: 0 0.25rem 0 1.5rem;
      border: none;
    }
  }
}
