@import './../../../styles/global.variables';

#AgentContractEditorComponent {
  .modal-content > .modal-body {
    padding: 0;

    h6 {
      border-bottom: 2px solid rgba(120, 120, 120, 0.3);
      text-align: center;
      background-color: rgba(220, 220, 220, 0.8);
      font-weight: 600 !important;
      font-family: 'Lato', sans-serif;
      font-size: 18px;
      padding: 10px 0;
    }

    & > table {
      table-layout: fixed;

      & > tbody {
        & > tr {
          & > td {
            vertical-align: middle;
            font-weight: 500;
            border-bottom: 1px solid rgba(220, 220, 220, 0.6);

            &:nth-of-type(2) {
              width: 16px;
            }

            &.form-input {
              .md-form,
              input {
                margin-bottom: 0;
              }
            }

            .react-datepicker-wrapper {
              width: 100%;
            }
          }
        }
      }
    }

    & > .row {
      margin: 0;
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: 500px) {
    .modal-content > .modal-body {
      & > table {
        margin: 15px;

        & > tbody {
          & > tr {
            & > td {
              &:nth-of-type(1) {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 501px) {
    .modal-content > .modal-body {
      & > table {
        margin: auto;
        margin-bottom: 30px;
      }
    }
  }

  @media screen and (min-width: 600px) {
    .modal-dialog.update-carrier-contract {
      max-width: 600px;
      width: 600px;

      .modal-content > .modal-body {
        & > table {
          & > tbody {
            & > tr {
              & > td {
                &:nth-of-type(3) {
                  width: 50%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 800px) {
    .modal-dialog.update-carrier-contract {
      max-width: 800px;
      width: 800px;
    }
  }
}
