
#ReportDatesSetter {
	.custom-datepicker {
		.react-datepicker-wrapper {
			width: 100%;

			.form-control-plaintext {
				background-color: #FFF;
				border: 1px solid rgb(180,180,180);
				border-bottom: 1px solid rgb(180,180,180);
				margin-bottom: 1rem;
			}
		}
	}	
}