@import './../../../../styles/global.variables';

.AgentUsertypeSelector {
  .agent-usertype-toggle {
    float: left;
    width: 100%;
    margin: 0;
    text-align: left;
    padding: 2px 4px;
    font-weight: 500;
    font-size: 0.85rem;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    &:after {
      right: 4px;
      top: 10px;
      position: absolute;
    }

    &[disabled] {
      opacity: 1;

      &:after {
        display: none;
      }
    }
  }

  .dropdown-menu {
    & > div > button.dropdown-item {
      position: relative;

      span {
        display: block;
        max-width: 100%;
      }
    }

    button.dropdown-item.selected {
      padding-left: 14px;

      &:before {
        content: '▸';
        position: absolute;
        left: 2px;
        width: 10px;
        top: 0.75rem;
        bottom: 0;
        line-height: 100%;
      }
    }
  }

  .action-btns {
    position: relative;
    width: 0;
    float: right;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition-property: opacity visibility width;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    height: 4rem;

    button {
      position: absolute;
      border: none;
      outline: none;
      background-color: transparent;
      width: 100%;
      text-align: center;
      transition-property: background-color color transform;
      transition-duration: 0.15s;
      transition-timing-function: ease-in-out;
      height: 2rem;
      visibility: hidden;
      opacity: 0;
      left: 0;

      &:nth-of-type(1) {
        color: green;
        top: 0;
      }

      &:nth-of-type(2) {
        color: rgb(200, 200, 200);
        top: 2rem;
      }
    }

    .save-icon {
      width: 2rem;
      top: 0.25rem;
      color: rgb(100, 100, 100);
      text-align: center;
      position: absolute;
      transform: translateX(100%);
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease-in-out;
    }
  }

  &:not(.pending-change) {
    button.btn.agent-usertype-toggle {
      box-shadow: none;
      background-color: #fff !important;
      color: #000;
    }
  }

  &.pending-change,
  &.saving-change {
    button.btn.agent-usertype-toggle {
      width: calc(100% - 2rem);
      background-color: #3f51b5 !important;
      color: #fff;
    }

    .action-btns {
      width: 2rem;
      visibility: visible;
      opacity: 1;
    }
  }

  &.pending-change {
    .action-btns {
      visibility: visible;
      opacity: 1;

      &:not(.is-saving) {
        button {
          opacity: 1;
          visibility: visible;
        }
        button:hover {
          &:nth-of-type(1) {
            color: #fff;
            background-color: green;
          }

          &:nth-of-type(2) {
            background-color: red;
            color: #fff;
          }
        }
      }

      &.is-saving {
        button {
          transform: translateX(-100%);
        }
        .save-icon {
          transform: translateX(0);
        }
      }
    }
  }

  &.saving-change {
    .action-btns {
      button {
        opacity: 0;
        visibility: hidden;
      }

      &.is-saving {
        button {
          transform: translateX(-100%);
        }
        .save-icon {
          transform: translateX(0);
        }
      }
    }
  }
}
