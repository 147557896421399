@import 'src/styles/global.variables';

#AgentFooterComponent {
	position: relative;
	z-index: 1;
	background-color: $color--usabg--blue;
	padding-top: 1rem;
	padding-bottom: 0.5rem;
	color: #FFF;

	a.footer-link {
		color: #FFF;
		position: relative;
		padding-left: 20px;
		display: inline-block;

		&:before {
	    content: '\f105';
	    position: absolute;
	    left: 0;
	    top: 1px;
	    font: 400 18px/24px FontAwesome;
	    color: #FFF;
	    transition: 0.33s all ease;
	  }

	  &:hover:before {
	    left: 4px;
	  }
	}

	li {
		color: #FFF;
		a {
			color: inherit;
		}
	}

	h5 {
		color: #FFF;
		font-weight: 500;

		&+*{
			margin-top: 0;
		}
	}

	p {
		color: #FFF;
		font-weight: 400;
		margin-top: 0;
	}

	ul.social-links {
		list-style-type: none;
		padding: 0;

		li {
			display: inline-block;
			font-size: 1.25rem;
			padding-right: 1rem;

			&+li {
				padding-left: 1rem;
			}
		}
	}

	ul.quick-links {
		display: flex;
		flex-wrap: wrap;

		li {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}

	ul.colophon {
		list-style-type: none;
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			font-size: 0.75rem;
			padding-right: 1rem;

			&+li {
				border-left: 2px solid white;
				padding-left: 1rem;
			}
		}
	}

	@media screen and (max-width: 991px) {
		&>.container>.row>div {
			&+div {
				margin-top: 2rem;
			}
		}

		ul.colophon {
			margin-top: 2rem;
		}
	}
}