@import 'src/styles/global.variables';

div.ledger-component {
  background-color: rgb(255, 255, 255);
  padding: 8px;
  border: 1px solid rgba(80, 80, 80, 0.4);
  border-radius: 0.4rem;
  box-shadow: 0px 0px 5px 1px rgba(80, 80, 80, 0.2);
}

div.ledger-title-component {
  display: flex;
  flex-wrap: nowrap;
  padding: 0 15px 0 0;
  flex-grow: 1;
  border-bottom: 1px solid rgba(220, 220, 220, 0.8);
  margin-bottom: 1rem;

  h6 {
    & + div {
      display: flex;
      flex-wrap: nowrap;
      padding: 0 15px 0 0;
      flex-shrink: 1;

      button.btn {
        align-self: flex-end;
        justify-self: end;
        border: none;
        color: #fff;
        padding: 2px 8px;
        font-size: 12px;
        margin: 0 2px;
        display: block;
        margin-right: auto;
        box-shadow: none;

        &.cancel-btn {
          background-color: $color--gray--md-1 !important;
          color: $color--gray--dk-2 !important;
        }

        &.save-btn {
          background-color: $color--sky-blue !important;
        }

        &.new-btn {
          background-color: $color--green !important;
        }
      }
    }
  }
}
