@import './../../../../styles/global.variables';

#AgentRowSorter {
  padding: 0;

  & > .row {
    flex-wrap: nowrap;

    & > .col {
      border: thin solid rgb(220, 220, 220);
      padding: 5px;
      font-size: 0.9rem;
      font-weight: 500;
      text-align: center;

      & > div {
        display: flex;
        flex-wrap: nowrap;

        & > .label {
          flex: 0 0 100%;
          max-width: 100%;
          overflow: hidden;
          text-align: center;
          justify-content: center;
        }

        & + small {
          font-size: 11px;
          font-weight: 500;
          color: #666;
        }
      }

      &.has-sorter {
        & > div {
          & > .label {
            flex: 0 0 calc(100% - 2rem);
            max-width: calc(100% - 2rem);
            margin-left: 1rem;
          }

          .sorter {
            flex: 0 0 1rem;
            max-width: 1rem;

            .up,
            .down {
              cursor: pointer;
              opacity: 0.35;
              border: 1px solid transparent;
              border-radius: 0.35rem;
              transition-property: opacity background-color color;
              transition-duration: 0.2s;
              transition-timing-function: ease-in-out;
              &:hover {
                opacity: 0.6;
              }
            }
          }
        }

        &.sorted-asc {
          .sorter .up {
            background-color: rgba(20, 50, 255, 0.7);
            .fa {
              color: #fff;
            }
            opacity: 1;
          }
        }

        &.sorted-desc {
          .sorter .down {
            background-color: rgba(20, 50, 255, 0.7);
            .fa {
              color: #fff;
            }
            opacity: 1;
          }
        }
      }
    }
  }

  .tooltip-wrapper {
    max-width: 400px;
    width: 400px;

    &.show {
      background-color: rgba(0, 0, 0, 0.9);
    }
  }

  .tooltip-trigger {
    max-width: 100%;
    font-size: 11px;
    font-weight: 500;
    color: #666;
    cursor: pointer;
    margin: auto;

    i.fa {
      background-color: #666;
      color: #fff;
      border: thin solid #666;
      border-radius: 50%;
      padding: 1px;
    }

    &:hover {
      color: #333;
      text-decoration: underline;
    }
  }

  .tooltip-content {
    & > table {
      width: 100%;

      thead {
        text-align: center;
        font-weight: bold;
      }

      tbody {
        tr > td {
          vertical-align: middle;

          &:first-of-type {
            font-weight: 500;
            white-space: nowrap;
            padding-right: 5px;
          }
        }
      }
    }
  }

  .min-w-5 {
    min-width: 5rem;
  }
  .min-w-6 {
    min-width: 6rem;
  }
  .min-w-7 {
    min-width: 7rem;
  }
  .min-w-8 {
    min-width: 8rem;
  }
}
