@import 'src/styles/global.variables';

.ui-input-dropdown {
  li {
    &:nth-of-type(even):not(:hover) {
      background-color: rgba(200, 200, 230, 0.2);
    }

    span {
      font-weight: 500;
    }

    span,
    small {
      display: block;
    }

    small {
      font-size: 12px;
      line-height: 14px;
      color: rgb(90, 90, 90);
    }

    &:hover {
      span {
        color: $color--usabg--blue;
      }
    }
  }
}
