@import './../../../../styles/global.variables';

#CarrierEditorFormsComponent {
  & > .row {
    & > .col-12,
    & > .col-6 {
      display: flex;
      flex-wrap: wrap;

      & > div.card {
        flex: 0 0 100%;
        max-width: 100;
        margin-bottom: 20px;

        .card-header {
          background-color: $color--usabg--blue;
          color: #fff;
          font-weight: 500;
        }

        button.btn[type='submit'] {
          &:not([disabled]) {
            background-color: $color--success !important;
          }
        }
      }
    }
  }
}
