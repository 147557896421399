@import 'src/styles/global.variables';

#PromotionCodesPage {
  .promoteCodeComponent {
    .list-col {
      max-width: 50%;
      transition-property: opacity, visibility, flex, max-width;
      transition-timing-function: ease-in-out;
      transition-duration: 0.25s;

      @media screen and (max-width: 767px) {
        max-width: 30%;
      }
    }
    .form-col {
      max-width: 50%;
      display: inline-block;
      transition-property: opacity, visibility, flex, max-width;
      transition-timing-function: ease-in-out;
      transition-duration: 0.25s;

      @media screen and (max-width: 767px) {
        max-width: 70%;
      }

      .edit-code-title {
        font-size: 24px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        div {
          cursor: pointer;
        }
      }
    }
    &.show-details {
      .list-col {
        max-width: 100%;
      }
      .form-col {
        max-width: 0%;
        display: none;
      }
    }
  }
  #PromoteCodes {
    ul.promote-code-list {
      padding: 0 0 0.5rem 0;
      margin: 0;
      list-style-type: none;
  
      & > li {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
  
        &:first-of-type {
          background-color: rgba(240, 240, 240, 0.75);
          font-weight: 500;
          color: #666;
        }
  
        &:nth-of-type(even) {
          background-color: rgba(0, 225, 225, 0.1);
        }
  
        & + li {
          border-top: 1px solid rgba(240, 240, 240, 0.75);
        }
  
        div {
          padding: 2px 0;
          font-size: 0.8rem;
          font-weight: 400;
          transition-property: opacity, visibility, flex, max-width, height;
          transition-timing-function: ease-in-out;
          transition-duration: 0.25s;
        }
  
        div.al-id {
          padding-left: 2px;
          flex: 0 0 calc(2.5rem - 2px);
          max-width: calc(2.5rem - 2px);
        }
  
        div.al-code {
          flex: 0 0 calc(100% - 2.5rem);
          max-width: calc(100% - 2.5rem);
          text-overflow: ellipsis;
          overflow: hidden;
        }
  
        div.al-start_at,
        div.al-end_at {
          flex: 0 0 0;
          max-width: 0;
          visibility: hidden;
          opacity: 0;
          height: 0;
        }
  
        div.al-btns {
          flex: 0 0 0;
          max-width: 0;
          visibility: hidden;
          opacity: 0;
          height: 0;
  
          button.btn {
            padding: 0;
            height: 100%;
            flex: 0 0 calc(50% - 2px);
            max-width: calc(50% - 2px);
            align-items: center;
            justify-content: center;
            margin: 1px 2px 0 0;
            background-color: $color--blue !important;
          }
        }
      }
  
      &.show-details {
        & > li {
          div.al-id {
            flex: 0 0 2.5rem;
            max-width: 2.5rem;
          }
  
          div.al-code {
            flex: 0 0 calc(40% - 2.5rem);
            max-width: calc(40% - 2.5rem);
            
            @media screen and (max-width: 767px) {
              flex: 0 0 calc(30% - 2.5rem);
              max-width: calc(30% - 2.5rem);
            }
          }
  
          div.al-start_at,
          div.al-end_at {
            flex: 0 0 calc(30% - 2.5rem);
            max-width: calc(30%);
            visibility: visible;
            opacity: 1;
            height: auto;

            @media screen and (max-width: 767px) {
              flex: 0 0 calc(35% - 2.5rem);
              max-width: calc(35% - 2.5rem);
            }
          }
  
          div.al-btns {
            flex: 0 0 5rem;
            max-width: 5rem;
            visibility: visible;
            opacity: 1;
            height: auto;
            display: flex;
          }
        }
      }
    }
  }
}
