@import 'global.variables';
@import 'global.fonts';

.padding-0 {
  padding: 0;
}

.padding-5 {
  padding: 5px;
}

.padding-10 {
  padding: 10px;
}

.content-wrapper {
  padding: 1rem;
  background-color: #fff;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 0.3rem;
}

.cursor-pointer {
  cursor: pointer;
}

.bg--orange {
  background-color: $color--orange;
}
.bg--yellow {
  background-color: $color--yellow;
}
.bg--green {
  background-color: $color--green;
}
.bg--blue {
  background-color: $color--blue;
}
.bg--usabg--blue {
  background-color: $color--usabg--blue;
}
.bg--indigo {
  background-color: $color--indigo;
}
.bg--purple {
  background-color: $color--purple;
}
.bg--red {
  background-color: $color--red;
}

.text--white {
  color: #fff;
}
.text--orange {
  color: $color--orange;
}
.text--yellow {
  color: $color--yellow;
}
.text--green {
  color: $color--green;
}
.text--blue {
  color: $color--blue;
}
.text--usabg--blue {
  color: $color--usabg--blue;
}
.text--indigo {
  color: $color--indigo;
}
.text--purple {
  color: $color--purple;
}
.text--red {
  color: $color--red;
}
.text--valid {
  color: $color--valid;
}
.text--invalid {
  color: $color--invalid;
}

.font--roboto {
  font-family: 'Roboto';
}

.font--lato {
  font-family: 'Lato';
}

.font--open-sans {
  font-family: 'Open Sans';
}

.fw--300 {
  font-weight: 300;
}

.fw--400 {
  font-weight: 400;
}

.fw--500 {
  font-weight: 500;
}

.fw--700 {
  font-weight: 700;
}

.fw--bold {
  font-weight: bold;
}

.fs--075rem {
  font-size: 0.75rem;
}

.fs--1rem {
  font-size: 1rem;
}

.fs--1125rem {
  font-size: 1.125rem;
}

.fs--125rem {
  font-size: 1.25rem;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.text--justify {
  text-align: justify;
}

.text--underline {
  text-decoration: underline;
}

.ratio-1x1 {
  aspect-ratio: 1;
}

.headshot-ratio {
  aspect-ratio: 0.83;
}
