@import './../../../styles/global.variables';

#ReplacementCartListComponent {
  padding: 0;
  margin: 0 0 30px 0;

  .ContentHtmlComponent > .content-html-inner-wrapper {
    padding: 0;

    div {
      font-size: 0.9rem;
      font-weight: 400;
      font-family: 'Roboto';

      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  ul.replace-cart-item-list {
    padding: 0;
    margin: 0;
    border: 1px solid rgba(220, 220, 200, 0.5);
    border-radius: 0.3rem;

    & > li {
      display: flex;
      flex-wrap: wrap;

      & > div.cart-item {
        flex-grow: 1;
        text-align: left;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;

        &-external {
          flex: 0 0 calc(50% - 1.5rem);
          max-width: calc(50% - 1.5rem);
          text-align: center;
          flex-wrap: wrap;
          text-align: left;

          & > div {
            padding-left: 8px;
            width: 100%;
            max-width: 100%;
            display: block;

            & + div {
              font-weight: 400;
              font-size: 0.8rem;
            }
          }
        }

        &-transition {
          flex: 0 0 3rem;
          max-width: 3rem;
          text-align: center;
        }

        &-replacement {
          flex: 0 0 calc(50% - 1.5rem);
          max-width: calc(50% - 1.5rem);
          text-align: center;
        }
      }

      &:nth-of-type(1) {
        background-color: rgba(180, 180, 180, 0.9);
        font-weight: 500;
        font-size: 0.9rem;
        padding: 6px 0;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;

        & > div.cart-item {
          text-align: left !important;
          display: block;
          padding: 2px;
        }
      }

      &:nth-of-type(2) {
        background-color: rgba(220, 220, 200, 0.35);
        font-weight: 500;
        font-size: 0.9rem;
        padding: 6px 0;
      }

      &:not(:nth-of-type(1)):not(:nth-of-type(2)) {
        &.is-external-carrier {
          background-color: rgba(220, 220, 200, 0.45);
        }

        & > div.cart-item-profit {
          font-weight: 600;
          font-size: 1rem;
        }

        & + li {
          border-top: 1px dotted rgba(0, 25, 125, 0.2);
        }
      }
    }
  }
}

.ConfirmReplaceCarrierModalComponent {
  .modal-body {
    text-align: center;
    font-weight: 400;
    font-size: 1.25rem;

    & > div {
      margin-bottom: 1rem;
    }
  }

  .modal-footer {
    background-color: $color--usabg--blue;
    display: flex;

    button.btn {
      margin: 0 4px;
      padding: 4px;
      height: 5rem;
      display: block;
      font-weight: 600;
      text-overflow: ellipsis;
      font-family: 'Roboto';

      &:nth-of-type(1) {
        background-color: $color--success !important;
        flex: 0 0 calc(100% - 9rem);
        max-width: calc(100% - 9rem);
        font-size: 1.5rem;
        line-height: 1.75rem;
      }

      &:nth-of-type(2) {
        flex: 0 0 8rem;
        max-width: 8rem;
        font-size: 1rem;
        line-height: 1.125rem;
      }
    }
  }
}
