@import 'src/styles/global.variables';

#PackageFeatureTableComponent {
	position: relative;

	div.table-wrapper {

		h5 {
			color: #FFF;
			font-weight: 500;
			width: 100%;
			background-color: $color--usabg--blue;
			display: block;
			border: thin solid $color--usabg--blue;
			border-top-left-radius: 0.5rem;
			border-top-right-radius: 0.5rem;
			margin: 0;
			padding: 8px;
		}

		&>table.feature-table {
			thead {
				background-color: rgba(180,180,180,0.5);

				tr {
					border-left: 1px solid rgba(180,180,180,1);
					border-right: 1px solid rgba(180,180,180,1);

					th {
						font-weight: 500;

						&:nth-of-type(1) {
							text-align: center;
						}

						&:nth-of-type(1),
						&:nth-of-type(2) {
							border-right: 1px solid rgba(180,180,180,0.85);
						}

						&:nth-of-type(2) {
							padding-left: 3px;
						}

						&:nth-of-type(3) {
							border-right: 1px solid rgba(180,180,180,1);
							text-align: center;
						}

						&:nth-of-type(4) {
							text-align: center;
						}

						&:nth-of-type(5),
						&:nth-of-type(7) {
							border-left: 1px solid rgba(180,180,180,1);
							border-right: 1px solid rgba(180,180,180,1);
							text-align: center;
						}
					}

					&:first-of-type {
						th {
							background-color: rgba(180,180,180,0.5);
							border-bottom: 1px solid rgba(160,160,160,1);
						}
					}

					&:not(:first-of-type) {
						th {
							&:nth-of-type(4),
							&:nth-of-type(5) {
								border-right: 1px solid rgba(160,160,160,1);
							}

							&:nth-of-type(4),
							&:nth-of-type(5),
							&:nth-of-type(6) {
								padding: 0 3px;
								text-align: center;
								width: 7rem;
							}
						}
					}

					&:last-of-type {
						th {
							&:nth-of-type(4),
							&:nth-of-type(5),
							&:nth-of-type(6) {
								border-top: none;
							}
						}
					}
				}
			}

			tbody {
				tr {
					border-left: 1px solid rgba(180,180,180,1);
					border-right: 1px solid rgba(180,180,180,1);

					td {
						&>div.ui-input,
						&>div.ui-input-button {}

						&:first-of-type {
							&>div.ui-input {
								max-width: 3rem;
							}
						}

						&:nth-of-type(1) {
							font-weight: 400;
							text-align: center;
						}

						&:nth-of-type(1),
						&:nth-of-type(2) {
							background-color: rgba(255,255,255,1);
							border-right: 1px solid rgba(180,180,180,0.25);
						}

						&:nth-of-type(2) {
							padding-left: 3px;
						}

						&:nth-of-type(3) {
							background-color: rgba(240,240,240,1);
							border-left: 1px solid rgba(180,180,180,0.75);
							border-right: 1px solid rgba(180,180,180,0.75);
							text-align: center;
						}

						&:nth-of-type(4),
						&:nth-of-type(5),
						&:nth-of-type(6) {
							padding: 0 1px 0 5px;
							text-align: center;
							border-left: 1px solid rgba(180,180,180,0.75);
						}

						&:nth-of-type(7) {
							background-color: rgba(240,240,240,1);
							text-align: center;
							border-left: 1px solid rgba(180,180,180,0.75);
						}

						button.btn.save-feature-btn,
						button.btn.edit-feature-btn,
						button.btn.delete-feature-btn {
							padding: 3px;
							background-color: transparent !important;
							box-shadow: none;
							color: $color--invalid;
							opacity: 0.5;
							transition: opacity 0.15s ease-in-out;

							&:hover {
								opacity: 1;
							}
						}

						button.btn.save-feature-btn {
							opacity: 0.75;
							font-size: rem;
							color: $color--success;
						}

						button.btn.edit-feature-btn {
							color: $color--orange;
						}

						button.btn.delete-feature-btn {
							color: $color--invalid;
						}
					}
				}

				tr + tr {
					border-top: 1px dotted rgba(200,200,200,0.5);
				}

				tr:last-of-type {
					border-bottom: 1px solid rgba(200,200,200,0.75);
				}
			}

			tfoot {
				background-color: rgba(180,180,180,0.5);
				&>tr {
					border-left: 1px solid rgba(180,180,180,1);
					border-right: 1px solid rgba(180,180,180,1);
					border-bottom: 1px solid rgba(180,180,180,1);

					&>td {
						&:nth-of-type(4),
						&:nth-of-type(5),
						&:nth-of-type(6) {
							padding: 0 3px;
							text-align: center;
							width: 7rem;
							border-left: 1px solid rgba(180,180,180,1);
							border-right: 1px solid rgba(180,180,180,1);
							font-weight: 400;

							.alert {
								padding: 3px;
								margin: 0;
								margin-top: 3px;
							}
						}

						div.create-feature {
							background-color: rgba(255,255,255,0.85);
							border: 1px solid rgba(255,255,255,0.65);
							border-radius: 0.5rem;
							padding: 0.5rem 0.5rem;

							button.btn {
								background-color: $color--success !important;
							}
						}
					}
				}
			}
		}
	}

	.loading-spinner {
		height: 0;
		opacity: 0;
		visibility: hidden;
	}

	div.table-wrapper {
		height: auto;
		opacity: 1;
		visibility: visible;
		transition-property: height, opacity, visibility;
		transition-duration: 0.2s;
		transition-timing-function: ease-in-out;
	}

	&.is-loading {
		&>.loading-spinner {
			height: 40vh;
			opacity: 1;
			visibility: visible;
		}

		div.table-wrapper {
			height: 0;
			opacity: 0;
			visibility: hidden;
		}
	}
}