.profile-container {
  overflow: hidden;

  .img-wrapper,
  .identity-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .profile-img-wrapper {
    border: 1px solid #cbcbcb;
    border-radius: 50% !important;
    overflow: hidden;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
    margin: auto;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .identity-wrapper {
    div.bonus-indicator {
      text-align: center;
      font-size: 10px;
      font-weight: 500;
      line-height: 13px;
      width: 3rem;

      display: flex;
      align-items: center;
      flex-wrap: wrap;

      img {
        display: block;
        max-width: 2rem;
        margin: auto;
      }

      div {
        flex: 0 0 0%;
        max-width: 100%;
        text-align: center;
        align-self: center;
        margin: auto;
        justify-content: center;
        align-items: center;
        white-space: normal;
        flex-wrap: wrap;
      }
    }
  }
}
