@import './../../../styles/global.variables';

.ReplacementCarrierSelectorComponent {
  padding: 0;
  margin: 0;

  div.dropdown {
    &:not(.is-replaced) {
      button.btn.btn-secondary.dropdown-toggle {
        background-color: $color--success !important;
        color: #fff !important;
        width: calc(100% - 12px);
        padding: 8px;
      }
    }
    &.is-replaced {
      button.btn.btn-secondary.dropdown-toggle {
        background-color: transparent !important;
        color: $color--usabg--blue;
        width: calc(100% - 4rem);

        & > div {
          font-family: 'Roboto';
          padding-left: 8px;
          font-size: 1rem;
          text-transform: none;

          & + div {
            font-weight: 400;
            font-size: 0.8rem;
            text-transform: none;
          }
        }
      }

      button.btn.remove-replace-btn {
        width: 2rem;
      }
    }

    div.dropdown-menu {
      background-color: transparent;
      width: calc(100% - 12px);
      display: block;
      position: relative;
      transition-property: background-color, opacity, visibility;
      transition-duration: 0.3s;
      opacity: 0;
      visibility: hidden;

      &.show {
        background-color: rgba(0, 25, 125, 1);
        opacity: 1;
        visibility: visible;
      }

      div.dropdown-menu-scroller {
        max-height: 50vh;
        height: auto;
        overflow: auto;
        position: relative;

        ul.replace-selector-list {
          border: thin solid rgba(0, 25, 125, 0.2);
          border-radius: 0.4rem;
          overflow: hidden;
          padding: 0;
          background-color: #fff;

          & + ul {
            margin-top: 10px;
          }

          &:before {
            background-color: #d1ecf1;
            padding-left: 5px;
            display: block;
            height: 2rem;
            line-height: 2rem;
          }

          &.list-core {
            &:before {
              content: 'Core Carriers';
            }
          }
          &.list-preferred {
            &:before {
              content: 'Preferred Carriers';
            }
          }
          &.list-additional {
            &:before {
              content: 'Additional Carriers';
            }
          }

          & > li {
            transition-property: opacity;
            transition-duration: 0.3s;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            font-family: 'Roboto';
            border-radius: 0;

            & > div:nth-of-type(1) {
              flex: 0 0 1.5rem;
              max-width: 1.5rem;
              font-weight: 500;
            }

            & > div:nth-of-type(2) {
              flex: 0 0 calc(100% - 1.5rem);
              max-width: calc(100% - 1.5rem);

              & > div:nth-of-type(1) {
                font-weight: 500;
              }

              & > div:nth-of-type(2) {
                font-size: 0.8rem;
                font-weight: 400;
              }
            }

            &:active {
              box-shadow: none;
            }

            & + li {
              border-top: 1px dotted rgba(0, 25, 125, 0.2);
            }
          }

          &.list-locked {
            opacity: 0.8;

            &.list-core {
              &:before {
                content: 'Core Carriers';
              }
            }
            &.list-preferred {
              &:before {
                content: 'Preferred Carriers Locked - Please select from Core Carriers first';
              }
            }
            &.list-additional {
              &:before {
                content: 'Additional Carriers Locked - Please select from Core or Preferred Carriers first';
              }
            }

            & > li {
              opacity: 0.3;
            }
          }
        }
      }

      div.dropdown-scroll-indicator {
        position: fixed;
        margin: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 1px 10px 17px -16px #0c5460 inset;
        transition-property: opacity, visibility;
        transition-duration: 0.3s;
        opacity: 0;
        visibility: hidden;

        &.show {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  button.btn.remove-replace-btn,
  button.btn.btn-secondary.dropdown-toggle {
    box-shadow: none;
    padding: 0;

    & > label {
      margin: 0;
    }

    .replacement-required {
      display: inline-block;
      border-radius: 0.5rem;
      padding: 0 5px;
      margin: 0 6px 0 0;
      font-size: 0.8rem;
      font-weight: 500;
      font-family: 'Roboto';

      .fa {
        margin-right: 4px;
      }
    }
  }
}
