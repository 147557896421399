@import './../../../../styles/global.variables';

.UserEventComponent {
  &.alert {
    padding: 0;
    margin: 0;
  }

  .ulog-event-type,
  .ulog-event-descrip,
  .ulog-related-model,
  .ulog-payload,
  .ulog-created-at {
    font-weight: 500;
    font-size: 13px;
  }

  .ulog-event-descrip,
  .ulog-related-model,
  .ulog-payload,
  .ulog-created-at {
    border-left: 1px dotted rgba(200,200,200,0.5);
  }
}
