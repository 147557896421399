.form-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.question-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: calc(50% - 5px);
  }
}
