@import './../../styles/global.variables';

#ResourceLibraryPage {
  padding-bottom: 2rem;

  li.nav-item {
    font-weight: 500;
    padding: 0;
  }

  .card {
    padding: 0;

    .card-header {
      background-color: $color--usabg--blue;
      font-weight: 500;
      color: #fff;

      &.toggleable {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .toggle-group {
        float: right;
        transition: transform 0.3s ease-in-out;
      }
    }

    .card-body {
      padding: 0;
      font-weight: 500;
    }

    &.open {
      .card-header {
        .toggle-group {
          transform: rotate(180deg);
        }
      }
    }
  }
}
