@import './../../../styles/global.variables';

#AgentContractingCartComponent {
  label.section-label {
    background-color: rgba(220, 220, 220, 0.8);
    border: 1px solid rgba(220, 220, 220, 0.8);
    border-radius: calc(0.3rem - 1px);
    display: block;
    font-weight: 500;
    color: #333;
    padding: 4px 16px;
  }
}
