@import 'src/styles/global.variables';

#ContactFormComponent {
	background-color: #FFF;
	padding-top: 1rem;
	padding-bottom: 1.5rem;
	height: 100%;

	form {
		background-color: #e5e8ef;
		padding: 0.5rem;
		display: flex;
		flex-wrap: wrap;

		.form-input-cell,
		.form-textarea-cell {
			padding: 5px;
			flex: 0 0 100%;
			max-width: 100%;
		}

		input.form-control,
		textarea.form-control {
			background-color: #FFF;
		}
	}

	@media screen and (min-width: 992px) {
		form {
			.form-input-cell {
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
	}
}