.DashboardCardComponent {
  padding: 0;
  margin-bottom: 1.5rem;
  overflow: hidden;

  &.card-blue {
    border-color: #2979ff;

    .card-header {
      background-color: #2979ff;
      color: #fff;
      .card-sub-title {
        color: #2979ff;
      }
    }
  }

  &.card-red {
    border-color: #ff1744;

    .card-header {
      background-color: #ff1744;
      color: #fff;
      .card-sub-title {
        color: #ff1744;
      }
    }
  }

  &.card-green {
    border-color: #28b84f;

    .card-header {
      background-color: #28b84f;
      color: #fff;
      .card-sub-title {
        color: #28b84f;
      }
    }
  }

  .card-header {
    padding-left: 0;
    padding-right: 0;
    font-size: 1.5rem;
    text-align: center;
    font-weight: 500;

    .card-sub-title {
      padding: 0.25rem 0;
      font-size: 1.75rem;
      width: 100%;
      margin-bottom: -1rem;
      margin-top: 0.5rem;
      background-color: rgb(220, 220, 220);
      height: 50px;
    }
  }

  &.card-agent {
    .card-body {
      .card-profile-jewel {
        text-align: center;

        .agent-points {
          font-size: 1.5rem;
          font-weight: 500;
        }

        img {
          margin-bottom: 1rem;
        }
      }
    }
  }

  &.card-division,
  &.card-ba_team {
    .card-body {
      .ba-team-img-wrapper,
      .division-img-wrapper {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;

        img {
          max-height: 100%;
          max-width: 100%;
          width: auto;
        }
      }

      .ba-team-points,
      .division-points {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
  }

  &.card-list {
    .card-body {
      table {
        width: 100%;
      }

      tr.list {
        height: 2rem;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

        td {
          padding-top: 0.5rem;
          font-weight: 500;
        }

        td.division-logo {
          width: 3.5rem;
          text-align: left;

          img {
            max-width: 3rem;
            max-height: 1.5rem;
          }
        }

        td:first-child,
        td.division-name {
          div {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        td:last-child {
          text-align: right;
          min-width: 4rem;
        }
      }
    }
  }

  &.card-recruits {
    .card-body {
      .recruits {
        .card-score {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }

      .recruiter-list {
        .label {
          text-align: center;
          background-color: rgb(220, 220, 220);
        }

        ul.list {
          list-style-type: none;

          li {
            display: flex;
            flex-wrap: nowrap;

            div {
              border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
              line-height: 2rem;
              height: 2rem;
            }

            div:nth-of-type(1) {
              text-align: left;
            }

            div:nth-of-type(2) {
              text-align: right;
            }

            &.no-recruits {
              & > div {
                line-height: 3rem;
                height: 3rem;
                text-align: center;
                flex: 0 0 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .profile-img-wrapper {
    height: 160px;
    width: 120px;
    border: 1px solid #cbcbcb;
    border-radius: 50% !important;
    overflow: hidden;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
    margin: auto;
    margin-bottom: 0.5rem;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  // xs
  @media screen and (max-width: 575px) {
    &.card-agent {
      .card-body {
        .card-profile-jewel img {
          margin-bottom: 1rem;
        }
      }
    }

    &.card-ba_team,
    &.card-division {
      .card-body {
        .ba-team-img-wrapper,
        .division-img-wrapper {
          height: 210px;
          width: 100%;
        }
      }
    }

    &.card-list {
      .card-body {
        tr.list {
          height: 2rem;
          margin-bottom: 0.25rem;
          line-height: 1.5rem;
          font-size: 1.25rem;
        }
      }
    }

    &.card-recruits {
      .card-body {
        .recruits {
          .card-score {
            height: calc(100% - 60px);
            margin: 30px 0;

            .label,
            .score {
              flex: 0 0 100%;
              max-width: 100%;
              font-size: 1.25rem;
              font-weight: 500;
              height: 1.5rem;
              line-height: 1.25rem;
            }

            .score {
              font-size: 1.5rem;
            }
          }
        }

        .recruiter-list {
          margin-left: -5px;
          margin-right: -5px;
          flex: 0 0 calc(100% + 10px);
          max-width: calc(100% + 10px);

          .label {
            padding: 3px 0;
            font-size: 1rem;
            font-weight: 500;
            background-color: rgb(220, 220, 220);
            width: 100%;
          }

          ul.list {
            padding: 0;
            margin: 0;

            li {
              font-weight: 500;

              div:nth-of-type(1) {
                flex: 0 0 60%;
                max-width: 60%;
                padding-left: 5px;
              }

              div:nth-of-type(2) {
                flex: 0 0 40%;
                max-width: 40%;
                padding-right: 5px;
              }

              &.no-recruits {
                div {
                  height: 3rem;
                  line-height: 3rem;
                  flex: 0 0 100%;
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  // sm
  @media screen and (min-width: 576px) {
    margin-left: 10%;
    margin-right: 10%;
    width: calc(100% - 20%);

    &.card-agent {
      .card-body {
        .card-profile-jewel img {
          margin-bottom: 1rem;
        }
      }
    }

    &.card-ba_team,
    &.card-division {
      .card-body {
        height: 280px;

        .ba-team-img-wrapper,
        .division-img-wrapper {
          height: 210px;
          width: 100%;
        }
      }
    }

    &.card-list {
      .card-body {
        tr.list {
          margin-bottom: 0.25rem;
          height: 2rem;
          line-height: 1.5rem;
          font-size: 1.25rem;
          font-weight: 500;
        }
      }
    }

    &.card-recruits {
      .card-body {
        .recruits {
          .card-score {
            height: calc(100% - 60px);
            margin: 30px 0;

            .label,
            .score {
              font-size: 1.25rem;
              font-weight: 500;
              width: 100%;
            }
          }
        }

        .recruiter-list {
          margin-left: -5px;
          margin-right: -5px;
          flex: 0 0 calc(100% + 10px);
          max-width: calc(100% + 10px);

          .label {
            padding: 3px 0;
            font-size: 1rem;
            font-weight: 500;
            background-color: rgb(220, 220, 220);
            width: 100%;
          }

          ul.list {
            padding: 0;
            margin: 0;

            li {
              font-weight: 500;

              div:nth-of-type(1) {
                flex: 0 0 60%;
                max-width: 60%;
                padding-left: 5px;
              }

              div:nth-of-type(2) {
                flex: 0 0 40%;
                max-width: 40%;
                padding-right: 5px;
              }

              &.no-recruits {
                div {
                  flex: 0 0 100%;
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  // md
  @media screen and (min-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;

    &.card-agent {
      height: 280px;

      .card-body {
        .card-profile-jewel img {
          margin-bottom: 0.5rem;
        }
        .agent-points {
          line-height: 1.5rem;
        }
      }
    }

    &.card-ba_team,
    &.card-division {
      height: 380px;

      .card-body {
        .ba-team-img-wrapper,
        .division-img-wrapper {
          height: 210px;
          width: 100%;
        }
      }
    }

    &.card-list {
      height: 460px;

      .card-body {
        tr.list {
          margin-bottom: 0.5rem;
          line-height: 1.125rem;
          font-size: 1rem;
          height: 1.5rem;
        }
      }
    }

    &.card-recruits {
      height: 460px;

      .card-body {
        .recruits {
          .card-score {
            height: calc(100% - 60px);
            margin: 20px 0 30px 0;
          }
        }

        .recruiter-list {
          margin-left: -5px;
          margin-right: -5px;
          flex: 0 0 calc(100% + 10px);
          max-width: calc(100% + 10px);

          .label {
            padding: 3px 0;
            font-size: 1rem;
            font-weight: 500;
            background-color: rgb(220, 220, 220);
            width: 100%;
          }

          ul.list {
            padding: 0;
            margin: 0;

            li {
              font-weight: 500;

              div:nth-of-type(1) {
                flex: 0 0 60%;
                max-width: 60%;
                padding-left: 5px;
              }

              div:nth-of-type(2) {
                flex: 0 0 40%;
                max-width: 40%;
                padding-right: 5px;
              }

              &.no-recruits {
                div {
                  flex: 0 0 100%;
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .card-header {
      font-size: 1rem;
      .card-sub-title {
        font-size: 1.125rem;
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .profile-img-wrapper {
      height: 128px;
      width: 96px;
    }
  }

  // lg
  @media screen and (min-width: 992px) {
    &.card-agent {
      height: 300px;

      .card-body {
        .card-profile-jewel img {
          margin-bottom: 1rem;
        }
      }
    }

    &.card-ba_team,
    &.card-division {
      height: 380px;

      .card-body {
        .ba-team-img-wrapper,
        .division-img-wrapper {
          height: 210px;
          width: 100%;
        }
      }
    }

    &.card-list {
      height: 460px;
    }

    &.card-recruits {
      height: 460px;

      .card-body {
        .recruits {
          .card-score {
            height: calc(100% - 60px);
            margin: 30px 0;
          }
        }

        .recruiter-list {
          margin-left: -5px;
          margin-right: -5px;
          flex: 0 0 calc(100% + 10px);
          max-width: calc(100% + 10px);

          .label {
            padding: 3px 0;
            font-size: 1rem;
            font-weight: 500;
            background-color: rgb(220, 220, 220);
            width: 100%;
          }

          ul.list {
            padding: 0;
            margin: 0;

            li {
              font-weight: 500;

              div:nth-of-type(1) {
                flex: 0 0 60%;
                max-width: 60%;
                padding-left: 5px;
              }

              div:nth-of-type(2) {
                flex: 0 0 40%;
                max-width: 40%;
                padding-right: 5px;
              }

              &.no-recruits {
                div {
                  flex: 0 0 100%;
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .card-header {
      font-size: 1.25rem;

      .card-sub-title {
        font-size: 1.5rem;
        height: 50px;
        padding-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  // xl
  @media screen and (min-width: 1200px) {
  }

  @media screen and (max-width: 1599px) {
    td:first-child,
    td.division-name {
      div {
        width: 5rem;
      }
    }
  }

  @media screen and (max-width: 1079px) {
    td:first-child,
    td.division-name {
      div {
        width: 4rem;
      }
    }
  }

  @media screen and (max-width: 991px) {
    td:first-child,
    td.division-name {
      div {
        width: 2.5rem;
      }
    }
  }

  @media screen and (max-width: 767px) {
    td:first-child,
    td.division-name {
      div {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 479px) {
    td:first-child,
    td.division-name {
      div {
        width: 5rem;
      }
    }
  }

  @media screen and (max-width: 359px) {
    td:first-child,
    td.division-name {
      div {
        width: 3rem;
      }
    }
  }
}
