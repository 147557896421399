.mainSection {
    .add-term-field {
        .form-group {
            label {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}