@import './../../../../styles/global.variables';

#ClientForm {
  min-height: 20vh;
  padding-top: 0.5rem;

  &.content-wrapper {
    padding: 1rem;
    background-color: #fff;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 0.3rem;
  }

  /* ---------------------------------------------------- */
  // Header/title bar content
  .client-form-header {
    display: flex;
    margin-bottom: 1rem;

    & > div {
      display: flex;

      h6 {
        color: $color--usabg--blue;
        font-weight: 500;
        margin-bottom: 0;
        height: 1.5rem;
        line-height: 20px;
      }
    }
  }

  @media screen and (min-width: 576px) {
    .client-form-header {
      flex-wrap: nowrap;
      border-bottom: 1px solid $color--usabg--blue;

      & > div {
        display: flex;

        &:first-of-type {
          flex-grow: 1;
        }

        &:last-of-type {
          flex-shrink: 1;
          height: 1.5rem;

          div.ui-input {
            span.caret {
              top: 5px;
            }

            input.select-dropdown {
              margin-bottom: 0;
              border: none;
              font-size: 14px;
              min-width: 13rem;
              height: 1.5rem;
            }

            label.mdb-main-label {
              margin: 0;
              transform: translateY(0);

              &.active {
                transform: translateY(-18px);
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    .client-form-header {
      flex-wrap: wrap;

      & > div {
        display: flex;

        &:first-of-type {
          border-bottom: 1px solid $color--usabg--blue;
          margin-bottom: 1.5rem;
        }

        &:first-of-type,
        &:last-of-type {
          flex-grow: 1;
          width: 100%;
        }

        div.ui-input {
          width: 100%;

          input.select-dropdown {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .submission-form-header {
    margin-bottom: 1rem;
    border-bottom: 1px solid $color--usabg--blue;
  }
  /* ---------------------------------------------------- */

  .clear-icon {
    position: absolute;
    right: 3px;
    bottom: 2rem;
    cursor: pointer;
  }
  .av-input-wrapper {
    .ui-input {
      padding-bottom: 1rem;

      .error-msg {
        opacity: 1;
        right: 0.5rem;
        position: absolute;
        font-weight: bold;
        color: $color--invalid;
        font-size: 12px;
        transform: translateY(-4px);
      }
    }

    .react-datepicker-popper {
      z-index: 999;
    }
  }

  position: relative;

  .LoadingSpinnerComponent {
    height: auto;
    position: relative;
    div.loading-wrapper {
      transform: translateY(100%);
    }
  }
}
