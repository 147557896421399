@import 'src/styles/global.variables';

#AvPoliciesListComponent {
  h6 {
    color: $color--usabg--blue;
    border-bottom: 1px solid $color--usabg--blue;
    font-weight: 500;
    margin-bottom: 1.5rem;
    position: relative;

    button.btn {
      background-color: transparent !important;
      padding: 0;
      position: absolute;
      right: 0;
      transform: translateY(-0.5rem);
      box-shadow: none;
      color: $color--usabg--blue;
    }
  }

  .card {
    .card-header table.policy-label-table {
      tr:first-of-type > td {
        &:nth-of-type(1) {
          width: 2rem;
        }
        &:nth-of-type(3),
        &:nth-of-type(4) {
          width: 5rem;
          button.btn {
            padding: 0;
            margin: 0;

            &.view-btn {
              background-color: $color--usabg--blue !important;
            }

            &.trash-btn {
              background-color: transparent !important;
              box-shadow: none;
              color: $color--invalid;
            }
          }
        }
      }
    }

    .card-body {
      padding-top: 0;
      // border: 1px solid rgb(220,220,220);
      // border-bottom-left-radius: 0.4rem;
      // border-bottom-right-radius: 0.4rem;
      // background-color: rgb(220,220,220);
    }
  }
}
