@import 'src/styles/global.variables';

#TrainingsPage {
  margin: 0 2%;
  padding-bottom: 4rem;

  .modal-fluid {
    margin-left: 2rem;
    margin-right: 2rem;
    width: calc(100% - 4rem);
    max-width: calc(100% - 4rem);
    max-height: 100%;

    &.blocked-access {
      .modal-header {
        background-color: $color--invalid; // $color--usabg--red
      }
      .modal-body {
        padding-top: 40px;
        text-align: center;
        h4,
        h5 {
          font-weight: bold;
        }
        div {
          text-align: center;
          font-weight: 500;

          @media screen and (min-width: 768px) {
            width: 70%;
            margin-left: 15%;
          }

          a {
            margin: 15px;
            display: block;
            font-size: 24px;

            &.btn {
              background-color: $color--usabg--blue;
              color: #fff;
            }
          }
        }
      }
    }
  }

  h2 {
    display: inline;
  }

  .add-new {
    display: inline;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 5px 10px;
    float: right;
  }

  .header-wrapper {
    margin-bottom: 10px;

    img {
      max-width: 100%;
    }

    & + div {
      margin-bottom: 20px;
    }
  }

  .modal-header {
    background-color: $color--usabg--blue;
    color: #fff;
    font-weight: bold;
    text-align: center;

    .modal-title {
      font-weight: bold;
      text-align: center;
      display: block;
      width: calc(100% - 2rem);
      margin-left: 2rem;

      span {
        font-size: 18px;
      }
    }

    button {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .training-code-form {
    button.btn {
      width: 100%;
    }
  }

  .card-header.usabg-university-logo {
    border: none;
    text-align: center;

    img {
      max-width: 100%;
      margin-bottom: 10px;
    }

    span {
      color: #3f51b5;
      font-weight: 500;
      font-size: 18px;
    }
  }

  .core-trainings,
  .additional-trainings {
    & > div.card {
      padding: 0;
      margin-bottom: 20px;

      & > .card-header {
        font-weight: bold;
      }
      & > .card-body {
        background-color: rgba(0, 0, 0, 0.15);
        padding: 5px;
      }
    }
  }
}
