.AgentManagerRow {
  padding: 0;
  flex-wrap: nowrap !important;

  & > .col {
    border: thin solid rgb(220, 220, 220);
    padding: 5px;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;

    &:not(:first-of-type) {
      padding-top: 10px;
    }

    &.agent-kpis {
      & > div > .alert {
        padding: 0;
        border-radius: 2px;
      }
    }
  }
  .agent-profile-img {
    height: 100px;
    width: 80px;
    object-fit: cover;
  }

  .min-w-5 {
    min-width: 5rem;
  }

  .min-w-6 {
    min-width: 6rem;
  }

  .min-w-7 {
    min-width: 7rem;
  }

  .min-w-8 {
    min-width: 8rem;
  }
}
