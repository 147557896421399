@import './../../styles/global.variables';
@import './../../styles/global.fonts';

#EnrollmentPage {
  overflow: hidden;

  .enroll-heading {
    padding: 25px 15px 15px 15px;

    div,
    h3 {
      @extend .font---lato;
      color: $color--usabg--blue;
      margin: 0;
    }
  }

  .enroll-upline-container {
    background-color: #f8f8f8;
    box-shadow: inset 0px 4px 4px #ebebeb, inset 0px -4px 4px #ebebeb;
    padding-top: 20px;
  }

  .enroll-stages-container {
    padding-bottom: 35rem;
  }
}
