@import 'src/styles/global.variables';

#AgentRequirementsComponent {
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    & > li {
      padding: 3px 0;
      display: flex;
      margin-bottom: 0.5rem;

      & > div {
        // conatiner
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 100%;
        max-width: 100%;

        & > div {
          // row
          display: flex;
          flex: 0 0 100%;
          max-width: 100%;

          &:nth-of-type(1) {
            // row 1
            flex: 0 0 100%;
            max-width: 100%;
            background: rgba(220, 220, 220, 0.4);
            color: #333;
            padding: 2px;
            font-weight: 500;

            span {
              margin-left: 0.5rem;
              opacity: 0.6;
              font-weight: 400;
              font-size: 13px;
              line-height: 25px;
            }
          }

          &:nth-of-type(2) {
            // row 2
            flex: 0 0 100%;
            max-width: 100%;
          }

          & > div {
            display: flex;

            &:nth-of-type(1) {
              // row 1
              padding: 4px;
              flex: 0 0 calc(100% - 5rem);
              max-width: calc(100% - 5rem);
            }

            &:nth-of-type(2) {
              // row 2
              flex: 0 0 5rem;
              max-width: 5rem;
              align-items: center;
              justify-content: center;
              border-radius: 0.3rem;
              font-weight: 600;

              &.pass {
                background-color: rgba(30, 220, 30, 0.5);
                color: $color--success;
                border: 1px solid $color--success;
              }

              &.fail {
                background-color: rgba(220, 50, 30, 0.5);
                color: $color--invalid;
                border: 1px solid $color--invalid;
              }
            }
          }
        }
      }

      &.loader {
        font-size: 18px;
        text-align: center;
        align-items: center;
        justify-content: center;

        i {
          margin-right: 0.25rem;
        }
      }
    }
  }
}
