@import 'src/styles/global.variables';

#ToggleAvAca {
  padding: 0;
  width: 100%;
  margin-bottom: 10px;

  & > .container {
    padding-left: 0;
    margin-left: 0;

    & > .row {
      padding-left: 0;
      margin-left: 0;

      & > .col {
        padding-left: 0;
        margin-left: 0;
        background-color: rgba(210, 210, 210, 0.8);
        border: thin solid rgb(210, 210, 210);
        border-radius: 0.2rem;

        .form-check {
          padding-left: 5px;
          font-weight: bold;
          font-size: 14px;
          color: #333;
        }
      }
    }
  }
}
