@import 'src/styles/global.variables';

.TrainingQuestionComponent {
  background-color: rgba(220, 220, 220, 0.5);
  border: thin solid rgba(220, 220, 220, 0.8);
  border-radius: 0.3rem;
  padding-top: 0.5rem;

  .training-question {
    font-size: 16px;
    font-weight: bold;

    .fas {
      margin-right: 0.3rem;
      color: rgb(180, 180, 180);
    }
  }

  &.answered {
    &.correct {
      border-color: $color--success;

      .training-question .fas {
        color: $color--success;
      }
    }
    &.incorrect {
      border-color: $color--invalid;

      .training-question .fas {
        color: $color--invalid;
      }
    }
  }
}
