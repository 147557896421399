#SalesTrackerPage {
  ul.policy-errors-list {
    padding: 0 0 0 10px;
    margin: 0 10px;

    & > li {
      font-size: 14px;
      font-weight: 500;

      & + li {
        margin-top: 1rem;
      }
    }
  }
}
