@import './../../../../styles/global.variables';

#AdminReferralFormComponent {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-content: flex-start;
  position: relative;

  & > .loading-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid transparent;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    text-align: center;
    transition-property: opacity, visibility;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;

    & > div {
      flex: 0 0 100%;
      max-width: 100%;
      font-size: 1.25rem;
    }
  }

  .alert {
    padding: 2px;
  }

  ul.payment-notes {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      border-left: 1px solid $color--gray--md-1;
      border-right: 1px solid $color--gray--md-1;

      & + li {
        border-bottom: 1px solid $color--gray--md-1;
      }

      table {
        // tr:first-of-type {
        td {
          border-bottom: 1px solid #f7f7f7;
          font-weight: 400;
          vertical-align: top;
          & > div + div {
            border-top: 1px solid #f7f7f7;
          }
        }
        // }
        // tr:last-of-type {
        //   td {
        //     border-bottom: 1px solid #f7f7f7;
        //     font-weight: 500;
        //   }
        // }
      }

      &:first-of-type {
        background-color: $color--gray--md-1;

        & > span {
          flex: 0 0 100%;
          max-width: 100%;
          text-align: left;
          font-size: 0.8rem;
          padding: 0 2px;
        }
      }

      &:not(:first-of-type) {
        padding: 2px;

        &:last-of-type {
          margin-bottom: 1rem;
        }
      }
    }
  }

  &.is-loading {
    opacity: 1;
    visibility: visible;
  }

  & > div.heading {
    color: red;
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    padding: 0 0 1rem 0;
  }

  & > div.col-12,
  & > div.col-6 {
    display: flex;
    flex-flow: column nowrap;
  }
}
