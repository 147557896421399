@import './../../../../styles/global.variables';

#AgentWritingCodeModal {
  .modal-header {
    background-color: $color--invalid;

    h4 {
      text-align: center;
      color: #fff;
      font-weight: bold;
      margin: auto;
    }
  }

  .modal-body {
    & > div.h6 {
      p {
        text-align: center;
        margin: 15px 0 0 0;
      }

      .md-form {
        margin-bottom: 0;
      }
    }

    & > div.form-row {
      margin-top: 30px;

      & > button {
        margin-top: 20px;
      }
    }
  }
}
