@import './../../styles/global.variables';

#AccountAlertsPage {
  .tab-pane {
    & > ul {
      li {
        display: flex;

        & > div {
          &.alert-key {
            font-weight: bold;
            background-color: #fff;
            text-align: center;
          }
          &.alert-message {
          }
          &.alert-action {
            background-color: #fff;
            text-align: center;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
  }

  .tab-pane {
    & > ul {
      padding: 0;
      margin: 0;

      li {
        padding: 1px;
        margin: 3px 0;
        border-radius: 0.3rem;

        & > div {
          &.alert-key {
            flex: 0 0 130px;
            max-width: 130px;
            padding: 5px 0;
            .fa {
              margin-right: 4px;
            }
          }

          &.alert-message {
            flex: 0 0 calc(100% - 130px);
            max-width: calc(100% - 130px);
            padding: 2px 4px;
          }

          &.alert-action {
            display: none;
          }
        }

        .btn {
          background-color: $color--usabg--blue !important;
          color: #fff;
          font-weight: 500;
          padding: 5px 7px;
        }
      }

      li.show-actions {
        & > div {
          &.alert-message {
            flex: 0 0 calc(100% - 280px);
            max-width: calc(100% - 280px);
          }

          &.alert-action {
            display: block;
            flex: 0 0 150px;
            max-width: 150px;
            padding: 5px 0;
          }
        }
      }
    }
  }
}
