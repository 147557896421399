@import './../../../styles/global.variables';

.RenderContractDatesComponent {
  width: 100%;

  & > tbody {
    & > tr {
      & > td {
        vertical-align: middle;
        padding-top: 2px;
        padding-bottom: 2px;
        font-weight: 500;

        &:last-of-type {
          padding-right: 5px;
          text-align: right;
          white-space: nowrap;
          font-size: 13px;
        }

        &:first-of-type {
          padding-left: 5px;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 13px;
        }
      }

      & + tr {
        td {
          border-top: 1px dashed rgba(220, 220, 220, 0.8);
        }
      }
    }
  }
}
