@import './../../../styles/global.variables';

#AuthMessagesComponent {
  position: relative;
  z-index: 0;
  overflow: hidden;

  & > div.alert {
    text-align: center;
    transform: translateY(-125%);
    transition-property: transform, opacity, border-color, background-color,
      color;
    transition-duration: 0.35s;
    transition-timing-function: ease-out;
    opacity: 0;
    font-weight: 500;
    font-family: 'Lato';
    font-size: 15px;
    margin: 0;
    line-height: 21px;
  }

  & > div.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  & > div.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &.show-message {
    & > div.alert-danger,
    & > div.alert-success {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
