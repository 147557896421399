@import './../../../../styles/global.variables';

#AdminW9FormComponent {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-content: flex-start;
  position: relative;

  & > .loading-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid transparent;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    text-align: center;
    transition-property: opacity, visibility;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;

    & > div {
      flex: 0 0 100%;
      max-width: 100%;
      font-size: 1.25rem;
    }
  }

  &.is-loading {
    opacity: 1;
    visibility: visible;
  }

  & > div.heading {
    color: green;
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    padding: 0 0 1rem 0;
  }

  & > div.col-12,
  & > div.col-6 {
    display: flex;
    flex-flow: column nowrap;

    .alert {
      padding: 3px;
      font-size: 0.8rem;
      font-weight: 500;
      text-align: center;
    }

    ul {
      padding: 0;
      margin: 0;
      margin-bottom: 1rem;
      list-style-type: none;

      li {
        flex-wrap: nowrap;
        display: flex;
        align-items: center;
        padding: 1px;
        border-left: 1px solid $color--gray--md-1;
        border-right: 1px solid $color--gray--md-1;

        & + li {
          border-bottom: 1px solid $color--gray--md-1;
        }

        & > span,
        & > small {
          flex-grow: 1;
        }

        small {
          color: #666;
        }

        button {
          flex: 0 0 1.5rem;
          max-width: 1.5rem;
          padding: 2px;
          background-color: transparent !important;
          border-color: transparent;
          font-size: 0.8rem;
          opacity: 0.6;
          transition: opacity 0.15s ease-in-out;

          &:hover {
            opacity: 1;
          }
        }

        &:first-of-type {
          background-color: $color--gray--md-1;

          & > span {
            flex: 0 0 50%;
            max-width: 50%;
            text-align: right;
            font-size: 0.8rem;
            padding: 0 2px;

            &:first-of-type {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
