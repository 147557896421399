@import './../../../styles/global.variables';

#ContractingViewStateChangeButtonComponent {
  height: 100%;
  & > .btn {
    display: flex;
    align-items: center;
    height: 100%;

    & > table {
      margin: auto;

      td {
        font-family: 'Roboto';
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-weight: 500;
        padding: 5px;
        i.fa {
          font-size: 3.5rem;
          margin: 0 10px;
        }
      }
    }
  }
}
