@import './../../styles/global.variables';

#DashboardLayoutComponent {
  & > .modal-backdrop.show {
    opacity: 0.75;
  }

  .payment-modal {
    & > .modal-content {
      & > .modal-header {
        background-color: $color--invalid;
        .close {
          display: none;
        }
      }
      & > .modal-body {
        text-align: center;
        div.salutation {
          text-align: left;
          font-weight: 500;
          margin: 20px;
        }
        p.copy {
          text-align: justify;
          margin: 20px;
        }
        div.closing {
          text-align: left;
          font-weight: 500;
          margin: 20px 20px 40px 20px;
        }
        .btn {
          background-color: $color--invalid !important;
          font-size: 18px;
        }
      }
    }
  }

  & > .side-nav {
    padding: 0;

    & > div.scrollbar-container {
      & > ul > li:first-of-type {
        margin-bottom: 4px;
      }
    }

    small.version-indicator {
      color: #fff;
      text-align: center;
      font-weight: 500;
      font-size: 0.8rem;
      display: inline-block;
      width: 100%;
      opacity: 0.35;
    }
  }

  #main-content {
    position: relative;
    min-height: 100vh;
    height: 100vh;
    overflow: auto;
    background-color: rgb(225, 225, 225);

    #page-content {
      position: relative;
      border-top: thin solid transparent;

      & > :not(.LoadingSpinnerComponent) {
        transition: opacity 0.2s ease-out;
        opacity: 1;
      }

      &.is-loading {
        & > :not(.LoadingSpinnerComponent) {
          transition: opacity 0.2s ease-out;
          opacity: 0;
        }
      }
    }
  }

  @media screen and (min-width: 1300px) {
    #main-content {
      margin-left: 240px;
    }
  }

  .modal-header {
    background-color: $color--usabg--blue;
    color: #fff;
    font-weight: bold;
    text-align: center;

    .modal-title {
      font-weight: bold;
      text-align: center;
      display: block;
      width: calc(100% - 2rem);
      margin-left: 2rem;

      span {
        font-size: 18px;
      }
    }

    button {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  @media screen and (max-width: 575px) {
    #main-content {
      #page-content {
        min-height: calc(100vh - 12rem);
      }
    }
  }

  @media screen and (min-width: 576px) {
    #main-content {
      #page-content {
        min-height: calc(100vh - 8rem);
      }
    }
  }

  @media screen and (min-width: 992px) {
    #main-content {
      #page-content {
        min-height: calc(100vh - 6rem);
      }
    }
  }
}
