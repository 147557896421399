@import './../../../../styles/global.variables';
@import './../../../../styles/global.fonts';

.EnrollmentUpline {
	&>.row {
		flex-wrap: nowrap;

		&>.stage-upline-wrapper {
			display: flex;
			flex: 0 0 40%;
			padding: 2rem 1rem;

			.upline-photo {
				margin: auto;
				padding-bottom: 30px;

				.img-wrapper {
			  	height: 250px;
			  	text-align: center;

			  	& > img {
			    	max-height: 100%;
			  	}
				}

		    .upline-name,
		    .upline-title {
		      @extend .font---lato;
		      text-align: center;
		      color: $color--usabg--blue;
		    }

		    .upline-name {
		      font-weight: 700;
		      font-size: 3rem;
		    }

		    .upline-title {
		      font-weight: 400;
		      font-size: 2rem;
		    }
			}
		}

		&>.stage-indicator-wrapper {
			display: flex;
			flex: 0 0 60%;
		}
	}

	@media screen and (max-width: 575px) {
		&>.row {
			flex-wrap: wrap;

			&>.stage-upline-wrapper {
				flex: 0 0 100%;
			}

			&>.stage-indicator-wrapper {
				flex: 0 0 100%;
			}
		}
	}
}
