@import 'src/styles/global.variables';

#WebsiteInfoFormComponent {
  background-color: rgba(255, 255, 255, 0.25);
  border: 1px solid $color--sky-blue;
  padding: 0.75rem;
  border-radius: 0.5rem;

  .ContentHtmlComponent {
    &>.content-html-inner-wrapper {
      padding: 0;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
