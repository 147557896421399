@import './../../styles/global.variables';

#ServiceConsumptionExamplePage {
  padding-bottom: 2rem;

  .card {
    .card-header {
      background-color: $color--usabg--blue;
      color: #fff;
      font-weight: 500;

      .card-header-btn {
        float: right;
        padding: 2px 4px;
        font-size: 0.8rem;
        font-weight: 500;
        margin: -1px;
      }

      & + .card-header {
        padding: 2px;

        & > form {
          background-color: #fff;
          border: thin solid #fff;
          border-radius: 4px;
          padding: 0;
          margin: 0 3px 2px 3px;

          div.md-form {
            margin: 8px 0 0 0;
          }
        }
      }
    }

    .card-body {
      padding: 5px;

      table {
        width: 100%;

        thead {
          th {
            border-bottom: 1px solid rgb(220, 220, 220);

            &.has-sorter {
              & > div {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;

                & > .label {
                  flex-basis: auto;
                  flex-grow: 0;
                  margin-left: 1rem;
                  padding-right: 5px;
                }

                .sorter {
                  flex: 0 0 1rem;
                  max-width: 1rem;
                  text-align: center;

                  .up,
                  .down {
                    cursor: pointer;
                    opacity: 0.35;
                    border: 1px solid transparent;
                    border-radius: 0.35rem;
                    transition-property: opacity background-color color;
                    transition-duration: 0.2s;
                    transition-timing-function: ease-in-out;

                    &:hover {
                      opacity: 0.6;
                    }
                  }
                }
              }

              &.sorted-asc {
                .sorter .up {
                  background-color: rgba(20, 50, 255, 0.7);
                  .fa {
                    color: #fff;
                  }
                  opacity: 1;
                }
              }

              &.sorted-desc {
                .sorter .down {
                  background-color: rgba(20, 50, 255, 0.7);
                  .fa {
                    color: #fff;
                  }
                  opacity: 1;
                }
              }
            }
          }
        }

        button.btn {
          padding: 0 3px;
          background-color: transparent !important;
          box-shadow: none;
          height: 16px;
          line-height: 16px;

          &:not([disabled]):hover {
            background-color: rgba(220, 220, 220, 0.8) !important;
          }
        }

        tr {
          td:nth-of-type(3) {
            button.btn {
              color: $color--usabg--blue;
            }
          }
          td:nth-of-type(4) {
            button.btn {
              color: $color--invalid;
            }
          }
          &:nth-of-type(even) {
            td {
              background-color: rgba(220, 220, 220, 0.4);
            }
          }
        }
      }

      div.pagination-wrapper {
        position: relative;
        max-width: 90%;
        margin-left: 5%;
        margin-top: 2px;
      }

      div.md-form {
        label {
          transition: margin-left 0.3s ease-in-out;
          &.disabled {
            margin-left: 3px;
          }
        }

        input.form-control {
          &[readonly] {
            background-color: rgba(220, 220, 220, 0.8);
          }
        }
      }
    }
  }
}

#ContactFormComponent {
  .card-header {
    background-color: $color--usabg--blue !important;
    color: white;

    button.btn {
      background-color: transparent !important;
    }
  }
}
