@import './../../styles/global.variables';

.VideoPlayerModal.modal-dialog.modal-fluid {
  transform: translate(0, 0);
  padding: 0;
  position: fixed;
  max-width: calc(100vw - 50px);
  max-height: calc(100vh - 50px);
  width: calc(100vw - 50px);
  height: calc(100vh - 50px);
  margin: 25px;

  & > .modal-content {
    max-height: 100%;
    height: 100%;

    .modal-header {
      background-color: $color--usabg--blue;
      color: #fff;
      font-weight: bold;
      text-align: center;
      height: 6.5rem;

      h4 {
        color: #fff;
        text-align: center;
      }

      span {
        font-size: 18px;
      }
    }

    button {
      color: rgba(255, 255, 255, 0.8);
    }

    & > .modal-body {
      max-height: calc(100% - 6.5rem);

      .video-wrapper {
        height: 100%;
        position: relative;

        & > div {
          display: block;
          margin: auto;
          max-width: 100%;
          max-height: 100%;
          height: 100%;
          transition-property: height, width;
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;
          overflow: hidden;

          & > iframe {
            height: 100%;
            width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }
}
