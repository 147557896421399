@import 'src/styles/global.variables';

#PackagesTableComponent {
	position: relative;

	div.table-wrapper {

		h5 {
			color: #FFF;
			font-weight: 500;
			width: 100%;
			background-color: $color--usabg--blue;
			display: block;
			border: thin solid $color--usabg--blue;
			border-top-left-radius: 0.5rem;
			border-top-right-radius: 0.5rem;
			margin: 0;
			padding: 8px;
		}

		&>table.package-table {
			&>thead {
				background-color: rgba(180,180,180,0.5);

				&>tr {
					border-left: 1px solid rgba(180,180,180,1);
					border-right: 1px solid rgba(180,180,180,1);

					&>th {
						font-weight: 500;

						&:nth-of-type(1) {
							text-align: center;
						}

						&:nth-of-type(1),
						&:nth-of-type(2),
						&:nth-of-type(3),
						&:nth-of-type(4),
						&:nth-of-type(5) {
							border-right: 1px solid rgba(180,180,180,0.85);
						}

						&:nth-of-type(2),
						&:nth-of-type(3),
						&:nth-of-type(4) {
							padding-left: 3px;
						}

						&:nth-of-type(5),
						&:nth-of-type(6) {
							text-align: center;
						}
					}
				}
			}

			&>tbody {
				&>tr {
					border-left: 1px solid rgba(180,180,180,1);
					border-right: 1px solid rgba(180,180,180,1);

					&>td {
						&>div.ui-input,
						&>div.ui-input-button {}

						&:first-of-type {
							&>div.ui-input {
								max-width: 3rem;
							}
						}

						&:nth-of-type(1) {
							font-weight: 400;
							text-align: center;
							border-left: 1px solid rgba(180,180,180,0.25);
						}

						&:nth-of-type(1),
						&:nth-of-type(2),
						&:nth-of-type(3),
						&:nth-of-type(4),
						&:nth-of-type(5),
						&:nth-of-type(6) {
							background-color: rgba(255,255,255,1);
							border-right: 1px solid rgba(180,180,180,0.25);
						}

						&:nth-of-type(2),
						&:nth-of-type(3),
						&:nth-of-type(4) {
							padding-left: 3px;
						}

						&:nth-of-type(5),
						&:nth-of-type(6) {
							text-align: center;
						}

						button.btn.save-package-btn,
						button.btn.edit-package-btn,
						button.btn.delete-package-btn {
							padding: 3px;
							background-color: transparent !important;
							box-shadow: none;
							color: $color--invalid;
							opacity: 0.5;
							transition: opacity 0.15s ease-in-out;

							&:hover {
								opacity: 1;
							}
						}

						button.btn.save-package-btn {
							opacity: 0.75;
							font-size: rem;
							color: $color--success;
						}

						button.btn.edit-package-btn {
							color: $color--orange;
						}

						button.btn.delete-package-btn {
							color: $color--invalid;
						}
					}
				}

				tr + tr {
					border-top: 1px dotted rgba(200,200,200,0.5);
				}

				tr:last-of-type {
					border-bottom: 1px solid rgba(200,200,200,0.75);
				}
			}
		}

		table.edit-package-table {
			tbody {
				tr {
					background-color: rgba(220,220,220,0.75);

					td {
						padding: 0 3px;
						&:nth-of-type(1) {
							.ui-input {
								width: 100%;
								.md-form {
									width: 100%;
								}
							}
						}

						&:nth-of-type(3),
						&:nth-of-type(4) {
							width: 200px;
							text-align: center;

							&>label {
								text-align: center;
								font-size: 0.75rem;
							}

							.ui-input.ui-input-toggle-switch {
								margin: auto;
								display: inline-block;
							}
						}
					}
				}
			}
		}
	}

	.loading-spinner {
		height: 0;
		opacity: 0;
		visibility: hidden;
	}

	div.table-wrapper {
		height: auto;
		opacity: 1;
		visibility: visible;
		transition-property: height, opacity, visibility;
		transition-duration: 0.2s;
		transition-timing-function: ease-in-out;
	}

	&.is-loading {
		&>.loading-spinner {
			height: 40vh;
			opacity: 1;
			visibility: visible;
		}

		div.table-wrapper {
			height: 0;
			opacity: 0;
			visibility: hidden;
		}
	}
}