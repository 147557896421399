@import './../../styles/global.variables';

#TechToolsPage {
  padding-bottom: 2rem;

  .header-img {
    text-align: center;

    img {
      max-width: 100%;
    }
  }

  .email-error-modal {
    .modal-header {
      background-color: $color--invalid;
      h4 {
        font-weight: 500;
      }
    }
    .modal-body {
      table {
        margin: auto;
        td {
          font-weight: 500;
        }
      }
      .fa {
        margin-right: 10px;
      }
    }
    button.btn.btn-default {
      background-color: $color--invalid !important;
      font-weight: 500;
    }
  }
}
