@import 'src/styles/global.variables';

.ui-datatable-component {
  .loading-spinner {
    position: absolute;
    height: calc(30vh - 2rem);
    top: 20px;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition-property: opacity, visibility, height;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
  }

  & > .table-filters {
    padding-top: 1rem;
    border: 1px solid transparent;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;

    .ui-input,
    .ui-input-select {
      margin-right: 5px;

      input,
      input.form-control {
        margin-bottom: 8px;
        height: 40px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 5px;
        line-height: 40px;
        background-color: #fff;
        border: thin solid transparent;
        border-radius: 0.3rem;
      }

      label {
        font-weight: 500;

        &.active {
          transform: translateY(-18px);
          font-size: 12.8px;
          color: $color--usabg--blue;
          padding-left: 0;
        }
      }

      span.caret {
        z-index: 100;
        pointer-events: none;
      }
    }

    .ui-input-button {
      .ui-input {
        margin-right: 5px;

        input.form-control {
          padding-left: 5px;
        }

        label {
          padding-left: 5px;
          display: block;
          margin-top: 0;
          font-size: 14px;
          font-weight: 500;

          &.active {
            transform: translateY(-18px);
            font-size: 12.8px;
            color: $color--usabg--blue;
            padding-left: 0;
          }
        }
      }
    }

    .ui-ib-button {
      button.btn {
        background-color: $color--usabg--blue !important;
      }
    }

    button.btn.export-btn {
      background-color: $color--usabg--blue !important;
      color: #fff;
      margin-top: 5px;
    }
  }

  .table-visibility-wrapper {
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition-property: opacity, visibility, height;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    margin: 0;
    overflow: auto;

    & > div:first-of-type > table {
      margin: 0;

      thead {
        tr {
          th {
            font-weight: 600;
            font-size: 1rem;
            padding: 3px 6px;
            border-top: 1px solid rgba(190, 190, 230, 0.75);

            & + th {
              border-left: 2px dotted rgb(190, 190, 230);
            }

            &:first-of-type {
              border-left: 1px solid rgb(210, 210, 210);
            }

            &:last-of-type {
              border-right: 1px solid rgb(210, 210, 210);
            }

            div.table-col-sorter {
              display: flex;
              flex-wrap: nowrap;
              align-items: center;

              & > label {
                flex: 0 0 calc(100% - 1.5rem);
                max-width: calc(100% - 1.5rem);
                margin: 0;
                align-self: center;
                flex-shrink: 0;
                padding-right: 1rem;
              }

              & > div.sorter {
                flex: 0 0 1.5rem;
                max-width: 1.5rem;
                text-align: center;

                & > div.up,
                & > div.down {
                  height: 1.25rem;
                  line-height: 1.25rem;
                  border: thin solid transparent;
                  border-radius: 0.25rem;
                  cursor: pointer;
                  opacity: 0.8;
                  transition-property: opacity, background-color;
                  transition-duration: 0.2s;

                  &:hover {
                    background-color: rgba(200, 200, 255, 0.5);
                    opacity: 1;
                  }
                }
              }

              &.sort-asc > div.sorter > div.up {
                background-color: rgba(20, 80, 245, 0.5);
                opacity: 1;
                &:hover {
                  background-color: rgba(20, 80, 245, 0.5);
                }
              }

              &.sort-desc > div.sorter > div.down {
                background-color: rgba(20, 80, 245, 0.5);
                opacity: 1;
                &:hover {
                  background-color: rgba(20, 80, 245, 0.5);
                }
              }

              &:not(.is-sortable) {
                & > label {
                  flex: 0 0 calc(100% - 0.125rem);
                  max-width: calc(100% - 0.125rem);
                }
                & > div.sorter {
                  flex: 0 0 0.125rem;
                  max-width: 0.125rem;
                  opacity: 0;
                  visibility: hidden;

                  & > div.up,
                  & > div.down {
                    pointer-events: none;
                  }
                }
              }
            }
          }
          th {
            div.table-col-sorter {
              display: flex;
              flex-wrap: nowrap;
              align-items: center;

              & > label {
                flex: 0 0 calc(100% - 1.5rem);
                max-width: calc(100% - 1.5rem);
                margin: 0;
                align-self: center;
                flex-shrink: 0;
                padding-right: 1rem;
              }

              & > div.sorter {
                flex: 0 0 1.5rem;
                max-width: 1.5rem;
                text-align: center;

                & > div.up,
                & > div.down {
                  height: 1.25rem;
                  line-height: 1.25rem;
                  border: thin solid transparent;
                  border-radius: 0.25rem;
                  cursor: pointer;
                  opacity: 0.8;
                  transition-property: opacity, background-color;
                  transition-duration: 0.2s;

                  &:hover {
                    background-color: rgba(200, 200, 255, 0.5);
                    opacity: 1;
                  }
                }
              }

              &.sort-asc > div.sorter > div.up {
                background-color: rgba(20, 80, 245, 0.5);
                opacity: 1;
                &:hover {
                  background-color: rgba(20, 80, 245, 0.5);
                }
              }

              &.sort-desc > div.sorter > div.down {
                background-color: rgba(20, 80, 245, 0.5);
                opacity: 1;
                &:hover {
                  background-color: rgba(20, 80, 245, 0.5);
                }
              }

              &:not(.is-sortable) {
                & > label {
                  flex: 0 0 calc(100% - 0.125rem);
                  max-width: calc(100% - 0.125rem);
                }
                & > div.sorter {
                  flex: 0 0 0.125rem;
                  max-width: 0.125rem;
                  opacity: 0;
                  visibility: hidden;

                  & > div.up,
                  & > div.down {
                    pointer-events: none;
                  }
                }
              }
            }
          }
        }
      }

      tbody {
        border: 1px solid rgb(190, 190, 230);

        td {
          font-weight: 500;
          padding: 3px 6px;

          .btn.edit-btn {
            padding: 0;
            box-shadow: none;
            transition-property: background-color, color;
            transition-duration: 0.2s;
            transition-timing-function: ease-in-out;
            background-color: transparent;
            color: darkblue;

            .fa {
              color: inherit;
            }

            &:not([disabled]):hover {
              background-color: $color--usabg--blue;
              color: #fff;
            }
          }

          & + td {
            border-left: 2px dotted rgb(190, 190, 230);
          }
        }

        & > tr {
          background-color: rgba(135, 235, 255, 0.15);

          &:nth-of-type(odd) {
            background-color: rgba(255, 255, 255, 0.7);
          }
        }
      }

      tfoot {
        td {
          background-color: rgb(250, 250, 250);
        }
      }
    }

    // div.table-pagination {
    //   padding: 0.5rem 1rem;
    //   border: 1px solid transparent;
    //   border-bottom-left-radius: 0.4rem;
    //   border-bottom-right-radius: 0.4rem;
    // }

    div.table-responsive {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-color: rgb(210, 210, 210);
      display: none;

      table.dataTable {
        margin-bottom: 0;

        thead {
          tr {
            th {
              border-right: none !important;
              border-top: 1px solid rgba(30, 30, 30, 0.35);

              & + th {
                border-left: 1px solid rgba(30, 30, 30, 0.35);
              }
            }
          }
        }

        table.footer-table {
          margin: 0;
          & > tbody {
            border: none;
            & > tr {
              & > td {
                padding: 0;
                border: none;

                &:nth-of-type(1) {
                  padding-right: 4px;
                }
              }

              & + tr {
                border-top: 1px dotted #d3d3d3;
              }
            }
          }
        }
      }
    }
  }

  .table-responsive {
    padding: 0;
    background-color: #fff;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 0.3rem;
  }

  &.is-fetching {
    height: 30vh;

    .loading-spinner {
      visibility: visible;
      opacity: 1;
      height: calc(30vh - 2rem);
    }

    .table-pagination {
      opacity: 0;
    }
  }

  &:not(.is-fetching) {
    .table-visibility-wrapper {
      visibility: visible;
      opacity: 1;
      height: auto;
    }
  }
}
