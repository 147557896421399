@import 'src/styles/global.variables';

#QuoteFormComponent {
	background-color: #FFF;
	padding-top: 1rem;
	padding-bottom: 1.5rem;
	display: flex;
	height: 100%;

	.quote-bg {
		height: 100%;
		background-size: contain;
		background-repeat: no-repeat;
	}

	form {
		background-color: #e5e8ef;
		display: flex;
		flex-wrap: wrap;

		.form-title-cell,
		.form-input-submit {
			flex: 0 0 100%;
			max-width: 100%;
			padding: 1rem 0.25rem 0.25rem 0.5rem;

			h6 {
				font-weight: 500;
			}

			button.btn {
				background-color: $color--blue !important;
				color: #FFF;
				border-color: #FFF;

				&:hover {
					border-color: $color--green;
				}
			}
		}

		.form-input-cell {
			padding: 5px;

			flex: 0 0 100%;
			max-width: 100%;
		}

		.cell-xs-12 {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	@media screen and (max-width: 991px) {
		.quote-bg {
			display: none;
		}
	}

	@media screen and (min-width: 992px) {
		form {
			.form-input-cell {
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
	}
}