#CarrierPrioritiesPage {
  .card-header {
    background-color: #404f9f;
    color: white;
    font-weight: bold;
  }
  .card-body {
    padding: 0;
  }
  table {
    margin: 0;
    thead {
      th {
        vertical-align: middle;
        label {
          margin: 0;
        }
      }
    }

    tr {
      th,
      td {
        &:nth-of-type(1),
        &:nth-of-type(2) {
          width: 30%;
        }
        &:nth-of-type(3) {
          width: 40%;
          word-break: break-word;
        }
      }
    }
  }
  .table-col-sorter:not(.is-sortable) .sorter {
    display: none;
  }

  .sorter > div {
    padding: 0 0.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    border: thin solid transparent;
    border-radius: 0.25rem;
  }

  .sorter .up,
  .sorter .down {
    cursor: pointer;
  }

  .sorter .up:hover,
  .sorter .down:hover {
    background-color: #dee2e6;
    color: black;
  }

  .sort-asc .sorter .up,
  .sort-desc .sorter .down {
    background-color: rgba(20, 80, 245, 0.5);
    &:hover {
      background-color: #dee2e6;
    }
  }

  td {
    font-weight: 400;

    & > .carrier-logo-container {
      text-align: center;

      img {
        height: 3rem;
      }
    }
  }
}
