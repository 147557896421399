#ResendEmailModal {
  & > .modal-lg.modal-dialog {
    flex: 0 0 90vw;
    max-width: 90vw;
    margin-left: 5vw;
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  p {
    word-break: break-all;
    margin-bottom: 0.2rem;
  }

  p:last-child {
    margin-bottom: 1rem;
  }

  tbody {
    td {
      cursor: pointer;
    }
  }

  .emailDetail {
    background-color: rgb(225, 225, 225);
    padding: 10px;
    border-radius: 5px;

    .emailDetailHeader {
      h5 {
        margin-bottom: 0;
      }
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .close-icon {
      cursor: pointer;
    }
  }
}
