@import './../../styles/global.variables';

#AgentLedgersPage {
  .ledger-actions.btn-group {
    flex-wrap: wrap;
    button.btn {
      flex-basis: 100%;
      padding: 2px 5px;
      background-color: $color--usabg--blue !important;
      color: #fff;
      margin: 0;
    }
  }

  .buck-logo {
    img {
      @media (max-width: 767px) {
        height: 120px;
      }

      @media (min-width: 768px) {
        height: 160px;
      }
    }
  }
}
