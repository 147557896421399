@import 'src/styles/global.variables';

#ApptBookerComponent {
	text-align: center;
	background-color: $color--usabg--blue;
	display: flex;

	&>.row {
		padding-left: 6rem;
		padding-right: 6rem;

		&>div {
			display: flex;
			flex-wrap: nowrap;

			&>.appt-label {
				font-size: 2.5rem;
				line-height: 2.5rem;
				color: #FFF;
				font-style: italic;
				font-weight: 700;
				padding: 1.75rem;
				align-self: center;
				cursor: pointer;
			}

			&>.appt-icon {
				font-size: 10rem;
				margin: auto;
				color: #FFF;
				align-self: center;
				cursor: pointer;
			}
		}
	}

	@media screen and (max-width: 991px) {
		&>.row {
			padding-left: 1rem;
			padding-right: 1rem;

			&>div {
				&>.appt-label {
					font-size: 1.75rem;
					line-height: 1.75rem;
					padding: .75rem;
				}

				&>.appt-icon {
					font-size: 5rem;
				}
			}
		}
	}
}