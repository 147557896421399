@import 'src/styles/global.variables';

#AgentHeaderComponent {
  position: absolute;
  top: 0;
  width: 100%;

  .rd-navbar-statement {
    color: #999;
    font-size: 10px;
  }

  /*
	@media screen and (max-width: 991px) {
		&>.rd-navbar-wrap {
			&>nav.rd-navbar {
				&>#AgentStickyNavbar {
					max-width: 100%;
			    transition-property: visibility opacity transform;
			    transition-duration: 0.35s;
			    transition-timing-function: ease-in-out;
			    visibility: visible;
			    opacity: 1;
			    transform: translateY(0);
		      position: fixed;
		      left: 0;
		      top: 0;
		      right: 0;
		      z-index: 999;
		      background: #fff;
		      height: 9rem;

					&.sticky .rd-navbar-group {
					  padding: 15px 15px;
					}

					.rd-navbar-panel {
						max-width: 100%;
						display: flex;

						&>button {
							height: 8rem;
						}
						&>a {
							img {
								max-width: 100%;
								height: auto;
								max-height: 8rem;
							}
						}
					}

					.rd-navbar-nav-wrap.toggle-original-elements {
						position: fixed;
						top: calc(9rem - 1px);
						left: 0;
						bottom: -56px;
						z-index: 998;
						width: 270px;
						padding: 112px 0 56px;
						color: #fff;
						-webkit-transform: translateX(-105%);
						transform: translateX(-105%);
						overflow: hidden;
						transition: 300ms ease;
						height: 500px;
						border: 1px solid #FFF;
						border-bottom-right-radius: 0.75rem;

						li {
							img {
								margin: auto;
								margin-top: 1rem;
								margin-bottom: 1rem;
								display: block;
							}
						}

						&.active {
					    -webkit-transform: translateX(0);
					    transform: translateX(0);
					    box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.5);
						}
					}
				}
			}
		}
	}
  */

  @media screen and (max-width: 767px) {
    & > .rd-navbar-wrap {
      & > nav.rd-navbar {
        & > #AgentStickyNavbar {
          max-width: 100%;
          transition-property: visibility opacity transform;
          transition-duration: 0.35s;
          transition-timing-function: ease-in-out;
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          z-index: 999;
          background: #fff;

          &.sticky .rd-navbar-group {
            padding: 15px 15px;
          }

          .rd-navbar-panel {
            max-width: 100%;
            display: flex;

            & > button {
              height: 8rem;
            }
            & > a {
              img {
                max-width: 100%;
                height: auto;
                max-height: 8rem;
              }
            }
          }
        }
        .rd-navbar-statement {
          padding: 0px;
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    & > .rd-navbar-wrap {
      & > nav.rd-navbar {
        & > #AgentStickyNavbar {
          max-width: 100%;
          transition-property: visibility opacity transform;
          transition-duration: 0.35s;
          transition-timing-function: ease-in-out;
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          z-index: 999;
          background: #fff;

          &.sticky .rd-navbar-group {
            padding: 15px 15px;
          }

          .rd-navbar-panel {
            max-width: 100%;
            width: 100%;
            display: flex;
            align-items: center;

            & > button {
              height: 8rem;
            }
            & > a {
              img {
                max-width: 100%;
                height: auto;
                max-height: 8rem;
              }
            }
          }
          .rd-navbar-statement {
            padding: 0px;
          }

          .cta-container {
            margin-left: auto;
          }
        }
      }
    }
  }

  .cta-container {
    display: inline-block;

    @media (min-width: 992px) {
      display: none;
    }

    @media (max-width: 767px) {
      display: none;
    }

    .cta-wrapper {
      display: flex;
    }
  }

  .cta-wrapper {
    display: flex;
    background-color: $color--usabg--red;
    height: auto;
    align-items: center;
    border: 1px solid transparent;
    overflow: hidden;
    border-radius: 0.4rem;
    text-align: center;

    @media (max-width: 991px) and (min-width: 768px) {
      display: none;
    }

    a {
      display: block;
      font-size: 16px;
      line-height: 28px;
      color: $color--usabg--blue;
      font-weight: 700;
      text-align: center;

      span.phone-number {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        display: block;
      }
    }
  }

  #menuBar {
    .rd-navbar-nav {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-left: auto;
      flex-shrink: 1;

      @media (max-width: 991px) {
        display: block;
      }

      li {
        &.supplemental {
          text-align: center;
          padding-bottom: 1rem;

          img {
            margin-bottom: 1rem;
          }
        }

        @media screen and (max-width: 991px) {
          &.supplemental {
            & > div {
              &.cta-wrapper {
                a {
                  flex-grow: 1;
                }
              }

              &:nth-of-type(3) {
                flex-grow: 1;
              }
            }
          }
        }

        @media screen and (min-width: 992px) {
          &:not(.supplemental) {
            flex-grow: 1;
            margin: 0;
            text-align: right;

            & > a {
              margin-left: auto;
            }

            ul.rd-navbar-dropdown {
              & > li {
                width: 100%;
                max-width: 100%;
                text-align: left;

                & > a {
                  display: block;
                }

                & + li {
                  margin-top: 1rem;
                }
              }
            }
          }

          &.supplemental {
            flex-basis: 100%;
            display: flex;
            flex-wrap: nowrap;
            padding-top: 2rem;
            margin-left: auto;

            .yearsField {
              display: flex;
              align-items: center;
              padding-right: 2rem;
              padding-left: 2rem;

              img {
                width: 140px;
                max-height: fit-content;
                margin-bottom: 0px;
              }
            }

            & > div {
              max-width: 33%;
              max-height: 3.5rem;

              &:first-of-type {
                margin-left: auto;
              }

              a {
                max-width: 100%;
              }

              img {
                max-height: 3.5rem;
              }

              &.cta-wrapper {
                text-align: center;
                min-width: 20px;

                a {
                  font-size: 24px;
                  line-height: 22px;
                  margin: auto;

                  span.phone-number {
                    font-size: 20px;
                  }
                }
              }

              &:nth-of-type(3) {
                flex-grow: 1;
              }
            }
          }
        }
      }
    }
  }
}
