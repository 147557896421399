@import './../../../../styles/global.variables';
@import './../../../../styles/global.fonts';

#EnrollmentStageManagerComponent {

	&>.container>.row {

		&>div {
			position: relative;

			&>.loading-wrapper {
				position: absolute;
				top: 0;
				width: calc(100% - 30px);
				min-height: 50vh;
				opacity: 0;
				transition-property: opacity;
				transition-timing-function: ease-in-out;
				transition-duration: 0.15s;

				&.is-loading {
					opacity: 1;
				}
			}

			&>.stage-wrapper {
				position: relative;
				background-color: #FFF;
				height: auto;
				overflow: visible;
			}
		}
	}

	.intake-form {

		div.ui-input,
		div.ui-input-select,
		div.ui-date-picker-input {
			position: relative;

			.md-form {
				position: relative;
				margin-bottom: 0;

				&>input.form-control,
				&>input.select-dropdown {
					font-size: 1.125rem;
					padding: 2px 4px;
					font-weight: 400;
				}

				&>input.select-dropdown {
			    font-size: 1.125rem;
			    padding: 2px 4px;
			    font-weight: 400;
			    line-height: 1.5;
			    height: auto;
			    min-height: 34px;
			  }

				&>label {
					font-size: 1rem;
					font-weight: 400;

					&:not(.active) {
						transform: translate(0.25rem,0.5rem);
					}

					&.active {
						transform: translate(2px, -1rem) scale(0.75);
					}
				}
			}

			div.ui-input-validation {
				position: absolute;
				font-size: 0.8rem;
				font-weight: 500;
				right: 0;
				transform: translate(-0.25rem, -0.5rem);

				&>span.success-msg {
					color: $color--valid;
				}

				&>span.error-msg {
					color: $color--invalid;
				}
			}

			&.ui-input-select {
		    div.ui-input-validation {
		    	transform: translate(-0.25rem, -1rem);
		    }
			}

			&.show-validity {
				&.is-valid {
					div.ui-input-validation > span.error-msg {
						display: none;
					}
				}

				&.is-invalid {
					.md-form > label {
						color: $color--invalid;
					}

					div.ui-input-validation > span.success-msg {
						display: none;
					}
				}
			}
		}

		div.intake-action-btns {
			margin-top: 3rem;
			button.btn.intake-action-btn {
				margin: auto;
				display: block;

				&.appear-disabled {
					opacity: 0.7;
					&:hover{
						box-shadow: none;
					}
				}
			}			
		}
	}

  .stage-heading {
    text-align: center;
    padding: 30px 15px;
    h3 {
      @extend .font---lato;
      border-bottom: 1px solid $color--usabg--blue;
    }
  }

  button.btn {
    @extend .font---lato;
    border-radius: 2rem;
    background-color: rgb(30, 144, 255) !important;
    color: #fff;

    &:hover {
      background-color: rgb(24, 115, 204) !important;
    }

    span + i.fa,
    i.fa + span {
      margin-left: 1rem;
    }
  }
}