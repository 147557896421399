@import 'src/styles/global.variables';

#AgentLedgerManagerComponent {
  .list-column,
  .form-column {
    transition-property: opacity, visibility, flex, max-width;
    transition-timing-function: ease-in-out;
    transition-duration: 0.25s;
    width: 670px;

    #EditFormComponent + #W9ManagerComponent {
      margin-top: 1.5rem;
    }
  }

  &.show-list-details {
    .list-column {
    }

    .form-column {
      opacity: 0;
      visibility: hidden;
      flex: 0 0 0;
      max-width: 0;
    }
  }

  &:not(.show-list-details) {
    .list-column {
      max-width: 310px;
    }
  }
}
