@import './../../../../styles/global.variables';
@import './../../../../styles/global.fonts';

#IntakeFormPaymentComponent {

  .invoice {
    & > table {
      width: 100%;

      & > tbody {
        & > tr:nth-of-type(1) {
          td:first-of-type {
            text-align: left;
            font-size: 1.5rem;
          }
          td:last-of-type {
            text-align: right;
            font-size: 1rem;
          }
          td {
            border-bottom: 2px solid #d3d3d3;
          }
        }

        & > tr:nth-of-type(2) {
          td {
            height: 15px;
          }
        }

        & > tr:nth-of-type(3),
        & > tr:nth-of-type(4) {
          td:first-of-type {
            text-align: left;
            font-size: 1.125rem;
            font-size: 500;
          }
          td:last-of-type {
            text-align: right;
            font-size: 1.125rem;
            font-size: 500;
          }
          td {
            padding: 3px;
            border-bottom: 1px solid #f2f2f2;
          }
        }

        & > tr:nth-of-type(5) {
          td {
            height: 35px;
          }
        }

        & > tr:nth-of-type(6) {
          td:nth-of-type(1) {
            text-align: left;
            .revert-plan {
              text-align: center;
              font-size: 1.5rem;
              color: #fff;
              cursor: pointer;
              background-color: dodgerblue !important;
              margin-top: 2px;
              font-weight: 500;
              padding: 2px;
              border: 1px solid dodgerblue;
              border-radius: 4px;
            }
          }
          td:nth-of-type(2) {
            text-align: right;
            font-size: 1.25rem;
          }
          td:nth-of-type(3) {
            text-align: right;
            font-size: 1.25rem;
          }
          td {
            border-top: 2px solid #d3d3d3;
          }
        }
      }
    }
  }

  .payment-form {
  	position: relative;
  	padding-top: 1rem;

  	.LoadingSpinnerComponent {
  		min-height: 250px;
  	}

    div > label {
      font-size: 1.25rem;
      width: 100%;
      text-align: left;
    }

    .cc-input-wrapper {
    	&>div {
      	&>label,
      	&>div {
      		width: 100%;
      	}
    	}
    }
  }
}
