@import './../../../../styles/global.variables';

#LeaderboardFilterSelector {
  padding: 0;

  & > .row {
    .btn.board-type-btn {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 10px;

      &.btn-indigo {
        border-color: $color--indigo;
        background-color: $color--indigo !important;
      }

      &.btn-red {
        border-color: #ff1744;
        background-color: #ff1744 !important;
      }

      &.btn-green {
        border-color: #28b84f;
        background-color: #28b84f !important;
      }

      &.btn-orange {
        border-color: $color--orange;
        background-color: $color--orange !important;
      }

      &.btn-purple {
        border-color: $color--purple;
        background-color: $color--purple !important;
      }
    }
  }

  .row.type-btns,
  .row.filter-btns {
    button.btn {
      padding-left: 5px;
      padding-right: 5px;
    }
    @media screen and (min-width: 576px) {
      & > div {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .row.filter-btns {
    .btn {
      margin-bottom: 10px;
    }

    .btn.btn-blue {
      border-color: #2979ff;
      background-color: #2979ff !important;
    }

    .btn.btn-red {
      border-color: #ff1744;
      background-color: #ff1744 !important;
    }

    .btn.btn-green {
      border-color: #28b84f;
      background-color: #28b84f !important;
    }

    .btn.btn-orange {
      border-color: $color--orange;
      background-color: $color--orange !important;
    }

    .btn.btn-purple {
      border-color: $color--purple;
      background-color: $color--purple !important;
    }

    .fa {
      visibility: visible;
      width: 1rem;
      margin-right: 5px;
    }

    .btn:not(.btn-active) {
      .fa {
        visibility: hidden;
        width: 0;
        margin-right: 0;
      }
    }
  }
}
