@import './../../styles/global.variables';

#AgentContractingPage {
  ul.support-link-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
    flex-wrap: wrap;

    & > li {
      flex: 0 0 calc(50% - 10px);
      flex-grow: 1;
      background-color: rgba(220, 220, 220, 1);
      margin: 5px;
      border: thin solid rgba(200, 200, 200, 1);
      border-radius: 0.3rem;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
      font-weight: 500;
      color: #007bff;
      display: flex;
      align-items: center;
      justify-content: center;

      & > a,
      & > div {
        display: flex;
        min-height: 30px;
        align-items: center;
        justify-content: center;
        padding-left: 5px;
        padding-right: 5px;
      }

      &:hover {
        background-color: rgba(200, 200, 200, 1);
      }
    }
  }

  .eagent-content-modal {
    .modal-body {
      .ContentHtmlComponent > .content-html-inner-wrapper > div:first-of-type {
        a {
          background-color: $color--usabg--blue;
          color: #fff;
          font-weight: 400;
          padding: 7px;
        }
      }
    }
  }
}
