@import './../../../../styles/global.variables';

#TrainingVideoForm {
  .md-form {
    margin-bottom: 0;
  }

  .error-msg {
    color: $color--invalid;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 0.5rem;
    float: right;
    height: 1.5rem;
  }

  button.btn {
    margin: 1.5rem 0;
  }
}
