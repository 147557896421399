@import './../../styles/global.variables';

#OrderLeadsPage {
  .PageHeadingComponent {
    & > .ph-row {
      & > div.ph-label,
      & > div.ph-btn-grp {
        @media screen and (max-width: 575px) {
          flex: 0 0 100%;
        }
      }
    }
  }

  .lead-pkg-wrapper {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    .card {
      width: 100%;
    }
  }

  a.lead-trans-btn {
    padding: 5px;
    font-weight: bold;
    text-align: center;
    display: block;
    border-color: #0d5915;
    background-color: #093c0e !important;
    color: #fff;

    span.golden {
      display: block;
      width: 100%;
      font-size: 1.25rem;
      background-image: linear-gradient(
        to right,
        #462523 0,
        #cb9b51 15%,
        #f6e27a 35%,
        #f6f2c0 50%,
        #f6e27a 65%,
        #cb9b51 85%,
        #462523 100%
      );
      color: transparent;
      -webkit-background-clip: text;
    }
  }

  a.lead-vendor-btn {
    padding: 5px;
    font-weight: bold;
    text-align: center;
    display: block;
    border-color: #127d1e;
    background-color: #17a327 !important;
    color: #fff;

    & + small {
      padding: 5px;
      display: block;
      line-height: 14px;
      color: #999;
      text-align: center;
    }
  }

  table.pkg-qty-prices {
    border: 1px solid #d3d3d3;
    width: 100%;

    thead {
      background-color: #d3d3d3;
    }

    tr {
      td {
        &:nth-of-type(1) {
          width: 1.5rem;
        }
        &:nth-of-type(4) {
          width: 6rem;
        }

        button.btn {
          margin-top: 0 !important;
          padding: 2px 5px;
          font-size: 14px;
          font-weight: 500;
          background-color: $color--success !important;
          .invalid {
            display: none;
          }
        }
      }

      &.is-invalid {
        td {
          &:not(:last-of-type) {
            text-decoration: line-through;
          }
          button.btn {
            background-color: #fff !important;
            color: $color--invalid !important;
            box-shadow: none;

            .valid {
              display: none;
            }
            .invalid {
              display: inline-block;
            }
          }
        }
      }

      & + tr {
        border-top: 1px solid #d3d3d3;
      }
    }
  }

  table.are-you-sure {
    width: 100%;
    tr {
      td {
        font-weight: 500;

        &:nth-of-type(1) {
          width: calc(100% - 4rem);
        }

        &:nth-of-type(2),
        &:nth-of-type(3) {
          max-width: 2rem;
        }

        &:nth-of-type(2) {
          button.btn {
            background-color: $color--success !important;
          }
        }

        &:nth-of-type(3) {
          button.btn {
            background-color: $color--invalid !important;
          }
        }
      }
    }
  }

  .ContentVideoComponent {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    & > .content-video-inner-wrapper {
      width: calc(50% - 2rem);
      margin: 1rem;
      position: relative;
    }
  }

  @media screen and (max-width: 767px) {
    .ContentVideoComponent > .content-video-inner-wrapper {
      width: 100%;
    }
  }
}
