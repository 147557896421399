@import './../../../../styles/global.variables';

#UserEventsComponent {
  list-style-type: none;
  padding: 0;
  margin: 0 0 1.5rem 0;

  .ulog-list-header {
    font-family: 'Lato';
    font-size: 13px;

    .ulog-event-descrip,
    .ulog-related-model,
    .ulog-payload,
    .ulog-created-at {
      border-left: 1px dotted rgba(200,200,200,0.75);
    }

    .ulog-event-type,
    .ulog-event-descrip,
    .ulog-related-model,
    .ulog-payload,
    .ulog-created-at {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  li {
    .alert {
      border-radius: 0;
    }
    &:nth-of-type(2) {
      .alert {
        border-top-left-radius: 0.4rem;
        border-top-right-radius: 0.4rem;
      }
    }
    &:last-of-type {
      .alert {
        border-bottom-left-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
      }
    }
  }

  transition: opacity 0.2s ease-in-out;
  opacity: 1;

  &.is-loading {
    opacity: 0.5;
  }
}
