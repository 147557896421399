@import './../../../styles/global.variables';

#AgentProfileIntake {
  position: relative;
  padding-top: 25px;

  span.validity {
    display: inline-block;
    transition-property: border-radius, border-color, border-width,
      background-color;
    transition-timing-function: ease-in-out;
    transition-duration: 0.15s;
    border-radius: 50%;
    border: thick solid rgba(220, 220, 220, 0.8);
    background-color: rgba(220, 220, 220, 0.8);
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 20px;

    &.valid {
      border-color: $color--success;
      background-color: $color--success;
    }

    .fa {
      color: #fff;
    }

    span.incomplete-tag {
      color: #fff;
    }
  }

  .content-wrapper {
    padding-top: 50px;
  }

  .state-licenses-input-wrapper {
    padding: 0;
    margin-left: 0;
    margin-right: 0;

    & > div {
      padding: 0;
      margin: 0;
    }
  }

  .blank-check-input-wrapper {
    .voided-check-img-wrapper {
      min-height: 150px;
      line-height: 150px;
      text-align: center;
      font-weight: 600;
      letter-spacing: 0.2;
      font-size: 20px;
      color: rgba(100, 100, 100, 0.8);
      background-color: rgba(220, 220, 220, 0.5);
      position: relative;

      .delete-btn {
        position: absolute;
        top: 10px;
        right: 0;
        height: 2rem;
        transform: translateY(-100%);
        cursor: pointer;

        .fa {
          display: block;
        }

        &:hover {
          .fa {
            color: $color--invalid;
          }
        }
      }

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
    .create-check-wrapper {
      .md-form {
        margin-top: 4px;
        margin-bottom: 8px;
      }
    }
  }

  .blank-check-input-wrapper,
  .npn-input-wrapper,
  .state-licenses-input-wrapper {
    .npn-text {
      font-weight: 400;
      font-size: 14px;
      margin: 2px;

      h5 {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        padding: 0;
        line-height: 30px;
      }
    }

    .md-form {
      margin: 0;
      input {
        margin: 0;
      }
    }
    hr {
      margin: 0;
    }
  }

  @media screen and (max-width: 767px) {
    .content-wrapper {
      padding-top: 75px;
    }
  }
}
