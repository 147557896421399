@import './../../styles/global.variables';

#ReportsPage {
  padding-top: 2rem;

  h2.reporting-title {
    display: flex;
    line-height: 2rem;
    font-weight: 500;

    & > div:first-of-type {
      margin: 0.25rem 0;
    }

    & > div:last-of-type {
      padding: 0;
      height: calc(2.5rem + 2px);
      flex-grow: 1;

      .btn {
        display: flex;
        height: 2.75rem;
        line-height: 2.5rem;
        margin: 0;
        padding: 0 0 0 1rem;
        box-shadow: none;
        width: 100%;
        font-size: 1.75rem;
        color: $color--usabg--blue;
        background-color: rgb(210, 210, 220) !important;
        border: 1px solid transparent;
        border-radius: 0.3rem;
        text-transform: none;
        text-align: left;

        span {
          display: inline-block;
          height: 2.5rem;
        }

        .fa {
          display: inline-block;
          line-height: 3rem;
          height: 3rem;
          &::before {
            margin-top: 5px;
          }
        }
      }
    }
  }
}
