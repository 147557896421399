@import './../../../styles/global.variables';

#AgentEandOManagerComponent {
  position: relative;

  .input-wrapper {
    border: thin solid transparent; //rgba(220,220,220,0.25);
    border-radius: 0.25rem;
    height: 100%;
    padding: 15px 5px 0 5px;

    label {
      font-weight: 500;
    }

    .react-datepicker-wrapper {
      width: 100%;

      .md-form {
      	margin-bottom: 0;
      }

      @media screen and (max-width: 575px) {
      	&+small {
      		margin-bottom: 10px;
      		display: block;
      	}
      }
    }

    .react-datepicker-popper {
      z-index: 9999 !important;
    }

    &.active-input:not(.disabled-input):not(.invalid-input) {
      border-color: green;
      border-radius: 0.25rem;
    }

    &.disabled-input {
      background-color: rgb(220, 220, 220);

      .md-form {
        width: 100%;
        margin: 0;
      }

      input {
        border-bottom-color: #999;
      }
    }

    &.invalid-input {
      border-color: red;
    }

    &.input-select-wrapper {
    }

    &.upload-input-wrapper {
      text-align: center;
      padding: 5px;
      min-height: 175px;

      .img-wrapper {
        position: relative;
        margin: 0;
        padding: 0;
        max-width: 100%;
        margin: auto;


        &>.img {
        	max-width: 75%;
        	margin: auto;
        }

        .delete-btn {
          position: absolute;
          z-index: 50;
          top: 0;
          right: 0;
          border: thin solid transparent;
          padding: 2px 5px;
          border-radius: 2px;
          transition-property: color, background-color;
          transition-duration: 0.2s;
          transition-timing-function: ease-in-out;
          color: #666;
          cursor: pointer;

          &:hover {
            background-color: $color--invalid;
            color: #fff;
          }
        }

        .download-btn {
          border-radius: 0.15rem;
          max-width: 90%;
          margin-left: 5%;
          padding: 2px 0;
          background-color: $color--success;
          color: #fff;
          font-weight: 500;
          cursor: pointer;
          margin-top: 10px;
          display: block;

          i.fa {
            margin-right: 10px;
          }
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }

        & > .md-form {
          & > .btn.btn-primary.btn-sm.float-left {
            display: block;
            float: none;
            max-width: 100%;
            width: calc(100% - 15px);
            margin-top: 35px;
          }
          input {
            text-align: center;
          }
        }
      }

      &.disabled-input {
        .img-wrapper {
          & > .md-form {
            & > .btn.btn-primary.btn-sm.float-left,
            input {
              pointer-events: none;
            }
            & > .btn.btn-primary.btn-sm.float-left {
            	opacity: 0.5;
            }
          }
        }
      }

      .file-path-wrapper {
        display: block;
        width: 100%;
      }

      a.btn.view-group-policy-btn {
      	background-color: $color--usabg--blue;
      	color: #FFF;
      	display: block;
      }
    }
  }

  & > div > .row > div {
    margin-bottom: 15px;
    height: 90px;
  }
}
