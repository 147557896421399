@import 'src/styles/global.variables';

#StateLicensesComponent {
	background-color: #FFF;
	padding-top: 1rem;
	padding-bottom: 1.5rem;
	height: 100%;

	.license-table {
		margin: auto;
		min-width: 450px;

		th,td {
			padding: 2px 1rem;
			text-align: center;
		}

		thead {
			background-color: rgb(200,200,200);
			font-weight: 500;

			tr:first-of-type th {
				font-size: 1.5rem;
			}

      tr {
        border-top: 1px solid #666;
        border-bottom: 1px solid #666;

        th:first-of-type {
          border-left: 1px solid #666;
        }

        th:last-of-type {
          border-right: 1px solid #666;
        }
      }
		}

		tbody {
			tr + tr {
				border-top: 1px dotted rgb(220,220,220);
			}

      tr {
        td:first-of-type {
          border-left: 1px solid #666;
        }

        td:last-of-type {
          border-right: 1px solid #666;
        }

        &:last-of-type {
          border-bottom: 1px solid #666;
        }
      }
		}
	}
}