@import './../../../../styles/global.variables';
@import './../../../../styles/global.fonts';

#IntakeFormProfileComponent {
	.react-datepicker {

		font-size: 1.125rem;

		&__month {
			padding: 0;
		}

		&__week {
			border-top: 1px solid rgba(220,220,220,0.5);
			&:first-of-type {
				border-top-color: transparent;
			}
		}

		&__day {
			padding: 5px 0;
			width: 2.5rem;
			font-weight: 400;
			text-align: center;
			margin: 0;
			border-left: 1px solid rgba(220,220,220,0.5);

			&:first-of-type {
				border-left-color: transparent;
			}
		}
	}

	.react-datepicker-popper {
		position: absolute;
		z-index: 9999;
	}
}
