@import 'src/styles/global.variables';

#HowHearFormComponent {
  background-color: rgba(255, 255, 255, 0.25);
  border: 1px solid $color--sky-blue;
  padding: 0.75rem;
  border-radius: 0.5rem;

  .btn-create {
    padding: 2px 5px;
    transform: translateY(-25%);
    background-color: $color--success !important;
    color: #fff;
  }

  .container.accordion {
    padding: 15px 5px 5px 5px;
    background-color: #fff;

    & > div {
      display: flex;

      & > div:first-child {
        width: calc(100% - 75px);
      }
    }
  }

  .btn-delete {
    padding: 3px;
    background-color: transparent !important;
    color: $color--invalid;
    box-shadow: none;

    &:hover {
      background-color: $color--invalid !important;
      color: #fff;
    }
  }
}
