@import 'src/styles/global.variables';

#GlobalLedgerControlsComponent {
  padding: 0;

  ul.ledger-settings {
    list-style-type: none;
    padding: 0;
    margin: 0;

    & > li {
      margin: 0;

      & + li {
        margin-top: 0.5rem;
      }
    }
  }
}
