@import 'src/styles/global.variables';

#NoteListComponent {

  .card-header {
    background-color: $color--usabg--blue;
    color: white;

    .note-header {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1rem;
      display: flex;
      flex-wrap: nowrap;

      &>div {
        flex-grow: 1;
        text-align: left;

        &:last-of-type {
          text-align: right;
        }
      }
    }
  }
}