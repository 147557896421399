@import './../../../../styles/global.variables';

#CarrierContractsFormComponent {
  position: relative;

  .contract-inputs {
    margin-bottom: 20px;

    .md-form {
      margin-bottom: 0;
    }
  }

  div.lockout-window {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 0.3rem;
  }

  .card-header {
    .card-header-btn {
      float: right;
      padding: 2px 4px;
      font-size: 0.8rem;
      font-weight: 500;
      margin: -1px;
      transition-property: background-color, color;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;

      &:hover {
        background-color: $color--success !important;
        color: #fff;
      }
    }
  }
}
