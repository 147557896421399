@import './../../../styles/global.variables';

.StateLicenseRowComponent {
  border: thin solid rgb(220, 220, 220);
  margin-bottom: 20px;
  border-radius: 0.4rem;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;

  .error-msg {
    opacity: 1;
    right: 0.5rem;
    bottom: 0rem;
    position: absolute;
    font-weight: bold;
    color: $color--invalid;
    font-size: 12px;
  }

  .input-wrapper {
    border: thin solid transparent; //rgba(220,220,220,0.25);
    border-radius: 0.25rem;
    height: 100%;
    padding: 15px 5px 0 5px;

    label {
      font-weight: 500;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker-popper {
      z-index: 9999 !important;
    }

    &.active-input:not(.disabled-input):not(.invalid-input) {
      border-color: green;
      border-radius: 0.25rem;
    }

    &.disabled-input {
      background-color: rgb(220, 220, 220);

      .md-form {
        width: 100%;
        margin-bottom: 0;
      }

      input {
        border-bottom-color: #999;
      }
    }

    &.invalid-input {
      border-color: red;
    }

    &.input-select-wrapper {
    }

    &.upload-input-wrapper {
      text-align: center;
      padding: 5px;
      min-height: 175px;

      .img-wrapper {
        position: relative;
        margin: 0;
        padding: 0;

        .btn-container {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .delete-btn {
            // position: absolute;
            // z-index: 50;
            // top: 0;
            // right: 0;
            border: thin solid transparent;
            padding: 2px 5px;
            border-radius: 2px;
            transition-property: color, background-color;
            transition-duration: 0.2s;
            transition-timing-function: ease-in-out;
            color: #666;
            cursor: pointer;

            &:hover {
              background-color: $color--invalid;
              color: #fff;
            }
          }

          .download-btn {
            border-radius: 0.3rem;
            max-width: 80%;
            width: 80%;
            padding: 2px 0;
            background-color: $color--success;
            color: #fff;
            font-weight: 500;
            cursor: pointer;

            i.fa {
              margin-right: 10px;
            }
          }
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }

        & > .md-form {
          & > .btn.btn-primary.btn-sm.float-left {
            display: block;
            float: none;
            max-width: 100%;
            width: calc(100% - 15px);
            margin-top: 35px;
          }
          input {
            text-align: center;
          }
        }
      }

      &.disabled-input {
        .img-wrapper {
          & > .md-form {
            & > .btn.btn-primary.btn-sm.float-left {
              pointer-events: none;
            }
          }
        }
      }

      .file-path-wrapper {
        display: block;
        width: 100%;
      }
    }
  }

  & > div > .row > div:not(.no-margin) {
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-bottom: 15px;
    }

    height: 90px;
  }

  .resident-state-switch {
    .switch {
      display: inline-block;
    }
    .alert {
      padding: 3px;
      font-weight: 500;
      display: inline-block;
    }

    .delete-license-link {
      cursor: pointer;
      color: $color--usabg--red;
      font-weight: 400;
    }
  }

  .nipr-pdb-wrapper {
    button.btn {
      padding: 5px 10px;
      margin: 1px 2px;
    }
  }

  .confirm-delete-modal {
    .modal-header {
      background-color: $color--invalid !important;
    }
  }
}
