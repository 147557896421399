@import './../../styles/global.variables';

#EmailDistributionPage {
  @keyframes spinner {
    100% {
      transform: rotate(360deg);
    }
  }

  hr:not(.seperator) {
    margin-bottom: 2rem;
  }

  hr.seperator {
    width: 100%;
    opacity: 0;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .dropdown {
    & > button.btn {
      width: 100%;
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .dropdown-menu {
    padding-top: 0;
    max-height: 300px;
    width: 100%;
    overflow: auto;

    .md-form {
      margin: 5px 0 0 0;
    }

    div.form-check {
      padding: 3px 0;

      & + div.form-check {
        border-top: 1px dashed rgb(225, 225, 225);
      }

      label.form-check-label {
        height: auto;
        font-weight: 500;
        display: block;
        color: #4285f4;
        small {
          display: block;
          color: #212529;
        }
      }
    }
  }

  ul.results {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;

    & > li:not(.all) {
      border: 1px solid rgb(180, 180, 180);
      border-radius: 0.75rem;
      background-color: rgb(220, 220, 220);
      display: inline;
      white-space: nowrap;
      font-weight: 500;
      font-size: 0.8rem;
      padding: 3px;
      padding-right: 23px;
      position: relative;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 3px 3px 0;

      small {
        display: inline;
        max-width: 100px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;
        margin-left: 0.3rem;
      }

      &:after {
        content: 'x';
        position: absolute;
        right: 3px;
        font-size: 1rem;
        background-color: rgb(180, 180, 180);
        border-radius: 50%;
        height: 16px;
        width: 16px;
        text-align: center;
        line-height: 14px;
        top: calc(50% - 7px);
      }
    }

    & > li.all {
      text-align: center;
      font-weight: 500;
      border-top: thin solid rgb(220, 220, 220);
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  div.generate-btn {
    & > button.btn {
      margin-top: 2rem;
      font-size: 1.125rem;

      span {
        margin-right: 1rem;
      }
    }

    &.generating {
      & > button.btn {
        i {
          animation: spinner 4s linear infinite;
        }
      }
    }
  }

  .addressButton {
    background-color: #3f51b5 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    width: 100%;
    height: 100%;

    input {
      position: relative !important;
      pointer-events: all;
      opacity: 1 !important;
    }

    p {
      font-size: 12px;
      color: white;
      font-weight: 700;
      padding-left: 12px;
      margin-top: 0px;
      margin-bottom: 0px;
      font-family: 'Open Sans';
      cursor: pointer;
    }
  }
}
