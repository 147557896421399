#LeaderGoalsForecastComponent {
  .collapse {
    padding: 0;
  }

  .alert {
    margin: 3px;
    padding: 3px;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
  }
}
