@import 'src/styles/global.variables';

#KbArticlesListComponent {
  .article-form-wrapper {
    padding: 0 2rem;

    form {
      padding-top: 1.5rem;
    }
  }

  button.btn.kba-new {
    float: right;
    padding: 2px 4px;
  }

  .ui-datatable-component.ui-datatable-wrapper table.table {
    tr > th:last-of-type,
    tr > td:last-of-type {
      text-align: right;
    }
  }

  span.kb-keyword {
    display: inline-block;
    margin: 1px 3px 1px 1px;
    padding: 2px 4px;
    border: thin solid rgba(50, 150, 250, 0.35);
    background-color: rgba(50, 150, 250, 0.35);
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    border-radius: 9px;
  }

  div.kba-action-btns {
    button.btn {
      padding: 2px 4px;
      box-shadow: none;
      font-weight: 500;
      background-color: $color--usabg--blue !important;
      font-size: 0.75rem;
    }
  }
}
