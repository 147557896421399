@import './../../styles/global.variables';

#DashboardSearchComponent {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 0.3rem;
  max-width: 375px;

  .ui-ib-input {
    max-width: calc(100% - 6rem);

    .md-form {
      margin: 0 0 0 3px;
      max-width: calc(100% - 0.5rem);
      width: calc(100% - 0.5rem);

      .caret {
        z-index: 5;
        color: white;
      }

      input {
        color: #fff;
        margin-bottom: 0.5rem;
        padding: 0.6rem 0 0.4rem 0;
        line-height: 1.5;
        height: auto;
      }

      & > label {
        background-color: $color--usabg--blue;
        color: white;
        text-align: left;
        top: 0;
      }
    }
  }

  .ui-input-select {
    & > .select-wrapper {
      & > span.caret {
        color: #fff;
      }
      & > input.select-dropdown {
        margin: 0 0 9px 0;
        text-align: left;
        color: #fff;
      }
      & > label.mdb-main-label {
        color: #fff;
        text-align: left;
        margin-bottom: 1.75rem;
        background-color: $color--usabg--blue;

        &.active.text-primary {
          color: #fff !important;
        }
      }
    }
  }

  div.dropdown {
    height: 100%;
    width: 6rem;
    padding: 0;
    margin: 0;

    & > button {
      padding: 0;
      margin: 0;
      height: 100%;
      width: 100%;
      border: 1px solid transparent;
      border-radius: 0;
      border-top-right-radius: 0.2rem;
      border-bottom-right-radius: 0.2rem;

      & > table {
        margin: auto;

        td {
          & > div {
            margin: 0 2px;
            height: 14px;
          }

          font-size: 0.75rem;
          font-weight: 500;
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    .md-form {
      // margin: 0.5rem 0;
    }
    margin: 0.25rem 0;
  }

  @media screen and (min-width: 576px) {
    .md-form {
      // margin: 0.5rem 0;
    }
  }
}
