@import 'src/styles/global.variables';

#UpcomingExpirationsComponent {
  text-align: center;
  margin: auto;

  @media screen and (max-width: 768px) {
    padding: 0px!important;
  }

  table {
    width: 100%;
    margin: auto;
    margin-bottom: 0px!important;

    td {
      text-align: left;
    }
  }
}
