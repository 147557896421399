#KOTHYearly {
  padding: 0;
  position: relative;

  & > .row {
    margin: 0 0 20px 0;

    & > div {
      padding: 0;

      .card {
        box-shadow: none;

        .card-header {
          text-align: center;
          font-weight: 500;
        }
      }

      &:nth-of-type(3) {
        background-color: #fff;

        .card {
          .card-body {
            .img-wrapper {
              transform: scale(130%);
              margin-top: 1rem;
              margin-bottom: 2.5rem;
            }

            h5 {
              font-size: 1.5rem;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  & > .container-fluid > .card {
    padding: 0;

    .card-header {
      text-align: center;
      font-weight: 500;
    }
  }

  & > .loading-spinner {
    z-index: 100;
    position: absolute;
    top: 40%;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    transition: opacity 0.2s ease-in-out;
  }

  .card-body {
    position: relative;

    & > h5,
    & > div:not(.loading-spinner) {
      display: block;
      transition: opacity 0.2s ease-in-out;
    }

    & > .loading-spinner {
      position: absolute;
      top: 15%;
      bottom: 0;
      right: 0;
      left: 0;
      display: block;
    }
  }

  &.is-loading {
    & > .loading-spinner {
      display: block;
    }

    .card-body {
      & > h5,
      & > div:not(.loading-spinner) {
        display: none;
      }
    }
  }
  &:not(.is-loading) {
    .mobile-only {
      display: none;
    }
  }

  // xs
  @media screen and (max-width: 575px) {
    .desktop-only {
      display: none;
    }

    & > .row {
      & > div {
        margin: 0 !important;
        padding: 0 !important;

        &:nth-of-type(1) {
          .card,
          .card-header,
          .card-body {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        &:nth-of-type(2),
        &:nth-of-type(2),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          .card,
          .card-header,
          .card-body {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }

        &:nth-of-type(5) {
          .card,
          .card-header,
          .card-body {
            // border-top-left-radius: 0;
            // border-bottom-left-radius: 0;
          }
        }

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          padding-top: 1rem;
          margin-bottom: 1rem;
        }

        &:nth-of-type(3) {
          background-color: #fff;

          .card {
            .card-body {
              .img-wrapper {
                transform: scale(130%);
                margin-top: 1rem;
                margin-bottom: 2.5rem;
              }

              h5 {
                font-size: 1.5rem;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  // sm
  @media screen and (min-width: 576px) and (max-width: 767px) {
    .desktop-only {
      display: none;
    }

    & > .row {
      & > div {
        margin: 0 !important;
        padding: 0 !important;

        &:nth-of-type(1) {
          .card,
          .card-header,
          .card-body {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        &:nth-of-type(2),
        &:nth-of-type(2),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          .card,
          .card-header,
          .card-body {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }

        &:nth-of-type(5) {
          .card,
          .card-header,
          .card-body {
            // border-top-left-radius: 0;
            // border-bottom-left-radius: 0;
          }
        }

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          padding-top: 1rem;
          margin-bottom: 1rem;
        }

        &:nth-of-type(3) {
          background-color: #fff;

          .card {
            .card-body {
              .img-wrapper {
                transform: scale(130%);
                margin-top: 1rem;
                margin-bottom: 2.5rem;
              }

              h5 {
                font-size: 1.5rem;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  // md
  @media screen and (min-width: 768px) {
    .mobile-only {
      display: none;
    }

    & > .row {
      margin: 0 0 20px 0;

      & > div {
        padding: 0;

        .card {
          box-shadow: none;

          .card-header {
            text-align: center;
            font-weight: 500;
          }
        }

        &:nth-of-type(1) {
          .card,
          .card-header,
          .card-body {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        &:nth-of-type(2),
        &:nth-of-type(4) {
          .card,
          .card-header,
          .card-body {
            border-radius: 0;
          }
        }

        &:nth-of-type(5) {
          .card,
          .card-header,
          .card-body {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          padding-top: 1rem;
          margin-bottom: 1rem;
        }

        &:nth-of-type(1),
        &:nth-of-type(2) {
          .card-body {
            position: relative;

            &::after {
              position: absolute;
              right: 0;
              z-index: 9999;
              bottom: 1rem;
              top: 1rem;
              width: 1px;
              background-color: #d3d3d3;
              content: ' ';
            }
          }
        }

        &:nth-of-type(4),
        &:nth-of-type(5) {
          .card-body {
            position: relative;

            &::after {
              position: absolute;
              left: 0;
              z-index: 9999;
              bottom: 1rem;
              top: 1rem;
              width: 1px;
              background-color: #d3d3d3;
              content: ' ';
            }
          }
        }

        &:nth-of-type(3) {
          background-color: #fff;

          .card {
            .card-body {
              .img-wrapper {
                transform: scale(130%);
                margin-top: 1rem;
                margin-bottom: 2.5rem;
              }

              h5 {
                font-size: 1.5rem;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}
