@import 'src/styles/global.variables';

#ProfileFormComponent {
  .form-group-card {
    box-shadow: none;
    margin-bottom: 1rem;

    & > .card-header {
      background-color: transparent;
      color: $color--usabg--blue;
      padding: 2px;
      border-color: $color--usabg--blue;

      h5 {
        font-weight: 400;
      }
    }

    & > .card-body {
      div.ui-input,
      div.ui-input-select,
      div.ui-date-picker-input {
        position: relative;
        padding: 2px 2px 12px 2px;

        .md-form {
          position: relative;
          margin-bottom: 0;

          & > input.form-control,
          & > input.select-dropdown {
            font-size: 1.125rem;
            padding: 2px 4px;
            font-weight: 400;
          }

          & > input.select-dropdown {
            font-size: 1.125rem;
            padding: 2px 4px;
            font-weight: 400;
            line-height: 1.5;
            height: auto;
            min-height: 34px;
          }

          & > label {
            font-size: 1rem;
            font-weight: 400;

            &:not(.active) {
              transform: translate(0.25rem, 0.5rem);
            }

            &.active {
              transform: translate(2px, -1rem) scale(0.75);
            }
          }
        }

        div.ui-input-validation {
          position: absolute;
          font-size: 0.8rem;
          font-weight: 500;
          right: 0;
          transform: translate(-0.25rem, -0.5rem);

          & > span.success-msg {
            color: $color--valid;
          }

          & > span.error-msg {
            color: $color--invalid;
          }
        }

        div.react-datepicker-popper {
          z-index: 99;
        }

        &.ui-input-select {
          div.ui-input-validation {
            transform: translate(-0.25rem, -1rem);
          }
        }

        &.show-validity {
          &.is-valid {
            div.ui-input-validation > span.error-msg {
              display: none;
            }
          }

          &.is-invalid {
            .md-form > label {
              color: $color--invalid;
            }

            div.ui-input-validation > span.success-msg {
              display: none;
            }
          }
        }
      }

      div.ui-input-button {
        div.ui-ib-button {
          height: calc(100% - 16px);
          padding: 0;

          button.btn {
            margin-left: 0;
            margin-right: 0;
            height: 100%;
            width: 100%;
            padding: 0;
            background-color: $color--usabg--blue !important;
            color: #fff;
            font-weight: 400;
          }
        }
      }
    }
  }

  button[type='submit'] {
    background-color: $color--valid !important;
  }
}
