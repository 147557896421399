@import './../styles/global.variables';

#InactivityTimerComponent {
  .modal-header {
    background-color: $color--invalid;
    text-align: center;

    .fa,
    h4 {
      display: inline-block;
      color: #fff;
      font-weight: bold;
      margin: auto;
    }
  }

  .modal-body {
    padding: 30px 15px;
    text-align: center;
    font-size: 20px;

    button.btn {
      margin-top: 40px;
      background-color: #999 !important;
      font-weight: 600 !important;
      font-family: Lato;
      font-size: 18px;
    }
  }
}
