@import './../../styles/global.variables';

#AccountProfilePage {
  margin: 0 2%;
  padding-bottom: 4rem;
  padding-top: 2rem;

  .modal-xl.modal-dialog {
    width: calc(100vw - 4rem);
    max-width: calc(100vw - 4rem);
    margin-left: 2rem;
  }

  .modal-header {
    background-color: $color--usabg--blue;

    h4 {
      color: #fff;
      font-weight: 500;
      width: calc(100% - 4rem);
      margin-left: 2rem;
      text-align: center;
    }

    button.close {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .card {
    padding: 0;

    & + .card {
      margin-top: 20px;
    }

    .card-header {
      .profile-tab {
        padding: 0px 10px;
        cursor: pointer;

        &.active {
          font-weight: 700;
          text-decoration: underline;
          text-decoration-thickness: 2px;
          text-underline-offset: 8px;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    form.agent-profile-form {
      &.read-only {
        input.form-control[readonly],
        div.select-wrapper[readonly] {
          pointer-events: none;

          span.caret {
            display: none !important;
          }
        }
      }

      .md-form {
        label {
          overflow: visible !important;
        }
      }
    }

    &.user-history {
      .card-body {
        padding: 0;
        padding-bottom: 20px;
      }
    }

    .has-helper-link {
      position: relative;
      margin: 0 auto;

      .md-form {
        border: thin solid transparent;
      }

      .helper-link {
        flex-wrap: nowrap;
        position: absolute;
        bottom: 1rem;
        text-align: left;
      }
      @media (min-width: 768px) {
        .helper-link {
          flex-wrap: nowrap;
          position: absolute;
          bottom: 2rem;
          text-align: left;
        }
      }
    }
  }

  .no-agents-alert {
    padding: 3px;
    font-size: 14px;
    line-height: 16px;
  }

  .user-active-dropdown {
    width: 100%;
    padding: 0;

    .alert {
      margin: 0;
    }

    .dropdown-toggle {
      width: 100%;
      position: relative;
      margin: 0;

      &:after {
        position: absolute;
        right: 10px;
        top: calc(50% - 5px);
      }
    }

    .dropdown-menu {
      width: calc(100% - 10px);

      .dropdown-item {
        padding: 0;

        .alert {
          margin: 0;
          text-align: center;
          font-weight: 500;
        }
      }
    }
  }
}
