.tiptap {
  strong {
    font-weight: 700;
  }

  p {
    color: #333; //  Default text color (adjust as needed)
    font-weight: normal; //  Default font weight
  }

  p {
    text-align: left; // Default text alignment
  }

  p[style*='text-align: justify'] {
    text-align: justify !important;
  }

  // Ensure it applies only when no other styles override it
  p:not([style*='color']) {
    color: #333 !important;
  }

  p:not([style*='font-weight']) {
    font-weight: normal !important;
  }

  .tableWrapper {
    min-height: 200px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
  }

  .editor-toolbar {
    display: flex;
    gap: 8px;
    margin-bottom: 10px;
  }

  .editor-toolbar button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #f9f9f9;
    cursor: pointer;
  }

  .editor-toolbar button.active {
    background: #007bff;
    color: white;
  }

  .editor-toolbar button:hover {
    background: #e0e0e0;
  }

  .tableWrapper table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
  }

  .tableWrapper th,
  .tableWrapper td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  .tableWrapper th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  .tableWrapper td {
    background-color: #ffffff;
  }

  .tableWrapper table tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .tableWrapper table tr:hover {
    background-color: #f1f1f1;
  }
}

.tiptap,
.ProseMirror {
  border: 2px solid #e0e0e0; /* Light gray border */
  min-height: 200px;
}

.editor-toolbar {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap if there’s not enough space */
  gap: 10px; /* Space between buttons */
  padding: 10px;
  background-color: #f8f9fa; /* Light background for contrast */
  border: 1px solid #e0e0e0; /* Subtle border for definition */
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.editor-toolbar button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Consistent button size */
  height: 40px;
  border: none;
  border-radius: 6px;
  background-color: #ffffff; /* Default background color */
  color: #495057; /* Default icon color */
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow for each button */
}

.editor-toolbar button:hover {
  background-color: #e9ecef; /* Lighter gray for hover effect */
  color: #007bff; /* Accent color for hover state */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* Slightly deeper shadow on hover */
}

.editor-toolbar button.active {
  background-color: #007bff; /* Active state background */
  color: #ffffff; /* White text/icon color */
  box-shadow: 0 3px 6px rgba(0, 123, 255, 0.4); /* Highlight active button */
}

.editor-toolbar button:disabled {
  background-color: #f8f9fa; /* Disabled state background */
  color: #ced4da; /* Disabled icon color */
  cursor: not-allowed; /* Show not-allowed cursor */
  box-shadow: none; /* No shadow for disabled buttons */
}

.editor-toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 10px;
  background: #f8f9fa;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.editor-toolbar button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease, box-shadow 0.2s ease;
}

.editor-toolbar button:hover {
  background: #e9ecef;
  color: #007bff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.editor-toolbar button.active {
  background: #007bff;
  color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 123, 255, 0.4);
}

.editor-toolbar button:disabled {
  background: #f8f9fa;
  color: #ced4da;
  cursor: not-allowed;
  box-shadow: none;
}

.upload-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.upload-label:hover {
  background: #e9ecef;
  color: #007bff;
}

.upload-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 6px;
  background-color: #ffffff;
  color: #495057;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.upload-label:hover {
  background-color: #e9ecef;
  color: #007bff;
}

.table-menu {
  position: relative;
}

.table-menu .table-dropdown {
  position: absolute;
  top: 45px;
  left: 0;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

.table-menu .table-dropdown button {
  display: block;
  width: 100%;
  padding: 10px 15px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  color: #495057;
  transition: background-color 0.2s ease;
}

.table-menu .table-dropdown button:hover {
  background-color: #f8f9fa;
  color: #007bff;
}

[style*='font-size'] {
  transition: font-size 0.2s ease;
}

.editor-toolbar button.active {
  background-color: #007bff;
  color: white;
}

.inline-popup {
  position: absolute;
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;
  width: 220px;
  text-align: center;
}

.inline-popup input {
  width: 90%;
  padding: 5px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.popup-actions {
  display: flex;
  justify-content: space-between;
}

.popup-actions button {
  padding: 5px 10px;
  border: none;
  background: #007bff;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}

.popup-actions button:last-child {
  background: #dc3545;
}
