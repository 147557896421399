@import './../../../styles/global.variables';

#PaymentMethodFormComponent {
  #payment-error {
    padding: 10px;
    color: $color--invalid;
    font-weight: bold;
  }

  #payment-success {
    padding: 10px;
    color: $color--success;
    font-weight: bold;
  }

  .cc-input-wrapper {
    margin-bottom: 15px;
  }

  .card {
    background-color: white;
    padding: 10px 20px 11px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #afafaf;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  }

  .submit-button {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
    color: white;
    font-weight: bold;
    font-size: medium;
    background-color: #556cd6;
    border: 0;
    border-radius: 5px;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    transition: box-shadow 500ms;
    cursor: pointer;

    &:disabled {
      background-color: #afafaf;
      box-shadow: none;
      cursor: default;

      &:hover {
        box-shadow: none;
      }
    }

    &:hover {
      box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
    }
  }

  &.payment-method {
    .submit-button {
      margin: 0;
    }
  }

  &.checkout-form {
    .submit-button {
      margin: 2rem 0;
    }
  }
}
