@import './../../../../styles/global.variables';
@import './../../../../styles/global.mixins';

#KpiDashboardComponent {
  padding-bottom: 2rem;
  color: #1e3953;

  .kpi-widget-container {
    margin-bottom: 1.5rem;

    .label {
      background-color: $color--usabg--blue;
      color: #fff;
      border: 1px solid $color--usabg--blue;
      border-top-left-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
      text-align: center;
      padding-top: 8px;

      h4 {
        font-weight: 500;
      }
    }

    .description {
      background-color: #fff;
      border-left: 1px solid rgb(200, 200, 200);
      border-right: 1px solid rgb(200, 200, 200);
      text-align: center;

      p {
        padding-top: 5px;
        min-height: 50px;
        @include truncateEllipsis(16px, 24px, 2, 100%);
      }
    }

    .avg-pw,
    .goal-pw {
      border: 1px solid rgb(200, 200, 200);
      background-color: rgb(240, 240, 240);

      & + .goal-pw {
        border-left: none;
      }

      table td {
        text-align: left;
        vertical-align: middle;

        &:nth-of-type(1) {
          font-size: 1.25rem;
          font-weight: 500;

          sub {
            border: thin solid white;
          }
        }

        &:nth-of-type(2) {
          font-size: 1.75rem;
          font-weight: bold;
        }
      }
    }

    .tracking {
      background-color: #fff;
      border: 1px solid rgb(200, 200, 200);
      border-top: none;
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;

      h6 {
        padding-top: 5px;
        font-weight: 500;
      }

      .kpi-chart {
        transform: scaleX(-1);
      }
    }

    .incomplete-goals {
      font-weight: 500;
      text-align: center;
      margin: 0.75rem;
      padding: 0.5rem;
    }
  }
}
