@import './../../../styles/global.variables';

#ContractingCartItemListComponent {
  padding: 0;
  margin: 0;

  .ContentHtmlComponent > .content-html-inner-wrapper {
    padding: 1rem 0;

    div {
      font-size: 0.9rem;
      font-weight: 400;
      font-family: 'Roboto';

      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  ul.contract-cart-item-list {
    padding: 0;
    margin: 0;
    border: 1px solid rgba(220, 220, 200, 0.5);
    border-radius: 0.3rem;

    & > li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > div.cart-item {
        flex-grow: 1;
        text-align: left;
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        transition-property: opacity, flex, width, max-width;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;

        &-profit {
          flex: 0 0 2rem;
          max-width: 2rem;
        }

        &-carrier {
          flex: 0 0 calc(50% - 4rem);
          max-width: calc(50% - 4rem);

          small {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        &-description {
          flex: 0 0 50%;
          max-width: 50%;
        }

        &-removal {
          flex: 0 0 0;
          max-width: 0;
          overflow: hidden;
          display: flex;

          button.alert,
          div.alert {
            padding: 0;
            margin: 0;
            text-align: center;
            font-size: 0.8rem;
            line-height: 0.8rem;
            font-weight: 500;
            flex: 1 100%;
            overflow: hidden;
            white-space: normal;

            &.half-width {
              max-width: 50%;

              &:nth-of-type(1) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }

              & + .alert {
                border-radius: 0;
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
              }
            }
          }
        }
      }

      &:first-of-type {
        background-color: rgba(220, 220, 200, 0.35);
        font-weight: 500;
        font-size: 0.9rem;
      }

      &:not(:first-of-type) {
        &.is-external-carrier {
          background-color: rgba(220, 220, 200, 0.45);
        }

        & > div.cart-item-profit {
          font-weight: 600;
          font-size: 1rem;
        }
      }

      & + li {
        border-top: 1px dotted rgba(0, 25, 125, 0.2);
      }
    }

    &.is-removable {
      & > li {
        & > div.cart-item {
          transition-property: opacity, flex, width, max-width;
          transition-duration: 0.2s;
          transition-timing-function: ease-in-out;

          &-carrier {
            flex: 0 0 calc(50% - 7rem);
            max-width: calc(50% - 7rem);
          }

          &-description {
            flex: 0 0 calc(50% - 6rem);
            max-width: calc(50% - 6rem);
          }

          &-removal {
            flex: 0 0 10rem;
            max-width: 10rem;
          }
        }
      }
    }
  }

  .max-external-cars {
    text-align: right;
    small {
      font-family: 'Roboto';
      font-weight: 500;
      color: $color--invalid;
      letter-spacing: 0.25px;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &.show small {
      opacity: 0.8;
    }
  }
}
