@import './../../../../styles/global.variables';

#MessageFormComponent {
  .error-msg {
    color: $color--invalid;
    font-weight: 500;
    font-size: 0.9rem;
    text-align: right;
    float: right;
  }
}
