@import 'src/styles/global.variables';

#VersionIndicatorComponent {

	div.safety-net,
	div.new-features {
		visibility: hidden;
		opacity: 0;	
	}

	button.btn.view-features-btn {
		padding: 0;
		box-shadow: none;
		background-color: transparent !important;
		text-transform: none;
		font-weight: 500;
		opacity: 0.5;


		&.safety-net-exist,
		&.new-features-exist {
			opacity: 0.9;

			&:hover {
				opacity: 1;
				text-decoration: underline;
			}
		}

		&.safety-net-exist {
			div.new-features {
				visibility: visible;
				opacity: 1;
				color: red;
			}
		}

		&.new-features-exist {
			div.new-features {
				visibility: visible;
				opacity: 1;
				color: pink;	
			}
		}
	}
}