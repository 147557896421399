@import './../../styles/global.variables';

#AgentPackagesPage {
  padding: 0;

  table {
    margin: 0;
    width: 100%;

    & > tbody {
      & > tr {
        & > td {
          padding: 2px 4px;
          font-size: 1.25rem;
          border: thin solid rgb(225, 225, 225);
          font-weight: 500;
          color: #333;

          &:nth-of-type(1) {
            width: 40%;
          }
          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(4) {
            width: 15%;
            font-size: 1.5rem;
            text-align: center;
          }
        }

        &:first-of-type {
          td {
            font-weight: 600;
            font-size: 1.5rem;
          }
        }
      }
    }

    & > tfoot {
      & > tr {
        & > td {
          padding: 5px;
          font-size: 1rem;
          border: thin solid rgb(225, 225, 225);
          font-weight: 500;
          color: #333;
          text-align: center;
        }
      }
    }
  }

  .card-header {
    background-color: $color--usabg--blue;
    color: #fff;
    text-align: center;
    font-weight: 500;
  }

  .card-body {
    height: auto;
    transition: height 0.3s ease-in-out;
    overflow: hidden;

    h4.loading {
      padding: 2rem;
      width: 100%;
      text-align: center;
      color: rgb(100, 100, 100);
    }
  }
}
