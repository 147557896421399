#LeaderGoalsSelectorComponent {
  .collapse {
    .goal-owner {
      display: flex;
      flex: 0 0 100%;
      justify-content: center;
      align-items: center;
    }

    .content-wrapper {
      display: flex;
      flex: 0 0 7rem;
      padding: 1rem 2px 0 2px;
    }

    .content-wrapper {
      display: flex;
      flex: 0 0 7rem;
      padding: 1rem 2px 0 2px;
      justify-content: center;
      align-items: center;

      &.no-goal {
        padding: 1rem 0.5rem;
        color: rgb(50, 50, 50);
        font-size: 1rem;
        line-height: 1.25rem;
        text-align: center;
        background-color: rgb(255, 200, 200);
        opacity: 0.7;
      }
    }
  }

  @media screen and (max-width: 575px) {
    .collapse {
      max-width: 20rem;
      margin: auto;

      .goal-owner {
        flex: 0 0 100%;
        min-height: 3rem;
      }

      .content-wrapper {
        flex: 0 0 calc(100% - 30px);
        margin-left: 15px;
        padding: 1rem 0 0 0;
        margin-bottom: 1rem;

        .ui-input-dropdown {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
  }

  @media screen and (min-width: 768px) {
    .collapse {
      .goal-owner {
        flex: 0 0 calc(100% - 8rem);
        padding: 0 1rem;
      }

      .content-wrapper {
        flex: 0 0 7rem;
        padding: 1rem 2px 0 2px;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .collapse {
      .goal-owner {
        flex: 0 0 calc(100% - 12rem);
        padding: 0 1rem;
      }

      .content-wrapper {
        flex: 0 0 10rem;
        padding: 1rem 2px 0 2px;
      }
    }
  }
}
