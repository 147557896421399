@import './../../../styles/global.variables';

.UpdateStateLicenseModalComponent {
  .modal {
    .modal-body {
      position: relative;
      min-height: 150px;
      padding: 0;

      & > form > div.alert {
        margin: 1.5rem;
        opacity: 1;
        transition: opacity 0.25s ease-in-out;

        &.has-uploaded {
          pointer-events: none;
        }
      }

      button.btn.mum-btn {
        font-weight: 500;
        font-family: 'Roboto';
        font-size: 1.5rem;
      }

      & > .loading-wrapper {
        height: 100px;
        padding-top: 50px;
        transition-property: opacity, visibility;
        transition-duration: 0.25s;
        transition-timing-function: ease-in-out;
        opacity: 0;
        visibility: hidden;
      }

      &.is-uploading {
        & > .loading-wrapper {
          opacity: 1;
          visibility: visible;
        }

        & > form > div.alert {
          opacity: 0.15;
          pointer-events: none;
        }
      }
    }
  }

  .blank-check-input-wrapper {
    .voided-check-disclaimer {
      display: flex;
      align-items: center;
    }
    .voided-check-img-wrapper {
      min-height: 150px;
      line-height: 150px;
      text-align: center;
      font-weight: 600;
      letter-spacing: 0.2;
      font-size: 20px;
      color: rgba(100, 100, 100, 0.8);
      background-color: rgba(220, 220, 220, 0.5);
      position: relative;

      .delete-btn {
        position: absolute;
        top: 10px;
        right: 0;
        height: 2rem;
        transform: translateY(-100%);
        cursor: pointer;

        .fa {
          display: block;
        }

        &:hover {
          .fa {
            color: $color--invalid;
          }
        }
      }

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
    .create-check-wrapper {
      .md-form {
        margin-top: 4px;
        margin-bottom: 8px;
      }
    }
  }

  .blank-check-input-wrapper,
  .npn-input-wrapper,
  .state-licenses-input-wrapper {
    .npn-text {
      font-weight: 400;
      font-size: 14px;
      margin: 2px;

      h5 {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        padding: 0;
        line-height: 30px;
      }
    }

    .md-form {
      margin: 0;
      input {
        margin: 0;
      }
    }
    hr {
      margin: 0;
    }
  }
}
