@import 'src/styles/global.variables';

#KbCategoryFormComponent {
  div.btn-group {
    width: 100%;

    button.btn {
      color: #fff;
      box-shadow: none;

      &:first-of-type {
        max-width: 30%;
      }
    }
  }
}
