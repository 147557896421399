@import 'src/styles/global.variables';

.TermSetReportComponent {
  padding: 0;

  .card-header {
    background-color: $color--usabg--blue !important;
    color: #fff;
    font-weight: 500;
  }

  table.table {
  	max-width: 100%;

		td {
			white-space: normal;

	  	button.btn.sign-btn {
	  		background-color: $color--usabg--blue !important;
	  		color: #FFF;
	  		box-shadow: none;
	  	}
		}
  }

  .card-body {
  	ul > li {
  		font-weight: 400;

  		& > a {
	  		color: blue;
	  		font-weight: 500;
	  	}
  	}
  }
}