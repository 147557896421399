@import 'src/styles/global.variables';

#PrivacyPolicyPage {
  padding-top: 2rem;

  p,
  ul.privacy-policy-list {
    font-size: 13px;
    font-weight: 500;

    li.primary {
      margin-bottom: 2rem;

      ul {
        margin-left: 1rem;
        li.secondary {}
      }
    }
  }
}
