@import './../../../styles/global.variables';

.ContentHtmlComponent {
  position: relative;

  .content-html-inner-wrapper {
    padding: 1rem;
  }

  & > .card-header {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.25px;
  }

  & > .card-body {
    padding: 2px;
  }
}
