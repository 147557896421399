@import 'src/styles/global.variables';

#AtYourServiceComponent {
	text-align: center;
	background-color: $color--sky-blue;
	color: #FFF;
	padding-bottom: 1.5rem;

	h3 {
		color: #FFF;
		font-style: italic;
		padding: 1.5rem;
	}

	.srvc-label {
		font-size: 1.5rem;
	}

	p {
		font-weight: 400;
		font-size: 1rem;
	}

	@media screen and (max-width: 991px) {
		&>.row>.col {
			flex: 0 0 100%;
			max-width: 100%;

			.srvc-label {
				font-size: 1.25rem;
			}

			p {
				font-size: 0.9rem;
				margin: 0 0 1rem 0;
			}
		}
	}
}