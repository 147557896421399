@import './../../../styles/global.variables';

#AgentTrainingIntake {
  position: relative;
  padding-top: 25px;
  margin-bottom: 100px;

  @media screen and (min-width: 576px) {
    #TrainingValidator {
      padding-top: 3rem;
    }
  }

  .modal .modal-fluid {
    margin-left: 2rem;
    margin-right: 2rem;
    width: calc(100% - 4rem);
    max-width: calc(100% - 4rem);
    max-height: 100%;
  }

  h2 {
    font-weight: 500;
    color: #fff;
    font-family: 'Roboto';
    margin: 0;
    text-align: center;
    padding: 5px;
    background-color: $color--usabg--blue;
    border: 1px solid $color--usabg--blue;
    border-radius: 0.25rem;
    width: 80%;
    margin-left: 10%;
    position: absolute;
    z-index: 100;
    top: 0px;

    span.intro-step {
      border-radius: 50%;
      border: 5px solid #fff;
      color: #fff;
      font-weight: 500;
      display: inline-block;
      height: 40px;
      width: 40px;
      text-align: center;
      line-height: 30px;
      margin-right: 10px;
    }
  }

  .content-wrapper {
    padding-top: 50px;

    & > .card {
      padding: 0;
      margin-bottom: 2rem;

      .card-header {
        padding-left: 8px;
        padding-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        .label {
          width: 100%;
          flex: 0 0 100%;
          max-width: 100%;
          font-weight: 500;
        }

        .edit-btn,
        .trash-btn {
          overflow: hidden;
          width: 0;
          cursor: pointer;
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }

        .trash-btn {
          color: $color--usabg--red;
        }
      }

      .card-body {
        padding: 0;

        ul.list-group {
          li.list-group-item {
            position: relative;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            transition: background-color 0.2s ease-in-out;

            div.label {
              flex: 0 0 100%;
              max-width: 100%;
            }

            div.attended-tag {
              overflow: hidden;
              flex: 0 0 0;
              max-width: 0;
              height: 1.5rem;
              line-height: 1.45rem;
              border: thin solid transparent;
              border-radius: 0.5rem;
              text-align: center;
              font-size: 0.75rem;
              font-weight: 500;
              background-color: $color--success;
              color: #fff;
              opacity: 0;
            }

            div.edit-btn,
            div.trash-btn {
              overflow: hidden;
              flex: 0 0 0;
              max-width: 0;
              color: $color--usabg--blue;
              opacity: 0.6;
              transition: opacity 0.2s ease-in-out;
              cursor: pointer;

              &:hover {
                opacity: 1;
              }
            }

            div.trash-btn {
              color: $color--usabg--red;
            }

            &.has-attended {
              div.label {
                flex: 0 0 calc(100% - 5rem);
                max-width: calc(100% - 5rem);
              }

              div.attended-tag {
                flex: 0 0 5rem;
                max-width: 5rem;
                opacity: 1;
              }
            }

            &:hover {
              background-color: rgba(0, 40, 255, 0.2);
              cursor: pointer;

              div.label {
                text-decoration: underline;
              }
            }
          }

          li.list-group-item {
            &.has-attended {
              div.label {
                flex: 0 0 calc(100% - 5rem);
                max-width: calc(100% - 5rem);
              }

              div.attended-tag {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
