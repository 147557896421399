#SubsiteBannerComponent {
  position: relative;

  & > section {
    height: 30vh;
    display: flex;
    align-items: center;

    &.page-title-wrap {
      background-position: top center;
      background-size: cover;

      .shell {
        .page-title {
          h2 {
            font-weight: 500;
            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
          }
        }
      }
    }

    @media screen and (min-width: 1200px) {
      height: 40vh;
    }
  }
}
