@import 'src/styles/global.variables';

#SubsiteSidebarComponent {
	width: 100%;
	height: 100%;
	background-color: $color--usabg--blue;
	display: flex;
	flex-wrap: wrap;
	flex-flow: column;
	border: 2px solid $color--usabg--blue;
	border-style: inset;
	border-top: none;
	border-bottom: none;
}