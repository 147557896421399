#DashboardAlertMessageComponent {
  word-break: break-word;
  .alert-container {
    position: relative;

    .alert-edit {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
    }
  }
}
