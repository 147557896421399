@import './../../styles/global.variables';

#UserEventLogPage {
  margin: 0 2%;
  padding-bottom: 4rem;
  padding-top: 2rem;

  .ColorLegendComponent {
    font-size: 12px;
    font-weight: 500;

    .col {
      padding-left: 5px;
      padding-right: 5px;
    }

    .alert {
      padding: 3px;
      text-align: center;
      height: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
}
