@import './../../../styles/global.variables';

#SignatureAgentBenefitsTableComponent {

	.alert {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 2px;

		&>div:first-of-type {
			flex-grow: 1;
			font-size: 20px;
			font-weight: 500;
			padding: 5px;
			text-align: center;
		}

		&>div:last-of-type {
			flex-grow: 1;

			a.btn {
				background-color: $color--usabg--blue;
				color: #FFF;
				font-size: 16px;
				width: calc(100% - .75rem);
			}
		}

		@media screen and (min-width: 768px) {
			flex-wrap: nowrap;

			&>div:last-of-type {
				flex-shrink: 1;

				a.btn {
					white-space: nowrap;
				}
			}
		}
	}

  .benefits-pkg-wrapper {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    .card {
      width: 100%;
      background-color: #fff;
      box-shadow: none;
      border: thin solid rgba(160, 160, 160, 0.4);
      border-radius: 0.4rem;

      .card-header {
        background-color: transparent !important;
        color: $color--usabg--blue;
        font-weight: 500;
        font-family: 'Roboto';
        text-align: center;
        border: none;
        position: relative;
        width: 100%;

        small {
          font-weight: bold;
          font-size: 0.75rem;
        }
      }

      .card-body {
        background-color: #fff;
        border-radius: 0.3rem;
        transition-property: background-color, opacity;
        transition-timing-function: ease-in-out;
        transition-duration: 0.3s;
      }
    }

    .text {
      padding-top: 5%;
    }

    &.disabled-pkg {
      .card-body {
        background-color: rgba(220, 220, 220, 1);
        opacity: 0.5;
      }
    }
  }

  .card {
    padding: 0;
  }
}
