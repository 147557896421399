#UITypeAhead {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  position: relative;

  input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    position: relative;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 5px;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    will-change: width, height;

    li {
      padding: 0.5rem;
      font-size: 0.9rem;
      color: #4285f4;
      cursor: pointer;
    }
  }

  .clear-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}
