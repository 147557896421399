@import './../../../../styles/global.variables';

.CarrierDocDatesSetterComponent {
  width: 100%;

  .carrier-contracts-setter-table {
    width: 100%;
    border: thin solid rgb(220, 220, 220);

    & + .carrier-contracts-setter-table {
      margin-top: 20px;
    }

    & > thead {
      background-color: rgba(100, 100, 100, 0.5);

      & > tr:first-of-type {
        th {
          font-weight: bold;
          text-align: center;
          font-size: 1.25rem;
          border-bottom: 1px solid rgba(100, 100, 100, 0.5);
        }
      }

      & > tr:last-of-type {
        th {
          font-weight: 500;
          text-align: left;

          &:first-of-type {
            padding-left: 5px;
          }

          &:not(:first-of-type) {
            text-align: center;
          }
        }
      }
    }

    & > tbody {
      td {
        text-align: left;
        padding: 2px 0;

        &:nth-of-type(1) {
          min-width: 140px;
          padding-left: 5px;
          font-weight: 500;
          width: 40%;

          & > .agent-writing-code,
          & > .agent-writing-code-missing {
            background: #fff;
            font-size: 0.8rem;
            font-weight: 500;
            padding: 2px 4px;
            border: thin solid transparent;
            border-radius: 0.3rem;
          }

          & > .agent-writing-code-missing {
            color: $color--invalid;
          }
        }

        &:not(:first-of-type) {
          width: 15%;
          text-align: center;
          font-size: 0.8rem;
          font-weight: 500;
        }

        & + td {
          border-left: 1px dashed rgb(245, 245, 245);
        }
      }

      tr + tr {
        td {
          border-top: 1px solid rgb(240, 240, 240);
        }
      }
    }
  }

  .btn.modal-trigger {
    margin: 0;
    padding: 2px;
    font-weight: 500;
    font-size: 0.875rem;
  }

  .modal-header {
    background-color: $color--usabg--blue;
    color: #fff;

    & > h4 {
      font-weight: 500;
      text-align: center;
      width: calc(100% - 5rem);
      margin-left: 2.5rem;
    }

    button.close {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .modal-body {
    .row {
      .col-12,
      .col-6,
      .col-sm-6 {
        display: flex;
        align-items: center;

        .alert {
          padding: 5px;
          margin: 0;
          width: 100%;
        }
      }
    }

    .btn {
      font-weight: bold;
      font-size: 1rem;
      margin-top: 1rem;
    }
  }
}
