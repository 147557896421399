@import './../../../styles/global.variables';

#TermsViewerComponent {
  .accept-terms {
    text-align: center;
    color: rgba(255, 255, 255, 0.8);

    .view-terms-btn {
      background-color: transparent;
      box-shadow: none;
      outline: none;
      border: none;
      color: $color--usabg--blue;
      color: rgba(255, 255, 255, 1);
    }
  }

  div.modal {
    div.modal-content {
      .modal-header {
        background-color: $color--usabg--blue;

        h4 {
          text-align: center;
          font-weight: 500;
          padding-left: 60px;
        }
      }

      .modal-body {
        max-height: calc(95vh - 4rem);
        overflow: auto;

        .loading-spinner {
          text-align: center;

          table {
            margin: auto;
            min-height: 20vh;

            td {
              color: rgb(100, 100, 100);
              padding: 1rem;
              font-size: 1.75rem;
              font-weight: 500;
            }
          }

          i.fa {
            font-size: 2.5rem;
          }
        }

        ul {
          li {
            color: black;
            a {
              color: blue;
            }

            text-align: left;
            font-weight: 500;

            & + li {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 576px) {
    div.modal {
      & > div.modal-dialog {
        max-width: calc(90vw - 5vw);
        margin: auto;
        margin-top: 10vh;
      }
    }
  }
}
