.MilestoneList {
  overflow: hidden;

  &.card {
    margin-bottom: 0 !important;
    & + div {
      margin-bottom: 30px;
    }
  }

  .card-header {
    text-align: center;
    font-weight: 500;
    display: flex;
    align-items: center;

    .title {
      width: 100%;
    }

    .sub-title {
      font-size: 14px;
    }

    .list-title {
      font-size: 16px;
    }

    i.fa {
      transition: transform 0.15s ease-in-out;
    }
  }

  .card-body {
    min-height: 470px;
    padding: 0;
    transition: height 0.5s ease-in-out;
    position: relative;

    & > .loading-spinner {
      background-color: #fff;
      z-index: 100;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: none;
      transition: opacity 0.2s ease-in-out;
    }

    &.is-loading {
      overflow: hidden !important;

      & > .loading-spinner {
        display: block;
      }
    }
  }

  &.is-open {
    .card-header {
      i.fa {
        transform: rotate(180deg);
      }
    }
  }

  &.is-closed {
    .card-body {
      height: 470px;
      overflow: auto;
    }
  }

  // xs
  @media screen and (max-width: 575px) {
    .card-header {
      // height: 80px;
    }
  }

  // sm
  @media screen and (min-width: 576px) and (max-width: 767px) {
  }

  // md
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .card-header {
      // height: 80px;
    }
  }

  @media screen and (max-width: 1880px) {
    .list-title {
      font-size: 16px !important;
    }

    .sub-title {
      font-size: 14px !important;
    }
  }

  @media screen and (max-width: 1417px) {
    .list-title {
      font-size: 15px !important;
    }

    .sub-title {
      font-size: 12px !important;
    }
  }

  @media screen and (max-width: 1360px) {
    .list-title {
      font-size: 18px !important;
    }

    .sub-title {
      font-size: 12px !important;
    }
  }
}
