@import 'src/styles/global.variables';

#SearchComponent {
  div.ui-input-button {
    div.ui-ib-input {
      flex: 0 0 calc(100% - 6rem);
      max-width: calc(100% - 6rem);

      div.ui-input {
        padding-left: 4px;
        padding-right: 4px;
        padding-top: 5px;

        div.md-form {
          input.form-control {
          }
          label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;

            &.active {
              transform: translateY(-10px) scale(0.8);
            }
          }
        }
      }
    }
    div.ui-ib-button {
      flex-wrap: nowrap;
      display: flex;
      flex: 0 0 6rem;
      max-width: 6rem;

      button.btn {
        padding: 0;
        display: flex;
        height: 100%;
        flex: 0 0 50%;
        max-width: 50%;
        margin: 0;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        flex-wrap: wrap;

        .fa {
          flex-basis: 100%;
        }

        small {
          margin: 0 2px;
          font-weight: 500;
        }

        &:nth-of-type(1) {
          background-color: $color--usabg--blue !important;
        }
        &:nth-of-type(2) {
          background-color: $color--green !important;
        }
      }
    }
  }
}
