@import 'src/styles/global.variables';

#MenuBuilderComponent {
  background-color: rgba(255, 255, 255, 0.25);
  border: 1px solid $color--sky-blue;
  padding: 0.75rem;
  border-radius: 0.5rem;

  .list-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    h5 {
      margin: 0;
      padding: 0;
      margin-right: auto;
    }

    button.add-link-btn {
      display: flex;
      flex-shrink: 1;
      align-items: center;
      background-color: $color--usabg--blue;
      color: #FFF;
      transition-property: background-color, color, opacity;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;

      &.close {
        background-color: $color--sky-blue;
        color: $color--usabg--blue;
        opacity: 0.7;
      }
    }
  }

  .link-form {
    background-color: #FFF;
    border-radius: 0.3rem;
    border: thin solid #FFF;
    padding: 2px;
    margin-bottom: 1rem;

    .ui-input {
      margin-bottom: 1rem;

      .success-msg {
        color: $color--valid;
        font-size: 0.8rem;
        font-weight: 500;
      }

      .error-msg {
        color: $color--invalid;
        font-size: 0.8rem;
        font-weight: 500;
      }
    }

    button.btn {
      background-color: $color--green;
    }
  }

  div.list-col {
    background-color: #FFF;
    border-radius: 0.3rem;
    border: thin solid #FFF;
    padding: 2px;

    table.menu-link-row {
      background: #FFF;
      margin-top: 1px;
      margin-bottom: 1px;

      tr {
        td:nth-of-type(1) {
          padding: 0 3px;
          font-weight: 500;
        }
        td:nth-of-type(2),
        td:nth-of-type(3) {
          width: 2.5rem;

          button {
            background: transparent;
            outline: none;
            border: none;
          }
        }
      }
    }
  }
}

  