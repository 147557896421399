@import './../../styles/global.variables';

#DashboardHeaderComponent {
  background-color: $color--usabg--blue;
  height: 7rem;
  padding: 0;
  margin: 0;
  width: 100%;
  color: #fff;
  text-align: center;

  .row {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
  }

  .toggle-nav-btn {
    border: thin solid transparent;
    flex: 0 0 3rem;
    max-width: 3rem;
  }

  .nav-bar-search {
    flex-grow: 1;
    margin-right: auto;
  }

  .nav-bar-links {
    border: thin solid transparent;

    .social-btns {
      display: flex;

      .social-btn {
        margin-right: 3px;

        img {
          max-width: 2.5rem;
        }
      }
    }
  }

  .alerts-btn,
  .help-btn {
    border: thin solid transparent;
    flex: 0 0 5rem;
    max-width: 5rem;
  }

  .profile-btn {
    border: thin solid transparent;
    flex: 0 0 6rem;
    max-width: 6rem;

    .profile-name {
      display: -webkit-box;
      padding-top: 6px;
      -webkit-box-orient: vertical;
    }
  }

  .logout-btn {
    border: thin solid transparent;
    flex: 0 0 3rem;
    max-width: 3rem;
  }

  @media screen and (max-width: 575px) {
    height: 8rem;

    &.can-search {
      height: 12rem;
    }

    .row {
      width: calc(100% - 1rem);
      margin: 0 0.5rem;
    }

    .nav-backoffice-btn {
      flex: 0 0 calc(100% - 3rem);
      width: calc(100% - 3rem);
    }

    .nav-bar-search {
      flex: 0 0 100%;
      width: 100%;
    }

    .nav-bar-links {
      .social-btns {
        margin-left: 7px;
        margin-right: 7px;
        border: thin solid transparent;

        .social-btn {
          flex-grow: 1;
          border: thin solid transparent;
          margin-top: 0px;

          img {
            max-width: 2.25rem;
          }
        }
      }
    }

    .alerts-btn {
      flex: 0 0 10%;
      max-width: 10%;
    }

    .help-btn {
      flex: 0 0 10%;
      max-width: 10%;
    }

    .profile-btn {
      flex: 0 0 20%;
      max-width: 20%;
      font-weight: 500;
      font-size: 0.8rem;
      line-height: 0.85rem;
    }

    .logout-btn {
      flex: 0 0 10%;
      max-width: 10%;
    }
  }

  @media screen and (min-width: 576px) {
    height: 8rem;

    .row {
      width: calc(100% - 1rem);
      margin: 0 0.5rem;
    }

    .nav-backoffice-btn {
      flex: 0 0 calc(55% - 3rem);
      width: calc(55% - 3rem);
    }

    .nav-bar-search {
      flex: 0 0 45%;
      width: 45%;
    }

    .nav-bar-links {
      .social-btns {
        .social-btn {
          img {
            max-width: 2.5rem;
          }
        }
      }
    }

    .alerts-btn,
    .help-btn,
    .profile-btn,
    .logout-btn {
      font-weight: 500;
      font-size: 0.8rem;
      line-height: 0.85rem;
    }

    .alerts-btn,
    .help-btn {
      flex: 0 0 10%;
      max-width: 10%;
    }

    .profile-btn {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  @media screen and (min-width: 993px) {
    padding-top: 0.75rem;
    height: 6rem;

    .toggle-nav-btn {
      flex: 0 0 3rem;
      max-width: 3rem;
    }

    .nav-backoffice-btn {
      flex: 0 0 calc(45% - 17rem);
      max-width: calc(45% - 17rem);
    }

    .nav-bar-search {
      flex: 0 0 calc(55% - 17rem);
      max-width: calc(55% - 17rem);
    }

    .nav-bar-links {
      .social-btns {
        margin-left: 5px;
        display: flex;

        .social-btn {
          img {
            margin-top: 6px;
            max-width: 1.5rem;
          }
        }
      }
    }

    .alerts-btn,
    .help-btn,
    .profile-btn {
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 1.125rem;
    }

    .alerts-btn,
    .help-btn {
      flex: 0 0 5rem;
      max-width: 5rem;
    }

    .profile-btn {
      flex: 0 0 6rem;
      max-width: 6rem;
    }

    .logout-btn {
      flex: 0 0 3rem;
      max-width: 3rem;
    }
  }

  @media screen and (max-width: 992px) {
    .row {
      flex-wrap: wrap;

      .nav-bar-links {
        margin-right: auto;

        .social-btns {
          flex-wrap: nowrap;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .row {
      .nav-bar-links {
        .social-btns {
          flex-wrap: wrap;

          .social-btn {
            img {
              max-width: 2rem;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
  }

  @media screen and (min-width: 1100px) {
  }

  @media screen and (min-width: 1300px) {
    .toggle-nav-btn {
      display: none;
    }

    .nav-bar-search {
      flex: 0 0 calc(50% - 12.5rem);
      max-width: calc(50% - 12.5rem);
    }
  }

  @media screen and (max-width: 576px) {
    .row {
      .nav-bar-links {
        max-width: 8rem;

        .social-btns {
          .social-btn {
            img {
              max-width: 1.3rem;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1500px) {
    .nav-bar-links {
      max-width: 9rem;

      .social-btns {
        flex-wrap: wrap;

        .line-break {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    .nav-bar-links {
      max-width: max-content;
    }
  }
}
