@import './../../../styles/global.variables';

.ConfirmDeleteModal {
  button.btn {
    padding: 0;
    margin: 0;
    background-color: transparent !important;
    box-shadow: none;
    font-size: 1rem;

    .fa {
      color: $color--invalid;
    }
  }

  .modal-header {
    background-color: $color--invalid !important;
  }

  .modal-body {
    text-align: center;
    font-size: 1.25rem;

    p {
      border-bottom: 1px solid rgb(220, 220, 220);
      width: 80%;
      margin-left: 10%;
      margin-top: 1rem;
      font-weight: bold;
    }
  }

  .modal-footer {
    display: block;

    .col-12:nth-of-type(1) {
      button.btn {
        padding: 5px;
        background-color: $color--invalid !important;
      }
    }
    .col-12:nth-of-type(2) {
      button.btn {
        padding: 5px;
        background-color: rgb(220, 220, 220) !important;
      }
    }
  }
}
