
#AdminTermsTableComponent {
	table {
		thead {
			background-color: rgba(220,220,220,0.8);
			th {
				border-color: rgba(180,180,180,0.8);
				vertical-align: middle;
				font-weight: 500;
			}
		}
		td {
			font-weight: 400;
		}
	}
	button.btn {
		padding: 3px;
	}
}