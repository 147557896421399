@import './../../../../styles/global.variables';

#CarrierContractingInstructionsForm {
  form + hr {
    margin: 35px 0;
  }

  h6 {
    margin-bottom: 0;
    width: 100%;
    text-align: center;
    background-color: rgba(220, 220, 220, 0.3);
    padding: 5px 0;
    font-weight: 500;
    font-family: Roboto;
    border: 1px solid #ddd;
    border-left: 3px solid $color--success;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    border-bottom: none;
  }

  div.instruction {
    margin-bottom: 10px;

    &.no-instructions {
      text-align: center;
      color: #999;
    }
  }

  .instruction-example {
    background-color: rgba(220, 220, 220, 0.3);
    border: 1px solid #ddd;
    border-left: 3px solid $color--success;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    border-top: none;
    color: #333;
    font-family: monospace;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 0.8rem;
    display: block;
    word-wrap: wrap;
    white-space: normal;
  }
}
