@import './../../../styles/global.variables';

#AgentContractManager {
  padding: 0;
  margin: 0;
  position: relative;

  div.acm-body-pane {
    position: relative;

    .acm-loading-wrapper,
    .acm-content-wrapper {
      position: relative;
      transition-property: opacity, visibility, height;
      transition-timing-function: ease-in-out;
      transition-duration: 0.5s;
    }

    .acm-loading-wrapper {
      visibility: hidden;
      opacity: 0.35;
      height: 0;
      overflow: hidden;

      & > .LoadingSpinnerComponent {
        opacity: 1;
        height: 100%;
        max-height: 100%;
        min-height: 6rem;

        & > .loading-wrapper {
          transform: translateY(0);
        }
      }
    }

    .acm-content-wrapper {
      visibility: visible;
      opacity: 1;

      .contract-cart-list {
        // border: thin solid pink;

        .section-label {
          // border: thin solid pink;
        }
      }
    }

    &.is-loading {
      .acm-loading-wrapper {
        visibility: visible;
        opacity: 1;
        height: 6rem;
      }

      .acm-content-wrapper {
        visibility: hidden;
        opacity: 0.35;
      }
    }
  }

  /*
  .content-wrapper {
    margin: 15px;
    padding-top: 0 !important;
    border: none;

    .contract-tables {
      & > div {
        padding: 0;
      }
    }
  }

  .contract-view-toggle {
    .content-wrapper {
      height: calc(100% - 30px);
      padding: 0;

      .shop-contracts-btn {
        height: auto;
        min-height: 50%;
        font-weight: 500;
        font-family: 'Lato';
        font-size: 1.25rem;
        letter-spacing: 0.5px;
        margin: 0;
        border-radius: 0.3rem;
      }
    }
  }

  .contract-cart-list {
    .content-wrapper {
    }
  }
	*/

  /*
  hr.table-spacer {
    margin: 10px;
  }
  */
}
