@import 'src/styles/global.variables';

#FactorsMilestones {
  .factor-explain {
    border: 1px solid $color--usabg--blue;
    border-radius: 0.5rem;
    background-color: #fff;

    h3 {
      background-color: $color--usabg--blue;
      color: #fff;
      font-weight: 400;
      padding: 4px 0;
    }

    p {
      font-weight: 400;
      font-size: 1rem;
      padding: 1rem;
    }

    h4 {
      width: 100%;
      background-color: rgba(220, 220, 220, 0.8);
      margin: 0;
      padding: 4px 0;
      border-top: 1px solid rgba(180, 180, 180, 0.6);
    }
  }

  .dates-selector {
    border: 1px solid rgba(180, 180, 180, 0.6);
    border-radius: 0.5rem;
    background-color: #fff;
    padding-top: 5px;
    margin: 0;

    h5 {
      margin: 0;
      font-weight: 400;
    }

    a.btn {
      padding: 4px;
    }
  }

  div.content-wrapper.jumped-level {
    background-color: rgba(160, 160, 160, 0.65);
    display: none;
  }

  #showCSRToggle {
    label {
      display: flex;
      align-items: center;
    }
  }
}
