@import './styles/theme.public';
@import './styles/theme.private';

html,
body {
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.mainSection {
  margin: 0 2%;
  padding-bottom: 0 0 5.5rem 0;

  .export-csv-button {
    background-color: #fd668c !important;
  }
}

.mainSection-fullWidth {
  margin-left: -2%;
  margin-right: -2%;
}

.current-news {
  margin: 20px 0;
  text-align: left;
  line-height: 12px;

  h5 {
    font-family: 'Lato';
    border-bottom: 1px solid rgba(220, 220, 220, 1);
  }

  a {
    display: block;
    color: blue;
    font-size: 16px;
    line-height: 20px;
    padding: 0 10px;

    &:hover {
      text-decoration: underline;
    }
  }

  small {
    font-family: 'Lato';
    font-weight: 500;
    color: black;
    display: block;
    padding: 0 10px;
  }
}

// ./src/components/newhome/**
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000000;

  &.not-fixed {
    position: static;
    background: transparent;
  }

  .loader-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

.custom-loader {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;

  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.testimonial {
  .swiper-slide {
    background-color: transparent !important;
  }
}

.tab-insurance {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

// ./src/components/adminShared/changeUserStatusInListComponent.jsx
.preventTextOverflow {
  text-overflow: initial;
  white-space: initial;
  overflow: initial;
}
