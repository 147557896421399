#PerformanceForm {
  .metric-intake {
    font-weight: 500;
    padding: 0.25rem 0;

    label {
      margin: 3px 0 0 0;
    }

    span {
      font-size: 0.8rem;
    }

    .md-form {
      margin: 0;
    }

    & + .metric-intake {
      border-top: 1px solid rgb(240, 240, 240);
    }
  }
}
