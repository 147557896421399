@import './../../../../styles/global.variables';

#PolicySubmitterComponent {
  button.delete-btn {
    color: $color--invalid;
    background-color: transparent;
    padding: 0;
    margin-left: auto;
    border: none;
    font-weight: 500;
    font-size: 13px;
    opacity: 0.6;
    float: right;
    margin: 1rem 0 0 0;

    &:hover {
      opacity: 1;
    }
  }

  .ul.policy-errors-list {
    padding: 0;
    margin: 0;

    li + li {
      margin-top: 1rem;
    }
  }
}
