@import 'src/styles/global.variables';

#ListComponent {
  ul.agent-ledger-list {
    padding: 0 0 0.5rem 0;
    margin: 0;
    list-style-type: none;
    overflow-x: scroll;

    & > li {
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;

      @media screen and (max-width: 767px) {
        width: 15rem;
        max-width: 15rem;
      }

      &:first-of-type {
        background-color: rgba(240, 240, 240, 0.75);
        font-weight: 500;
        color: #666;
      }

      &:nth-of-type(even) {
        background-color: rgba(0, 225, 225, 0.1);
      }

      & + li {
        border-top: 1px solid rgba(240, 240, 240, 0.75);
      }

      div {
        padding: 2px 0;
        font-size: 0.8rem;
        font-weight: 400;
        transition-property: opacity, visibility, flex, max-width, height;
        transition-timing-function: ease-in-out;
        transition-duration: 0.25s;
      }

      div.al-id {
        padding-left: 2px;
        flex: 0 0 calc(2.5rem - 2px);
        max-width: calc(2.5rem - 2px);
      }

      div.al-name {
        flex: 0 0 calc(100% - 5rem);
        max-width: calc(100% - 5rem);
        text-overflow: ellipsis;
        overflow: hidden;
      }

      div.al-email,
      div.al-balance,
      div.al-age {
        flex: 0 0 0;
        max-width: 0;
        visibility: hidden;
        opacity: 0;
        height: 0;
      }

      div.al-btns {
        flex: 0 0 2rem;
        max-width: 2rem;
        display: flex;

        button.btn {
          padding: 0;
          height: 100%;
          flex: 0 0 calc(100% - 2px);
          max-width: calc(100% - 2px);
          align-items: center;
          justify-content: center;
          margin: 1px 2px 0 0;
          background-color: $color--blue !important;
        }
      }
    }

    &.show-details {
      & > li {
        @media screen and (max-width: 767px) {
          width: 30rem;
          max-width: 30rem;
        }

        div.al-id {
          flex: 0 0 2.5rem;
          max-width: 2.5rem;
        }

        div.al-name {
          flex: 0 0 calc(30% - 2.5rem);
          max-width: calc(30% - 2.5rem);
        }

        div.al-email {
          flex: 0 0 calc(30% - 2.5rem);
          max-width: calc(30% - 2.5rem);
        }

        div.al-balance,
        div.al-age {
          flex: 0 0 calc(20%);
          max-width: calc(20%);
          visibility: visible;
          opacity: 1;
          height: auto;
        }

        div.al-btns {
          flex: 0 0 2.5rem;
          max-width: 2.5rem;
        }
      }
    }
  }

  div.pagination-wrapper {
    padding: 0.5rem;
    background-color: rgba(240, 240, 240, 0.5);

    .PaginationControlsComponent {
      & > .col {
        flex: 0 0 100%;
        max-width: 100%;

        &.page-indicator > div,
        & > ul.justify-content-end {
          text-align: center;
          justify-content: center !important;
        }
      }
    }
  }
}
