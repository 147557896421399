#DashboardPage {
  .top-5-pip-agents {
    padding: 0;

    h4.card-header {
      font-weight: 500;
    }

    .card-body {
      .agent-name,
      .agent-points {
        text-align: center;

        div {
          font-weight: 500;
          margin: auto;
        }
      }

      .agent-name {
        div {
          line-height: 24px;
        }
      }

      .profile-img-wrapper {
        overflow: hidden;
        margin: auto;
        border: 1px solid #cbcbcb;
        border-radius: 50% !important;
        box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .team-logo-img-wrapper {
        overflow: hidden;
        margin: auto;
      }
    }
  }

  .theme-banner-mid,
  .theme-banner-full {
    img {
      margin: auto;
    }
  }

  .video-wrapper {
    position: relative;
  }

  .message-viewer-wrapper {
    padding: 0;
  }

  @media screen and (max-width: 575px) {
    .theme-banner-full {
      img {
        max-width: 100%;
      }
    }

    .top-5-pip-agents {
      padding: 0;

      .desktop-card {
        display: none;
      }

      .card-body {
        & > .row > div {
          border-bottom: 1px solid rgb(220, 220, 220);
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        .agent-name,
        .agent-points {
          div {
            font-size: 1.25rem;
          }
        }

        .profile-img-wrapper {
          height: 128px;
          width: 96px;
        }

        .team-logo-img-wrapper {
          width: 120px;
        }

        .agent-jewel-img {
          margin-top: 10px;
        }
      }
    }
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    .theme-banner-full {
      img {
        max-width: 80%;
      }
    }

    .top-5-pip-agents {
      padding: 0;
      margin-left: 10%;
      margin-right: 10%;
      width: calc(100% - 20%);

      .desktop-card {
        display: none;
      }

      .card-body {
        & > .row > div {
          border-bottom: 1px solid rgb(220, 220, 220);
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        .agent-name {
          height: 4rem;

          div {
            font-size: 1.25rem;
          }
        }

        .profile-img-wrapper {
          height: 160px;
          width: 120px;
        }

        .team-logo-img-wrapper {
          width: 150px;
        }

        .agent-points {
          height: 2rem;

          div {
            font-size: 1.25rem;
          }
        }

        .agent-jewel-img {
          margin-top: 10px;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .theme-banner-full {
      img {
        max-width: 70%;
      }
    }

    .top-5-pip-agents {
      padding: 0;

      .mobile-card {
        display: none;
      }

      .card-body {
        .agent-name {
          height: 4rem;
          margin-bottom: 10px;

          div {
            font-size: 1.25rem;
          }
        }

        .profile-img-wrapper {
          height: 120px;
          width: 90px;
        }

        .team-logo-img-wrapper {
          width: 113px;
        }

        .agent-points {
          margin-top: 10px;
          height: 4rem;

          div {
            font-size: 1.25rem;
          }
        }

        .agent-jewel-img {
          margin-top: 10px;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .theme-banner-full {
      img {
        max-width: 70%;
      }
    }

    .top-5-pip-agents {
      padding: 0;

      .mobile-card {
        display: none;
      }

      .card-body {
        .agent-name {
          height: 4rem;

          div {
            font-size: 1.25rem;
          }
        }

        .profile-img-wrapper {
          height: 160px;
          width: 120px;
        }

        .team-logo-img-wrapper {
          width: 150px;
        }

        .agent-points {
          margin-top: 10px;
          height: 4rem;

          div {
            font-size: 1.25rem;
          }
        }

        .agent-jewel-img {
          margin-top: 10px;
        }
      }
    }
  }

  @media screen and (min-width: 1100px) {
    .theme-banner-mid {
      img {
        max-width: 650px;
      }
    }
    .theme-banner-full {
      img {
        max-width: 750px;
      }
    }

    .top-5-pip-agents {
      .card-body {
        .agent-points {
          height: 2rem;
        }
      }
    }
  }

  @media screen and (min-width: 1300px) {
    .theme-banner-mid {
      img {
        max-width: 650px;
      }
    }
    .theme-banner-full {
      img {
        max-width: 750px;
      }
    }

    .top-5-pip-agents {
      .card-body {
        .agent-points {
          height: 4rem;
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .top-5-pip-agents {
      .card-body {
        .agent-points {
          height: 2rem;
        }
      }
    }
  }
}
