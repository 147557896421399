@import 'src/styles/global.variables';

#KbCategoriesListComponent {
  button.btn.kbc-new {
    float: right;
    padding: 2px 4px;
  }

  .ui-datatable-component.ui-datatable-wrapper table.table {
    tr > th:last-of-type,
    tr > td:last-of-type {
      max-width: 1rem;
      text-align: right;
    }
  }

  div.kbc-action-btns {
    button.btn {
      padding: 2px 4px;
      box-shadow: none;
      font-weight: 500;
      background-color: $color--usabg--blue !important;
      font-size: 0.75rem;
      white-space: nowrap;
    }
  }
}
