.StateLicenseMapComponent {
  max-width: 100%;
  width: 100%;
  display: block;
  margin-bottom: 2rem;

  svg {
    width: 100%;
  }

  table.state-map-legend {
    margin: auto;

    tr {
      border-top: 1px solid #666;
      border-bottom: 1px solid #666;
    }

    thead {
      background-color: #c8c8c8;

      th {
        text-align: center;
        // color: #666;
        padding: 4px;
        border-left: 1px solid #666;
        border-right: 1px solid #666;
      }
    }

    tr {
      td:first-of-type {
        height: 1.5rem;
        width: 1.5rem;
        border-left: 1px solid #666;
      }

      td {
        padding: 3px;
        border-right: 1px solid #666;
      }
    }
  }
}
