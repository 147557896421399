@import 'src/styles/global.variables';

#TrainingQuizesPage {
  .TrainingQuizesComponent {
  }
  #TrainingQuizes {
    font-size: 12px !important;
    ul.training-quizes-list {
      padding: 0 0 0.5rem 0;
      margin: 0;
      list-style-type: none;

      & > li {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;

        &:first-of-type {
          background-color: rgba(240, 240, 240, 0.75);
          font-weight: 500;
          color: #666;
        }

        &:nth-of-type(even) {
          background-color: rgba(0, 225, 225, 0.1);
        }

        & + li {
          border-top: 1px solid rgba(240, 240, 240, 0.75);
        }

        div {
          padding: 2px 0;
          font-size: 0.8rem;
          font-weight: 400;
          transition-property: opacity, visibility, flex, max-width, height;
          transition-timing-function: ease-in-out;
          transition-duration: 0.25s;
        }

        div.al-id {
          padding-left: 2px;
          flex: 0 0 calc(3rem - 2px);
          max-width: calc(3rem - 2px);
        }

        div.al-training {
          flex: 0 0 calc(100% - 2.5rem - 70px);
          max-width: calc(100% - 2.5rem - 70px);
          text-overflow: ellipsis;
          overflow: hidden;
        }

        div.al-btns {
          flex: 0 0 70px;
          max-width: 70px;
          display: flex;
          justify-content: center;
          align-items: center;

          button.btn {
            padding: 0 5px;
            height: 100%;
            max-width: calc(50% - 5px);
            align-items: center;
            justify-content: center;
            margin: 0px 4px 0px 0px;
          }
        }
      }
    }
  }
}
