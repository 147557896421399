@import './../../styles/global.variables';

#KpiTrackingPage {
  .date-intake {
    background-color: rgb(245, 245, 245);
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgb(215, 215, 215);

    label {
      font-weight: 500;
    }
  }

  .forms-intake {
    background-color: #fff;
    padding-bottom: 1rem;
    border: 1px solid rgb(215, 215, 215);
    border-bottom-left-radius: 0.35rem;
    border-bottom-right-radius: 0.35rem;
  }
}
