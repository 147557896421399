@import './../../../styles/global.variables';

#SpecializationSelector {
  box-shadow: none;

  .card-header {
    background-color: rgb(180, 180, 180);
    text-align: left;
    color: rgb(60, 60, 60);
    padding: 10px 5px;
    border-radius: calc(0.3rem - 1px);

    div {
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 1.25rem;
    }
    small {
      text-transform: none;
      letter-spacing: 0;
      font-weight: 500;
    }
  }

  .certificate-uploader {
    .blue-btn {
      background: $color--usabg--blue !important;
    }
    .green-btn {
      background: $color--green !important;
    }

    display: flex;
    & > button {
      display: flex;
    }
  }

  .card-body {
    padding: 10px 0;
  }

  ul.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style-type: none;
    margin: 0;
    padding-bottom: 0;
    position: relative;

    .agent-spec-btn {
      div.spec-lock-down {
        position: absolute;
        border: 4px solid rgb(80, 80, 80);
        border-radius: 0.6rem;
        background-color: rgba(220, 220, 220, 0.75);
        top: 0;
        bottom: 0;
        right: 0;
        padding: 1rem;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        height: 6rem;
        opacity: 0;
        visibility: hidden;
        transition-property: visibility, opacity;
        transition-duration: 0.25s;
        transition-timing-function: ease-in-out;
        z-index: 100;

        .close-form {
          position: absolute;
          top: -15px;
          right: -5px;
        }

        table {
          margin: auto;

          td {
            &:first-of-type {
              padding: 0 10px;
            }

            font-weight: bold;
            font-size: 1rem;

            .react-datepicker-popper {
            }
          }
        }
        .text {
          font-size: 0.9rem;
          border: thin solid red;
          margin: 0;
          display: flex;
          align-self: middle;
          margin: auto;
        }
      }

      img.spec-ribbon {
        position: absolute;
        pointer-events: none;
        z-index: 100;
      }

      .icon {
        cursor: pointer;
      }

      .save {
        cursor: pointer;
      }

      .text {
        font-weight: bold;
        position: absolute;
        padding: 0;
        font-size: 0.7rem;
        bottom: 0.5rem;
        flex-wrap: nowrap;
      }

      .spinner {
        padding: 0.5rem;
        margin: 0.14rem;
      }

      @media screen and (min-width: 768px) {
        div.spec-lock-down {
          bottom: 40px;
        }

        .text {
          font-size: 0.7rem;
          bottom: 0.3rem;
        }
      }

      button.spec-btn {
        background-color: #fff;
        border-radius: 0.6rem;
        outline: none;
        height: 6rem;
        width: 100%;
        font-weight: 500;
        font-size: 1.25rem;
        transition-property: color, background-color, border-color;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;

        &[disabled] {
          // opacity: 0.75;
        }

        &[title='ACA'] {
          border: 3px solid #e87a20;
          color: #e87a20;
        }

        &[title='HEALTH'] {
          border: 3px solid #2979ff;
          color: #2979ff;
        }

        &[title='SENIOR'] {
          border: 3px solid #ff1744;
          color: #ff1744;
        }

        &[title='LIFE'] {
          border: 3px solid #28b84f;
          color: #28b84f;
        }
      }

      &.spec-selected {
        button {
          &[title='ACA'] {
            background-color: #e87a20;
            border-color: #fff;
            color: #fff;
          }

          &[title='HEALTH'] {
            background-color: #2979ff;
            border-color: #fff;
            color: #fff;
          }

          &[title='SENIOR'] {
            background-color: #ff1744;
            border-color: #fff;
            color: #fff;
          }

          &[title='LIFE'] {
            background-color: #28b84f;
            border-color: #fff;
            color: #fff;
          }
        }
      }

      &.is-locked {
        div.spec-lock-down {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .aca-wrapper {
    visibility: hidden;
    opacity: 0;
    transition-property: visibility, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    button {
      outline: none;
      background-color: transparent;
      border: none;
      text-align: center;
      width: 100%;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        text-decoration: underline;
        background-color: rgba(0, 0, 255, 0.15);
      }
    }

    &.enable {
      visibility: visible;
      opacity: 1;

      &.spec-selected {
        background-color: rgba(0, 0, 255, 0.15);
      }
    }
  }

  @media screen and (max-width: 768px) {
    ul.row {
      li {
        padding: 0;

        & + li {
          margin-top: 5px;
        }
        img.spec-ribbon {
          top: -9px;
          left: -1px;
          height: 100px;
          width: 125px;
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 950px) {
    ul.row {
      li {
        padding: 0;
        img.spec-ribbon {
          top: -6px;
          height: 75px;
          width: 94px;
        }
      }
    }
  }

  @media screen and (min-width: 950px) {
    ul.row {
      li {
        &:nth-of-type(1) {
          padding-left: 0px;
          padding-right: 20px;

          div.spec-lock-down {
            left: 0;
            width: calc(100% - 20px);
          }
        }
        &:nth-of-type(2) {
          padding-left: 10px;
          padding-right: 10px;

          div.spec-lock-down {
            left: 10px;
            width: calc(100% - 20px);
          }
        }
        &:nth-of-type(3) {
          padding-left: 20px;
          padding-right: 0px;

          div.spec-lock-down {
            left: 20px;
            width: calc(100% - 20px);
          }
        }
        img.spec-ribbon {
          top: -9px;
          left: -1px;
          height: 100px;
          width: 125px;
        }
      }
    }
  }
}

.SpecUnlockComponent {
  small.read-only {
    background-color: rgba(255, 255, 255, 1);
    padding: 4px;
    display: block;
    border: 1px solid transparent;
    border-radius: 0.35rem;
    color: $color--invalid;
  }

  &.has-certified {
    display: none;

    small.read-only {
      display: none;
    }
  }
}
