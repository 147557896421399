@import 'src/styles/global.variables';

#RequiredTrainingChartComponent {
	.card-body {
		table {
			width: 100%;

			&>thead {
				background-color: rgba(220,220,220,1);
				color: #333;
				th {text-align: center;}
			}

			&>tbody {
				td:last-of-type {
					text-align: right;
				}
			}

			&+table {
				margin-top: 1rem;
			}
		}
	}
}