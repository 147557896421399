@import './../../styles/global.variables';

#AdminDashboardNotificationEmails {
  .card {
    margin-bottom: 1rem;

    .card-header {
      background-color: $color--usabg--blue;
      color: #fff;
      font-weight: 500;
    }

    .card-body {
      padding: 0;

      table {
        margin-bottom: 0 !important;
      }
    }
  }
}
