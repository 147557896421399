@import './../../../../styles/global.variables';

.LeaderReportTableComponent {
  position: relative;
  padding: 0;
  margin: 0 0 1rem 0;
  min-height: 250px;
  max-width: 124rem;

  & > .row {
    padding: 0;
    margin: 0;

    & > .table-wrapper {
      padding: 0;
      margin: 0;

      & > .card {
        .card-header {
          background-color: $color--usabg--blue;
          color: #fff;
          font-weight: 500;
          font-size: 1.5rem;
          padding: 4px;
          width: 100%;
          // overflow-x: auto;
          // overflow-y: hidden;

          .headerField {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 767px) {
              display: block;
            }

            @media (max-width: 575px) {
              .report-date-column {
                max-width: 20rem;
                margin: auto;
              }
            }

            .report-selector-column {
              flex-grow: 1;
            }

            .report-date-column {
              flex-shrink: 1;
              background-color: #fff;
              border: 1px solid #fff;
              border-radius: 0.4rem;
              padding: 0 3px;

              .ui-input.ui-date-picker-input {
                padding-top: 6px;

                input.form-control {
                  margin: 0;
                }
              }
            }
          }
        }
        .card-body {
          padding: 0;
          height: auto;
          overflow: auto;
          transition-property: opacity, visibility;
          transition-timing-function: ease-in-out;
          transition-duration: 0.3s;

          .alert {
            font-weight: 500;
            text-align: center;
            margin: 0;
          }
        }
      }

      button.btn.show-prev-btn {
        padding: 2px 8px;
        float: right;
        background-color: rgb(220, 220, 220) !important;
        font-weight: 500;
        color: #757575;
        opacity: 0.6;

        &:hover {
          opacity: 1;
        }

        &:not(:hover) {
          box-shadow: none;
        }
      }
    }
  }

  .loading-spinner {
    position: absolute;
    height: 250px;
    top: 20px;
    left: 15px;
    right: 15px;
    padding-top: 100px;
    visibility: hidden;
    opacity: 0;
    transition-property: opacity, visibility;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    background-color: #fff;
    border: thin solid transparent;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  &.is-loading {
    .loading-spinner {
      visibility: visible;
      opacity: 1;
      height: calc(100% - 20px);
    }

    .table-wrapper .card {
      background-color: transparent;

      .card-body {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &:not(.is-loading) {
    .table-wrapper {
      visibility: visible;
      opacity: 1;
    }
  }

  /* ================================
  *  ====  KEY INDICATOR  ===========
  *  ================================ 
  */
  .key-indicator {
    flex: 0 0 2rem;
    max-width: 2rem;
    background-color: #757575;
  }

  .lgr-key-indicator {
    @extend .key-indicator;
    position: sticky;
    bottom: 0;
    left: 0;
    top: 0;
    width: 2rem;
    z-index: 999;
    padding: 0 !important;

    & > div {
      @extend .key-indicator;
      color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;

      & > div {
        transform: rotate(-90deg);
        position: absolute;
        top: 50%;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 0.75rem;
        font-weight: 1.125rem;
      }
    }
  }

  /* ================================
  *  ====  TABLE CELLS  =============
  *  ================================ 
  */
  .lgr-cell {
    padding: 3px 4px;

    &.cell-idx-0 {
      @extend .key-indicator;
    }

    &.cell-idx-1 {
      border-right: 1px solid #aeaeae;
    }

    &.cell-splitter {
      flex: 0 0 1rem;
      max-width: 1rem;
      background-color: rgba(220, 220, 220, 0.8);
      border-left: thin solid rgba(200, 200, 200, 0.6);
      border-right: thin solid rgba(200, 200, 200, 0.6);
    }

    input.lgr-cell-input {
      padding: 0;
      margin: 0;
      border: none;
      background-color: transparent;
      font-weight: 500;
      font-size: 0.8rem;
      display: inline-block;
      text-align: inherit;
      min-width: 0px;
      max-width: 100%;
    }
    &.lgr-cell-clickable {
      input.lgr-cell-input {
        color: blue;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    & > span.grade-indicator {
      position: absolute;
      width: 1rem;

      .fa.icon-success {
        color: $color--success;
      }

      .fa.icon-warning {
        color: $color--yellow;
      }
      .fa.icon-danger {
        color: $color--invalid;
      }
    }

    & > span.grade-indicator + input,
    small {
      padding-left: 1.25rem;
    }

    small {
      font-weight: 500;
      font-size: 0.7rem;
    }

    & > .lgr-source-wrapper {
      display: flex;
      flex-wrap: nowrap;

      & > .lgr-source-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
      }

      & > .source-is-10x {
        display: inline-flex;
        padding: 2px 4px;
        margin-left: 4px;
        font-size: 0.7rem;
        background-color: transparent;
        border-color: transparent;
        font-style: italic;
        flex-shrink: 1;
      }
    }
  }

  #lgr-table-wrapper {
    height: auto;
    width: auto;
    cursor: grab;

    .lgr-dataset {
      position: relative;
      width: 100%;
      max-width: 100%;
    }

    .lgr-header,
    .lgr-descriptor,
    .lgr-timespan,
    .lgr-body,
    .lgr-row {
      width: 1980px;
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex: 1 0 100%;
      align-content: center;

      &.show-prev {
        width: 2880px;
      }

      .lgr-cell {
        align-content: center;
        justify-content: center;
        align-item: center;

        &.cell-idx-1 {
          flex: 0 0 14rem;
          max-width: 14rem;
        }

        &.cell-idx-2,
        &.cell-idx-3,
        &.cell-idx-4,
        &.cell-idx-5,
        &.cell-idx-6,
        &.cell-idx-7,
        &.cell-idx-8,
        &.cell-idx-9,
        &.cell-idx-10,
        &.cell-idx-11,
        &.cell-idx-12,
        &.cell-idx-13,
        &.cell-idx-14,
        &.cell-idx-15,
        &.cell-idx-16,
        &.cell-idx-17,
        &.cell-idx-18,
        &.cell-idx-19,
        &.cell-idx-20,
        &.cell-idx-21,
        &.cell-idx-22,
        &.cell-idx-23,
        &.cell-idx-24 {
          flex: 0 0 8rem;
          max-width: 8rem;
        }
      }
    }

    .lgr-header,
    .lgr-descriptor {
      background-color: #959595;

      .lgr-cell {
        &:not(.cell-splitter) {
          background-color: #959595;
        }

        &:not(:first-of-type) {
          border-color: #8c8c8c;
        }
      }
    }

    .lgr-header,
    .lgr-descriptor {
      .lgr-cell {
        &.cell-idx-2,
        &.cell-idx-3,
        &.cell-idx-4,
        &.cell-idx-5,
        &.cell-idx-7,
        &.cell-idx-8,
        &.cell-idx-9 {
          flex: 0 0 16rem;
          max-width: 16rem;
        }
      }

      &.show-prev {
        .lgr-cell {
          &.cell-idx-2,
          &.cell-idx-3,
          &.cell-idx-4,
          &.cell-idx-5 {
            flex: 0 0 24rem;
            max-width: 24rem;
          }

          &.cell-idx-6 {
            flex: 0 0 16rem;
            max-width: 16rem;
          }

          &.cell-idx-7,
          &.cell-idx-8,
          &.cell-idx-9 {
            flex: 0 0 24rem;
            max-width: 24rem;
          }
        }
      }
    }

    .lgr-header {
      .lgr-cell > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .lgr-timespan {
      background-color: #e1e1e1;

      .lgr-cell-dark {
        background-color: #757575 !important;
        border-bottom: 1px solid #757575 !important;
      }

      .lgr-cell {
        background-color: #e1e1e1;
        border-top: 1px solid #757575;
        border-bottom: 1px solid #b9b7b7;

        &:not(.cell-idx-0) {
          &:not(.cell-idx-1):not(.cell-splitter) {
            & + .lgr-cell:not(.cell-splitter) {
              border-left: 1px dotted #b9b7b7;
            }
          }
        }
      }
    }

    .lgr-body {
      flex-wrap: wrap;

      .lgr-row-wrapper + .lgr-row-wrapper {
        .lgr-cell {
          &:not(.cell-idx-0) {
            border-top: 1px dashed rgba(200, 200, 200, 0.6);
          }
          &:not(.cell-idx-1):not(.cell-splitter) {
            & + .lgr-cell:not(.cell-splitter) {
              border-left: 1px dotted #d3d3d3;
            }
          }
        }
      }

      .lgr-cell {
        &:not(.cell-idx-1):not(.cell-splitter) {
          & + .lgr-cell:not(.cell-splitter) {
            border-left: 1px dotted #d3d3d3;
          }
        }
      }
    }
  }

  .modal-fullscreen.modal-dialog {
    width: 90vw;
    max-width: 90vw;

    .lgr-dd-table {
      list-style-type: none;
      padding: 0;
      overflow: auto;
      max-width: 100%;

      & > .dd-row {
        display: flex;
        flex-wrap: nowrap;
        padding: 0;
        flex: 0 0 100%;
        margin: 0;

        &:first-of-type {
          background-color: rgb(205, 205, 205);
          & > div {
            background-color: rgb(205, 205, 205);
          }
        }

        & > div {
          flex: 0 0 10rem;
          max-width: 10rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 13px;
          font-weight: 500;
          text-align: left;

          &:first-of-type {
            flex: 0 0 2rem;
            max-width: 2rem;
          }
        }

        &.alert {
          border-radius: 0;

          &.alert-danger {
            & > div {
              color: #721c24;
              background-color: #f8d7da;
              border-color: #f5c6cb;
            }
          }

          &.alert-success {
            & > div {
              color: #155724;
              background-color: #d4edda;
              border-color: #c3e6cb;
            }
          }

          &.alert-warning {
            & > div {
              color: #856404;
              background-color: #fff3cd;
              border-color: #ffeeba;
            }
          }

          &.alert-secondary {
            & > div {
              color: #383d41;
              background-color: #e2e3e5;
              border-color: #d6d8db;
            }
          }
        }
      }

      &.metric-enrolled {
        & > .dd-row {
          & > div {
            &:nth-of-type(2),
            &:nth-of-type(3) {
              flex: 0 0 5rem;
              max-width: 5rem;
            }

            &:nth-of-type(4) {
              flex: 0 0 10rem;
              max-width: 10rem;
            }

            &:nth-of-type(5) {
              display: none;
            }

            &:nth-of-type(6) {
              flex: 0 0 10rem;
              max-width: 10rem;
            }
          }
        }
      }
    }
  }

  .sticky-element-first {
    position: sticky;
    left: 0px;
    z-index: 99;
  }

  .sticky-element-second {
    position: sticky;
    left: 32px;
    z-index: 99;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
  }

  &.isScrolled {
    .sticky-element-second {
      @media screen and (max-width: 768px) {
        flex: 0 0 7rem !important;
        max-width: 7rem !important;
      }
    }

    .lgr-header,
    .lgr-descriptor,
    .lgr-timespan,
    .lgr-body,
    .lgr-row {
      @media screen and (max-width: 768px) {
        width: 1860px !important;
      }

      &.show-prev {
        @media screen and (max-width: 768px) {
          width: 2760px !important;
        }
      }
    }
  }
}
