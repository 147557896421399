$color--invalid: #a10a0a;
$color--valid:   #1c802b;
$color--success: #1c802b;

$color--usabg--blue: #1a237e;
$color--usabg--red: #e60000;

$color--gray--md-1: #d3d3d3;
$color--gray--md-2: #bfbfbf;
$color--gray--md-3: #adadad;

$color--gray--dk-1: #737373;
$color--gray--dk-2: #525252;
$color--gray--dk-3: #363636;

$header--height--desktop: 7rem;
$header--height--mobile: 7rem;

$color--orange: #f57c00;
$color--yellow: #fb3;
$color--green: #00c851;
$color--blue: #1976d2;
$color--sky-blue: #6ec0e6;
$color--indigo: #3f51b5;
$color--purple: #512da8;
$color--red: #ff3547;
