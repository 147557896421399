#UplineContactInfoComponent {
    padding: 20px 0px;

    .row {
        margin-right: 0!important;
        margin-left: 0!important;
    }

    p {
        margin-bottom: 0.5rem;
        font-weight: 500;
    }
    
    .upline-type {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 50px;

        p {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 0!important;
        }

        p.direct-upline {
            font-size: 20px;
            font-weight: 600;
        }
    }

    .upline-contact-info-body {
        background-color: rgba(220,220,220,0.8);
        border: 1px solid transparent;
        border-radius: 20px;

        .upline-info {
            display: flex;
            justify-content: center;
            align-items: center;

            .upline-name {
                font-size: 22px;
                font-weight: 500;
                text-align: center;

                @media screen and (max-width: 575px) {
                    padding-top: 10px;
                }
            }

            .upline-title {
                font-size: 14px;
                font-weight: 500;
                text-align: center;
            }
        }
    }
}