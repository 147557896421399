#AgentOptionsComponent.accordion {
  border: 1px solid #ddd;
  margin: 20px auto;

  .accordion-item {
    border-bottom: 1px solid #ddd;

    &:last-child {
      border-bottom: none;
    }
  }

  .accordion-header {
    padding: 15px;
    font-weight: bold;
    cursor: pointer;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;

    &.active,
    &:hover {
      background-color: #e3e3e3;
    }
  }

  .accordion-icon {
    font-size: 18px;
    margin-left: auto;
  }

  .accordion-body {
    padding: 0px 15px;
    background-color: #fff;
    border-top: 1px solid #ddd;
    display: none;

    &.expanded {
      display: block;
    }
  }
}
