@import './../../../styles/global.variables';

#EnableExternalCarrierToggleCheckboxComponent {
  & > .container-fluid {
    & > .row {
      & > div.ext-carrier-checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        flex-grow: 1;

        font-size: 0.8rem;
        line-height: 1.125rem;
        padding: 8px;
        font-weight: 400;
        margin: auto;

        & > div.ext-state-indicator {
          flex: 0 0 3rem;
          max-width: 3rem;
          display: inline-block;
          text-align: center;
          transition: opacity 0.25s ease-in-out;
          font-weight: 500;
          cursor: pointer;

          &.is-saving {
            opacity: 0.5;
          }

          span {
            margin-left: 5px;
          }
        }

        & > label {
          margin: 0;
          border-left: thin solid #d3d3d3;
          color: #666;
          padding: 0 4px;

          & > strong {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
