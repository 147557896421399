@import './../../styles/global.variables';

#DivisionMessagesPage {
  padding-bottom: 2rem;

  .divisions-selector {
    position: absolute;
    right: 2rem;
  }
}
