@import 'src/styles/global.variables';

#GetAQuoteComponent {
	text-align: center;
	background-color: #FFF;
	padding-top: 1rem;
	padding-bottom: 1.5rem;

	a.col {
		display: flex;
		flex-wrap: wrap;
		border-radius: 0.5rem;
		transition: background-color 0.15s ease-in-out;
		height: 10rem;
		padding-bottom: 0.25rem;
		padding-top: 0.25rem;
		flex-flow: column;
		margin: 0 0.5rem;

		.quote-icon {
			font-size: 3.75rem;
			max-height: 4rem;
			color: $color--usabg--blue;
			flex: 0 0 100%;
			max-width: 100%;
			align-items: center;

			.fa {
				margin: 0;
				padding: 0;
				display: block;
			}
		}

		.quote-label {
			font-size: 1.25rem;
			line-height: 1.5rem;
			text-transform: uppercase;
			color: $color--usabg--blue;
			max-height: 3.5rem;
			flex: 0 0 100%;
			max-width: 100%;
			flex-grow: 1;
			align-items: center;
		}

		.quote-link {
			font-weight: 500;
			font-size: 1.25rem;
			background-color: $color--sky-blue;
			cursor: pointer;
			color: #FFF;
			transition: color 0.15s ease-in-out;
			position: relative;
			line-height: 2rem;
			flex: 0 0 100%;
			max-width: 100%;
			align-items: center;
			max-height: 2rem;


			&::after {
				transition: border-color 0.15s ease-in-out;
				position: absolute;
				content: ' ';
				top: 0;
				right: 0;
			  width: 0; 
			  height: 0; 
			  border-top: 1rem solid #FFF;
			  border-bottom: 1rem solid #FFF;
			  border-left: 1rem solid $color--sky-blue;
			}
		}

		&:hover {
			transition: border-color, background-color 0.15s ease-in-out;
			background-color: rgb(240,240,240);
			border-color: rgb(240,240,240);

			.quote-link {
				color: $color--usabg--blue;

				&::after {
				  border-top-color: rgb(240,240,240);
				  border-bottom-color: rgb(240,240,240);
				}
			}
		}
	}

	@media screen and (max-width: 991px) {
		a.col {
			height: 8rem;
			padding-bottom: 0.25rem;
			padding-top: 0.25rem;
			flex-flow: column;
			margin: 0 0.5rem;

			.quote-icon {
				font-size: 2.75rem;
				max-height: 3rem;
			}

			.quote-label {
				font-size: 1rem;
				line-height: 1.25rem;
				max-height: 2.75rem;
			}

			.quote-link {
				font-size: 1rem;
				line-height: 1.5rem;
				max-height: 1.5rem;

				&::after {
				  border-top: 0.75rem solid #FFF;
				  border-bottom: 0.75rem solid #FFF;
				  border-left: 0.75rem solid $color--sky-blue;
				}
			}
		}
	}
}