@import './../../styles/global.variables';
@import './../../styles/global.fonts';
@import './../../styles/global.mixins';

#CalendarPage {
  position: relative;
  padding-top: 2rem;

  @extend .font---lato;

  .bg-primary,
  .border-primary {
    background-color: #fd668c !important;
  }

  .bg-default,
  .border-default {
    background-color: rgba(118, 118, 118, 1) !important;
  }

  .bg-secondary,
  .border-secondary {
    background-color: rgba(60, 97, 174, 1) !important;
  }

  .loading-panel {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    background-color: #fff;
    transition-property: height, opacity, visibility;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    & > div {
      padding-top: 30%;
      color: $color--usabg--blue;
      font-size: 2rem;
      font-weight: bold;
    }

    @keyframes ellipsis {
      from {
        width: 2px;
      }
      to {
        width: 25px;
      }
    }

    @-webkit-keyframes ellipsis {
      from {
        width: 2px;
      }
      to {
        width: 25px;
      }
    }

    @-moz-keyframes ellipsis {
      from {
        width: 2px;
      }
      to {
        width: 25px;
      }
    }

    .ellipsis-exterior {
      width: 30px;
      display: inline-block;
      text-align: left;
    }

    .ellipsis-interior {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis 1s infinite;
      -moz-animation: ellipsis 1s infinite;
    }
  }

  &.is-loading {
    .loading-panel {
      opacity: 1;
      visibility: visible;
      height: auto;
    }
  }

  .container-fluid {
    & > .row:first-of-type {
      & > div:first-of-type {
        position: relative;

        h2 {
          width: 100%;
          font-weight: 500;
        }

        .resync-btn {
          position: absolute;
          top: 0;
          right: 0.5rem;
          border: none;
          outline: none;
          margin: auto;
          display: inline-block;
          color: $color--usabg--blue;
          background-color: rgb(225, 225, 225);
          border: 1px solid $color--usabg--blue;
          border-radius: 4px;
        }

        @media screen and (max-width: 767px) {
          text-align: center;

          .resync-btn {
            left: 0;
            right: 0;
            top: 2.5rem;
          }
        }
        @media screen and (max-width: 576px) {
        }
      }
    }
  }

  .fc-header-toolbar.fc-toolbar {
    display: none;
  }

  .fc-day-today {
    background-color: rgba(255, 220, 40, 0.35);
    position: relative;
    border-bottom-color: black;
    border-bottom-width: 2px;

    .fc-daygrid-day-top:before {
      position: absolute;
      content: 'TODAY';
      top: 0;
      right: 0;
      left: 0;
      padding-top: 2px;
      padding-bottom: 2px;
      text-align: center;
      background-color: rgba(255, 220, 40, 0.75);
      font-weight: bold;
      border-top: 2px solid black;
      border-bottom: 1px solid black;
    }

    .fc-daygrid-day-number {
      font-weight: 600;
    }
  }


  .fc-nsmWeekView-view {
    .fc-day-today,
    .fc-daygrid-day-top:before {
      background-color: #FFF;
    }

    .fc-day-today {
      border-bottom-width: 1px;
      border-bottom-color: #ddd;
    }

    div.fc-daygrid-event-harness {
      &>a.fc-daygrid-event {
        height: auto;

        div.fc-event-main {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          padding: 3px;

          div.fc-daygrid-event-dot {
            display: none;
          }

          div.fc-event-time {
            flex: 0 0 3rem;
            max-width: 3rem;
            font-size: 0.9rem;
            text-align: center;
          }

          div.fc-event-title {
            flex: 0 0 calc(100% - 4rem);
            max-width: calc(100% - 4rem);
            font-size: 0.9rem;
            white-space: normal;
          }
        }
      }
    }
  }



  @media screen and (max-width: 767px) {
    .fc-header-toolbar.fc-toolbar {
      margin-top: 10px;
      flex-wrap: wrap;

      h2 {
        font-size: 1.5rem;
        font-weight: 500;
      }

      .fc-toolbar-chunk {
        padding: 4px 0;
        flex: 0 0 100%;
        max-width: 100%;

        text-align: center;
        & > button {
          margin: auto;
        }
      }
    }
  }

  .calendar-mgmt-btn-row,
  .event-source-btn-row {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    .date-header {
      text-align: center;
      font-size: 2.25rem;
    }

    .cal-date-selector,
    .cal-view-selector {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      list-style-type: none;

      li.btn {
        background-color: #2c3e50;
        color: #fff;
        font-weight: bold;
        flex-grow: 1;
        padding: 5px;
        margin: 0;
        border-radius: 0;
        font-size: 1rem;

        &:first-of-type {
          border-top-left-radius: 0.3rem;
          border-bottom-left-radius: 0.3rem;
        }

        &:last-of-type {
          border-top-right-radius: 0.3rem;
          border-bottom-right-radius: 0.3rem;
        }
      }
    }
  }

  .event-source-btn-row {
    margin-bottom: 15px;

    .event-source-btn {
      margin-bottom: 5px;

      button.btn {
        font-weight: 500;
        font-size: 0.8rem;
        i {
          margin-right: 8px;
        }
      }
    }
  }

  .fc-view:not(.fc-nsmWeekView-view) {

    /* 
  	*	 NON-iOS Declarations.  
  	*  `truncateEllipsis` mixin does not render well in all webkit engines. 
  	*
  	* iOS webkit is the only engine that supports 
  	* the `webkit-touch-callout` rule.
  	*/
    @supports not (-webkit-touch-callout: none) {
      .fc-daygrid-event-harness {
        height: 40px;
        margin-bottom: 1px;

        & > .fc-daygrid-event {
          height: 40px;

          .fc-event-main {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
          }

          .fc-daygrid-event-dot {
          }

          .fc-event-time {
            width: 40px;
          }

          .fc-event-title {
            white-space: normal;
            visibility: visible;
            @include truncateEllipsis(13px, 18px, 2, calc(100% - 65px));
          }
        }
      }
    }

    /* 
  	*	 iOS-specific Declarations.  
  	*/
    @supports (-webkit-touch-callout: none) {
      .fc-daygrid-event-harness {
        & > a.fc-daygrid-event {
          display: block;
          position: relative;

          .fc-daygrid-event-dot {
            margin-top: 3px;
          }

          .fc-event-time {
            position: absolute;
            top: 0;
            left: 20px;
            right: 0;
            overflow: hidden;
            border: thin solid transparent;
            white-space: nowrap;
          }

          .fc-event-title {
            margin-top: 4px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0 4px;
          }
        }
      }
    }
  }
}
