.TechToolWidgetComponent {

	.tech-tool-header {
		background-color: rgba(255,255,255,1);

		.tt-img {
			display: flex;
  		align-items: center;
  		font-size: 22px;
  		color: #29448b;

			img {}
		}

		.tt-toggle {
			display: flex;
  		align-items: center;
  		justify-content: center;
		}
	}

	div.card-body {
		padding: 0 1.25rem;
		transition: padding 0.15s ease-in-out;

		.tt-subtitle {
			text-align: center;
			height: 40px;
			font-weight: bold;
			line-height: 40px;
			color: #FFF;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		iframe {
			max-width: 100%;
		}

		&.open {
			padding: 1.25rem;
			padding-top: 0.25rem;
			transition: padding 0.15s ease-in-out;
		}
	}

	div.collapse,
	div.collapsing {
		background-color: rgba(255,255,255,1);
		border-radius: 6px;
		border: thick solid white;
		overflow: auto;

		&.show {}

		// Work-around to center svg elements 
		// in DYL table.  WYSIWYG editor strips
		// the center tags inside <td>
		table {
			tbody tr td:not(:first-of-type) {
				text-align: center;
			}
		}
	}

	// xs
	@media screen and (max-width: 575px) {
		.tech-tool-header {

			.tt-img {
				justify-content: center;
				height: 100px;
				padding: 8px 25px;

				img {
					max-width: 100%;
					max-height: 100%;
				}
			}

			.tt-toggle {}
		}

		div.card-body {
			a.btn {
				width: 90%;
				margin: auto;
				margin-bottom: 10px;
			}
		}
	}

	// sm 
	@media screen and (min-width: 576px) {
		.tech-tool-header {

			.tt-img {
				justify-content: center;
				height: 100px;
				padding: 8px 25px;

				img {
					max-width: 100%;
					max-height: 100%;
				}
			}

			.tt-toggle {}
		}

		div.card-body {
			a.btn {
				width: 90%;
				margin: auto;
				margin-bottom: 10px;
			}
		}
	}

	// md
	@media screen and (min-width: 768px) {}

	// lg
	@media screen and (min-width: 992px) {}

	// xl
	@media screen and (min-width: 1200px) {}
}