@import './../../../styles/global.variables';

#AgentContractingIntake {
  position: relative;
  padding-top: 25px;
  margin-bottom: 100px;

  .content-wrapper {
    padding-top: 50px;
  }

  @media screen and (max-width: 575px) {
  	&>.row+.row {
  		padding-top: 30px;
  	}
  }
}
