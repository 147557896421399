@import 'src/styles/global.variables';

#TransfersBarComponent {
  // Base Styling
  & > .row.btns-row {
    background-color: rgba(220, 220, 220, 0.8);
    // padding-top: 2px;
    // padding-bottom: 2px;
    // border: 1px solid transparent;
    border-radius: 3px;
    overflow: hidden;

    & > div.col {
      display: flex;

      button.btn {
        box-shadow: none;
        display: flex;
        width: 100%;
        flex-grow: 1;
        padding: 6px 16px;
        margin: 0;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Lato';
        color: #fff;

        &.expense-btn,
        &.debit-btn {
          .fa-lock {
            opacity: 0.5;
          }
        }

        &.expense-btn {
          background-color: $color--red !important;

          &.active {
            color: $color--red;
            background-color: #fff !important;
          }
        }

        &.debit-btn {
          background-color: $color--usabg--blue !important;

          &.active {
            color: $color--usabg--blue;
            background-color: #fff !important;
          }
        }
      }

      &:first-of-type {
        border: 1px solid rgba(220, 220, 220, 0.8);
      }

      &:last-of-type {
        border: 1px solid rgba(220, 220, 220, 0.8);
      }

      & + div.col {
        border: 1px solid rgba(220, 220, 220, 0.8);
      }
    }
  }

  .alert {
    margin-top: 1rem;
  }

  // Desktop
  @media screen and (min-width: 768px) {
    & > .row.btns-row {
      & > div.col {
        padding-left: 0;
        padding-right: 0;
        button.btn {
          border-radius: 0;
        }
        &:first-of-type {
          // padding-left: 8px;
          button.btn {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }
        }
        &:last-of-type {
          // padding-right: 8px;
          button.btn {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }
        }
      }
      button.btn {
        height: 4rem;
      }
    }
  }

  // Mobile
  @media screen and (max-width: 767px) {
    & > .row.btns-row {
      & > div.col {
        padding-left: 0;
        padding-right: 0;
        button.btn {
          border-radius: 0;
        }
        &:first-of-type {
          // padding-left: 8px;
          button.btn {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }
        }
        &:last-of-type {
          // padding-right: 8px;
          button.btn {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }
        }
      }
      button.btn {
        height: 3rem;
      }
    }
  }

  .transfer-form-wrapper {
    background-color: rgba(220, 220, 220, 0.8);
    padding: 0 1rem;
    border: thin solid rgb(190, 190, 190);
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: inset 0px 3px 8px 0px rgba(0, 0, 0, 0.175);

    .close-btn {
      display: flex;

      button.btn {
        transition: opacity 0.2s ease-in-out;
        padding: 2px;
        opacity: 0.5;
        box-shadow: none;
        background-color: transparent !important;
        color: $color--gray--dk-1;
        border-color: transparent;
        margin-left: auto;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}

#TransferFormComponent {
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.175);
  margin-bottom: 1rem;

  .ui-input {
    padding: 10px 6px 10px 6px;
    position: relative;

    .dropdown-content li span {
      font-weight: 500;

      small {
        font-size: 12px;
        line-height: 14px;
        color: #5a5a5a;
      }
    }

    .md-form .form-control {
      margin-bottom: 0;
    }

    .ui-input-validation {
      right: 5px;
      padding-right: 5px;
      position: absolute;
    }

    &.ui-input-select {
      input,
      input.select-dropdown {
        margin-bottom: 0;
      }

      label {
        top: 0;
      }
    }
  }

  span.min-order {
    padding: 0 6px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    display: block;
    color: $color--invalid;
  }

  button.btn[type='submit'] {
    background-color: $color--success !important;
    width: 100%;
    margin-top: 3rem;
  }

  button {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .receiptUploadBtnField {
    margin-top: 3rem;
    margin-bottom: -2rem;
  }
}

#ToggleAgentCashWithdrawalComponent {
  align-items: center;
  background-color: rgba(205, 205, 205, 0.8);

  .alert {
    margin: 0;
  }

  .ui-input {
    padding: 0;
  }
}
