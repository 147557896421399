@import './../../../styles/global.variables';

#OnboardingStageManagerComponent {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 40px;

    li {
      text-align: center;
      position: relative;

      &:nth-of-type(odd) {
        div.description {
          border: thin solid rgba(200, 200, 200, 0.9);
          background-color: rgba(220, 220, 220, 0.6);
          width: calc(100% - 16px);
          margin-left: 8px;
          border-top: none;
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
      }

      .ind-bg {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      &.stage-indicator {
        width: 150px;

        .ind-bg {
          height: 80px;
          border-radius: 0.5rem;
          border: 2px solid gray;
        }

        .stage-name {
          border-radius: 0.5rem;
          font-weight: 500;
        }

        .stage-validity {
          border: 2px solid gray;
          background-color: rgb(220, 220, 220);
          padding-top: 3px;
        }

        div.description {
          font-weight: 400;
          line-height: 18px;
          font-size: 14px;
          text-align: center;
          padding: 6px 3px;
        }

        &.is-valid {
          .ind-bg {
            border-color: green;
          }
          .stage-validity {
            border-color: green;
          }
        }

        &.is-invalid {
          .ind-bg {
            border-color: $color--invalid;
          }
          .stage-validity {
            border-color: $color--invalid;
          }
        }

        &.is-active {
          .ind-bg {
            border-color: $color--usabg--blue;
          }
          .stage-validity {
            border-color: $color--usabg--blue;
          }
        }
      }

      &.stage-prev {
        .ind-bg {
          width: 50px;
          height: 80px;
        }
      }

      &.stage-next {
        .ind-bg {
          width: 50px;
          height: 80px;
        }
      }
    }
  }

  .next-steps-bar {
    background-color: rgba(220, 220, 220, 0.7);
    color: #333;
    font-weight: 500;
    text-align: center;

    & > div:first-of-type {
    }
    & > div:last-of-type {
      font-size: 20px;
    }
  }

  .current-status-table,
  .current-upline-table {
    width: 100%;
    table-layout: fixed;

    tbody {
      tr {
        td {
          padding: 3px;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }

  .current-status-table {
    tbody {
      tr {
        td {
          border: thin solid purple;
          padding: 15px 0;
          font-size: 16px;
        }
      }
    }
  }

  .current-upline-table {
    thead {
      tr {
        th {
          background-color: rgba(220, 220, 220, 1);
          font-weight: 500;
          padding: 3px;
        }
      }
    }
    tbody {
      tr {
        td {
          font-weight: 500;
        }
      }
    }
  }

  .btn {
    font-weight: 600;
    font-family: 'Roboto';
    font-size: 18px;

    &:not(.btn-short) {
      height: 100%;
    }

    &.go-back {
      opacity: 0.7;
    }

    &.can-advance {
      background-color: $color--success !important;
      font-size: 28px;
      letter-spacing: 0.25px;
    }

    &.cant-advance {
      background-color: #fff !important;
      box-shadow: none;
      font-size: 20px;
      letter-spacing: 0.25px;
      color: #666;
    }
  }

  .modal-header {
    background-color: $color--success;
  }

  @media (max-width: 767px) {
    ul {
      flex-wrap: wrap;

      li {
        width: 100%;
        max-width: 100%;
        position: relative;
        z-index: 0;

        &:nth-of-type(odd) {
          div.description {
            position: absolute;
            width: calc(100% - 160px);
            border-radius: 0.5rem;
            top: 10px;
            bottom: 10px;
            left: 135px;
            z-index: 1;
          }
        }

        &.stage-indicator {
          flex: 0 0 100%;
          max-width: 100%;

          .ind-bg {
            height: 70px;
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            width: 150px;
            position: relative;
            z-index: 2;
          }

          .stage-name {
            font-size: 16px;
            line-height: 18px;
          }

          .stage-validity {
            border-top: none;
            height: 30px;
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            width: 100%;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
            width: 150px;
            position: relative;
            z-index: 2;
          }
        }

        &.stage-next {
          flex: 0 0 100%;
          max-width: 100%;

          .ind-bg {
            height: 50px;
            transform: rotate(90deg);
            margin: auto;
          }
        }

        &.is-active {
          .ind-bg,
          .stage-validity {
          }
        }
      }
    }

	  .current-upline-table {
	  	border-bottom: 1px solid rgba(220, 220, 220, 1);
	  }

	  .btn {
	    &.go-back {
	      margin-top: 1rem;
	    }

	    &.can-advance {
	      margin-top: 1rem;
	    }

	    &.cant-advance {
	      margin-top: 1rem;
	    }
	  }
  }

  @media (min-width: 768px) {
    .next-steps-bar {
      & > div:first-of-type {
        text-align: left;
      }
      & > div:last-of-type {
        text-align: right;
      }
    }

    ul {
      li {
        &.stage-indicator {
          flex: 0 0 calc(20% - 20px);
          max-width: 125px;

          .ind-bg {
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          .stage-name {
          }

          .stage-validity {
            border-top: none;
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            height: 30px;
            width: 100%;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
            position: relative;
            z-index: 2;
          }
        }

        &.stage-next {
          flex: 0 0 40px;
          max-width: 40px;
        }

        &.is-active {
          .ind-bg,
          .stage-validity {
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    ul {
      li {
        &.stage-indicator {
          flex: 0 0 calc(20% - 20px);
          max-width: 150px;
        }
      }
    }
  }

  // @media (min-width: 768px) { ... }
  // @media (min-width: 992px) { ... }
  // @media (min-width: 1200px) { ... }
}
