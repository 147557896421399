@mixin uiDatePickerInput() {
  &.ui-date-picker-input {
    .react-datepicker-wrapper {
      max-width: 100%;
      width: 100%;
    }
    &.read-only {
      pointer-events: none;
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.75;
    }
  }
}

@mixin uiInputToggleSwitch() {
  &.ui-input-toggle-switch {
    padding-bottom: 0rem;

    .toggle-container {
      width: 70px;
      background-color: #ffffff;
      cursor: pointer;
      user-select: none;
      border-radius: 3px;
      padding: 2px;
      height: 32px;
      position: relative;
    }

    .dialog-button {
      top: 0;
      font-size: 14px;
      line-height: 16px;
      font-weight: bold;
      cursor: pointer;
      background-color: #002b49;
      color: white;
      padding: 8px 12px;
      border-radius: 18px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      min-width: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      min-width: unset;
      border-radius: 3px;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      position: absolute;
      left: 34px;
      transition: all 0.3s ease;
    }

    .disabled {
      background-color: #707070;
      left: 2px;
    }
  }
}

@mixin uiSelect() {
  &.ui-input-select {
    .mdb-select.md-form {
      & > span.caret {
        border: thin solid transparent;
      }
      &.hide-caret {
        & > span.caret {
          opacity: 0;
        }
      }
    }
    ul.dropdown-content.select-dropdown.fadeIn {
      display: block;
    }
    &.is-disabled {
      pointer-events: none;

      .mdb-select.md-form {
        & > span.caret {
          opacity: 0;
        }
      }
    }
  }
}

.ui-input-button {
  display: flex;

  div.ui-ib-button {
    flex: 0 0 5rem;
    max-width: 5rem;

    .ui-input-btn {
      max-width: 100%;
      width: 100%;
      height: calc(100% - 8px);
      margin: 0 0 8px 0;
      background-color: transparent !important;
      box-shadow: none;
      border: none;
      border-bottom: 1px solid #ced4da;
    }

    &.ui-ib-button-left {
    }

    &.ui-ib-button-right {
    }
  }

  div.ui-ib-input {
    flex: 0 0 calc(100% - 5rem);
    max-width: calc(100% - 5rem);
  }

  &.btn-left {
  }

  &.btn-right {
  }
}

.ui-input {
  .md-form {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }

  @include uiDatePickerInput;

  @include uiInputToggleSwitch;

  @include uiSelect;
}
