@import './../../../styles/global.variables';

#BreakTheBarDetails {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  margin: 0;

  .card-header {
    text-align: center;
    padding: 0;
    font-size: 0.9rem;
    background-color: transparent;
  }

  .card-body {
    padding: 0;
    border: 1px solid rgb(150, 150, 150);
    border-radius: 0.35rem;
    overflow: hidden;
    text-align: center;

    .ContentHtmlComponent {
      width: calc(100% - 1rem);
      .content-html-inner-wrapper {
        margin: 0;
      }
    }

    .break-the-bar-indicator {
      background-color: $color--usabg--blue;
      color: #fff;
      font-size: 2.25rem;
      padding-top: 10px;

      span.small {
        font-size: 1.5rem;
      }

      .alert {
        padding: 5px;
        font-size: 1rem;
        line-height: 1.25rem;
        width: calc(100% - 1rem);
        margin-left: 1rem;
      }

      @media screen and (max-width: 991px) {
        font-size: 2rem;

        span.small {
          font-size: 1.125rem;
        }

        .alert {
          width: calc(100% - 2rem);
        }
      }
    }

    .break-the-bar-details {
      @media screen and (min-width: 576px) {
        padding: 0;
      }

      div.title {
        font-weight: 500;
        background-color: rgb(150, 150, 150);
      }

      div.copy {
        padding: 0 2rem;

        p {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}
