@import 'src/styles/global.variables';

#EditTransferFormComponent {
  form {
    box-shadow: none;
  }

  div.ui-input {
    padding: 2px 5px;

    .md-form {
      & > label {
        transform: translateY(-8px) scale(0.8);
      }
    }

    div.ui-input-validation {
      transform: translateY(-40%);
      span {
        font-size: 0.8rem;
        font-weight: 500;
      }
      span.success-msg {
        color: $color--green;
      }
      span.error-msg {
        color: $color--invalid;
      }
    }

    &.is-disabled {
      background-color: rgb(220, 220, 220);
    }
  }

  // div > .row {
  //   & > div.col {
  //     padding-left: 0;
  //     padding-right: 0;

  //     &:first-of-type {
  //       padding-left: 15px;
  //     }

  //     &:last-of-type {
  //       padding-right: 15px;
  //     }
  //   }

  //   div.ui-input {
  //     padding: 2px 5px;

  //     .md-form {
  //       & > label {
  //         transform: translateY(-8px) scale(0.8);
  //       }
  //     }

  //     div.ui-input-validation {
  //       transform: translateY(-40%);
  //       span {
  //         font-size: 0.8rem;
  //         font-weight: 500;
  //       }
  //       span.success-msg {
  //         color: $color--green;
  //       }
  //       span.error-msg {
  //         color: $color--invalid;
  //       }
  //     }

  //     &.is-disabled {
  //       background-color: rgb(220, 220, 220);
  //     }
  //   }
  // }

  div.alert {
    padding: 2px;
    font-size: 13px;
    font-weight: 500;
  }

  ul.agent-search-results {
    & > li {
      display: flex;
      flex-wrap: wrap;

      & > div {
        flex-shrink: 1;
        &:nth-of-type(1) {
          flex-basis: 100%;
        }
        &:nth-of-type(2),
        &:nth-of-type(3) {
          font-size: 0.8rem;
          font-weight: 500;

          & + div {
            border-left: thin solid rgba(220, 220, 220, 0.8);
            margin-left: 15px;
            padding-left: 15px;
          }
        }
      }
    }
  }
}
