#WeeklyMarketingCommitmentForm {
  p {
    margin: 5px -15px;
    padding: 0 10px;
    line-height: 2.5rem;
    font-size: 1.5rem;
    background-color: rgb(230, 230, 230);
    color: rgb(30, 30, 30);
    border: 1px solid rgb(220, 220, 220);
    border-radius: 0.3rem;
  }

  form {
    margin: 15px -15px 5px -15px;
  }

  .metric-intake {
    font-weight: 500;
    padding: 0.25rem 0;

    label {
      margin: 3px 0 0 0;
    }

    span {
      font-size: 0.8rem;
    }

    .md-form {
      margin: 0;
    }

    & + .metric-intake {
      border-top: 1px solid rgb(240, 240, 240);
    }
  }
}