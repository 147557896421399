@import './../../styles/global.variables';
@import './../../styles/global.fonts';

body {
  &.content-editor-modal-open {
    &.cke_dialog_open {
      .cke_dialog_container {
        z-index: 99999 !important;
        .cke_dialog.cke_browser_webkit {
          border: 2px solid #999;
        }
      }
    }
  }
}

.AdminContentEditPopupComponent {
  .modal-header {
    background-color: $color--usabg--blue;
    &.red {
      background-color: $color--invalid;
    }
    h4 {
      width: 90%;
      margin-left: 5%;
      text-align: center;
      color: #fff;
      @extend .font---lato;
    }
    button {
      color: #fff;
    }
  }

  form.container-fluid {
    & > .row {
      margin: 0;
    }

    input.form-control {
      border: 1px solid rgb(210, 210, 210);
      border-radius: 2px;
      padding: 8px;
      margin-bottom: 20px;
      min-height: auto;
    }

    button.btn.content-editor-btn {
      border: 1px solid transparent !important;
      font-size: 1.75rem;
      padding: 6px;
      border-radius: 0.35rem;

      &.content-editor-update-btn {
        background-color: $color--usabg--blue !important;
      }

      &.content-editor-cancel-btn {
        background-color: rgb(120, 120, 120) !important;
      }
    }
  }
}
