@import './../../../../styles/global.variables';

.CarrierTableComponent {
  position: relative;

  div.view-selector {
    height: 50px;
    position: relative;

    & > ul {
      padding: 0;
      margin: 0;
      position: absolute;
      bottom: 0;

      li {
        display: inline-block;
        border: thin solid rgb(220, 220, 220);
        font-weight: 500;
        font-size: 18px;
        cursor: pointer;

        padding: 8px 12px;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        border-bottom: 0;

        background-color: rgba(220, 220, 220, 0.25);
        transform: translateY(5px);
        padding-top: 5px;

        transition-property: background-color, transform, padding-top, opacity;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;

        &.active {
          background-color: #fff;
          transform: translateY(0);
          padding-top: 8px;
          opacity: 1;
        }

        &:not(.active) {
          opacity: 0.75;
        }
      }

      li.loader-wheel {
        background-color: transparent;
        font-size: 14px;
        color: $color--usabg--blue;
        border: none;
        opacity: 0;

        &.is-loading {
          opacity: 1;
        }
      }
    }
  }

  & > .dataTables_wrapper {
    & > .row:first-of-type {
      & > div:first-of-type {
        visibility: hidden;
        opacity: 0;
        height: 0;
      }
      & > div:last-of-type {
        .md-form {
          margin: 0;
        }

        position: absolute;
        right: 25px;
        top: 0px;
        height: 50px;
      }
    }

    table.table.dataTable {
      width: 100%;
      max-width: 100%;
      table-layout: fixed;

      & > thead > tr {
        background-color: rgb(220, 220, 220);

        th,
        th.sorting,
        th.sorting_desc,
        th.sorting_asc {
          &:nth-of-type(1) {
            width: 45%;
          }

          &:nth-of-type(3) {
            width: 60px;
          }

          &:before,
          &:after {
            bottom: 0;
          }
        }
      }

      & > thead > tr,
      & > tbody > tr {
        transition-property: background-color;
        transition-duration: 0.25s;
        transition-timing-function: ease-in-out;

        & > th,
        & > td {
          padding: 2px 5px;
          vertical-align: middle;
          transition-property: width;
          transition-duration: 0.25s;
          transition-timing-function: ease-in-out;
        }

        & > th {
          font-weight: bold;

          & + th {
            border-left: thin solid rgb(220, 220, 220);
          }
        }

        & > td {
          font-weight: 500;
          font-size: 14px;

          & + td {
            border-left: thin solid rgb(220, 220, 220);
          }

          &.action-cell {
            cursor: pointer;
            i {
              padding: 5px;
              font-size: 20px;
            }
          }
        }

        & + tr {
          td {
            border-top: 1px dotted rgba(220, 220, 220, 0.8);
          }
        }

        &:nth-of-type(even) {
          background-color: rgba(220, 220, 255, 0.4);
        }

        &.selected-carrier {
          background-color: rgba(0, 175, 25, 0.3);

          td {
            border-top-color: rgba(0, 155, 30, 0.7);
            border-left-color: rgba(0, 155, 30, 0.5);

            &:nth-of-type(3) {
              color: #000;
            }

            &.action-cell {
              color: $color--success;
            }
          }
        }

        &.contracted-carrier {
          &:not(.alert-danger):not(.alert-warning):not(.alert-success) {
            background-color: rgba(0, 25, 185, 0.3);
          }

          td {
            border-top-color: rgba(0, 25, 185, 0.7);
            border-left-color: rgba(0, 25, 185, 0.5);

            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3) {
              color: #000;
            }

            &.action-cell {
              cursor: default;
            }
          }
        }
      }

      & > tbody > tr {
        td {
          overflow: hidden;

          &:nth-of-type(3) {
            text-align: center;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: rgb(0, 200, 200);
        }
      }

      button.btn {
        box-shadow: none;
        background-color: transparent !important;
        padding: 2px;
        margin: 0;
        font-size: 20px;
        color: #333;
      }
    }

    ul.pagination {
      display: none;
    }
  }

  &.show-priority {
    & > .dataTables_wrapper {
      table.table.dataTable {
        & > thead > tr,
        & > tbody > tr {
          th,
          th.sorting,
          th.sorting_desc,
          th.sorting_asc,
          td {
            &:nth-of-type(1),
            &:nth-of-type(4) {
              width: 40px;
              text-align: center;
            }

            &:nth-of-type(2),
            &:nth-of-type(3) {
              width: calc(50% - 40px);
            }
          }

          th:first-of-type,
          th:last-of-type {
            &.sorting,
            &.sorting_desc,
            &.sorting_asc {
              pointer-events: none;
              cursor: default;
              &:before,
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
