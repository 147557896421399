@import './../../../../styles/global.variables';

#PaymentMethodsComponent {
  padding: 0;

  .card-header {
    background-color: $color--usabg--blue;
    color: #fff;
    text-align: center;
    font-weight: 500;
  }

  .card-body {
    h6 {
      border-bottom: 2px solid $color--usabg--blue;
      margin-bottom: 1rem;
    }

    &.payment-method {
      padding-bottom: 0;

      table {
        width: 100%;
        border: 1px solid rgb(200, 200, 200);
        border-radius: 0.25rem;
        margin-bottom: 2rem;

        thead {
          background-color: rgb(220, 220, 220);

          th:not(.action) {
            text-align: left;
            padding-left: 3px;
          }
        }

        tr + tr {
          td {
            border-top: 1px dashed rgb(230, 230, 230);
            padding-top: 2px;
            padding-bottom: 2px;
          }
        }

        td:not(.action) {
          text-align: left;
          padding-left: 3px;
        }

        .action {
          text-align: center;

          .fa-check {
            color: $color--success;
          }

          .default-btn,
          .delete-btn {
            border: none;
            background-color: transparent;
            transition-property: background-color, color;
            transition-duration: 0.2s;
          }

          .default-btn {
            background-color: rgba(0, 75, 255, 0.25);
            color: $color--usabg--blue;
            &:not([disabled]) {
              &:hover {
                background-color: $color--usabg--blue;
                color: #fff;
              }
            }
            &[disabled] {
              cursor: default;
              opacity: 0.5;
            }
          }

          .delete-btn {
            background-color: rgba(255, 0, 0, 0.25);
            color: $color--usabg--red;
            &:not([disabled]) {
              &:hover {
                background-color: $color--usabg--red;
                color: #fff;
              }
            }
            &[disabled] {
              cursor: default;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
