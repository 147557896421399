#MyDocsPage {
  .card-body {
    padding: 0;
    position: relative;
  }

  .loader {
    position: absolute;
    width: 100%;

    .LoadingSpinnerComponent .loading-wrapper {
      transform: translateY(2.5rem);
    }
  }

  .upload-widget-table {
    table {
      padding: 0;
      margin: 0;

      tbody + thead {
        display: none;
      }

      tr {
        & > th:last-of-type,
        & > td:last-of-type {
          text-align: center;
          width: 200px;
          max-width: 200px;
        }
      }
    }

    & > .row:nth-of-type(1),
    & > .row:nth-of-type(3) {
      display: none;
    }
  }
}
