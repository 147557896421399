@import './../../../../styles/global.variables';

#CarrierDivisionsFormComponent {
  position: relative;

  div.lockout-window {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 0.3rem;
  }
}
