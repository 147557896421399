@import './../../../../styles/global.variables';

#SubscriptionPackageComponent {
  padding: 0;

  .card-header {
    background-color: $color--usabg--blue;
    color: #fff;
    text-align: center;
    font-weight: 500;
  }

  .card-body {
    h6 {
      border-bottom: 2px solid $color--usabg--blue;
      margin-bottom: 1rem;
    }
  }

  .invoice {
    border: thin solid rgb(180, 180, 180);
    padding: 5px;
    border-radius: 0.35rem;
    margin-bottom: 20px;

    & > table {
      width: 100%;

      & > tbody {
        & > tr:nth-of-type(1) {
          td {
            height: 15px;
          }
        }

        & > tr:nth-of-type(2),
        & > tr:nth-of-type(3) {
          td:first-of-type {
            text-align: left;
            font-size: 1.25rem;
          }
          td:nth-of-type(2) {
            opacity: 0;
            font-weight: bold;
            color: #ff6037;
          }
          td:last-of-type {
            text-align: right;
            font-size: 1.25rem;
            // text-decoration: line-through;
          }
          td {
            padding: 3px;
            border-bottom: 1px solid #f2f2f2;
          }
        }

        & > tr:nth-of-type(4) {
          td {
            height: 35px;
          }
        }

        & > tr:nth-of-type(5) {
          td:nth-of-type(1) {
            text-align: left;
            .revert-plan {
              text-align: left;
              font-size: 1.25rem;
              color: $color--usabg--blue;
              cursor: pointer;
            }
          }
          td:nth-of-type(2) {
            text-align: right;
            font-size: 1.25rem;
          }
          td:nth-of-type(3) {
            text-align: right;
            font-size: 1.25rem;
          }
          td {
            border-top: 2px solid #d3d3d3;
          }
        }
      }
    }

    &.on-grace {
      border: thin solid rgb(200, 200, 200);

      & > table {
        width: 100%;

        & > tbody {
          & > tr:nth-of-type(3) {
            td:nth-of-type(2) {
              opacity: 1;
            }
            td:last-of-type {
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }

  .downgrade-modal {
    .modal-header {
      background-color: $color--invalid;
      h4 {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
      }
    }
    button.btn {
      font-weight: 500;
      font-size: 1rem;
      font-family: 'Roboto', sans-serif;
    }
  }
}
