@import './../../../styles/global.variables';

#KpiGoalManager {
  .kpi-dashboard-link {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;
    margin-bottom: 1rem;

    li.btn,
    div.dropdown > li {
      display: inline-block;
      margin: 0 2%;
      width: 46%;
      background-color: $color--usabg--blue;
      border: 1px solid $color--usabg--blue;
      border-radius: 0.35rem;
      color: #fff;
      font-family: Roboto;
      font-weight: 400;
      font-size: 1rem;
      text-transform: capitalize;

      i.fa {
        margin-right: 5px;
      }
    }

    div.dropdown > li {
      width: calc(100% - 4%);
      margin-top: 10px;
    }
  }

  .goals-complete-alert {
    font-weight: 500;
    background-color: yellow;
    padding: 0 5px;
    text-align: center;
    border: thin solid yellow;
    border-radius: 0.3rem;
    height: 2px;
    opacity: 0;
    margin: 0;
    transition-property: height, padding, opacity, margin;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;
    overflow: hidden;

    &.show {
      opacity: 1;
      height: 60px;
      padding: 5px;
      margin-bottom: 1.5rem;
    }
  }

  .goals-past-alert {
    font-weight: 500;
    background-color: rgba(255, 0, 0, 0.35);
    text-align: center;
    border: thin solid rgba(255, 0, 0, 0.35);
    border-radius: 0.3rem;
    margin: 0;
    transition-property: height, padding, margin;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;
    overflow: hidden;
    padding: 5px;
    margin-bottom: 1.5rem;

    button.btn {
      padding: 4px 8px;
      font-size: 14px !important;
    }
  }

  & > div.row {
    button.btn {
      font-weight: 500;
      font-family: Roboto;

      &:not([disabled]) {
        background-color: $color--usabg--blue !important;
      }
    }

    & > div.col-6,
    & > div.col-12 {
      & > div.kpi-step {
        border: 3px dotted rgb(180, 180, 180);
        border-radius: 1rem;
        display: flex;
        flex-wrap: wrap;

        & > h5.kpi-step-label {
          background-color: $color--usabg--blue;
          text-align: center;
          color: #fff;
          border-top-right-radius: 1rem;
          border-top-left-radius: 1rem;
          font-weight: 500;

          span i.fa {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
          }
        }

        & > div.kpi-step-descriptor {
          background-color: rgba(240, 240, 240, 0.75);
          font-weight: 400;
        }

        & > div.kpi-step-explainer {
          background-color: rgba(240, 240, 240, 0.75);
          font-weight: 400;
        }

        & > div.kpi-baseline-wrapper {
          background-color: #ddd;
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          & > div {
            text-align: center;
            font-weight: 500;
          }
        }

        & > div.kpi-input-wrapper {
          background-color: #fff;
          border-bottom-right-radius: 1rem;
          border-bottom-left-radius: 1rem;
          overflow: hidden;
          padding-bottom: 1rem;

          .kpi-input {
            h6 {
              font-weight: 500;
              font-family: Roboto;

              & + div {
                input {
                  text-align: right;
                }
              }
            }

            .ui-tooltip-trigger {
              h6 {
                width: 100%;
                max-width: 100%;
              }

              & + div {
                input {
                  text-align: right;
                }
              }
            }

            &.is-incomplete {
              .ui-tooltip-trigger,
              h6 {
                .danger-icon {
                  background-color: #e60000 !important;
                  border: 1px solid #e60000;
                }

                & + div {
                  input:not([readonly]):not([disabled]) {
                    background-color: yellow;
                  }
                }

                &.invalid-label + div {
                  input:not([readonly]):not([disabled]) {
                    background-color: rgba(230, 0, 0, 0.4);
                  }
                }
              }

              .bg-danger {
                .popover_arrow,
                .popover_arrow::before {
                  border-color: #e60000 transparent transparent transparent;
                }
              }
            }

            &.is-disabled {
              .ui-tooltip-trigger,
              h6 {
                & + div {
                  &:before {
                    border-bottom: none !important;
                  }

                  input[disabled] {
                    opacity: 1;
                    font-weight: 500;
                    color: #333;
                  }
                }
              }
            }

            & + .kpi-input {
              border-top: 1px dashed rgba(200, 200, 200, 0.5);
            }
          }
        }

        &.agent-past-performance,
        &.agent-calc-metrics,
        &.agent-action-plan {
        }

        &.is-disabled {
          opacity: 0.4;
        }

        &.is-valid {
          & > h5.kpi-step-label {
            background-color: $color--success;

            span i.fa {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  & > div.row {
    button.btn {
      font-size: 1.25rem;
    }

    & > div.col-6,
    & > div.col-12 {
      & > div.kpi-step {
        & > h5.kpi-step-label {
          width: 100%;
          padding: 8px;
          margin: 0;
          height: 2.5rem;

          span i.fa {
            width: 30px;
          }
        }

        & > div.kpi-step-descriptor {
          padding: 0.75rem;
          margin: 0;
          line-height: 1.25rem;
          font-size: 0.9rem;
          width: 100%;
        }

        & > div.kpi-step-explainer {
          padding: 0.75rem;
          margin: 0;
          width: 100%;

          button.btn {
            padding: 4px;
            font-size: 14px;
          }
        }

        & > div.kpi-baseline-wrapper {
          width: 100%;
          height: 2.5rem;

          & > div {
            flex: 0 0 50%;
            max-width: 50%;

            button.btn {
              padding: 2px 6px;
              font-size: 0.8rem;
            }
          }
        }

        & > div.kpi-input-wrapper {
          width: 100%;
          padding-top: 0.5rem;
          height: calc(100% - 13rem);

          .kpi-input {
            padding: 4px 0;

            .ui-tooltip-trigger,
            h6 {
              width: 55%;
              max-width: 55%;
              padding-left: 0.5rem;
              font-size: 0.85rem;
              margin: 0 5% 0 0;
              height: 100%;

              & + div.ToolTipComponent + div,
              & + div:not(.ToolTipComponent) {
                width: 40%;
                max-width: 40%;

                input {
                  margin: 0;
                  padding-left: 0.3rem;
                  padding-right: 0.5rem;
                  text-align: right;
                }
              }
            }

            .ui-tooltip-trigger {
              h6 {
                padding-left: 0;
              }
            }
          }
        }

        &.agent-action-plan {
          & > div.kpi-input-wrapper {
            height: calc(100% - 19.5rem);

            &.short-wrapper {
              height: 14rem;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    & > div.row {
      button.btn {
        margin-bottom: 3rem;
      }

      & > div.col-6,
      & > div.col-12 {
        & > div.kpi-step {
          margin-bottom: 1.5rem;

          & > h5.kpi-step-label {
            span i.fa {
            }
          }

          & > div.kpi-step-descriptor {
          }

          & > div.kpi-baseline-wrapper {
            & > div {
              button.btn {
                margin-bottom: 0;
              }
            }
          }

          & > div.kpi-input-wrapper {
            .kpi-input {
              h6 {
                & + div {
                  input {
                  }
                }
              }
            }
          }

          &.agent-action-plan {
            & > div.kpi-input-wrapper {
              &.short-wrapper {
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .kpi-dashboard-link {
      li.btn {
        margin: 0 2% 10px 2%;
        width: 96%;
      }

      div.dropdown > li {
        margin-top: 0;
      }
    }

    & > div.row {
      & > div.col-6,
      & > div.col-12 {
        & > div.kpi-step {
          height: 100%;

          & > h5.kpi-step-label {
            padding: 12px 8px 8px 8px;
            font-size: 1rem;
          }

          & > div.kpi-step-descriptor {
            height: 10rem;
          }

          & > div.kpi-step-explainer {
          }

          & > div.kpi-baseline-wrapper {
            height: 3.5rem;
          }

          & > div.kpi-input-wrapper {
            height: calc(100% - 19.5rem);
          }

          &.agent-action-plan {
            & > div.kpi-input-wrapper {
              height: calc(100% - 36.75rem);
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    & > div.row {
      & > div.col-6,
      & > div.col-12 {
        & > div.kpi-step {
          height: 100%;

          & > h5.kpi-step-label {
            padding: 8px;
            font-size: 1.25rem;
          }

          & > div.kpi-step-descriptor {
            height: 9rem;
          }

          & > div.kpi-step-explainer {
          }

          & > div.kpi-baseline-wrapper {
            height: 3.5rem;
          }

          & > div.kpi-input-wrapper {
            height: calc(100% - 18.75rem);
          }

          &.agent-action-plan {
            & > div.kpi-input-wrapper {
              height: calc(100% - 36rem);
            }
          }
        }
      }
    }
  }
}
