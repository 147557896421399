@import './../../../styles/global.variables';

#AgentNotesComponent {
  border: 1px solid $color--gray--md-2;
  border-radius: 0.5rem;

  #message-field {
    height: 500px;
    padding: 0.5rem;
    overflow-y: auto;
    position: relative;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background-color: white;

    .messages-spinner {
      position: sticky;
      top: 45%;
      display: flex;
      justify-content: center;
      align-items: centers;
      z-index: 999;
      height: 0;
    }

    .message-container {
      margin-bottom: 1rem;
      border-radius: 0.25rem;
      box-shadow: 0px 1px 3px $color--gray--md-2;
      background-color: #c9f3ff;
      padding: 0.5rem;
      position: relative;

      &.locked {
        background-color: #ffe0bf;
      }

      .message {
        width: 90%;
        position: relative;
        color: rgb(50, 50, 50);
        font-weight: 500;
        margin-bottom: 1rem;
        white-space: pre-line;
      }

      .message-date {
        font-size: 12px;
        font-weight: 400;
        color: $color--gray--dk-1;
      }

      .message-author {
        font-size: 12px;
        font-weight: 400;
        color: $color--gray--dk-1;
      }

      .message-delete {
        position: absolute;
        cursor: pointer;
        top: 0.3rem;
        right: 0.5rem;
      }

      .message-locked {
        position: absolute;
        bottom: 0.3rem;
        right: 0.5rem;
      }
    }
  }

  #message-editor {
    border-top: 1px solid $color--gray--md-2;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 0.25rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    textarea {
      border: 1px solid $color--gray--md-2;
      border-radius: 0.5rem;
      height: 100%;
      width: 100%;
      padding: 4px 8px;
      resize: none;
      outline: 0;
      color: rgb(50, 50, 50);
    }

    &:focus {
      box-shadow: none;
    }

    i {
      cursor: pointer;
    }

    .private-toggle {
      font-weight: 400;
      cursor: pointer;
    }

    .filter-toggle {
      cursor: pointer;
      min-width: 65px;

      p {
        font-weight: 500;
        font-size: 12px;
        margin: 0;
        text-transform: capitalize;
      }
    }
  }
}
