@import './../../../styles/global.variables';

#ElectiveCartListComponent {
  padding: 0;
  margin: 0 0 30px 0;

  .ContentHtmlComponent > .content-html-inner-wrapper {
    padding: 1rem 0;

    div {
      font-size: 0.9rem;
      font-weight: 400;
      font-family: 'Roboto';

      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  ul.contract-cart-item-list {
    padding: 0;
    margin: 0;
    border: 1px solid rgba(220, 220, 200, 0.5);
    border-radius: 0.3rem;

    & > li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > div.cart-item {
        flex-grow: 1;
        text-align: left;
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        transition-property: opacity, flex, width, max-width;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;

        &-profit {
          flex: 0 0 4rem;
          max-width: 4rem;
        }

        &-carrier {
          flex: 0 0 calc(50% - 4rem);
          max-width: calc(50% - 4rem);

          small {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        &-description {
          flex: 0 0 50%;
          max-width: 50%;
        }

        &-include {
          max-width: 0;
          overflow: hidden;
        }
      }

      &:nth-of-type(1) {
        background-color: #2b7f8d;
        border-color-bottom: #2b7f8d;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;

        & > div.cart-item {
          font-weight: 600;
          padding: 2px;
          font-size:1.125rem;
          color: #FFF;

          small {
            display: block;
          }

          div.required-items {
            background: rgba(255,255,255,0.2);
            border: thin solid transparent;
            border-radius: 0.3rem;
            text-align: center;
            font-weight: 500;
            font-size: 0.8rem;
            max-width: 9rem;
            float: right;
            white-space: normal;
            display: block;
            margin: 3px 3px 0 0;
            transition-property: background-color, color;
            transition-timing-function: ease-in-out;
            transition-duration: 0.2s;

            span {
              display: inline-block;
              background-color: #FFF;
              border: thin solid white;
              border-radius: 0.2rem;
              padding: 0 2px;
              color: $color--usabg--blue;
              transition: opacity 0.2s ease-in-out;
              opacity: 1;
            }

            &.is-ready {
              background: rgba(255,255,255,1);
              color: #29733a;

              span {
                opacity: 0;
              }
            }
          }
        }
      }

      &:nth-of-type(2) {
        background-color: rgba(220, 220, 200, 0.35);
        font-weight: 500;
        font-size: 0.9rem;


        & > div.cart-item {
          &-include {
            text-align: center;
            padding-right: 1rem;
          }
        }
      }

      &:not(:nth-of-type(1)):not(:nth-of-type(2)) {
        &.is-include-carrier {
          background-color: rgba(220, 220, 200, 0.45);
        }

        &.is-selectable-carrier {}

        & > div.cart-item-profit {
          font-weight: 600;
          font-size: 1rem;
        }
      }

      & + li {
        border-top: 1px dotted rgba(0, 25, 125, 0.2);
      }
    }

    &.show-include {
      & > li {
        & > div.cart-item {
          transition-property: opacity, flex, width, max-width;
          transition-duration: 0.2s;
          transition-timing-function: ease-in-out;
        }
      }
    }

    &.show-include {
      & > li {
        & > div.cart-item {
          &-carrier {
            flex: 0 0 calc(50% - 8rem);
            max-width: calc(50% - 8rem);
          }

          &-description {
            flex: 0 0 calc(50% - 3rem);
            max-width: calc(50% - 3rem);
          }

          &-include {
            max-width: 7rem;
          }
        }
      }
    }
  }
}
