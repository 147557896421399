@import './../../../styles/global.variables';

.AdditionalDocumentsListComponent {
  margin: 20px;

  ul.documents-list {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      display: flex;

      & > div:nth-of-type(1) {
        flex: 0 0 calc(100% - 12rem);
        max-width: calc(100% - 12rem);

        input.form-control {
          margin: 0;
        }
      }

      & > div:nth-of-type(2) {
        padding-top: 4px;
        padding-left: 0.5rem;
        flex: 0 0 6rem;
        max-width: 6rem;
      }

      & > div:nth-of-type(3),
      & > div:nth-of-type(4) {
        padding-top: 4px;
        flex: 0 0 3rem;
        max-width: 3rem;

        .btn.btn-sm {
          padding: 0;
          text-align: center;
          height: 32px;
          line-height: 32px;
          font-size: 1rem;
        }
      }

      &:first-of-type {
        & > div:nth-of-type(1),
        & > div:nth-of-type(2),
        & > div:nth-of-type(3) {
          font-size: 0.8rem;
          font-weight: 500;
          border-bottom: 1px solid #d3d3d3;
        }

        & > div:nth-of-type(2),
        & > div:nth-of-type(3) {
          text-align: center;
          padding: 0 2px;
        }
        & > div:nth-of-type(3) {
          max-width: 6rem;
          flex: 0 0 6rem;
        }
      }
    }
  }
}
