@import './../../../styles/global.variables';

#AgentOnboarder {
  .onboarding-manager-wrapper {
    background-color: #fff;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.3);
    padding: 40px 10px;
    margin-bottom: 40px;

    h3 {
      border-bottom: 2px solid $color--usabg--blue;

      & + div {
        font-weight: 400;
        margin-bottom: 30px;
      }
    }
  }

  .container.cont-1-a {
    @media screen and (max-width: 1199px) {
      max-width: 100%;
      width: 100%;
    }

    @media screen and (min-width: 1200px) {
      max-width: 1140px;
      width: 1140px;
    }

    @media screen and (min-width: 1400px) {
      max-width: 1340px;
      width: 1340px;
    }

    // @media (min-width: 768px) { max-width: 720px; }
    // @media (min-width: 576px) { max-width: 540px; }
    // @media (min-width: 1200px) { max-width: 1140px; }
    // @media (min-width: 992px) { max-width: 960px; }
    // @media (min-width: 768px) { max-width: 720px; }
    // @media (min-width: 576px) { max-width: 540px; }
  }

  #onboarding-manager-stage {
    position: relative;

    & > .agent-profile,
    & > .contracting,
    & > .training,
    & > .set-kpi-goals,
    & > .writing-numbers {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition-property: opacity, visibility;
      transition-duration: 0.5s;
      transition-timing-function: ease-in-out;
      border: thick solid transparent;
      width: 100%;

      .onboarding-stage-label {
        h2 {
          font-weight: 500;
          color: #fff;
          font-family: 'Roboto';
          margin: 0;
          text-align: center;
          padding: 5px;
          background-color: $color--usabg--blue;
          border: 1px solid $color--usabg--blue;
          border-radius: 0.25rem;
          position: absolute;
          z-index: 100;
          top: 0px;
          transform: translateY(-50%);
          width: calc(100% - 30px);
          max-width: calc(100% - 30px);

          span.intro-step {
            border-radius: 50%;
            border: 5px solid #fff;
            color: #fff;
            font-weight: 500;
            display: inline-block;
            height: 40px;
            width: 40px;
            text-align: center;
            line-height: 30px;
            margin-right: 10px;
          }
        }
      }
    }

    &.agent-profile {
      & > .agent-profile {
        pointer-events: auto;
        opacity: 1;
        visibility: visible;
      }
    }
    &.contracting {
      & > .contracting {
        pointer-events: auto;
        opacity: 1;
        visibility: visible;
      }
    }
    &.training {
      & > .training {
        pointer-events: auto;
        opacity: 1;
        visibility: visible;
      }
    }
    &.set-kpi-goals {
      & > .set-kpi-goals {
        pointer-events: auto;
        opacity: 1;
        visibility: visible;
      }
    }
    &.writing-numbers {
      & > .writing-numbers {
        pointer-events: auto;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
