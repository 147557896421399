@import 'src/styles/global.variables';

#VersionsTableComponent {
	&>.container {
		max-height: 80vh;
		overflow: scroll;

		&>.row {
			padding-top: 1rem;
			padding-bottom: 1rem;

			&:nth-of-type(even) {
				background-color: rgba(220,220,220,0.5);
			}
		}

		div.change-type {
			&>.alert {
				margin: 0;

				.version-number {
					margin-bottom: 1rem;
					span {
						font-size: 1.5rem;
						font-weight: 500;
						line-height: 1.75rem;
					}
				}

				.release-date {
					font-size: 0.8rem;
					span {
						font-size: 1rem;
						font-weight: 500;
						line-height: 1.125rem;
					}
				}

				.tech-tip {
					font-size: 1.5rem;
					font-weight: 500;
					line-height: 1.75rem;
				}
			}
		}

		div.change-detail {
			font-weight: 400;
			font-size: 1.25rem;
			line-height: 1.75rem;

			@media only screen and (max-width:767px) {
				margin-top: 12px;
			}
		}
	}
}