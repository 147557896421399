@import './../../styles/global.classes';
@import './../../styles/global.variables';

ul#AgentNavLinksComponent {
	padding: 0;
	margin: 0;

	/*
	* -----------------------------------------
	* Reusable Nav Link Classes.
	*
	*/
	.nav-button-colors {
    /*
    Override to correct indigo-skin theme colors.
    This can go away when we get rid of the faulty 
    indigo-skin css classes.
    */
    &.bg--orange {
      background-color: $color--orange !important;
    }
    &.bg--yellow {
      background-color: $color--yellow !important;
    }
    &.bg--green {
      background-color: $color--green !important;
    }
    &.bg--blue {
      background-color: $color--blue !important;
    }
    &.bg--indigo {
      background-color: $color--indigo !important;
    }
    &.bg--purple {
      background-color: $color--purple !important;
    }
    &.bg--red {
      background-color: $color--red !important;
    }
	}

	.nav-a-link {
		color: #FFF;
		font-size: 1rem;
		font-weight: 500;
		padding:  1.125rem 0;
		height: auto;
		margin: 0;
		display: block;
		line-height: 1.125rem;
		border-radius: 0;
	}


	/*
	* -----------------------------------------
	* Modifier Nav Link Classes.
	*
	*/
	i.fa-lock {
	  overflow: hidden;
	  opacity: 0;
	  font-size: 0.9rem;
	  margin-right: 0;
	  width: 0;
	}

	.is-hidden-link:not(.is-overriden-link) {
		display: none;
	}

	.is-hidden-dropdown:not(.is-overriden-dropdown) {
		display: none;
	}

  .is-locked-link,
	.is-hidden-link.is-overriden-link,
	.is-hidden-dropdown.is-overriden-dropdown {
		opacity: 0.65;

		&>a {
			i.fa-lock {
				opacity: 0.85;
				overflow: visible;
				margin-right: 0.25rem;
				width: 12px;
			}
		}
	}

	&>li {
		margin-bottom: 3px;
		text-align: center;

		// ------------------------------------------------------
		// MajorNavButton 
		&.majorNavButton {
			padding: 0;

			&>a {
				@extend .nav-button-colors;
				@extend .nav-a-link;
			}
		}

		// ------------------------------------------------------
		// MajorNavDropdown 
		&>ul.majorNavDropdown {
			margin: 0;

			&>li {
				&>a {
					@extend .nav-button-colors;
					@extend .nav-a-link;

					&>i.fa-angle-down {
						margin-top: 4px;
					}
				}

	      &>div.collapsing,
	      &>div.collapse {

	      	// MajorNavDropdown > First-Tier Nested UL
	      	// ie: 'Agent Resources (dropdown)' > 'Agent Tools (dropdown)'
	      	&>div>ul {

	      		.Ripple,
	      		.Ripple.is-reppling {
	      			display: none;
	      		}

	      		&>li.minorNavLink {
	      			// border: 2px solid aqua;
      				border-top: 1px solid rgba(225, 255, 255, 0.05);

				      &>a {
				      	// border: 1px solid red;	
				      	padding: 0;
				      	font-size: 0.9rem;
				      	font-weight: 500;
				      }

              &.is-locked-link,
	      			&.is-overriden-link {
	      				// border: 2px solid yellow;

	      				// &>a {
				      	// 	padding-left: 1.25rem;

		      			// 	i.fa-lock {
							  //     opacity: 0.85;
							  //     overflow: visible;
							  //     margin-right: 0.25rem;
		      			// 	}
	      				// }
	      			}
	      		}

	      		&>li:not(.minorNavLink) {
	      			// border: 2px solid pink !important;
      				border-top: 1px solid rgba(225, 255, 255, 0.05);

				      &>ul.minorNavDropdown {
				      	// border: 2px solid purple !important;
				      	margin-top: 0;

				      	&>li {
						      &>a {
						      	padding: 0;
						      	font-size: 0.9rem;
						      	font-weight: 500;
						      }

						      &>div.collapsing,
						      &>div.collapse {
						      	&>div>ul {
						      		// border: 2px solid white;
											background-color: rgba(200,200,200,0.8) !important;

						      		&>li.minorNavLink {
						      			// border: thin solid white;
      									border-top: 1px solid rgba(0, 0, 0, 0.55);

									      &>a {
									      	// border: 1px solid red;	
									      	padding: 0;
									      	font-size: 0.9rem;
									      	font-weight: 500;

													&:hover {
														background-color: rgba(80,80,80,0.8) !important;
													}
									      }

                        &.is-locked-link,
						      			&.is-overriden-link {
						      				// border: 2px solid yellow;

						      				// &>a {
									      	// 	padding-left: 1.25rem;

							      			// 	i.fa-lock {
												  //     opacity: 0.85;
												  //     overflow: visible;
												  //     margin-right: 0.25rem;
							      			// 	}
						      				// }
						      			}
						      		}
						      	}
						      }
				      	}
				      }
	      		}

				    li:last-of-type {
				      border-bottom: 1px solid rgba(0, 20, 100, 0.8);
				    }
	    		}
	    	}
			}
		}
	}
}














/*
li.AgentNavLinksComponent {
  
  & > a {
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 1rem;
    color: #fff;
    font-weight: 500;

    & > i.fa-lock {
      overflow: hidden;
      opacity: 0;
      font-size: 0.9rem;
      margin-left: 0;
    }
  }

  &.is-hidden-link,
  li.is-hidden-link {
  	// border: thick solid black !important;
  }


  // &.is-hidden-link > a {
  //   opacity: 0.65;

  //   & > i.fa-lock {
  //     opacity: 0.85;
  //     overflow: visible;
  //     margin-left: 0.25rem;
  //   }
  // }


	ul.agentNavLinks,
	ul.minorNavDropdown {
		border: thick solid black !important;
	  &.collapsible {
	  	margin-top: 0;
	  }
	}
}

ul.agentNavLinks {
  & > li {
    & > a {
      & > i.fa-lock {
        overflow: hidden;
        opacity: 0;
        font-size: 0.9rem;
        margin-left: 0;
      }
    }
  }

  &.collapsible.with-children {
    margin-bottom: 2px;

    & > li {
      & > a {
				height: auto;
				line-height: 1.25rem;
				padding: 1rem 0;
        display: block;

			  & > i {
			    height: calc(100% - 2rem);
			    line-height: 100%;
			    margin-top: 0.25rem;
			  }
      }

      &>div.collapsing,
      &>div.collapse {
      	&>div>ul {

      		.Ripple,
      		.Ripple.is-reppling {
      			display: none;
      		}

					&>li {
						&>a {
							padding: 0;
						}
						&.is-hidden-link {
							border: thick solid pink;
					    opacity: 0.65;

					    & > li > a {
					      & > i.fa-lock {
					        opacity: 0.85;
					        overflow: visible;
					        margin-left: 0.25rem;
					      }

					      & + div.collapse,
					      & + div.collapsing {
					        i.fa-lock {
					          margin-left: 0.25rem;
					        }
					      }
					    }



						}
					}
	      }
      }
    }

    li {
      & > a {
        margin: 0;
        text-align: center;
        font-size: 1rem;
        color: #fff;
        font-weight: 500;
        border-radius: 0;

        /|
		    Override to correct indigo-skin theme colors.
		    This can go away when we get rid of the faulty 
		    indigo-skin css classes.
		    |/
        &.bg--orange {
          background-color: $color--orange !important;
        }
        &.bg--yellow {
          background-color: $color--yellow !important;
        }
        &.bg--green {
          background-color: $color--green !important;
        }
        &.bg--blue {
          background-color: $color--blue !important;
        }
        &.bg--indigo {
          background-color: $color--indigo !important;
        }
        &.bg--purple {
          background-color: $color--purple !important;
        }
        &.bg--red {
          background-color: $color--red !important;
        }
      }

      ul.minorNavDropdown {
		    li {
		      & > a {
		        padding: 0;
		      }
		    }
      }
    }

    li + li {
      border-top: 1px solid rgba(225, 255, 255, 0.05);
    }

    li:last-of-type {
      border-bottom: 1px solid rgba(0, 20, 100, 0.8);
    }
  }

  // &:not(.is-hidden-links) {
  //   & > li > a {
  //     & + div.collapse,
  //     & + div.collapsing {
  //       i.fa-lock {
  //         display: none;
  //         margin: 0;
  //       }
  //     }
  //   }
  // }

  // &.is-hidden-links {
  //   opacity: 0.65;

  //   & > li > a {
  //     & > i.fa-lock {
  //       opacity: 0.85;
  //       overflow: visible;
  //       margin-left: 0.25rem;
  //     }

  //     & + div.collapse,
  //     & + div.collapsing {
  //       i.fa-lock {
  //         margin-left: 0.25rem;
  //       }
  //     }
  //   }
  // }
}

ul.minorNavDropdown {
	ul>li {
		background-color: rgba(200,200,200,0.8) !important;
		&>a:hover {
			background-color: rgba(80,80,80,0.8) !important;
		}
	}
}

.side-nav li.AgentNavLinksComponent > a {
	height: auto;
	line-height: 1.25rem;
	padding: 1rem 0;
}
*/