@import './../../../../styles/global.variables';

.TrainingListComponent {
  padding: 0;
  margin-bottom: 2rem;
  box-shadow: none;
  background-color: transparent;

  .card-header {
    padding-left: 16px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);

    .label {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
      font-weight: 500;
    }

    .edit-btn,
    .trash-btn {
      overflow: hidden;
      width: 0;
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    .trash-btn {
      color: $color--usabg--red;
    }

    .caret-close-btn {
      transform: rotate(180deg);
      transition: transform 0.3s;
    }

    .caret-open-btn {
      transform: rotate(0deg);
      transition: transform 0.3s;
    }
  }

  .card-body {
    padding: 0;
    background-color: #fff;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);

    ul.list-group {
      li.list-group-item {
        position: relative;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        transition: background-color 0.2s ease-in-out;

        div.label {
          flex: 0 0 100%;
          max-width: 100%;
        }

        div.attended-editable,
        div.attended-tag {
          overflow: hidden;
          flex: 0 0 0;
          max-width: 0;
          height: 1.5rem;
          line-height: 1.45rem;
          border: thin solid transparent;
          border-radius: 0.5rem;
          text-align: center;
          font-size: 0.75rem;
          font-weight: 500;
          background-color: $color--success;
          color: #fff;
          opacity: 0;
        }

        div.edit-btn,
        div.trash-btn {
          overflow: hidden;
          flex: 0 0 0;
          max-width: 0;
          color: $color--usabg--blue;
          opacity: 0.6;
          transition: opacity 0.2s ease-in-out;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }

        div.trash-btn {
          color: $color--usabg--red;
        }

        &.has-attended {
          div.label {
            flex: 0 0 calc(100% - 5rem);
            max-width: calc(100% - 5rem);
          }

          div.attended-tag {
            flex: 0 0 5rem;
            max-width: 5rem;
            opacity: 1;
          }

          &.can-delete-attended {
            div.label {
              flex: 0 0 calc(100% - 6rem);
              max-width: calc(100% - 6rem);
            }

            div.attended-tag {
              flex: 0 0 6rem;
              max-width: 6rem;
              opacity: 1;
            }
          }
        }

        &:hover {
          background-color: rgba(0, 40, 255, 0.2);
          cursor: pointer;

          div.label {
            text-decoration: underline;
          }
        }

        .training-pills {
          margin-left: 1.5rem;

          span.badge {
            margin-right: 0.3rem;
          }
        }
      }
    }
  }

  .training-icon {
    font-size: 12px;
    margin: 0 6px 0 3px;
  }

  &.card.can-edit {
    .card-header {
      .label {
        flex: 0 0 calc(100% - 2rem);
        max-width: calc(100% - 2rem);
      }

      .edit-btn {
        flex: 0 0 2rem;
        max-width: 2rem;
        text-align: center;
        opacity: 0.6;

        &:hover {
          opacity: 1;
        }
      }
    }

    .card-body {
      padding: 0;

      ul.list-group {
        li.list-group-item {
          div.label {
            flex: 0 0 calc(100% - 4rem);
            max-width: calc(100% - 4rem);
          }

          div.edit-btn {
            flex: 0 0 2rem;
            text-align: center;
            max-width: 2rem;
          }

          div.trash-btn {
            flex: 0 0 2rem;
            text-align: center;
            max-width: 2rem;
          }

          &.has-attended {
            div.label {
              flex: 0 0 calc(100% - 10rem);
              max-width: calc(100% - 10rem);
            }

            div.attended-tag {
              flex: 0 0 6rem;
              max-width: 6rem;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &.card.can-trash {
    .card-header {
      .label {
        flex: 0 0 calc(100% - 4rem);
        max-width: calc(100% - 4rem);
      }

      .trash-btn {
        flex: 0 0 2rem;
        max-width: 2rem;
        text-align: center;
        opacity: 0.6;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &.is-open {
    .card-header {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
