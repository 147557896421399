@import './../../../../styles/global.variables';

#FMOEditorFormComponent {
  background-color: rgba(220, 220, 220, 0.3);
  border: thin solid rgba(220, 220, 220, 0.6);
  border-radius: 0.4rem;
  margin-bottom: 0.5rem;

  .action-wrapper {
    button.btn {
      padding: 3px;
      background-color: $color--success !important;
      margin: 12px 0;
    }

    div.md-form {
      margin: 0;
    }
  }

  .form-wrapper {
    button.btn {
      font-weight: bold !important;
      font-size: 0.8rem;

      &[type='submit'] {
        background-color: $color--success !important;
      }
    }
  }
}
