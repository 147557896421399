@mixin truncateEllipsis($font-size, $line-height, $lines-to-show, $max-width) {
  // display: block; // Fallback for non-webkit
  // display: -webkit-box;
  // max-width: #{$max-width};
  // height: #{$font-size}*#{$line-height}*#{$lines-to-show}; // Fallback for non-webkit
  // font-size: $font-size;
  // line-height: $line-height;
  // -webkit-line-clamp: $lines-to-show;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
  // text-overflow: ellipsis;

  $height: #{$line-height * $lines-to-show}px;

  display: block; // Fallback for non-webkit
  display: -webkit-box;
  max-width: #{$max-width};
  height: $height;
  font-size: #{$font-size}px;
  line-height: #{$line-height}px;
  // height: #{$font-size}*#{$line-height}*#{$lines-to-show}; // Fallback for non-webkit
  // font-size: $font-size;
  // line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
