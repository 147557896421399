@import './../../../styles/global.variables';

#SaveOnboardingCartButtonComponent {
  & > .btn.start-checkout-btn {
    i.fa {
      transition: width, opacity 0.2s ease-in-out;
      width: 1.5rem;
      opacity: 1;
    }
    &[disabled] {
      i.fa {
        overflow: hidden;
        width: 0;
        opacity: 0;
      }
    }
  }
}
