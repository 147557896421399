.cal-color-picker {
	padding: 0;

	&>button.btn {
		width: 100%;
		padding: 4px;
	}

	button.dropdown-item {
		padding: 0 !important;

		&>button.btn {
			width: calc(100% - 4px);
			text-align: center;
			margin: 2px;
			padding: 3px 8px;
		}
	}
}