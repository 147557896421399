@import './../../../styles/global.variables';

.AgentWalletDocumentsButtonComponent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & > .w9-btn,
  & > .dd-btn {
    flex: 0 0 calc(50% - 5rem);
    max-width: calc(50% - 5rem);
    margin: 0 0 0 2rem;
    padding: 0;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > .w9-icon.alert,
  & > .dd-icon.alert {
    flex: 3rem;
    max-width: 3rem;
    padding: 0 !important;
    margin: 0 1rem 0 0 !important;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
