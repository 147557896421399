@import 'src/styles/global.variables';

#BeastModePackagesComponent {
  h5 {
    background-color: $color--sky-blue;
    padding: 0.75rem;
    border: thin solid transparent;
    border-radius: 0.5rem;
    color: #fff;
    font-family: 'Lato' !important;
    font-weight: 700 !important;
  }

  .ContentHtmlComponent {
    table {
      thead {
        th {
          background-color: rgba(220, 220, 220, 0.8);
          font-weight: 500;
        }
      }

      tbody {
        td {
          font-weight: 400;
          font-size: 0.9rem;

          & + td {
            border-left: 1px dotted rgba(220, 220, 220, 0.8);
          }
        }
      }

      thead,
      tbody {
        th,
        td {
          text-align: center;

          &:first-of-type {
            text-align: left;
          }
        }
      }
    }
  }

  button.btn {
    background-color: $color--green !important;
  }

  form {
    margin: 2rem 1rem 1rem 1rem;

    button.btn[type='submit'] {
      background-color: $color--usabg--blue !important;
    }
  }
}
