@import 'src/styles/global.variables';

#KbArticlesFormComponent {
  .select-wrapper > label.mdb-main-label {
    top: 0;
  }

  div.ui-input-validation {
    position: absolute;
    font-size: 0.8rem;
    font-weight: 500;
    right: 0;
    transform: translate(-0.25rem, -0.5rem);

    & > span.success-msg {
      color: $color--valid;
    }

    & > span.error-msg {
      color: $color--invalid;
    }
  }

  ul.kw-list-form {
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin: 1px 3px 1px 1px;
      padding: 2px 4px;
      border: thin solid rgba(50, 150, 250, 0.35);
      background-color: rgba(50, 150, 250, 0.35);
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      border-radius: 9px;
    }
  }

  .kba-add-keyword {
    padding: 2px 4px;
  }

  div.btn-group {
    width: 100%;

    button.btn {
      color: #fff;
      box-shadow: none;

      &:first-of-type {
        max-width: 30%;
      }
    }
  }
}
