@import 'src/styles/global.variables';

.LeadPackageCardComponent {
  &.card {
    padding: 0;
    box-shadow: none;
    background-color: transparent !important;

    .card-header {
      display: flex;
      flex-wrap: nowrap;
      height: 4.5rem;
      overflow: hidden;

      & > div {
        display: flex;
        align-items: center;
        font-size: 1.125rem;
        height: 100%;

        &:nth-of-type(1) {
          text-align: left;
          flex-grow: 1;
          text-overflow: ellipsis;
        }

        &:nth-of-type(2) {
          text-align: center;
          justify-content: center;
          align-items: center;

          .toggle-open-btn {
            display: block;
            align-self: flex-end;
            padding: 4px 1.25rem;
            height: 100%;
            margin: 0;
            width: 100%;
            background-color: transparent !important;
            outline: none;
            box-shadow: none;

            i.fa {
              font-size: 1.125rem;
              transition: transform ease-in-out 0.35s;
            }

            &.is-open {
              i.fa {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }

    .card-body {
      background-color: #fff;
    }
  }
}
