@import './../../../styles/global.variables';

#LoginComponent {
  position: relative;

  & > .card-header {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.25px;
  }

  & > .card-body {
    padding: 2px;
  }
}
