@import 'src/styles/global.variables';

#ToggleWalletLockComponent {
  div.label-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    color: #333;
  }

  div.switch-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: #666;
  }

  &.is-loading {
    // background-color: rgba(200, 200, 200, 0.4);
  }

  &.is-activated {
    // background-color: rgba(200, 20, 20, 0.4);
  }

  &.is-deactivated {
    // background-color: rgba(20, 200, 20, 0.4);
  }
}
