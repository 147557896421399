@import 'src/styles/global.variables';

#W9ManagerComponent {
  .card-header {
    background-color: $color--usabg--blue;
    color: #fff;
  }

  .card-body {
    form.w9-form {
      margin-bottom: 1.5rem;

      .ui-input:not(.ui-date-picker-input) label > small {
        display: none;
      }

      div.col {
        &:nth-of-type(1),
        &:nth-of-type(2) {
          flex: 0 0 calc(50% - 4rem);
          max-width: calc(50% - 4rem);
        }
        &:nth-of-type(3),
        &:nth-of-type(4) {
          flex: 0 0 4rem;
          max-width: 4rem;
          padding: 0 3px;
        }
      }

      button.btn {
        padding: 3px;
        width: 100%;
        max-width: 4rem;
        height: 100%;
        margin: 0;
        &[type='button'] {
          background-color: $color--invalid !important;
        }
        &[type='submit'] {
          background-color: $color--green !important;
        }
      }
    }
  }

  ul.rp-w9-list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    & > li {
      display: flex;
      font-size: 0.8rem;

      &:first-of-type {
        background-color: rgba(240, 240, 240, 0.75);
        font-weight: 500;
        color: #666;
      }

      &:nth-of-type(even) {
        background-color: rgba(0, 225, 225, 0.1);
      }

      & + li {
        border-top: 1px solid rgba(240, 240, 240, 0.75);
      }

      div.w9-year {
        padding-left: 2px;
        flex: 0 0 4rem;
        max-width: 4rem;
      }
      div.w9-received-at {
        padding-left: 2px;
        flex-grow: 1;
      }
      div.w9-upload {
        flex: 0 0 4rem;
        max-width: 4rem;

        button.btn {
          background-color: $color--yellow !important;
        }
      }
      div.w9-download {
        flex: 0 0 4rem;
        max-width: 4rem;

        button.btn {
          background-color: $color--green !important;
        }
      }

      div.w9-edit {
        flex: 0 0 3rem;
        max-width: 3rem;

        button.btn {
          background-color: $color--blue !important;
        }
      }

      div.w9-delete {
        flex: 0 0 3rem;
        max-width: 3rem;

        button.btn {
          background-color: $color--red !important;
        }
      }

      div.w9-upload,
      div.w9-download,
      div.w9-edit,
      div.w9-delete {
        text-align: center;

        button.btn {
          width: calc(100% - 4px);
          margin: 2px;
          padding: 2px 0;
        }
      }
    }
  }
}
