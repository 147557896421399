@import './../../../styles/global.variables';

.AvailableContractsListComponent {

  // REPLACEMENT CARRIER SELECTOR
  div.dropdown {
    &:not(.is-replaced) {
      button.btn.btn-secondary.dropdown-toggle {
        background-color: $color--success !important;
        color: #fff !important;
        width: calc(100% - 12px);
        padding: 8px;
      }
    }
    &.is-replaced {
      button.btn.btn-secondary.dropdown-toggle {
        background-color: transparent !important;
        color: $color--usabg--blue;
        width: calc(100% - 4rem);

        & > div {
          font-family: 'Roboto';
          padding-left: 8px;
          font-size: 1rem;
          text-transform: none;

          & + div {
            font-weight: 400;
            font-size: 0.8rem;
            text-transform: none;
          }
        }
      }

      button.btn.remove-replace-btn {
        width: 2rem;
      }
    }

    div.dropdown-menu {
      background-color: transparent;
      width: calc(100% - 12px);
      display: block;
      position: relative;
      transition-property: background-color, opacity, visibility;
      transition-duration: 0.3s;
      opacity: 0;
      visibility: hidden;

      &.show {
        background-color: rgba(0, 25, 125, 1);
        opacity: 1;
        visibility: visible;
      }

      div.dropdown-menu-scroller {
        max-height: 50vh;
        height: auto;
        overflow: auto;
        position: relative;

        ul.replace-selector-list {
          border: thin solid rgba(0, 25, 125, 0.2);
          border-radius: 0.4rem;
          overflow: hidden;
          padding: 0;
          background-color: #fff;

          & + ul {
            margin-top: 10px;
          }

          &:before {
            background-color: #d1ecf1;
            padding-left: 5px;
            display: block;
            height: 2rem;
            line-height: 2rem;
          }

          &.list-core {
            &:before {
              content: 'Core Carriers';
            }
          }
          &.list-preferred {
            &:before {
              content: 'Preferred Carriers';
            }
          }
          &.list-additional {
            &:before {
              content: 'Additional Carriers';
            }
          }

          & > li {
            transition-property: opacity;
            transition-duration: 0.3s;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            font-family: 'Roboto';
            border-radius: 0;

            & > div:nth-of-type(1) {
              flex: 0 0 1.5rem;
              max-width: 1.5rem;
              font-weight: 500;
            }

            & > div:nth-of-type(2) {
              flex: 0 0 calc(100% - 1.5rem);
              max-width: calc(100% - 1.5rem);

              & > div:nth-of-type(1) {
                font-weight: 500;
              }

              & > div:nth-of-type(2) {
                font-size: 0.8rem;
                font-weight: 400;
              }
            }

            &:active {
              box-shadow: none;
            }

            & + li {
              border-top: 1px dotted rgba(0, 25, 125, 0.2);
            }
          }

          &.list-locked {
            opacity: 0.8;

            &.list-core {
              &:before {
                content: 'Core Carriers';
              }
            }
            &.list-preferred {
              &:before {
                content: 'Preferred Carriers Locked - Please select from Core Carriers first';
              }
            }
            &.list-additional {
              &:before {
                content: 'Additional Carriers Locked - Please select from Core or Preferred Carriers first';
              }
            }

            & > li {
              opacity: 0.3;
            }
          }
        }
      }

      div.dropdown-scroll-indicator {
        position: fixed;
        margin: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 1px 10px 17px -16px #0c5460 inset;
        transition-property: opacity, visibility;
        transition-duration: 0.3s;
        opacity: 0;
        visibility: hidden;

        &.show {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  button.btn.remove-replace-btn,
  button.btn.btn-secondary.dropdown-toggle {
    box-shadow: none;
    padding: 0;

    & > label {
      margin: 0;
    }

    .replacement-required {
      display: inline-block;
      border-radius: 0.5rem;
      padding: 0 5px;
      margin: 0 6px 0 0;
      font-size: 0.8rem;
      font-weight: 500;
      font-family: 'Roboto';

      .fa {
        margin-right: 4px;
      }
    }
  }

  // WAS CARRIER SELECTOR.
  & > .row {
    & > div {
      position: relative;
      padding: 0;

      div.carrier-cover {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.2);
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 10;
      }

      .card {
        position: relative;
      }
    }
  }

  .carrier-table-card {
    & > .card-header {
      background-color: $color--usabg--blue;
      text-align: left;
      color: #fff;
      padding: 10px 5px;

      & > .row {
        & > div {
          position: relative;

          .btn.contracts-store-btn {
            font-family: 'Lato';
            font-weight: 600;
            font-size: 1rem;
            height: calc(100% - 15px);
          }

          .loader-wheel {
            transition: opacity 0.3s ease-in-out;
            opacity: 0;
            margin-left: 10px;
            color: rgba(10, 180, 250, 0.8);
            position: absolute;
            font-size: 0.85rem;
            align-self: center;
            top: 5px;
          }

          & > div {
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 1.25rem;
          }

          & > small {
            text-transform: none;
            letter-spacing: 0;
          }

          .select-all {
            transition: opacity 0.3s ease-in-out;
            opacity: 1;
            float: right;
            margin: 0;
            padding: 2px 8px;
            font-weight: bold;
            font-size: 13px;
            font-family: 'Roboto';
            position: absolute;
            right: 20px;
          }
        }
      }

      &.is-loading > .row {
        & > div {
          .loader-wheel {
            opacity: 1;
          }
          .select-all {
            opacity: 0;
          }
        }
      }

      &.is-loading .select-all {
        opacity: 0;
      }
    }

    & > .card-body {
      padding: 0;
    }
  }

  .error-msg {
    opacity: 1;
    right: 0.5rem;
    bottom: 0rem;
    position: absolute;
    font-weight: bold;
    color: $color--invalid;
    font-size: 12px;
  }

  // WAS CARRIER TABLE
  // .no-carriers {
  //   padding: 3px 3px 3px 10px;
  // }
  .selectable-carriers-table {
    &.empty-list {
      padding: 8px;
      text-align: center;

      background-color: rgba(0, 0, 0, 0.2);
    }

    & > .selectable-row,
    & > .selectable-sibling-row {
      padding-top: 2px;
      padding-bottom: 2px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      background-color: transparent;
      transition-property: background-color;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;

      &:nth-of-type(even) {
        background-color: rgba(220, 220, 255, 0.4);
      }

      .selectable-cell {
        &.cell-name {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 3px;

          & > h6 {
            font-weight: 600;
            font-family: 'Lato';
          }
        }

        &.cell-descrip {
          flex: 0 0 100%;
          max-width: 100%;
          font-size: 0.85rem;
          padding: 2px 3px;
        }

        &.cell-action {
          flex: 0 0 100%;
          max-width: 100%;

          .toggle-carrier-btn {
            margin: 0;
            padding: 2px 5px;
            min-height: 25px;
            background-color: rgb(240, 240, 240) !important;
            box-shadow: none;
            text-align: center;
            transition-property: background-color, color, opacity;
            transition-duration: 0.2s;
            transition-timing-function: ease-in-out;
            margin-bottom: 3px;
            opacity: 0.6;
            display: flex;
            align-items: center;

            &:hover {
              opacity: 1;
            }

            & > i.fa {
              display: inline-block;
              height: 25px;
              line-height: 25px;
            }

            & > span {
              display: inline-block;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0.5px;
              line-height: 15px;
              font-family: 'Roboto', sans-serif;
            }
          }

          .toggle-carrier-btn {
            color: $color--success;
            &:hover {
              background-color: $color--success !important;
              color: #fff;
            }

            &.selected {
              color: $color--success;
              opacity: 1;
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }

      .sibling-rows {
        flex: 0 0 calc(50% + 5rem);
        max-width: calc(50% + 5rem);
        display: flex;
        flex-wrap: wrap;

        .sibling-cell-row {
          display: flex;
          flex-wrap: nowrap;
          flex: 0 0 calc(100% - 2.5rem);
          max-width: calc(100% - 2.5rem);
          align-items: center;
          transition: background-color 0.15s ease-in-out;

          &+.sibling-cell-row {
            border-top: 2px dotted rgba(220,220,220,0.5);
          }

          &:hover {
            background-color: #FFF;
          }
        }
      }

      &.selectable-row {
        & > .selectable-cell {
          transition: background-color 0.15s ease-in-out;
          min-height: 38px;

          &.cell-descrip {
            flex: 0 0 calc(100% - 7.5rem);
            max-width: calc(100% - 7.5rem);
            font-size: 0.85rem;
            padding: 2px 3px;

            div {
              display: flex;
              align-self: center;
            }
          }

          &.cell-action {
            flex: 0 0 7.5rem;
            max-width: 7.5rem;
          }
        }

        &:hover {
          & > .selectable-cell {
            &:not(:first-of-type) {
              background-color: #FFF;
            }
          }
        }
      }

      &.row-selected {
        background-color: rgba(0, 200, 100, 0.4);
      }

      &:hover {
        background-color: rgba(0, 200, 200, 0.4);
      }

      &:nth-of-type(even) {
        &.row-selected {
          background-color: rgba(0, 200, 100, 0.3);
        }
        &:hover {
          background-color: rgba(0, 200, 200, 0.3);
        }
      }

      & + .selectable-carriers-table {
        border-top: 1px solid rgb(120, 120, 120);
      }
    }
  }

  @media screen and (max-width: 767px) {
    .sibling-cell-row {
      & + .sibling-cell-row {
        border-top: 1px solid rgba(220, 220, 220, 0.5);
      }
    }
  }
  @media screen and (min-width: 576px) {
  }
  @media screen and (min-width: 768px) {
    .selectable-carriers-table {
      & > .selectable-row,
      & > .selectable-sibling-row {
        flex-wrap: nowrap;

        .selectable-cell {
          &.cell-name {
            flex: 0 0 calc(50% - 5rem);
            max-width: calc(50% - 5rem);
          }

          &.cell-descrip {
            flex: 0 0 calc(50% - 5rem);
            max-width: calc(50% - 5rem);
          }

          &.cell-action {
            flex: 0 0 10rem;
            max-width: 10rem;
          }
        }

        .sibling-rows {
          &>.sibling-cell-row {
            border: 2px dotted rgba(220,220,220,0.5);

            &:first-of-type {
              border-top-left-radius: 0.3rem;
              border-top-right-radius: 0.3rem;
            }

            &:last-of-type {
              border-bottom-left-radius: 0.3rem;
              border-bottom-right-radius: 0.3rem;
            }
          }

          .selectable-cell {
            &.cell-descrip {
              flex: 0 0 calc(100% - 7.5rem);
              max-width: calc(100% - 7.5rem);
            }

            &.cell-action {
              flex: 0 0 7.5rem;
              max-width: 7.5rem;
            }
          }
        }

        &.selectable-row {
          & > .selectable-cell {
            &.cell-descrip {
              flex: 0 0 calc(50% - 5rem);
              max-width: calc(50% - 5rem);
              font-size: 0.85rem;
              padding: 2px 3px;
              border: 2px dotted rgba(220,220,220,0.5);
              border-top-left-radius: 0.3rem;
              border-bottom-left-radius: 0.3rem;
              border-right: none;
		        }

		        &.cell-action {
		          flex: 0 0 7.5rem;
		          max-width: 7.5rem;
              border: 2px dotted rgba(220,220,220,0.5);
              border-top-right-radius: 0.3rem;
              border-bottom-right-radius: 0.3rem;
              border-left: none;
		        }
		      }
		    }
      }
    }
  }
  @media screen and (min-width: 850px) {
  }
  @media screen and (min-width: 992px) {
  }
  @media screen and (min-width: 1200px) {
  }
}
