@import 'src/styles/global.variables';

.AvPolicyFormComponent {
  border: 1px solid rgb(220, 220, 220);
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  background-color: rgba(220, 220, 220, 0.25);
  border-top: none;
  margin-bottom: 1rem;
  padding-top: 1rem;

  .av-input-wrapper {
    .ui-input {
      padding-bottom: 1rem;

      .error-msg {
        font-weight: bold;
        color: $color--invalid;
        font-size: 12px;
        position: absolute;
        right: 1.25rem;
        transform: translateY(-4px);
      }
    }
  }
}
