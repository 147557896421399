@import './../../../styles/global.variables';

.SendToCarrierButtonComponent {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 3px;

  div.alert {
    padding: 0;
    margin: 0;
    font-weight: 500;
    text-align: center;
    font-size: 0.9rem;
    min-height: 25px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &.alert-secondary {
      opacity: 0.75;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 0.85rem;
    }
  }

  button.btn.send-btn {
    display: flex;
    margin: 0;
    padding: 2px 5px;
    flex-grow: 1;

    text-align: center;
    align-items: center;

    background-color: rgb(240, 240, 240) !important;
    box-shadow: none;
    color: $color--success;
    opacity: 0.6;

    transition-property: background-color, color, opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;

    &:hover {
      background-color: $color--success !important;
      color: #fff;
      opacity: 1;
    }

    & > i.fa {
      display: inline-block;
      height: 25px;
      line-height: 25px;
      width: 20px;
    }

    & > span {
      display: inline-block;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 15px;
      font-family: 'Roboto', sans-serif;
      width: calc(100% - 22px);
    }
  }

  button.btn.history-btn {
    display: flex;
    margin: 0;
    padding: 2px 5px;
    flex-shrink: 1;
    text-align: center;
    align-items: center;
    flex-direction: column;

    background-color: rgb(240, 240, 240) !important;
    box-shadow: none;
    color: $color--success;
    opacity: 0.6;

    transition-property: background-color, color, opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;

    &:hover {
      background-color: $color--success !important;
      color: #fff;
      opacity: 1;
    }

    & > i.fa {
      display: block;
      height: 12.5px;
      line-height: 12.5px;
      width: 20px;
      font-size: 12px;
    }
  }
}

