@import './../../../styles/global.variables';

#CareerRequestModalComponent {
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-lg {
    margin-left: 1rem;
    flex: 0 0 calc(100vw - 2rem);
    max-width: calc(1300px);
    margin: auto;
    height: 100vh;
    display: flex;
    align-items: center;
  }

  .modal-header {
    background-color: $color--usabg--blue;
    color: #fff;

    h4 {
      color: #fff;
      font-weight: 600;
    }
  }

  .modal-body {
    position: relative;
    min-height: 150px;
    padding: 0;
    max-height: 90vh;
    overflow: auto;

    section#quotationTabs {
      padding-top: 1rem;
      padding-bottom: 1rem;

      ul.nav-tabs > li.nav-item {
        @media screen and (max-width: 767px) {
          &.active a {
            color: #fff;
            font-weight: 500;
          }
        }
      }

      form.rd-mailform {
        margin-top: 0;
        padding-top: 10px;

        @media screen and (max-width: 767px) {
          .offset-sm-top-30,
          .offset-top-20,
          .offset-sm-top-0 {
            margin-top: 10px;
          }
        }

        .form-group {
          padding-top: 0;
          margin-top: 0;
        }
      }
    }

    button.btn {
      font-weight: 500;
      font-family: 'Roboto';
      font-size: 1.5rem;
    }

    & > .loading-wrapper {
      height: 100px;
      padding-top: 50px;
      transition-property: opacity, visibility;
      transition-duration: 0.25s;
      transition-timing-function: ease-in-out;
      opacity: 0;
      visibility: hidden;
    }

    &.is-loading {
      & > .loading-wrapper {
        opacity: 1;
        visibility: visible;
      }
    }

    a {
      color: $color--usabg--blue;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

body.quote-modal-open {
  div.page {
    overflow: hidden;
    max-height: 100vh;
    div.modal-backdrop.fade.show {
      z-index: 9999;
    }
  }
}
