@import './../../../../styles/global.variables';

#CorporateEmailModal {
  .modal-message {
    padding: 20px 0;
    text-align: center;
    font-weight: 500;

    strong {
      font-size: 1.5rem;
    }

    table {
      margin: auto;

      td {
        font-weight: 500;
        font-size: 1rem;
      }
    }
  }

  .error-modal {
    .modal-header {
      background-color: $color--invalid;
    }
  }

  .success-modal {
    .modal-header {
      background-color: $color--success;
    }
  }
}
