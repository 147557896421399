@import './../../../styles/global.variables';

.ContentHtmlCardComponent {
  position: relative;

  & > .card-header {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.25px;
    padding: 0;

    & > div {
      padding: 12px 20px;
    }
  }

  & > .card-body {
    padding: 2px;
  }
}
