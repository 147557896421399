.PaginationComponent {

	ul.pagination,
	ul.pagination.mb-5 {
		padding-bottom: 0;
		margin-bottom: 0 !important;

		li.no-click {
			cursor: not-allowed;
			a {
				cursor: not-allowed;
				&:hover {
					background-color: #FFF;
				}
			}
		}
	}
}