@import './../../../../styles/global.variables';

#LeaderGoalsCardComponent {
  overflow: auto;

  .container-fluid.goal-container {
    overflow: scroll;
    background-color: rgb(205, 205, 205);

    .goal-row {
      border-color: #d3d3d3;
      font-weight: 500;
      flex-wrap: nowrap;

      & + .goal-row {
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(8) {
          .goal-cell {
            &.goal-cell-metric {
              border-top: 1px dashed #959595;

              .slots-loader {
                display: inline-block;
                width: 1.25rem;
                margin-left: 0.25rem;
                height: 1.25rem;
                border: thin solid transparent;
                opacity: 0;
                transition: opacity 0.2s ease-in-out;

                .fa {
                  font-size: 1rem;
                  line-height: 1rem;
                }

                &.is-loading {
                  opacity: 1;
                }
              }

              .invest-slots-incomplete {
                padding: 2px;
                text-align: left;
                font-size: 12px;
                margin: 0;
              }
            }

            &.goal-cell-input {
              background-color: rgba(220, 220, 220, 0.25);

              &:not(.goal-cell-input-disabled) {
                .md-form {
                  padding-left: 4px;
                  padding-right: 4px;

                  & > input {
                    background-color: #fff;
                    border: thin solid rgba(220, 220, 220, 0.25);
                    border-radius: 4px;
                  }
                }
              }
            }

            &.goal-cell-indv-prev-ytd,
            &.goal-cell-team-prev-ytd,
            &.goal-cell-indv-goal-mtd,
            &.goal-cell-indv-goal-ytd,
            &.goal-cell-team-goal-mtd,
            &.goal-cell-team-goal-ytd {
              border-top: 1px dashed #d3d3d3;
            }

            &.goal-cell-notes {
              border-top: 1px dashed #e1e1e1;
            }
          }
        }

        &.goal-row-sig-agents {
          .goal-cell {
            border-top: 2px double #d3d3d3;

            &.goal-cell-metric {
              border-top: 1px double #959595;
            }
            &.goal-cell-notes {
              border-top: 1px double #e1e1e1;
            }
          }
        }
      }

      .goal-cell {
        border-color: #999;
        height: 100%;
        display: flex;
        padding: 3px;
        font-size: 14px;
        background-color: #fff;

        &.goal-cell-metric {
          flex: 0 0 12rem;
          max-width: 12rem;
          border-right: 1px solid #999;
          background-color: #757575;
          color: #fff;
          justify-content: left;
          flex-wrap: wrap;
          * {
            width: 100%;
          }
          small {
            opacity: 0.8;
          }

          align-items: center;
          min-width: 10rem;
        }
        &.goal-cell-indv-prev-ytd {
          border-left: 1px solid rgba(0, 0, 0, 0.22);
          border-right: 1px solid rgba(0, 0, 0, 0.22);
          flex: 0 0 calc(16.65% - 5rem);
          min-width: 5rem;
          font-size: 18px;

          justify-content: center;
          align-items: center;
        }
        &.goal-cell-team-prev-ytd {
          border-left: 1px solid rgba(0, 0, 0, 0.22);
          border-right: 1px solid rgba(0, 0, 0, 0.22);
          flex: 0 0 calc(16.65% - 5rem);
          min-width: 5rem;
          font-size: 18px;

          justify-content: center;
          align-items: center;
        }
        &.goal-cell-indv-goal-ytd {
          border-left: 1px solid rgba(0, 0, 0, 0.22);
          border-right: 1px solid rgba(0, 0, 0, 0.22);
          flex: 0 0 calc(16.65% - 5rem);
          min-width: 5rem;
          font-size: 18px;

          justify-content: center;
          align-items: center;
        }
        &.goal-cell-indv-goal-mtd {
          border-left: 1px solid rgba(0, 0, 0, 0.22);
          border-right: 1px solid rgba(0, 0, 0, 0.22);
          flex: 0 0 calc(16.65% - 5rem);
          min-width: 5rem;
          font-size: 18px;

          justify-content: center;
          align-items: center;
        }
        &.goal-cell-team-goal-mtd {
          border-left: 1px solid rgba(0, 0, 0, 0.22);
          border-right: 1px solid rgba(0, 0, 0, 0.22);
          flex: 0 0 calc(16.65% - 5rem);
          min-width: 5rem;
          font-size: 18px;

          justify-content: center;
          align-items: center;
        }
        &.goal-cell-team-goal-ytd {
          border-left: 1px solid rgba(0, 0, 0, 0.22);
          border-right: 1px solid rgba(0, 0, 0, 0.22);
          flex: 0 0 calc(16.65% - 5rem);
          min-width: 5rem;
          font-size: 18px;

          justify-content: center;
          align-items: center;
        }
        &.goal-cell-notes {
          border-left: 1px solid #999;
          flex: 0 0 18rem;
          background-color: rgb(205, 205, 205);
          align-items: center;
          justify-content: center;

          justify-content: center;
          align-items: center;
          min-width: 18rem;
        }
      }

      &.goal-row-header {
        height: 6rem;
        background-color: #959595;

        .goal-cell {
          text-align: center;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          background-color: #959595;
        }

        .goal-cell.goal-cell-metric,
        .goal-cell.goal-cell-notes {
          align-items: center;
          justify-content: center;
          background-color: transparent;
        }

        .goal-cell.goal-cell-notes {
          background-color: #959595;
          min-width: 18rem;
        }
      }
      &.goal-row-descrip {
        height: 2rem;
        background-color: #e1e1e1;

        .goal-cell {
          background-color: #e1e1e1;
        }

        .goal-cell.goal-cell-metric {
          background-color: transparent;
          justify-content: left;
          font-size: 12px;
          color: #757575;
        }

        .goal-cell.goal-cell-notes {
          background-color: #e1e1e1;
          font-size: 12px;
          color: #757575;
        }
      }
      &.goal-row-points {
        height: 5rem;
        .goal-cell-notes {
          font-size: 12px;
          line-height: 14px;
        }
      }
      &.goal-row-enrolled {
        height: 5rem;
        .goal-cell-notes {
          font-size: 12px;
          line-height: 14px;
        }
      }
      &.goal-row-completed {
        height: 5rem;
        .goal-cell-notes {
          font-size: 12px;
          line-height: 14px;
        }
      }
      &.goal-row-converted {
        height: 5rem;
        .goal-cell-notes {
          font-size: 12px;
          line-height: 14px;
        }
      }
      &.goal-row-slot-invest {
        height: 5rem;
        .goal-cell-notes {
          font-size: 12px;
          line-height: 14px;
        }
      }
      &.goal-row-upline-invest {
        height: 5rem;
        .goal-cell-notes {
          font-size: 12px;
          line-height: 14px;
        }
      }
      &.goal-row-sig-agents {
        height: 5rem;
        .goal-cell-notes {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}
