@import './../../../../styles/global.variables';

#LeaderboardDateFilter {
  h4 {
    font-weight: 500;
  }

  .agent-type-selector {
    text-align: center;
    margin: 0;
    margin-bottom: 1rem;
    padding: 0;

    button.btn {
      padding: 0;
      margin: 0;
      box-shadow: none;
      width: 100%;
      max-width: 100%;
      display: block;
      padding: 2px 5px;
      background-color: $color--usabg--blue !important;
      text-overflow: ellipsis;
      border: 1px solid #d3d3d3;
      height: 33%;

      &:nth-of-type(1) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:nth-of-type(2) {
        border-radius: 0;
      }
      &:nth-of-type(3) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .row.leaderboard-links {
    margin-top: 10px;

    .btn.board-type-btn {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 10px;

      &.btn-indigo {
        border-color: $color--indigo;
        background-color: $color--indigo !important;
      }

      &.btn-red {
        border-color: #ff1744;
        background-color: #ff1744 !important;
      }

      &.btn-green {
        border-color: #28b84f;
        background-color: #28b84f !important;
      }

      &.btn-orange {
        border-color: $color--orange;
        background-color: $color--orange !important;
      }

      &.btn-purple {
        border-color: $color--purple;
        background-color: $color--purple !important;
      }
    }
  }

  .row.type-btns,
  .row.filter-btns {
    button.btn {
      padding-left: 5px;
      padding-right: 5px;
    }
    @media screen and (min-width: 576px) {
      & > div {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .row.filter-btns {
    .btn {
      margin-bottom: 10px;
    }

    .btn.btn-blue {
      border-color: #2979ff;
      background-color: #2979ff !important;
    }

    .btn.btn-red {
      border-color: #ff1744;
      background-color: #ff1744 !important;
    }

    .btn.btn-green {
      border-color: #28b84f;
      background-color: #28b84f !important;
    }

    .btn.btn-orange {
      border-color: $color--orange;
      background-color: $color--orange !important;
    }

    .btn.btn-purple {
      border-color: $color--purple;
      background-color: $color--purple !important;
    }

    .fa {
      visibility: visible;
      width: 1rem;
      margin-right: 5px;
    }

    .btn:not(.btn-active) {
      .fa {
        visibility: hidden;
        width: 0;
        margin-right: 0;
      }
    }
  }

  .custom-datepicker {
    .react-datepicker-wrapper {
      width: 100%;

      .form-control-plaintext {
        background-color: #fff;
        border: 1px solid rgb(180, 180, 180);
        border-bottom: 1px solid rgb(180, 180, 180);
        margin-bottom: 1rem;
      }
    }
  }
}
