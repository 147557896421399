#LeaderReportSelectorComponent {
  align-items: center;
  display: flex;

  button.btn {
    box-shadow: none;
    padding: 6px 18px;
  }

  div.btn-group + div.ui-input {
    display: inline-flex;
    font-size: 0.8rem;
    border: thin solid rgba(255, 255, 255, 0.25);
    border-radius: 0.3rem;
    background-color: rgba(255, 255, 255, 0.25);

    .form-check {
      padding: 0 0.5rem;
    }
  }

  @media screen and (max-width: 575px) {
    display: flex;
    justify-content: center;
    align-items: center;

    div.btn-group {
      display: flex;
      flex: 0 0 20rem;
      max-width: 20rem;
      flex-wrap: wrap;
      margin-bottom: 0.5rem;

      button.btn {
        display: flex;
        flex: 0 0 20rem;
        max-width: 20rem;
        margin: 0;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
      }

      & + div.ui-input {
        display: flex;
        margin-bottom: 0.5rem;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
