@import './../../styles/global.variables';

#SubscriptionReportsPage {
	.card {
		.card-header {
			background-color: $color--usabg--blue;
			color: #FFF;
		}
		.card-body {
			padding: 0;
			min-height: 250px;
			position: relative;

			&>.loading-wrapper {
				height: 125px;
				margin-top: 75px;
			}

			table td {
				font-weight: 400;

				a {
					color: blue;
				}
			}
		}
	}
}
