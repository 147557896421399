@import 'src/styles/global.variables';

#PointsDetailsPage {
  padding-bottom: 2rem;

  .buck-logo {
    img {
      @media (max-width: 767px) {
        height: 120px;
      }

      @media (min-width: 768px) {
        height: 160px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    ul.nav > li.nav-item {
      width: 100%;
    }

    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }

  ul.nav > li.nav-item {
    width: 33%;
  }

  .pip-locked {
    border: 1px solid grey;
    background-color: rgb(220, 220, 220);
    border-radius: 0.4rem;

    a {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.points-component {
  &.card {
    box-shadow: none;
  }

  table.points-payout,
  table.points-overrides {
    width: calc(100% - 30px);
    margin: 15px 0 15px 15px;
    border-top: 1px solid rgb(200, 200, 200);
    border-bottom: 1px solid rgb(200, 200, 200);

    thead {
      background-color: rgb(225, 225, 225);

      tr th {
        padding: 2px 4px;
        font-weight: 500;
        text-align: center;
        border-left: 1px solid rgb(200, 200, 200);

        &:last-of-type {
          border-right: 1px solid rgb(200, 200, 200);
        }
      }
    }

    tbody {
      tr td {
        padding: 2px 4px;
        border-bottom: 1px dashed rgb(200, 200, 200);
        border-left: 1px solid rgb(200, 200, 200);
        font-weight: 500;

        &:last-of-type {
          border-right: 1px solid rgb(200, 200, 200);
        }
      }
    }
  }

  table.points-payout {
    thead {
      tr th {
        &:nth-of-type(1) {
          width: 100px;
        }

        &:nth-of-type(2) {
          width: calc(100% - 475px);
        }

        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          width: 125px;
        }
      }
    }
  }

  table.points-overrides {
    thead {
      tr th {
        &:nth-of-type(4) {
          width: 125px;
        }
      }
    }
  }
}

#AwardLevelsShowcaseComponent {
  .pip-level > div {
    border: 2px solid #d3d3d3;
    border-radius: 0.3rem;
    padding: 0.5rem;
    margin: 0.5rem 0;
    text-align: center;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.18);

    img {
      margin: auto;
      display: inline-block;
    }

    hr {
      border-width: 2px;
    }

    table.point-legend {
      width: 80%;
      min-width: 9rem;
      margin: auto;

      tr:not(:first-child) {
        td {
          text-wrap: nowrap;
        }
      }
    }
  }
}

#PointsBreakdownComponent {
  .pip-details {
    table {
      border: none;

      thead {
        background-color: #e1e1e1;

        th {
          font-weight: 500;
        }
      }

      tr {
        td {
          border: none;
          border-top: 1px dashed #d3d3d3;
          padding: 4px 0;
        }
      }
    }
  }
}

#PointsRedemptionComponent {
  .card-body.pip-points-redemption {
    .ContentHtmlComponent > .content-html-inner-wrapper > div:first-of-type {
      a {
        background-color: $color--usabg--blue;
        color: #fff;
        font-weight: 400;
        padding: 7px;
      }
    }
  }
}
