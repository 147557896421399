@import './../../styles/global.variables';

#GuidelinesPage {
  h2 {
    display: inline;
  }

  .has-accepted {
    float: right;
    background-color: $color--success;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 0.4rem;
    padding: 3px 10px;
    margin: 10px 0;
  }

  .accept-btn {
    float: right;
  }
}
