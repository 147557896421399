@import './../../../../styles/global.variables';
@import './../../../../styles/global.fonts';

#IntakeFormAcceptanceComponent {

	.accept-all {
		border: thin solid rgba(20,120,200,0.25);
		border-radius: 0.5rem;
		background-color: rgba(30,130,230,0.25);

		.ui-input.ui-input-checkbox {
			margin-bottom: 0;
			padding: 1rem 0;

			.form-control {
				min-height: 0;
			}

			.form-check-input[type='checkbox']:not(:checked) + label:before {
				background-color: #FFF;
			}

			.form-check-input[type='checkbox']:checked + label:before {
				background-color: transparent;
				top: -10px;
			}
		}

		margin-bottom: 25px;
	}

	div.ui-input.ui-input-checkbox {
		position: relative;
		margin-bottom: 25px;

		a {
			color: blue;
		}
	}

	.row:not(.intake-action-btns) {
		a.btn {
			@extend .font---lato;
			color: #FFF;
			background-color: $color--indigo;
		}
	}

	.collapse,
	.requires-sigs-warning {
		font-size: 14px;
		font-weight: 500;
		transform: translate(3px, -8px);
	}

	.enroll-as {
		font-size: 14px;
		font-weight: 500;
		color: rgba(50,50,50,0.6);
		padding-left: 0.25rem;
	}

	@media (max-width: 540px) {
		.collapse {
			width: calc(100% - 7rem);
		}

		.row:not(.intake-action-btns) {
			border-radius: 0.4rem;
			padding-top: 1rem;

			.ui-input.ui-input-checkbox {
				margin-bottom: 0;
			}

			a.btn {
				display: block;
				width: 100%;
				margin-bottom: 10px;
			}
		}
	}
}