@import 'src/styles/global.variables';

#TestimonialFormComponent {
  background-color: rgba(255, 255, 255, 0.25);
  border: 1px solid $color--sky-blue;
  padding: 0.75rem;
  border-radius: 0.5rem;

  h5,
  button {
    display: inline-block;
  }

  .btn-create {
    padding: 2px 5px;
    transform: translateY(-25%);
    background-color: $color--success !important;
    color: #fff;
  }

  .container.accordion {
    padding: 0;
  }

  .card-body {
    background-color: #fff;

    .btn-delete {
      padding: 3px;
      background-color: transparent !important;
      color: $color--invalid;
      box-shadow: none;

      &:hover {
        background-color: $color--invalid !important;
        color: #fff;
      }
    }
  }
}
