
#SubsiteNavigationComponent {
	background-color: rgb(245,245,245);
	flex-grow: 1;

	& > ul {
		background-color: rgb(255,255,255);

		&>li {
			padding: 1px;

			&>a {
				padding: 1rem;
				background-color: rgba(0,0,0,0.1);
				display: block;
				font-weight: 500;
				text-align: center;
				transition-property: background-color, color;
				transition-timing-function: ease-in-out;
				transition-duration: 0.25s;

				.fa {
					font-size: 1.5rem;
					margin-right: 3px;
				}

        label.state-lic-link {
          font-weight: 500;

          &>.StateLicenseMapComponent{
            margin: 0;

            svg {
              height: 9rem !important;
            }
          }
        }

				&:hover {
					background-color: #FFF;
				}
			}

			&:first-of-type {
				a {
					background-color: rgba(255,20,20,0.8);
					color: #FFF;
				}
			}
		}
	}
}