@import './../../../../styles/global.variables';

#AdobeDocListModalComponent {
  padding: 0;

  .modal-body {
    padding: 0;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      padding: 10px;
      background-color: #fff;

      transition: background-color 0.2s ease;

      & > div {
        margin: 0;
      }

      & > small {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
      }

      i {
        margin: 0 6px;
      }

      span.alert-copied {
        background-color: $color--success;
        display: inline-block;
        font-weight: 600;
        padding: 1px 6px;
        font-size: 12px;
        color: #fff;
        border: thin solid transparent;
        border-radius: 0.3rem;
      }

      & + li {
        border-top: 1px solid rgb(220, 220, 220);
      }

      &:nth-of-type(even) {
        background-color: rgba(220, 220, 220, 0.4);
      }

      &:hover {
        background-color: rgb(0, 200, 200);
      }
    }
  }
}
