@import './../../../../styles/global.variables';

#CarrierPrioritiesFormComponent,
#CarrierNotesFormComponent {
  // height: calc(100% - 20px);
  position: relative;

  div.lockout-window {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 0.3rem;
  }

  .card-header {
    .card-header-btn {
      float: right;
      padding: 2px 4px;
      font-size: 0.8rem;
      font-weight: 500;
      margin: -1px;
      transition-property: background-color, color;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;

      &:hover {
        background-color: $color--success !important;
        color: #fff;
      }
    }
  }

  .card-body {
    padding: 5px;

    table {
      width: 100%;

      thead {
        th {
          border-bottom: 1px solid rgb(220, 220, 220);
        }
      }

      button.btn {
        padding: 0 3px;
        background-color: transparent !important;
        box-shadow: none;
        height: 16px;
        line-height: 16px;
        &:not([disabled]):hover {
          background-color: rgba(220, 220, 220, 0.8) !important;
        }
      }

      tr {
        td:nth-of-type(2) {
          button.btn {
            color: $color--usabg--blue;
          }
        }
        td:nth-of-type(4) {
          button.btn {
            color: $color--invalid;
          }
        }
        &:nth-of-type(even) {
          td {
            background-color: rgba(220, 220, 220, 0.4);
          }
        }
      }
    }

    div.md-form {
      label {
        transition: margin-left 0.3s ease-in-out;
        &.disabled {
          margin-left: 3px;
        }
      }
      input.form-control {
        &[readonly] {
          background-color: rgba(220, 220, 220, 0.8);
        }
      }
    }
  }

  table.priorities-table {
    table-layout: fixed;
    width: 100%;

    thead {
      background-color: rgba(220, 220, 220, 0.7);
      tr {
        th {
          padding: 2px;
          font-weight: 500;
          border-bottom: 1px solid rgb(220, 220, 220);

          &:nth-of-type(1) {
            width: calc(100% - 6rem);
          }
          &:nth-of-type(2) {
            width: 3rem;
          }
          &:nth-of-type(3) {
            width: 3rem;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          // border: thin solid red;
        }
        & + tr {
          td {
            border-top: 1px solid rgba(220, 220, 220, 0.7);
          }
        }
      }
    }
  }

  table.priority-form-table {
    table-layout: fixed;
    margin-bottom: 40px;

    thead {
      background-color: rgba(220, 220, 220, 0.7);
      tr {
        th {
          padding: 2px;
          font-weight: 500;
        }
      }
    }

    tbody {
      tr {
        td {
          width: 25%;

          &:first-of-type {
            width: 50%;
          }
        }
        & + tr {
          td {
            border-top: 1px solid rgba(220, 220, 220, 0.7);
          }
        }
      }
    }
  }
}
