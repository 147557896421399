@import 'src/styles/global.variables';

#AgentSubsitePage {
  position: relative;

  .bg-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('https://firebasestorage.googleapis.com/v0/b/totemic-bonus-239316.appspot.com/o/static%2Fsubsites%2Fbg.subsite-flag.jpg?alt=media')
      center center no-repeat;
    background-size: cover;
    opacity: 0.2;
    z-index: 0;
  }

  .design-custom-policy {
    position: relative;
    z-index: 1;
    background-color: $color--sky-blue;
    padding: 0.5rem 0;
    text-align: center;

    .container > .row > div {
      &:nth-of-type(1) {
        color: $color--usabg--blue;
        font-size: 2.5rem;
        text-transform: uppercase;
      }
      &:nth-of-type(2) {
        color: #fff;
        font-style: italic;
        font-size: 1.5rem;
      }
    }
  }

  .subsite-main-body {
    background-color: rgba(26, 35, 126, 0.8);
  }

  // Xtra-Small --
  @media screen and (max-width: 575px) {
    .design-custom-policy {
      padding: 0.5rem 0;

      .container > .row > div {
        &:nth-of-type(1) {
          font-size: 1.5rem;
        }
        &:nth-of-type(2) {
          font-size: 1.25rem;
        }
      }
    }

    .body-content {
      padding: 0;
    }

    .sidebar-content {
      padding: 0;

      &.sidebar-content-desktop {
        display: none;
      }
    }

    padding-top: 8.5rem;
  }

  // Small -- 768px
  @media screen and (min-width: 576px) and (max-width: 991px) {
    .design-custom-policy {
      padding: 0.5rem 0;

      .container > .row > div {
        &:nth-of-type(1) {
          font-size: 1.5rem;
        }
        &:nth-of-type(2) {
          color: #fff;
          font-style: italic;
          font-size: 1.5rem;
        }
      }
    }

    .body-content {
      padding: 0;
    }

    .sidebar-content {
      padding: 0;

      &.sidebar-content-desktop {
        display: none;
      }
    }

    padding-top: 8.5rem;
  }

  // Medium --
  @media screen and (min-width: 992px) {
    .body-content {
      padding: 0;
      background-color: #fff;
    }

    .sidebar-content {
      padding: 0;

      &.sidebar-content-mobile {
        display: none;
      }
    }

    padding-top: 10rem;
  }

  // Large --
  @media screen and (min-width: 1100px) {
  }

  // X-Large 1 --
  @media screen and (min-width: 1300px) {
  }

  // X-Large 2 --
  @media screen and (min-width: 1400px) {
  }
}

#blueBookModal {
  .modal-backdrop {
    // .fade.show
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal.fade.top.show {
    div.modal-dialog {
      width: 80%;
      max-width: 80%;
      margin-left: 10%;

      div.modal-content {
        div.modal-header {
          background-color: $color--usabg--blue;
          padding: 8px 0;

          h4.modal-title {
            font-weight: 500;
            color: #fff;
            text-align: center;
          }

          button.close {
            color: #fff;
            margin-right: 1rem;
          }
        }

        div.modal-body {
          text-align: center;

          p {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 2rem;
          }

          .ui-input {
            margin-bottom: 1rem;

            & > .form-check > label.form-check-label {
              font-weight: 400;
            }
          }

          & > button {
            display: block;
            background-color: transparent !important;
            text-align: center;
            color: $color--usabg--blue;
            outline: none;
            margin: auto;
            border: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
