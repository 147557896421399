@import './../../../../styles/global.variables';

#DeniedAccessModal {
  .modal-message {
    padding: 20px 0;
    text-align: center;
    font-weight: 500;

    strong {
      font-size: 1.5rem;
    }

    table {
      margin: auto;

      td {
        font-weight: 500;
        font-size: 1.25rem;
      }
    }
  }

  .error-modal {
    .modal-header {
      background-color: $color--invalid;
    }

    table {
      max-width: 80%;

      .fa {
        font-size: 2rem;
        margin: 10px;
      }
    }

    .upgrade-btn {
      padding: 8px;
      background-color: $color--usabg--blue !important;
      box-shadow: none;
      font-weight: bold;
      font-size: 1.5rem;
    }
  }
}
