.cat-picker {
  .dropdown-menu {
    div {
      overflow-y: auto;
      max-height: 80vh;
    }
  }
}

button.dropdown-item {
  padding: 0.4rem 0.5rem !important;
}
