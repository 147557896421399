#LeaderDateSelectorComponent {
  button.btn {
    box-shadow: none;
    padding: 6px 18px;
  }

  .ui-dropdown,
  .ui-input-dropdown {
    margin-top: 1rem;
  }

  @media screen and (max-width: 575px) {
  }

  @media screen and (min-width: 768px) {
    .ui-dropdown,
    .ui-input-dropdown {
      max-width: 7rem;
    }
  }

  @media screen and (min-width: 992px) {
    .ui-dropdown,
    .ui-input-dropdown {
      max-width: 10rem;
    }
  }

  &.view-reports {
    padding: 0.5rem 0;
  }
}
