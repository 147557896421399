@import './../../../../styles/global.variables';

#UserKpiMessagingComponent {
    border: 1px solid $color--gray--md-2;
    border-radius: 0.25rem;

    #message-field {
        height: 400px;
        padding: 0.5rem;
        overflow-y: auto;
        position: relative;

        .messages-spinner {
            position: sticky;
            top: 45%;
            display: flex;
            justify-content: center;
            align-items: centers;
            z-index: 999;
        }

        .message-container {
            margin-bottom: 1rem;

            .message {
                width: 70%;
                border-radius: 0.25rem;
                box-shadow: 0px 1px 3px $color--gray--md-2;
                padding: 0.5rem;
                position: relative;
                color: rgb(50, 50, 50);
                font-weight: 500;

                &.user-message {
                    background-color: #c9f3ff;
                }

                &.direct-upline-message {
                    margin-left: auto;
                    background-color: #ffe0bf;
                }
        
                &.other-message {
                    margin-left: auto;
                    background-color: #ffeec9;
                }
            }

            .message-date {
                font-size: 12px;
                font-weight: 400;
                color: $color--gray--dk-1;
                margin-top: 0.1rem;
                margin-left: 0.25rem;

                &.non-own-date {
                    text-align: right;
                    margin-right: 0.25rem;
                    margin-top: 0;
                }
            }

            .message-author {
                font-size: 12px;
                font-weight: 400;
                color: $color--gray--dk-1;
                text-align: right;
                margin-top: 0.1rem;
                margin-right: 0.25rem;
            }
        }
    }

    #message-editor {
        border-top: 1px solid $color--gray--md-2;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        padding: 0.25rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        textarea {
            border: 1px solid $color--gray--md-2;
            border-radius: 0.5rem;
            height: 100%;
            width: 100%;
            padding: 4px 8px;
            resize: none;
            outline: 0;
            color: rgb(50, 50, 50);
        }
        
        &:focus {
            box-shadow: none;
        }
    }
}