#UIListComponent {
  td,
  th {
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;

    .switch label {
      margin-bottom: 0px;
    }
  }

  &.hasVerticalBorder {
    td,
    th {
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }
  }

  td.switch-cell {
    text-align: center;
  }
}
