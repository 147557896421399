
#RecentApplications {
	.card {
		padding: 0;

		.card-header {
			padding: 5px;

			h5 {
				width: 100%;
				font-weight: bold;
			}
		}

		.card-body {
			padding: 0;

			ul.list-group {
				margin: 0;
				padding: 0;
			}
		}
	}
}