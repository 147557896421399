@import './../../../styles/global.variables';

#AgentOnboardingCartComponent {
  label.section-label {
    background-color: rgba(220, 220, 220, 0.8);
    border: 1px solid rgba(220, 220, 220, 0.8);
    border-radius: calc(0.3rem - 1px);
    display: block;
    font-weight: 500;
    color: #333;
    padding: 4px 16px;
  }

  div.cart-checkout-contents {
    border: thin solid blue;
    background-color: rgba(220, 220, 220, 0.8);
    border: 1px solid rgba(220, 220, 220, 0.8);
    border-radius: calc(0.3rem - 1px);
    text-align: center;
  }

  & > .container-fluid {
    margin: 0 10px 10px 10px;
    max-width: calc(100% - 20px);

    & > .row {
      & > div {
        padding: 0;
      }
    }

    & > .row + .row {
      margin-top: 10px;
    }
  }

  button.btn.start-checkout-btn {
    background-color: $color--success !important;
    font-size: 1.25rem;
    font-weight: 600;
    font-family: 'Roboto';
  }
}
