@import 'src/styles/global.variables';

#AgentLedgerComponent {
  table {
    margin-bottom: 1rem;
  }

  .card {
    margin-bottom: 1rem;
  }

  .card-header {
    background-color: $color--usabg--blue;
    color: #fff;

    .col + .col {
      display: flex;

      button.btn {
        padding: 3px 8px;
        margin: 0;
        margin-left: auto;
        background-color: $color--success !important;
      }
    }
  }

  .alert {
    padding: 5px;
    text-align: center;
    font-weight: bold;
  }
}
