#LeaderboardsResultsTable {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;

  & > .row {
    padding: 0;
    margin: 0;

    & > .table-wrapper {
      padding: 0;
      margin: 0;
      overflow: auto;

      tbody > tr {
        .img-fluid.pip-award-badge.empty-img {
          opacity: 0;
        }
      }
    }
  }

  .loading-spinner {
    position: absolute;
    height: 240px;
    top: 20px;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition-property: opacity, visibility;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
  }

  .leaderboard-profile-img,
  .leaderboard-division-img {
    height: 100px;
    width: 80px;
    object-fit: cover;
  }

  .leaderboard-jewel-img,
  .leaderboard-division-img,
  .leaderboard-logo-img {
    height: 100px;
    width: 100px;
    max-width: 120px;
    object-fit: contain;
  }

  .star-img-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      max-width: 15px;
      height: 15px;
      margin-left: 2px;
    }
  }

  .table-responsive {
    padding: 0;
    background-color: #fff;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 0.3rem;
  }

  .leaderboard-table {
    visibility: hidden;
    opacity: 0;
    transition-property: opacity, visibility;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    margin: 0;

    // Show Upline, Show Awards.
    &.incl-awards.incl-upline {
      thead,
      tbody {
        tr {
          td,
          th {
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4) {
              width: 150px !important;
              min-width: 150px !important;
              font-size: 1rem;
            }
          }
        }
      }
    }

    // Show Awards, Hide Upline.
    &.incl-awards:not(.incl-upline) {
      thead,
      tbody {
        tr {
          td,
          th {
            &:nth-of-type(2),
            &:nth-of-type(3) {
              width: 150px !important;
              min-width: 150px !important;
              font-size: 1rem;
            }
          }
        }
      }
    }

    // Show Upline, Hide Awards.
    &.incl-upline:not(.incl-awards) {
      thead,
      tbody {
        tr {
          td,
          th {
            &:nth-of-type(2),
            &:nth-of-type(3) {
              width: 150px !important;
              min-width: 150px !important;
              font-size: 1rem;
            }
          }
        }
      }
    }

    // Hide Upline, Hide Awards.
    &:not(.incl-upline):not(.incl-awards) {
      thead,
      tbody {
        tr {
          td,
          th {
            &:nth-of-type(2) {
              width: 150px !important;
              min-width: 150px !important;
              font-size: 1rem;
            }
          }
        }
      }
    }

    thead,
    tbody {
      tr {
        th {
          font-weight: 500;

          .table-col-sorter {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            .sorter {
              overflow: hidden;

              & > div {
                padding: 0 0.25rem;
                height: 1.25rem;
                line-height: 1.25rem;
                border: thin solid transparent;
                border-radius: 0.25rem;
                &:hover {
                  background-color: grey;
                }
              }
            }

            &.sort-asc {
              .sorter .up {
                background-color: rgba(20, 80, 245, 0.5);
              }
            }

            &.sort-desc {
              .sorter .down {
                background-color: rgba(20, 80, 245, 0.5);
              }
            }
          }

          .table-col-sorter:not(.is-sortable) {
            .sorter {
              display: none;
            }
          }

          .header-toggle-btn-group {
            display: flex;

            & > span:not([class]) {
              padding: 0 4px;
            }

            &.division_logo {
              & > span:last-child {
                color: grey;
              }
            }

            &.up_name {
              & > span:first-child {
                color: grey;
              }
            }
          }

          .header-toggle-btn {
            cursor: pointer;

            .header-icon {
              font-size: 0.75rem;
              opacity: 0.8;
            }
          }
        }

        td {
          font-weight: 500;
          font-size: 1.25rem;
          vertical-align: middle;

          p {
            margin: 0;
          }
        }

        td:nth-of-type(1),
        th:nth-of-type(1) {
          width: 45px !important;
          text-align: center;
        }

        // move after filtered classes below.
        &:last-of-type {
          td {
            font-size: 1.25rem !important;
          }
        }
      }
    }
  }

  &.is-fetching {
    .loading-spinner {
      visibility: visible;
      opacity: 1;
    }
  }

  &:not(.is-fetching) {
    .leaderboard-table {
      visibility: visible;
      opacity: 1;
    }
  }
}
