@import './../../../../styles/global.variables';

#ReportSelector {
  list-style-type: none;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;

  li {
    display: block;
    flex: 0 1 auto;
    flex-grow: 1;
    list-style-type: none;
    background-color: #fff;
    margin: 0 10px 10px 10px;
    border: 2px solid transparent;
    border-radius: 0.35rem;
    opacity: 1;
    transition-property: background-color, color, opacity;
    transition-timing-function: east-in-out;
    transition-duration: 0.2s;

    &.active {
      border-color: rgba(120, 120, 220, 1);
      background-color: $color--usabg--blue;
      color: #fff;
    }

    &.disabled {
      opacity: 0.65;
      pointer-events: none;
      cursor: default;
    }

    a {
      text-align: center;
      display: block;
      line-height: 1.25rem;
      font-weight: 500;
      padding: 10px;
      color: inherit;

      @media screen and (min-width: 768px) {
        padding: 10px 30px;
      }

      @media screen and (min-width: 992px) {
        padding: 10px 40px;
      }
    }
  }
}
