@import 'global.variables';
@import 'global.fonts';
@import 'global.classes';
@import 'global.theme';

body.usabg-private-theme {
  &:not(.chat-enabled) {
    #si-wrapper .silc-btn {
      bottom: -125px !important;
      right: -125px !important;
      height: 0 !important;
      width: 0 !important;
      display: none !important;
    }
  }
  #mainNav,
  #mainNav .navbar-brand,
  .btn,
  h1, h2, h3, h4, h5, h6 {
    @extend .font--open-sans;
  }
  hr {
    max-width: 50px;
    border-width: 3px;
    border-color: #0f4295;
    max-width: 100% !important;
    &.light {
      border-color: #fff;
    }
  }
  .bg-primary {
    background-color: #0f4295 !important;
  }
  .bg-dark {
    background-color: #212529 !important;
  }
  .text-faded {
    color: rgba(255, 255, 255, 0.7);
  }
  ::-moz-selection,
  ::selection {
    color: #fff;
    background: #212529;
    text-shadow: none;
  }
  img::selection,
  img::-moz-selection {
    background: transparent;
  }
  section {
    padding: 8rem 0;
  }
  .section-heading {
    margin-top: 0;
  }
  #mainNav {
    border-bottom: 1px solid #fff;
    background-color: #fff;
    transition: all 0.2s;
    .navbar-brand {
      font-weight: 700;
      text-transform: uppercase;
      color: #f8202b;
      &:focus,
      &:hover {
        color: #f8202b;
      }
    }
    .navbar-nav > li.nav-item a.nav-link {
      font-size: 0.9rem;
      font-weight: 700;
      text-transform: uppercase;
      color: #212529;
      &:hover,
      &:focus {
        color: #f8202b;
      }
      &.active {
        color: #0f4295 !important;
        background-color: transparent;
        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }
    &.navbar-shrink {
      border-bottom: 1px solid rgba(33, 37, 41, 0.1);
      background-color: #fff;
      .navbar-brand,
      .navbar-brand:focus,
      .navbar-brand:hover {
        color: #f8202b;
      }
      .navbar-nav > li.nav-item a.nav-link,
      .navbar-nav > li.nav-item a.nav-link:focus {
        color: #212529;
      }
      .navbar-nav > li.nav-item a.nav-link:hover {
        color: #f8202b;
      }
    }
  }
  header.masthead {
    padding-top: 10rem;
    padding-bottom: calc(10rem - 56px);
    background-position: center center;
    background-size: cover;
    hr {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    h1 {
      font-size: 2rem;
    }
    p {
      font-weight: 300;
      @media (min-width: 768px) {
        font-size: 1.15rem;
      }
    }
    @media (min-width: 992px) {
      height: 100vh;
      min-height: 650px;
      padding-top: 0;
      padding-bottom: 0;
      h1 {
        font-size: 3rem;
      }
    }
    @media (min-width: 1200px) {
      h1 {
        font-size: 4rem;
      }
    }
  }
  .service-box {
    max-width: 400px;
  }
  .portfolio-box {
    position: relative;
    display: block;
    max-width: 650px;
    margin: 0 auto;
    &-caption {
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      opacity: 0;
      color: #fff;
      background: rgba(240, 95, 64, 0.9);
      transition: all 0.2s;
      .portfolio-box-caption-content {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        text-align: center;
        .project-category,
        .project-name {
          padding: 0 15px;
          font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
        }
        .project-category {
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
        }
        .project-name {
          font-size: 18px;
        }
      }
    }
    &:hover .portfolio-box-caption {
      opacity: 1;
    }
    &:focus {
      outline: 0;
    }
    @media (min-width: 768px) {
      .portfolio-box-caption-content {
        .project-category {
          font-size: 16px;
        }
        .project-name {
          font-size: 22px;
        }
      }
    }
  }
  .btn {
    font-weight: 700;
    &.btn-xl {
      padding: 1rem 2rem;
    }
    &.btn-primary {
      background-color: #f05f40;
      border-color: #f05f40;
      &:active,
      &:focus,
      &:hover {
        color: #fff;
        background-color: #ee4b28 !important;
      }
      &:active,
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(240, 95, 64, 0.5) !important;
      }
    }
  }
  .logo {
    height: 100px;
  }
  .bbb {
    height: 50px;
  }
  .years {
    height: 75px;
  }
  .btn-usabg {
    background-color: #0f4295 !important;
    margin-top: 50px;
    color: #fff !important;
  }
  .btn-red {
    background-color: #f8202b;
    border-color: #f8202b;
    color: #fff;
  }
  .map {
    padding: 0 !important;
  }
  .social {
    margin-left: 10px;
    i {
      padding-right: 10px;
    }
  }
  .video {
    margin-top: 120px;
    background-repeat: no-repeat;
    background-position: center;
  }
  .headerCarousel {
    position: absolute;
    right: 15%;
    top: 66%;
    width: 100%;
    left: 0;
    z-index: 10;
    color: #fff;
    text-align: left;
    padding-bottom: 0;
    h2 {
      font-size: 42px;
      font-weight: 700;
      padding-left: 35px;
    }
    h4 {
      font-size: 22px;
      font-weight: 500;
      padding-left: 35px;
    }
  }
  .blueBlock,
  .redBlock {
    float: left;
    width: 50%;
    padding: 22px;
    display: block;
    text-align: center;
    height: 100px;
    opacity: 0.7;
    a {
      color: #fff;
    }
  }
  .blueBlock {
    background-color: #0f4295;
  }
  .redBlock {
    background-color: #f8202b;
  }
  #services,
  #about {
    padding: 50px !important;
  }
  #about {
    h4,
    h5 {
      color: #fff;
    }
    ul {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  .productsSection {
    padding: 50px !important;
    .card {
      margin-top: 10px;
    }
    ul {
      list-style: none;
      a {
        color: #0f4295;

        &:hover {
          color: #f8202b;
        }
      }
    }
  }
  .productDetails {
    background: no-repeat center center / cover;
    position: relative;
    width: 100%;
    display: table;
    overflow: hidden;
    .details {
      margin-top: 1.5em;
      background: #fff;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      width: 100%;
      transition: opacity 0.3s, transform 0.3s;
      h4 {
        padding-top: 20px;
        font-size: 20px;
        font-weight: 500;
        color: #0f4295;
        cursor: pointer;
        transform: translate3d(0, -5px, 0);
        transition: opacity 0.3s, transform 0.3s;
        &:hover {
          transform: translate3d(0, 0, 0);
        }
      }
      span {
        font-size: 16px;
        transition: transform 0.3s;
      }
    }
    &:hover .details {
      opacity: 0.8;
      background: #fff;
    }
    &:hover .details h4,
    &:hover .details span {
      transform: translate3d(0, 0, 0);
    }
  }
  .whyChoose {
    margin-top: 50px;
  }
  #contact {
    i {
      float: left;
    }
    p {
      padding-left: 45px;
    }
    ul {
      list-style: none;
      margin-left: 35px;
    }
    .subInfo {
      margin-bottom: 45px;
      margin-left: 65px;
    }
  }
  .iboomPackages,
  .subPages {
    margin-top: 50px;
  }
  .page-header {
    background: rgba(0, 0, 0, 0) url('../assets/images/banner2.jpg') no-repeat center center / cover;
    text-align: center;
    text-transform: uppercase;
    opacity: 1;
    background-position: 60% 0%;
    height: 400px;
    padding: 200px 0;
    .row {
      margin-top: 25px;
    }
  }
  .iboomPkgs {
    ul {
      list-style: none;
    }
    li {
      margin-bottom: 10px;
    }
    .text-muted {
      font-size: 15px;
    }
    h5 {
      color: #7ca3c4;
    }
  }
  .btn-options {
    background-color: #fff;
    color: #000;
    border: 1px solid #d3d3d3;
    font-size: 11.5px;
    &:hover {
      background-color: black;
      color: #fff;
    }
  }
  .priceBlock {
    color: #fff;
    height: 35px;
    width: 65%;
    font-size: 21px;
    margin: auto;
    &.admin {
      background-color: #cbcbcb;
      margin-top: 177px;
    }
    &.silver {
      background-color: #808080;
      margin-top: 210px;
    }
    &.gold {
      background-color: #c6a85f;
      margin-top: 20px;
    }
    &.plat {
      background-color: #7ca3c4;
      margin-top: 84px;
    }
  }
  .bestValue {
    background-color: #e4cfa2;
    color: #fff;
    height: 35px;
    font-size: 24px;
    margin-bottom: 15px;
  }
  .totalPkg {
    font-size: 21px;
    margin-top: 43px;
  }
  .btn-option {
    &.gold {
      background-color: #c6a85f;
      color: #fff;
      &:hover {
        background-color: #fff;
        color: #c6a85f;
        border: 1px solid #c6a85f;
      }
    }
    &.silver {
      background-color: #808080;
      color: #fff;
      &:hover {
        background-color: #fff;
        color: #808080;
        border: 1px solid #808080;
      }
    }
  }
  .upgrade {
    margin-top: 15px;
  }
  .btn-purchase {
    background-color: #0f4295;
    color: #fff !important;
  }
  .silverPkg,
  .goldPkg,
  .platPkg {
    h5 {
      font-size: 18px;
      margin-top: 20px;
    }
    ul {
      color: inherit;
    }
  }
  .silverPkg {
    h5 {
      color: #808080;
    }
    ul {
      color: #808080;
    }
  }
  .goldPkg {
    h5 {
      color: #000;
    }
    ul {
      color: #000;
    }
  }
  .platPkg {
    h5 {
      color: #7ca3c4;
      font-size: 16px;
    }
    ul {
      color: #7ca3c4;
    }
  }
  footer {
    height: 100px;
    background-color: #0f4295;
    color: #fff;
    h6 {
      margin-top: 45px;
      vertical-align: middle;
    }
    a {
      text-decoration: none;
      color: inherit;
      &:hover {
        color: grey;
      }
    }
  }
  .whiteBlock {
    float: left;
    width: 50%;
    padding: 22px;
    display: block;
    background-color: #fff;
    color: #0f4295;
    opacity: 0.7;
    text-align: center;
    height: 100px;
  }
  @media (max-width: 375px) {
    #about {
      text-align: center !important;
    }
  }
  .product-box {
    display: block;
    border: 1px solid #e2e2e2;
    padding: 10px;
    margin: 10px 0;
  }  
  .product-type-header {
    display: block;
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    margin-top: 20px;
  }
  .iframe-border {
    border: 0;
  }
  .font-size16 {
    font-size: 16px;
  }
  .font-size14 {
    font-size: 14px;
  }
  .terms-cond {
    font-size: 9px;
    margin-top: 15px;
  }
  .video-tag {
    width: 100%;
    height: auto;
  }
  .p-tag-center {
    text-align: center;
  }
  .span-color {
    color: rgb(178, 34, 34);
  }
  .span-font-family {
    font-family: Arial, Helvetica, sans-serif;
  }
  .ptag-margin {
    margin-bottom: 7.5pt;
    margin: 0 0 0.0001pt;
  }
  
  .li-margin {
    margin: 0 0 0.0001pt;
  }
  .background-white {
    background: white;
  }
  .stylefcolor {
    color: #333;
  }
  .line-height18 {
    line-height: 18pt;
  }
  .pmargin {
    margin: 0 0 15pt 0;
  }
  .font-size11 {
    font-size: 11pt;
  }
  .vertical-align-top {
    vertical-align: top;
  }
  .font-familyCalibri {
    font-family: Calibri, sans-serif;
  }
  .color-black {
    color: black;
  }
  .font-size10 {
    font-size: 10pt;
  }
  .font-verdana {
    font-family: Verdana, sans-serif;
  }
  .outline0px {
    outline: 0;
  }
  .pstyle-margin {
    margin: 0;
    margin-bottom: 0.0001pt;
  }
  .bordernone, .bordernone2, .bordernone3 {
    border: none;
    padding: 0;
  }
  .bordernone {
    font-size: 10pt;
  }
  .bordernone2 {
    font-size: 12pt;
  }
  .bordernone3 {
    font-size: 10.5pt;
  }
  .margin0px {
    margin: 0 0 0.0001pt;
  }
  .margin-top50px {
    margin-top: 50px;
  }
  .margin-bottom10px {
    margin-bottom: 10px;
  }
  #mainNav {
    .navbar-nav {
      > li.nav-item {
        > a.nav-link,
        > a.nav-link:focus,
        .dropdown a.nav-link,
        .dropdown a.nav-link span {
          color: #0f4295;

          &:hover,
          &:focus:hover {
            color: #f8202b;
          }
        }
      }
    }
  }
  header {
    margin-bottom: 115px;
  }
  .signAgent {
    height: 60px;
  }
  .colImage {
    margin: 15px 0 20px;
  }
  .bannerLinks {
    margin-top: 15px;
    padding: 0 15px;
  }
  .whyUsabg, .ourMission {
    padding: 50px !important;
  }
  .blueBlock2, .redBlock2 {
    color: white;
    height: 65px;
    text-align: center;
    padding-top: 10px;
    border-right: 2px solid white;
    opacity: 0.7;
  }
  .blueBlock2 {
    background-color: #0f4295;
  }
  .redBlock2 {
    background-color: #f8202b;
  }
  #resources a {
    color: #000;
  }
  .middleNav a {
    color: white;
  }
  #productsContainer img {
    margin-top: 10px;
  }
  .productHr {
    min-width: 100% !important;
    border-width: 3px;
    border-color: #0f4295;
  }
  .aboutAgent {
    padding: 45px 0 55px;
    a {
      margin-bottom: 25px;
    }
    img {
      margin-top: 25px;
    }
  }
  .whiteIcon {
    color: #fff;
  }
  .dropdown-content.fadeIn {
    display: block;
  }
  .testimonial-card {
    .avatar {
      height: 120px;
      object-fit: cover;
      img {
        width: 100%;
        height: 120px;
        object-fit: cover;
      }
    }
  }
  .customDatepickerZIndex {
    z-index: 11;
  }
  .side-slide-exit-done {
    width: 240px;
    transform: translateX(-100%);
  }
  .side-slide-enter-done {
    width: 240px;
    transform: translateX(0);
  }
  .placeholder-color-white + label {
    color: #fff !important;
  }
  .background-color-white {
    background-color: #fff !important;
  }
  .indigo-skin {
    .navbar {
      color: #fff;
      background-color: #29448b;
    }
  }
  .indigo-color {
    background-color: #29448b !important;
  }
  .card .card-body .card-text {
    font-size: 0.9rem;
    font-weight: 400;
    color: #000;
  }
  .ba-team-logo {
    border: 1px solid transparent;
    border-radius: 0.25rem;
    overflow: hidden;
    img {
      max-width: 100%;
      aspect-ratio: 1;
      object-fit: contain;
    }
  }
}
