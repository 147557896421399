@import './../../../../styles/global.variables';

#CarrierContractLinkBankComponent {
  position: relative;

  div.lockout-window {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 0.3rem;
  }

  form {
    button.btn-submit {
      margin: 0;
    }
    button.btn-cancel {
      margin: 0;
    }

    .contract-link-setter {
      border: thin solid rgba(220, 220, 220, 0.8);
      border-radius: 0.5rem;
      padding-left: 0;
      padding-right: 0;
      margin: 0 10px 5px 10px;

      & > label {
        background-color: $color--usabg--blue;
        width: 100%;
        padding: 2px 4px;
        color: #fff;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;

        small {
          float: right;
        }
      }
    }
  }
}
