#HomeVideoBannerComponent {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: rgb(26, 35, 126);

	.scale-container {
		position: relative;
		min-height: 200px;
	}

 	.video-container {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
		max-width: 100%;
		height: 500px;
		overflow: hidden;

    .banner-page-title {
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;
      display: flex;
      align-items: center;
      font-size: 74px;
      font-weight: 500;
      color: #FFF;
      text-shadow: 1px 1px #000, -1px -1px #000, 0px 0px 3px #000000;

      span {
        margin: auto;
      }
    }

  	iframe {
  		transform: translateY(-70px);
  		width: 100%;
  	}
 	}

	@media screen and (max-width: 575px) {
		margin-top: 140px;

		.scale-container {
			min-height: 260px;
		}

		.video-container {
			height: 260px;

		  iframe {
		  	height: 400px;
		  }
		}
	}

	@media screen and (min-width: 576px) {
		margin-top: 120px;

		.scale-container {
			min-height: 300px;
		}
		.video-container {
			height: 300px;

		  iframe {
		  	height: 440px;
		  }
		}
	}

	@media screen and (min-width: 768px) {
		.scale-container {
			min-height: 400px;
		}
		.video-container {
			height: 400px;

		  iframe {
		  	transform: translateY(-80px);
		  	height: 560px;
		  }
		}
	}

	@media screen and (min-width: 992px) {
		.scale-container {
			min-height: 500px;
		}
		.video-container {
			height: 500px;

		  iframe {
		  	height: 660px;
		  }
		}
	}

	@media screen and (min-width: 1024px) {

	}

	@media screen and (min-width: 1100px) {
		.scale-container {
			min-height: 450px;
		}
		.video-container {
			height: 450px;
		  iframe {
		  	transform: translateY(-150px);
		  	height: 760px;
		  }
		}
	}

	@media screen and (min-width: 1300px) {
		.scale-container {
			min-height: 500px;
		}
		.video-container {
			height: 500px;
		  iframe {
		  	height: 850px;
		  }
		}
	}

	@media screen and (min-width: 1500px) {
		.video-container {
		  iframe {
		  	height: 975px;
		  }
		}
	}

	@media screen and (min-width: 1700px) {
		.scale-container {
			min-height: 550px;
		}
		.video-container {
			height: 550px;
		  iframe {
		  	transform: translateY(-195px);
		  	height: 1100px;
		  }
		}
	}

	@media screen and (min-width: 1900px) {
		.video-container {
		  iframe {
		  	transform: translateY(-215px);
		  	height: 1175px;
		  }
		}
	}

	@media screen and (min-width: 2100px) {
		.scale-container {
			min-height: 575px;
		}
		.video-container {
			height: 575px;
		  iframe {
		  	transform: translateY(-245px);
		  	height: 1300px;
		  }
		}
	}

	@media screen and (min-width: 2300px) {
		.scale-container {
			min-height: 600px;
		}
		.video-container {
			height: 600px;
		  iframe {
		  	transform: translateY(-275px);
		  	height: 1450px;
		  }
		}
	}
}