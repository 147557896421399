#ViewModeSelectorComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 120px;

  & > label {
    font-size: 13px;
    font-weight: 500;
    opacity: 0.8;
    line-height: 15px;
    margin: 0 0 4px 0;
  }

  @media screen and (max-width: 575px) {
    max-width: 20rem;
    margin: auto;
    margin-top: 0.5rem;

    & > label {
      margin-right: 0.5rem;
    }
  }
}
