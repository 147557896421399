@import './../../../styles/global.variables';

.ExternalCarrierToggleComponent {
  & > span.uec-spinner {
    display: inline-block;
    text-align: right;
    width: 25px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.2s;
  }

  & > div.switch {
    display: inline-block;
    opacity: 1;
    transition: opacity 0.2s;

    & > label {
      padding: 0 4px;
      margin: 0 4px;

      & > span.lever {
        margin: 0;
        width: 1.75rem;

        &:after {
          width: 1rem;
          height: 1rem;
          border-radius: 1rem;
          top: -1px;
        }
      }

      & > input[value='true'] + span.lever {
        &:after {
          left: 1.25rem;
        }
      }
    }
  }

  &.is-disabled,
  &.is-toggling {
    & > div.switch {
      opacity: 0.5;

      & > label {
        cursor: default;
      }
    }
  }

  &.is-toggling {
    & > span.uec-spinner {
      opacity: 1;
    }
  }
}
