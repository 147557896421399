@import 'src/styles/global.variables';

#SocialMediaFormComponent {
  background-color: rgba(255, 255, 255, 0.25);
  border: 1px solid $color--sky-blue;
  padding: 0.75rem;
  border-radius: 0.5rem;

  .ContentHtmlComponent > .content-html-inner-wrapper {
    padding: 0;
  }

  div.form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    .quote-icon {
      font-size: 3.75rem;
      max-height: 4rem;
      color: $color--usabg--blue;
      flex: 0 0 100%;
      max-width: 100%;
      align-items: center;
      margin-bottom: 0.5rem;

      .fa {
        margin: 0;
        padding: 0;
        display: block;
      }
    }

    .quote-label {
      font-size: 1rem;
      line-height: 1.25rem;
      text-transform: uppercase;
      color: $color--usabg--blue;
      max-height: 3.5rem;
      flex: 0 0 100%;
      max-width: 100%;
      flex-grow: 1;
      align-items: center;
      font-weight: 500;
    }
  }

  @media screen and (max-width: 991px) {
    .quote-icon {
      font-size: 2.75rem;
      max-height: 3rem;
    }
    .quote-label {
      font-size: 1rem;
      line-height: 1.25rem;
      max-height: 2.75rem;
    }
  }
}
