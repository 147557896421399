@import './../../../styles/global.variables';

.ContentVideoComponent {
  position: relative;

  & > .card-header {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.25px;
  }
}
