@import 'src/styles/global.variables';

#BeastModeAccountLinkComponent {
	.card-header {
		background-color: $color--usabg--blue !important;
		text-align: center;
		color: #FFF;
		font-weight: 500;
	}

	.card-body {
		.save-btn {
			background-color: $color--success !important;
			border: thin solid blue;
			height: 2rem;
			line-height: 2rem;
			padding: 0;
			width: calc(100% - 1rem);
		}
	}
}