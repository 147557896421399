@import 'src/styles/global.variables';

#KbAdminComponent {
  h6 {
    color: $color--usabg--blue;
    margin: 2px;
  }

  & > div.content-wrapper {
    padding: 0;

    .cat-row {
      .cat-list {
        transition-property: width, max-width, flex, padding;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
      }

      .cat-form {
        padding-top: 0;
        transition-property: width, max-width, flex, padding;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
      }

      &.form-is-open {
        .cat-form {
          padding-top: 2rem;
        }
      }
    }
  }
}
