@import './../../styles/global.variables';

#AuthPage {
  .page-inner {
    .auth-head {
    }

    .auth-body {
      .form-group {
        input {
          text-align: center;
        }
      }

      .submit-wrapper {
        background-color: #0f4295;

        button.btn {
          width: calc(100% - 8px);
          max-width: calc(100% - 8px);
          margin-bottom: 5px;
        }
      }

      .help-link {
        color: #d3d3d3;

        a {
          color: #fff;

          &:hover {
            color: #fff;
            text-decoration: underline;
          }
        }
      }

      @media screen and (min-width: 768px) {
        padding-bottom: 150px;
      }
    }

    .auth-foot {
      a {
        color: #fff;
        margin-left: 15px;
      }
    }
  }
}
