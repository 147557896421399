@import './../../../styles/global.variables';

.UpdatePaymentMethodModalComponent {
  .modal {
    .modal-lg {
      margin-left: calc(10vw + 1rem);
      width: calc(80vw - 2rem);
      max-width: calc(80vw - 2rem);
    }

    .modal-body {
      position: relative;
      padding: 0;

      & > .loading-wrapper {
        height: 0;
        padding-top: 0;
        transition-property: height, opacity, visibility, padding-top;
        transition-duration: 0.25s;
        transition-timing-function: ease-in-out;
        opacity: 0;
        visibility: hidden;
      }

      &.is-loading {
        & > .loading-wrapper {
	        height: 200px;
	        padding-top: 50px;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
