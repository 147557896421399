.RequestFormComponent {
  .container-fluid {
    background-color: rgb(220, 220, 220);
    border-radius: 1rem;
    margin: 0px -15px;
    padding: 2rem 0;

    .sub-head-small {
      font-weight: bold;
    }
  }
}
