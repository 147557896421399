@import './../../styles/global.variables';

#BeastModeSignUpPage {
  padding-bottom: 4rem;

  .content-wrapper {
    height: 100%;

    h5 {
      font-weight: 500;
      text-align: center;
    }
  }
}
