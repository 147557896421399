@import './../../../../styles/global.variables';

#EventViewerComponent {
  .modal-fluid {
    width: 80%;
  }

  .modal-header {
    padding: 25px;
    position: relative;

    .modal-title {
      font-weight: bold;
    }

    .delete-btn {
      position: absolute;
      bottom: -1.75rem;
      bottom: 0.125rem;
      right: 0;
      left: 0;
      outline: none;
      margin: auto;
      display: inline-block;
      color: $color--invalid;
      background-color: rgb(225, 225, 225);
      border: 1px solid $color--invalid;
      border-radius: 4px;
      font-size: 0.8rem;
      font-weight: 500;
    }

    &.text-white > button {
      color: rgba(255, 255, 255, 0.8);

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .cal-add-btn > div {
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }

  .cal-dates,
  .cal-description,
  .cal-add-btn {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 10px;
    margin-bottom: 20px;

    strong {
      font-weight: bold;
    }

    .content {
      padding-left: 10px;
    }
  }

  .cal-summary {
    display: flex;

    .cal-dates {
      flex: 0 0 calc(100% - 30rem);
      max-width: calc(100% - 30rem);

      .cal-date-boundry {
        .cal-label {
          width: 90px;
          display: inline-block;
        }
        .cal-time {
          width: 90px;
          display: inline-block;
        }
      }
    }

    .cal-add-btn {
      flex: 0 0 30rem;
      max-width: 30rem;
    }

    &.can-add-event {
      .cal-add-btn > div {
        opacity: 1;
      }
    }
  }

  .event-footer {
    button.btn {
      width: calc(100% - 15px);
    }
  }

  @media screen and (max-width: 991px) {
    .modal-fluid {
      width: 95%;
    }

    .cal-summary {
      flex-wrap: wrap;

      .cal-dates,
      .cal-add-btn {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
