@import 'src/styles/global.variables';

#stickyNavbar {
  @media (max-width: 991px) and (min-width: 768px) {
    .rd-navbar-panel {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  .rd-navbar-statement {
    color: #999;
    font-size: 10px;
  }

  .cta-container {
    display: inline-flex;
    margin-left: auto;

    @media (min-width: 992px) {
      display: none;
    }

    @media (max-width: 767px) {
      display: none;
    }

    .cta-wrapper {
      display: inline-block;
    }
  }

  .cta-wrapper {
    display: flex;
    background-color: $color--usabg--red;
    height: auto;
    align-items: center;
    border: 1px solid transparent;
    overflow: hidden;
    border-radius: 0.4rem;
    text-align: center;

    @media (max-width: 991px) and (min-width: 768px) {
      display: none;
    }

    a {
      display: block;
      font-size: 16px;
      line-height: 28px;
      color: $color--usabg--blue;
      font-weight: 700;
      text-align: center;

      span.phone-number {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        display: block;
      }
    }
  }

  #menuBar {
    .rd-navbar-nav {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 991px) {
        display: block;
      }

      li {
        &.supplemental {
          text-align: center;
          padding-bottom: 1rem;

          img {
            margin-bottom: 1rem;
          }
        }

        & > ul.rd-navbar-dropdown {
          z-index: 125;
        }

        @media screen and (max-width: 991px) {
          &.supplemental {
            & > div {
              &.cta-wrapper {
                a {
                  flex-grow: 1;
                }
              }

              &:nth-of-type(3) {
                flex-grow: 1;
              }
            }
          }
        }

        @media screen and (min-width: 992px) {
          &:nth-of-type(1),
          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(4),
          &:nth-of-type(5),
          &:nth-of-type(6) {
            flex: 0 0 calc(100% / 6);
            max-width: calc(100% / 6);
            margin: 0;
            text-align: right;

            & > a {
              margin-left: auto;
            }

            ul.rd-navbar-dropdown {
              & > li {
                width: 100%;
                max-width: 100%;
                text-align: left;

                & > a {
                  display: block;
                }

                & + li {
                  margin-top: 1rem;
                }
              }
            }
          }

          &.supplemental {
            display: flex;
            flex-wrap: nowrap;
            padding-top: 2rem;
            margin-left: auto;

            .yearsField {
              display: flex;
              align-items: center;
              padding-right: 2rem;
              padding-left: 1.5rem;

              img {
                width: 140px;
                max-height: fit-content;
                margin-bottom: 0px;
              }
            }

            & > div {
              flex-grow: 1;
              max-height: 3.5rem;

              img {
                max-height: 100%;
              }

              .cta-wrapper {
                a {
                  font-size: 24px;
                  line-height: 22px;

                  span.phone-number {
                    font-size: 20px;
                  }
                }
              }

              &:nth-of-type(3) {
                flex-grow: 1;
              }
            }
          }
        }
      }
    }
  }
}
